import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import { isMobile } from "../../js/designManager";
//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS

// ------------- CONST & PAGES ------------
import { drawerWidth, drawerWidthMobile } from "./cockpitDrawer";
import AdminDashboardIntegrationsPage from "../admin_integrations/AdminDashboardIntegrationsPage";
import AdminRestaurantPage from "../admin_restaurants/AdminRestaurantPage";
import PortalRestaurantsPage from "../portal_restaurants/PortalRestaurantsPage";
import AdminUsersPage from "../admin_users/AdminUsersPage";
import AdminAnomaliesPage from "../admin_anomalies/AdminAnomaliesPage";
import MagnetMacroCampaignsPage from "../magnet_campaigns/MagnetMacroCampaignsPage";
import JetpackCampaignsAdminPage from "../jetpack_campaigns_admin/JetpackCampaignsAdminPage";
import JetpackCampaignsPlatformPage from "../jetpack_campaigns_platform/JetpackCampaignsPlatformPage";
import RadarClassicPage from "../radar_format_classic/RadarClassicPage";
import RadarMainDashPage from "../radar_main_dash/RadarMainDashPage";
import RadarLeaderBoardPage from "../radar_leaderboard/RadarLeaderBoardPage";
import DebugPage from "../admin_dev/DebugPage";
import JetpackCampaignsRestaurantPage from "../jetpack_campaigns_restaurant/JetpackCampaignsRestaurantPage";
import JetpackPerformanceRestaurantV2Page from "../jetpack_performance_restaurant_page/JetpackPerformanceRestaurantV2Page";
import JetpackPerformanceRestaurantPage from "../jetpack_performance_restaurant_page/JetpackPerformanceRestaurantPage";
import MaintenancePage from "../errors/MaintenancePage";
import RadarCustomersPage from "../radar_customers/RadarCustomersPage";
import AdminSettingsRestaurantPage from "../admin_settings_restaurants/AdminSettingsRestaurantPage";
import RadarScrappingsPage from "../radar_scrappings/RadarScrappingsPage";
import RadarCompetitorsPage from "../radar_competitors/RadarCompetitorsPage";
import RadarMarketSharesPage from "../radar_marketshares/RadarMarketSharesPage";
import MagnetRestaurantPage from "../magnet_restaurants/MagnetRestaurantPage";
import RadarHealthPage from "../radar_healthboard/RadarHealthPage";
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    transition: "all 500ms ease-in-out",
    overflow: "auto",
    position: "fixed",
    top: theme.spacing.unit * 8,
    height: "calc(100% - 64px)", // Subtract width of header
    backgroundColor: theme.palette.background.default,
    width: "100%",
  },
  contentLeft: {
    transition: "all 400ms ease",
    marginLeft: `${isMobile() ? drawerWidthMobile : drawerWidth}px`,
    width: `calc(100% - ${isMobile() ? drawerWidthMobile : drawerWidth}px)`,
  },
  content: {
    padding: theme.spacing(3),
  },
}));

function CockpitMain(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { cockpitDrawerOpen, cockpitView, constants } = useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayContent = () => {
    if (constants && constants.MAINTENANCE_FRONTEND === "TRUE") {
      return <MaintenancePage />;
    }
    console.log(
      "constants.MAINTENANCE_FRONTEND",
      constants ? constants.MAINTENANCE_FRONTEND : ""
    );
    switch (cockpitView) {
      case "portal_restaurant":
        return <PortalRestaurantsPage />;
      case "admin_integrations":
        return <AdminDashboardIntegrationsPage />;
      case "admin_restaurants":
        return <AdminRestaurantPage />;
      case "admin_settings_restaurants":
        return <AdminSettingsRestaurantPage />;
      case "admin_anomalies":
        return <AdminAnomaliesPage />;
      case "admin_users":
        return <AdminUsersPage />;
      case "admin_dev":
        return <DebugPage />;
      case "magnet_macroCampaigns":
        return <MagnetMacroCampaignsPage />;
      case "magnet_restaurants":
        return <MagnetRestaurantPage />;
      case "jetpack_campaigns_admin":
        return <JetpackCampaignsAdminPage />;
      case "jetpack_campaigns_platforms":
        return <JetpackCampaignsPlatformPage />;
      case "jetpack_performance_admin":
        return <JetpackPerformanceRestaurantPage />;
      case "jetpack_campaigns_restaurant":
        return <JetpackCampaignsRestaurantPage />;
      case "jetpack_performance_restaurant":
        return <JetpackPerformanceRestaurantV2Page />;
        return <JetpackPerformanceRestaurantPage />;
      case "radar_dashboard":
        return <RadarMainDashPage />;
      case "radar_health":
        return <RadarHealthPage />;
      case "radar_leaderboard":
        return <RadarLeaderBoardPage />;
      case "radar_overview":
        return <RadarClassicPage page="overview" />;
      case "radar_conversion":
        return <RadarClassicPage page="conversion" />;
      case "radar_ranking":
        return <RadarClassicPage page="ranking" />;
      case "radar_sponsoring":
        return <RadarClassicPage page="sponsoring" />;
      case "radar_downtimes":
        return <RadarClassicPage page="downtimes" />;
      case "radar_scrappings":
        return <RadarScrappingsPage />;
      case "radar_marketshares":
        return <RadarMarketSharesPage />;
      case "radar_competitors":
        return <RadarCompetitorsPage />;
      case "radar_customers":
        return <RadarCustomersPage />;
      default:
        return <div>Arrive bientôt...</div>;
    }
  };

  return (
    <div
      className={classNames(classes.contentWrapper, {
        [classes.contentLeft]: cockpitDrawerOpen,
      })}
    >
      <div className={classes.content}>{displayContent()}</div>
    </div>
  );
}

CockpitMain.propTypes = {};

export default CockpitMain;
