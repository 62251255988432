import moment from "moment";
import _ from "lodash";
import { capitalizeFirstLetter } from "./stringManager";

//return a object with below fields:
//-actionType
//-customerTarget
//-actionDetails
export const getCampaignsDetailsFromJetpack = (jetpackCampaign, constants) => {
  var customersTarget = "Tous clients";
  var actionType = "";
  var actionDetails = [];

  try {
    var actionsValueObject =
      jetpackCampaign.actions &&
      jetpackCampaign.actions.length > 0 &&
      jetpackCampaign.actions[0].valueObject
        ? JSON.parse(jetpackCampaign.actions[0].valueObject)
        : {};
    if (actionsValueObject && actionsValueObject.target) {
      try {
        customersTarget = _.find(constants.jetpack_customersTargets, [
          "id",
          actionsValueObject.target,
        ]).label;
      } catch (err) {
        customersTarget = "Tous clients";
      }
    }

    try {
      actionType = _.find(constants.jetpack_actionTypes, [
        "id",
        jetpackCampaign.actions[0].type,
      ]).title;
    } catch (err) {
      console.log(
        "#BUG constants.jetpack_actionTypes",
        constants.jetpack_actionTypes
      );
      console.log(
        "#BUG jetpackCampaign.actions[0].type",
        jetpackCampaign.actions[0].type
      );
      actionType = "(erreur de chargement)";
    }

    switch (jetpackCampaign.actions[0].type) {
      case "free_item":
        if (
          actionsValueObject.freeItems &&
          actionsValueObject.freeItems.length > 0
        ) {
          actionsValueObject.freeItems.map((item) => {
            actionDetails.push(
              item.title + " - " + actionsValueObject.minAmount + "€"
            );
          });
        }
        break;
      case "discount_item":
        if (
          actionsValueObject.discountItems &&
          actionsValueObject.discountItems.length > 0
        ) {
          if (actionsValueObject.discount_item_or_category === "CATEGORY") {
            actionDetails.push(
              actionsValueObject.discount_category_label +
                " - " +
                actionsValueObject.discount_category_percent +
                "%"
            );
          } else {
            actionsValueObject.discountItems.map((item) => {
              actionDetails.push(item.title + " - " + item.discount + "%");
            });
          }
        }
        break;
      case "free_delivery":
        actionDetails.push(
          "Budget max: " +
            (actionsValueObject.free_delivery_max_budget < 0
              ? "illimité"
              : actionsValueObject.free_delivery_max_budget + "€")
        );
        break;
      case "bogo":
        if (
          actionsValueObject.bogoItems &&
          actionsValueObject.bogoItems.length > 0
        ) {
          actionsValueObject.bogoItems.map((item) => {
            actionDetails.push(item.title);
          });
        }
        break;
      case "discount":
        if (actionsValueObject.discount_type === "FLAT_15_3") {
          actionDetails.push("3€/15€");
        } else if (actionsValueObject.discount_type === "FLAT_20_5") {
          actionDetails.push("5€/20€");
        } else if (actionsValueObject.discount_type === "FLAT_30_8") {
          actionDetails.push("8€/30€");
        }
        break;
      case "sponsoring":
        actionDetails.push(
          "Budget max: " +
            (actionsValueObject.sponsoring_budget < 0
              ? "illimité"
              : actionsValueObject.sponsoring_budget + "€")
        );
        break;
      default:
    }
  } catch (err) {
    console.log("En error occured during getCampaignsDetailsFromJetpack", err);
  }

  return {
    customersTarget,
    actionType,
    actionDetails,
  };
};

export const generateAgendaDescriptionForJetpack = (jetpackCampaign) => {
  var descriptions = ["À certains moment de la semaine"];
  var localDescriptionObjectArray = [];
  if (
    jetpackCampaign &&
    jetpackCampaign.constraints &&
    jetpackCampaign.constraints.length > 0
  ) {
    if (jetpackCampaign.constraints[0].type === "permanent") {
      descriptions = ["Tout le temps"];
    } else {
      var valueObject = jetpackCampaign.constraints[0].valueObject
        ? JSON.parse(jetpackCampaign.constraints[0].valueObject)
        : {};
      var schedule =
        valueObject.schedule &&
        valueObject.schedule.length > 0 &&
        valueObject.schedule[0].schedule &&
        valueObject.schedule[0].schedule.length > 0
          ? valueObject.schedule[0].schedule
          : [];

      if (schedule.length < 1) {
        descriptions = [];
      } else {
        var startTime = moment().format("LT");
        var endTime = moment().format("LT");
        var lastTRDescription = "";
        var firstDayWithSameTRDescription = "";
        descriptions = [];
        var finishedYesterdayAtMidnight = false;
        //Check if finished Sunday at midnigtht
        if (
          schedule &&
          schedule.length >= 7 &&
          schedule[6].time_periods &&
          schedule[6].time_periods.length > 0 &&
          schedule[6].time_periods[schedule[6].time_periods.length - 1]
            .end_time === "24:00"
        ) {
          finishedYesterdayAtMidnight = true;
        }
        schedule.map((day, indexDay) => {
          if (!day.time_periods || day.time_periods.length < 1) {
            return null;
          }
          var tmpDescription = "";
          var tmpDayInLocalLanguage = translateDay(day.day_of_week);
          var recordedTR = 0;
          day.time_periods.map((tr, indexTR) => {
            if (finishedYesterdayAtMidnight && tr.start_time === "0:00") {
              return null;
            }

            var tmpStartTime = tr.start_time;
            var tmpEndTime = tr.end_time;
            //if finishing at 24:00 let's check next day starting
            if (tmpEndTime === "24:00") {
              finishedYesterdayAtMidnight = true;
              var nextDayIndex =
                indexDay === schedule.length - 1 ? 0 : indexDay + 1;

              if (
                schedule[nextDayIndex].time_periods &&
                schedule[nextDayIndex].time_periods.length > 0 &&
                schedule[nextDayIndex].time_periods[0].start_time === "0:00"
              ) {
                tmpEndTime = schedule[nextDayIndex].time_periods[0].end_time;
              }
            }

            tmpDescription =
              tmpDescription +
              (recordedTR > 0 ? " et " : "") +
              tmpStartTime +
              " - " +
              tmpEndTime;
            recordedTR = recordedTR + 1;
          });
          if (lastTRDescription === tmpDescription) {
            localDescriptionObjectArray[
              localDescriptionObjectArray.length - 1
            ].days = `Du ${firstDayWithSameTRDescription} au ${tmpDayInLocalLanguage}`;
          } else {
            localDescriptionObjectArray.push({
              days: capitalizeFirstLetter(tmpDayInLocalLanguage),
              time: tmpDescription,
            });
            firstDayWithSameTRDescription = tmpDayInLocalLanguage;
            lastTRDescription = tmpDescription;
          }
        });
        localDescriptionObjectArray.map((object) => {
          descriptions.push(object.days + " : " + object.time);
        });
      }
    }
  } else {
    descriptions = ["Tout le temps"];
  }
  return descriptions;
};

export const generateGlobalScheduleDescriptionForJetpack = (
  jetpackCampaign
) => {
  var description = "À certains moments";
  var isAllLunchesOnly = true;
  var isAllDinersOnly = true;
  var isAllLunchesWeekOnly = true;
  var isAllDinersWeekOnly = true;
  var isAllLunchesWeekendOnly = true;
  var isAllDinersWeekendOnly = true;
  var isWeekOnly = true;
  var isWeekendOnly = true;
  if (
    jetpackCampaign &&
    jetpackCampaign.constraints &&
    jetpackCampaign.constraints.length > 0
  ) {
    var valueObject = jetpackCampaign.constraints[0].valueObject
      ? JSON.parse(jetpackCampaign.constraints[0].valueObject)
      : {};
    var schedule =
      valueObject.schedule &&
      valueObject.schedule.length > 0 &&
      valueObject.schedule[0].schedule &&
      valueObject.schedule[0].schedule.length > 0
        ? valueObject.schedule[0].schedule
        : [];

    if (schedule.length < 1) {
      description = "Tout le temps";
    } else {
      schedule.map((day, indexDay) => {
        /*
              isAllLunchesOnly = true;
              isAllDinersOnly = true;              
              isAllLunchesWeekOnly = true;
              isAllDinersWeekOnly = true;
              isAllLunchesWeekendOnly = true;
              isAllDinersWeekendOnly = true;
              isWeekOnly = true;
              isWeekendOnly = true;

          */
        if (isLunchWhiteList(day.time_periods)) {
          isAllDinersOnly = false;
          isAllDinersWeekOnly = false;
          isAllDinersWeekendOnly = false;
          if (isFriday(day.day_of_week) || isWeekDay(day.day_of_week)) {
            isAllLunchesWeekendOnly = false;
            isWeekendOnly = false;
          } else {
            isAllLunchesWeekOnly = false;
            isWeekOnly = false;
          }
        } else {
          isAllLunchesOnly = false;
          if (isFriday(day.day_of_week) || isWeekDay(day.day_of_week)) {
            isAllLunchesWeekOnly = false;
            isWeekOnly = false;
          } else {
            isAllLunchesWeekendOnly = false;
            isWeekendOnly = false;
          }
        }
        if (isDinnerWhiteList(day.time_periods)) {
          isAllLunchesOnly = false;
          isAllLunchesWeekOnly = false;
          isAllLunchesWeekendOnly = false;
          if (isFriday(day.day_of_week) || isWeekendDay(day.day_of_week)) {
            isAllDinersWeekOnly = false;
            isWeekOnly = false;
          } else {
            isAllDinersWeekendOnly = false;
            isWeekendOnly = false;
          }
        } else {
          isAllDinersOnly = false;
          if (isFriday(day.day_of_week) || isWeekendDay(day.day_of_week)) {
            isAllDinersWeekendOnly = false;
            isWeekendOnly = false;
          } else {
            isAllDinersWeekOnly = false;
            isWeekOnly = false;
          }
        }
      });
      if (isAllLunchesOnly) {
        description = "Tous les midis";
      } else if (isAllDinersOnly) {
        description = "Tous les soirs";
      } else if (isAllLunchesWeekOnly) {
        description = "Midis semaine";
      } else if (isAllDinersWeekOnly) {
        description = "Soirs semaine";
      } else if (isAllLunchesWeekendOnly) {
        description = "Midis week-end";
      } else if (isAllDinersWeekendOnly) {
        description = "Soirs week-end";
      } else if (isWeekOnly) {
        description = "En semaine";
      } else if (isWeekendOnly) {
        description = "Le week-end";
      }
    }
  } else {
    description = "Tout le temps";
  }
  return description;
};

export const isWeekDay = (day) => {
  switch (day) {
    case "monday":
    case "tuesday":
    case "wednesday":
    case "thursday":
      return true;
    case "friday":
    case "saturday":
    case "sunday":
      return false;
    default:
      return false;
  }
};

export const isWeekendDay = (day) => {
  switch (day) {
    case "monday":
    case "tuesday":
    case "wednesday":
    case "thursday":
    case "friday":
      return false;
    case "saturday":
    case "sunday":
      return true;
    default:
      return false;
  }
};

export const isFriday = (day) => {
  return day === "friday";
};

export const isLunchWhiteList = (TR) => {
  var toReturn = false;
  if (!TR || TR.length === 0) {
    return toReturn;
  } else {
    TR.map((tr) => {
      var hourStart = tr.start_time.split(":")[0];
      var hourEnd = tr.end_time.split(":")[0];
      if (hourStart > 7 && hourStart <= 12 && hourEnd >= 14) {
        toReturn = true;
      }
    });
    return toReturn;
  }
};

export const isDinnerWhiteList = (TR) => {
  var toReturn = false;
  if (!TR || TR.length === 0) {
    return toReturn;
  } else {
    TR.map((tr) => {
      var hourStart = tr.start_time.split(":")[0];
      var hourEnd = tr.end_time.split(":")[0];
      if (hourStart <= 20 && hourEnd >= 23) {
        toReturn = true;
      }
    });
    return toReturn;
  }
};

const translateDay = (day) => {
  switch (day) {
    case "monday":
      return "lundi";
    case "tuesday":
      return "mardi";
    case "wednesday":
      return "mercredi";
    case "thursday":
      return "jeudi";
    case "friday":
      return "vendredi";
    case "saturday":
      return "samedi";
    case "sunday":
      return "dimanche";
    default:
      return "n/a";
  }
};
