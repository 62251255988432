import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//MATERIAL UI COMPONENT IMPORTS
import { Button, Grid, Divider } from "@mui/material";
import ModalNewIntegration from "./ModalNewIntegration";
import CardIntegrations from "./CardIntegrations";
import ModalGetDateForOrdersHistory from "./ModalGetDateForOrdersHistory";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

//[Cockpit main page] This page aims to display Uber Eats and Deliveroo Dashboard integration. Letting user create and edit some.
function AdminDashboardIntegrationsPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [platforms, setPlatforms] = useState([]);
  const [platformLogins, setPlatformLogins] = useState([]);
  const [adsPlatformLogins, setAdsPlatformLogins] = useState([]);
  const [apiLogins, setApiLogins] = useState([]);
  const [selectedPlatformLoginId, setSelectedPlatformLoginId] = useState("");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    //ONLY ONE TIME //TO TO : GATHER API CALL FOR EACH PAGE IN A INIT API ENDPOINT
    init();
  }, []);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTION *******************
  // *******************************************************

  const init = () => {
    GET("/api/pages/admindashboardintegrationspage/init")
      .then((response) => {
        //displayNotification("success", "Chargement réussi", "top", "center");
        setPlatforms(response.data.platforms);
        setPlatformLogins(response.data.platformLogins);
        setAdsPlatformLogins(response.data.adsPlatformLogins);
        setApiLogins(response.data.apiLogins)
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  return (
    <div className={classes.div}>
      <Button
        variant="contained"
        onClick={() => {
          setDialogsOpen({ ...dialogsOpen, ModalNewIntegration: true });
        }}
        startIcon={<AddCircleOutlineIcon />}
      >
        Ajouter
      </Button>
      <Divider sx={{ mt: 2 }}>Restaurants Manager</Divider>
      <Grid container spacing={2} mt={1}>
        {platformLogins.map((platformLogin) => {
          return (
            <Grid key={platformLogin.id} item xs={12} sm={12} md={6} lg={4}>
              <CardIntegrations
                platformLogin={platformLogin}
                initPage={init}
                loginType="Restaurant manager"
                handleSelectedPlatformLogin={(value) => {
                  var newValueId = value.id;
                  setSelectedPlatformLoginId(newValueId);
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <Divider sx={{ mt: 2 }}>Ads Manager</Divider>
      <Grid container spacing={2} mt={1}>
        {adsPlatformLogins.map((adsPlatformLogin) => {
          return (
            <Grid key={adsPlatformLogin.id} item xs={12} sm={12} md={6} lg={4}>
              <CardIntegrations
                key={"cardIntegrations_" + adsPlatformLogin.id}
                platformLogin={adsPlatformLogin}
                loginType="AdsManager"
                initPage={init}
              />
            </Grid>
          );
        })}
      </Grid>
      <Divider sx={{ mt: 2 }}>API Connexion</Divider>
      <Grid container spacing={2} mt={1}>
        {apiLogins.map((apiLogin) => {
          return (
            <Grid key={apiLogin.id} item xs={12} sm={12} md={6} lg={4}>
              <CardIntegrations
                key={"cardIntegrations_" + apiLogin.id}
                platformLogin={apiLogin}
                loginType="API"
                initPage={init}
              />
            </Grid>
          );
        })}
      </Grid>
      <ModalNewIntegration platforms={platforms} initPage={init} />
      <ModalGetDateForOrdersHistory
        initPage={init}
        platformLoginId={selectedPlatformLoginId}
      />
    </div>
  );
}

//AdminDashboardIntegrationsPage.propTypes = {};

export default AdminDashboardIntegrationsPage;
