import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
//import {GET,POST} from '../../js/HTTPRequest'
//import Error from "../../components/errors/Error"
//import {handleError} from '../../js/ErrorManager'
//import {toast} from '../../js/Toasting'
import $ from "jquery";
import _ from "lodash";
//IMAGES AND ICONS
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import PublicIcon from "@mui/icons-material/Public";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ScienceIcon from "@mui/icons-material/Science";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EuroIcon from "@mui/icons-material/Euro";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import LightModeIcon from "@mui/icons-material/LightMode";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import PeopleIcon from "@mui/icons-material/People";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import CloseIcon from "@mui/icons-material/Close";

import PropTypes from "prop-types";

// material-ui
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Grid,
  Menu,
  MenuItem,
  Typography,
  Backdrop,
} from "@mui/material";

// project imports
import MainCard from "../../ui-components/cards/MainCard";
import SkeletonDashboardCard from "../../ui-components/Skeleton/SkeletonDashboardCard";

// assets
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppOutlined";
import { LightenColor } from "../../js/designManager";

const DashboardsCards = ({
  isLoading,
  primaryBGColor,
  primaryFontColor,
  subtitle,
  metric,
  suffix,
  value,
  icon,
  trend,
  onChangeChart,
}) => {
  // style constant
  var iconTrend = "trending_flat";
  var colorTrend = "lightgray";
  switch (trend) {
    case "up":
      iconTrend = "trending_up";
      colorTrend = "#27b376";
      break;
    case "down":
      iconTrend = "trending_down";
      colorTrend = "#bf212f";
      break;
    case "flat":
      iconTrend = "trending_flat";
      colorTrend = "lightgray";
      break;
    default:
      iconTrend = "trending_flat";
      colorTrend = "lightgray";
      break;
  }
  console.log(
    "LightenColor:",
    LightenColor(primaryBGColor, -10) + "!important"
  );
  const useStyles = makeStyles((theme) => ({
    card: {
      backgroundColor: primaryBGColor + "!important",
      color: "#fff",
      overflow: "hidden",
      borderRadius: "20px",
      position: "relative",
      fontFamily: "Quicksand !important",
      "&:after": {
        content: '""',
        position: "absolute",
        width: "210px",
        height: "210px",
        background: LightenColor(primaryBGColor, -10), //theme.palette.secondary[800],
        borderRadius: "50%",
        top: "-85px",
        right: "-95px",
      },
      "&:before": {
        content: '""',
        position: "absolute",
        width: "210px",
        height: "210px",
        background: LightenColor(primaryBGColor, 10), //theme.palette.secondary[800],
        borderRadius: "50%",
        top: "-125px",
        right: "-15px",
        opacity: 0.5,
      },
    },
    content: {
      padding: "20px !important",
    },
    avatar: {
      backgroundColor: LightenColor(primaryBGColor, -10),
      marginTop: "8px",
    },
    avatarRight: {
      backgroundColor: primaryBGColor + "!important",
      color: "white",
      zIndex: 1,
    },
    cardHeading: {
      fontSize: "2.125rem",
      fontWeight: 500,
      marginRight: "8px",
      marginTop: "14px",
      marginBottom: "6px",
      color: primaryFontColor,
      fontFamily: "Quicksand !important",
    },
    subHeading: {
      fontSize: "1rem",
      fontWeight: 500,
      color: "black",
      fontFamily: "Quicksand !important",
    },
    avatarCircle: {
      cursor: "pointer",
      backgroundColor: colorTrend + "!important",
      color: "white",
      width: "30px!important",
      height: "30px!important",
    },
    circleIcon: {
      transform: "rotate3d(1, 1, 1, 45deg)",
    },
    menuItem: {
      marginRight: "14px",
      fontSize: "1.25rem",
    },
  }));
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = () => {
    onChangeChart(subtitle, metric, suffix);
    setAnchorEl(null);
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const getIcon = (strIcon, sx) => {
    switch (strIcon) {
      case "world":
        return <PublicIcon sx={sx} />;
      case "store":
        return <StoreMallDirectoryIcon sx={sx} />;
      case "location":
        return <LocationOnIcon sx={sx} />;
      case "business":
        return <BusinessCenterIcon sx={sx} />;
      case "lab":
        return <ScienceIcon sx={sx} />;
      case "burger":
        return <LunchDiningIcon sx={sx} />;
      case "delivery":
        return <DeliveryDiningIcon sx={sx} />;
      case "phone":
        return <PhoneAndroidIcon sx={sx} />;
      case "euro":
        return <EuroIcon sx={sx} />;
      case "person":
        return <PersonIcon sx={sx} />;
      case "shopping_bag":
        return <ShoppingBagIcon sx={sx} />;
      case "discount":
        return <LocalOfferIcon sx={sx} />;
      case "trending_up":
        return <TrendingUpIcon sx={sx} />;
      case "trending_down":
        return <TrendingDownIcon sx={sx} />;
      case "trending_flat":
        return <TrendingFlatIcon sx={sx} />;
      case "eye":
        return <VisibilityIcon sx={sx} />;
      case "menu":
        return <MenuBookIcon sx={sx} />;
      case "day":
        return <LightModeIcon sx={sx} />;
      case "night":
        return <ModeNightIcon sx={sx} />;
      case "people":
        return <PeopleIcon sx={sx} />;
      case "donut":
        return <DonutLargeIcon sx={sx} />;
      case "fire":
        return <WhatshotIcon sx={sx} />;
      case "cross":
        return <CloseIcon sx={sx} />;

      default:
        return <LunchDiningIcon sx={sx} />;
    }
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <SkeletonDashboardCard />
      ) : (
        <MainCard
          border={false}
          className={classes.card}
          contentClass={classes.content}
        >
          <Grid container direction="column">
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Avatar variant="rounded" className={classes.avatar}>
                    {getIcon(icon, {})}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Avatar
                    variant="rounded"
                    className={classes.avatarRight}
                    aria-controls="menu-earning-card"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreHorizIcon fontSize="inherit" />
                  </Avatar>
                  <Menu
                    id="menu-earning-card"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem onClick={handleMenuItemClick}>
                      <GetAppTwoToneIcon
                        fontSize="inherit"
                        className={classes.menuItem}
                      />{" "}
                      Afficher graphique
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="h4" className={classes.cardHeading}>
                    {value}
                  </Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatarCircle}>
                    {getIcon(iconTrend, {})}
                  </Avatar>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ mb: 1.25 }}>
              <Typography className={classes.subHeading}>{subtitle}</Typography>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </React.Fragment>
  );
};

DashboardsCards.propTypes = {
  isLoading: PropTypes.bool,
};

export default DashboardsCards;
