import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
import classNames from "classnames";
//IMAGES AND ICONS
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  CardContent,
  CardMedia,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SchedulerFunc from "../../generic/scheduler/SchedulerFunc";

//CONST AND INTERNAL IMPORT

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "secondary !important",
  },
  activeCard: {
    backgroundColor:
      alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) +
      " !important",
  },
  disabledCard: {
    backgroundColor: theme.palette.action.disabledBackground + " !important",
    color: theme.palette.action.disabled + " !important",
  },
}));

// ********************* COMPONENT START ***********************

function ModalNewOrEditJetpackCampaignStepConstraintsDetails({
  constraintType,
  constraintValueObject,
  setConstraintValueObject,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log(
      "Frontend Info - I am inside ModalNewOrEditJetpackCampaignStepConstraintsDetails"
    );
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const setScheduleAndUpdateConstraint = (schedule) => {
    setConstraintValueObject({ ...constraintValueObject, schedule });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayContent = () => {
    switch (constraintType) {
      case "permanent":
        return (
          <Box m={6}>
            <Grid container spacing={2}>
              <Typography>
                Plein gaz c'est plein gaz. Rien à paramétrer ici, vos campagnes
                marketing seront constamment actives.
              </Typography>
            </Grid>
          </Box>
        );
      case "day_time":
        return (
          <Box m={0} sx={{ width: "100%" }}>
            {/* Clearly change or document this component asap >> pure shit show <<  */}
            <SchedulerFunc
              viewOnly={false}
              watermark={[]}
              show={true}
              items={[
                {
                  id: "whitelist",
                  name: "Horaires cibles",
                  shortName: "H",
                  color: theme.palette.primary.main,
                },
              ]}
              schedule={
                constraintValueObject && constraintValueObject.schedule
                  ? constraintValueObject.schedule
                  : []
              }
              setSchedule={setScheduleAndUpdateConstraint}
            />
          </Box>
        );
      default:
        return (
          <Box m={6}>
            <Grid container spacing={2}>
              <Typography>Oula... comment suis-je arrivé ici.</Typography>{" "}
            </Grid>
          </Box>
        );
    }
  };

  return <Grid container>{displayContent()}</Grid>;
}

ModalNewOrEditJetpackCampaignStepConstraintsDetails.propTypes = {
  
};

// ********************* COMPONENT END ***********************

export default ModalNewOrEditJetpackCampaignStepConstraintsDetails;
