import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
//import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS
import {
  Button,
  Dialog,
  Select,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
const filter = createFilterOptions();
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "10px !important",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ********************* COMPONENT START ***********************
//This component is an enhanced autocomplete component allowing user to add an item into the list passing through a form
function AutocompleteWithAddForm({
  title,
  options,
  fields,
  createNewEntry,
  mainField,
  multiple,
  value,
  setValue,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  //const { user, displayNotification, dialogsOpen, setDialogsOpen } =
  //  useContext(UserContext);
  const [open, toggleOpen] = useState(false);

  const emptyValues = {};
  fields.map((field) => {
    return (emptyValues[field.id] = null); //Create an empty object with relevant fields
  });
  const [dialogValue, setDialogValue] = useState(emptyValues);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const handleClose = () => {
    setDialogValue(emptyValues);
    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    /*
    if (multiple) {
      var newValues = [...value, dialogValue];
      setValue(newValues);
    } else {
      setValue(dialogValue);
    }*/

    createNewEntry(dialogValue);
    handleClose();
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.wrapper}>
      <Autocomplete
        value={value}
        multiple={multiple}
        onChange={(event, newValue) => {
          var lastItemAdded = newValue;
          if (multiple) {
            //We need to check whether it's a click on "add xxx" by checking last item if exist
            lastItemAdded =
              newValue.length > 0 ? newValue[newValue.length - 1] : null;
          }
          if (lastItemAdded && typeof lastItemAdded === "string") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              var prefillFieldId = null;
              fields.map((field) => {
                if (field.prefill) {
                  prefillFieldId = field.id;
                }
                return null;
              });
              if (prefillFieldId) {
                setDialogValue({
                  ...dialogValue,
                  [prefillFieldId]: lastItemAdded,
                });
              }
            });
          } else if (lastItemAdded && lastItemAdded.inputValue) {
            toggleOpen(true);
            var prefillFieldId = null;
            fields.map((field) => {
              if (field.prefill) {
                prefillFieldId = field.id;
              }
              return null;
            });
            if (prefillFieldId) {
              setDialogValue({
                ...dialogValue,
                [prefillFieldId]: lastItemAdded.inputValue,
              });
            }
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "") {
            var addItem = {
              inputValue: params.inputValue,
            };
            addItem[mainField] = `Ajouter "${params.inputValue}"`;
            filtered.push(addItem);
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={options}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option[mainField];
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props}>{option[mainField]}</li>
        )}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => <TextField {...params} label={title} />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Création {title.toLowerCase()}</DialogTitle>
          <DialogContent>
            <DialogContentText>Compléter ces informations.</DialogContentText>
            <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
              {fields.map((field) => {
                if (field.restrictedValue && field.restrictedValue.length > 0) {
                  return (
                    <Select
                      displayEmpty
                      value={dialogValue[field.id]}
                      onChange={(event) => {
                        setDialogValue({
                          ...dialogValue,
                          [field.id]: event.target.value.value,
                        });
                      }}
                      required
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return <em>Choisissez une option</em>;
                        }
                        return selected; //.join(", ");
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>Choisissez une option</em>
                      </MenuItem>
                      {field.restrictedValue.map((item) => (
                        <MenuItem key={item.value} value={item}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }
                return (
                  <TextField
                    key={field.id}
                    autoFocus={field.autofocus ? true : false}
                    margin="dense"
                    id={field.id}
                    value={dialogValue[field.id]}
                    onChange={(event) =>
                      setDialogValue({
                        ...dialogValue,
                        [field.id]: event.target.value,
                      })
                    }
                    label={field.label}
                    type={field.type}
                    variant="standard"
                  />
                );
              })}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Annuler</Button>
            <Button type="submit">Créer</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

AutocompleteWithAddForm.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  createNewEntry: PropTypes.func.isRequired,
  mainField: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  setValue: PropTypes.func.isRequired,
};

// ********************* COMPONENT END ***********************

export default AutocompleteWithAddForm;
