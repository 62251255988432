import React from "react";

function MaintenancePage() {
  return (
    <h3>
      🛠️ Jarvis est actuellement en maintenance. Nos équipes sont en ce moment
      même sur le pont ! 🛠️
    </h3>
  );
}

export default MaintenancePage;
