import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AutocompleteWithAddForm from "../generic/AutocompleteWithAddForm";

//CONST

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalNewOrEditMacroCampaign({
  initPage,
  locations,
  brands,
  others,
  editMode = false,
  editInitValues = null,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [brand, setBrand] = useState("");
  const [other, setOther] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedOthers, setSelectedOthers] = useState([]);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    initModal();
  }, [locations, brands, others]);

  useEffect(() => {
    if (editMode) {
      //init values
      setName(editInitValues.name ? editInitValues.name : "");

      var targetedTags = editInitValues.targetedTags
        ? JSON.parse(editInitValues.targetedTags)
        : {};
      setSelectedLocations(
        targetedTags.locations ? targetedTags.locations : []
      );
      setSelectedBrands(targetedTags.brands ? targetedTags.brands : []);
      setSelectedOthers(targetedTags.others ? targetedTags.others : []);
      /*
      setError("");
      setSelectedLocations([locations[0]]);
      setSelectedBrands([brands[0]]);
      setSelectedOthers([others[0]]);
      setName("");
      setIsLoading(false);*/
    } else {
      initModal();
    }
  }, [editInitValues, editMode]);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setDialogsOpen({ ...dialogsOpen, ModalNewOrEditMacroCampaign: false });
  };

  const handleSubmit = (e) => {
    if (!name || name === "") {
      setError("Vous devez saisir un nom pour la campagne.");
      return;
    }
    setError("");
    setIsLoading(true);
    if (editMode) {
      updateMacroCampaign();
    } else {
      createMacroCampaign();
    }
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const initModal = () => {
    setError("");
    setSelectedLocations([locations[0]]);
    setSelectedBrands([brands[0]]);
    setSelectedOthers([others[0]]);
    setName("");
    setIsLoading(false);
  };

  const createMacroCampaign = () => {
    var data = {
      name,
      locations: selectedLocations,
      brands: selectedBrands,
      others: selectedOthers,
      userGroupId: user.userGroupId,
    };
    POST("/api/macrocampaigns/create", data)
      .then((response) => {
        initPage();
        initModal();
        handleClose();
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const updateMacroCampaign = () => {
    var data = {
      id: editInitValues.id,
      name,
      locations: selectedLocations,
      brands: selectedBrands,
      others: selectedOthers,
      userGroupId: user.userGroupId,
    };
    POST("/api/macrocampaigns/update", data)
      .then((response) => {
        initPage();
        initModal();
        handleClose();
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const handleAllTagSelected = (newValue) => {
    var adjustedNewValue = newValue;
    if (newValue.length > 1) {
      //If there are at least 2 items selected. Check that "all" item is not selected. If it :
      //> that's the last item > remove other items.
      //> that's not the last item > remove "all" item
      var isAllSelected = false;
      var isAllSelectedLast = false;
      newValue.map((value, Index) => {
        if (value.id === "all") {
          isAllSelected = true;
          if (Index === newValue.length - 1) {
            isAllSelectedLast = true;
          }
        }
      });
      if (isAllSelected) {
        adjustedNewValue = [newValue[newValue.length - 1]];
      }
    }
    return adjustedNewValue;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Dialog
      open={dialogsOpen.ModalNewOrEditMacroCampaign}
      onClose={handleClose}
    >
      <DialogTitle>
        {editMode ? "Edition de votre" : "Nouvelle"} campagne de réponse aux
        avis
      </DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            {editMode ? "Mettez à jour votre" : "Créer une nouvelle"} campagne
            de réponse automatique aux avis. Indiquez sur quels restaurants
            s'applique cette campagne.
          </DialogContentText>
          <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
            <TextField
              autoFocus
              id={"filter-name"}
              value={name}
              onChange={(event) => setName(event.target.value)}
              label={"Nom de la campagne"}
              type={"text"}
              variant="standard"
            />
            {/* -------------------- ADDRESS -------------------- */}
            <Autocomplete
              multiple
              id="filter-locations"
              value={selectedLocations}
              options={locations}
              getOptionLabel={(option) => {
                return option ? option.name : "";
              }}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedLocations(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les adressess"
                  placeholder="Ajouter une adresse"
                />
              )}
            />
            {/* -------------------- BRAND -------------------- */}
            <Autocomplete
              multiple
              id="filter-brands"
              value={selectedBrands}
              options={brands}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedBrands(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les marques"
                  placeholder="Ajouter une marque"
                />
              )}
            />
            {/* -------------------- OTHER TAGS -------------------- */}
            <Autocomplete
              multiple
              id="filter-others"
              value={selectedOthers}
              options={others}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedOthers(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les autres tag"
                  placeholder="Ajouter un tag"
                />
              )}
            />
            <Typography component="div" className={classes.error}>
              <Box className={classes.error}>{error}</Box>
            </Typography>
          </FormControl>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<QuestionAnswerIcon />}
            >
              {editMode ? "Sauvegarder" : "Créér"}
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalNewOrEditMacroCampaign.propTypes = {};

export default ModalNewOrEditMacroCampaign;
