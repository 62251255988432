import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST, POSTLONG } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import LinkIcon from "@mui/icons-material/Link";
import UploadIcon from "@mui/icons-material/Upload";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
//CONST
const uber_ads_manager_id = "uber_ads_manager_id";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//This component is a modal allowing creation (or modification ?) of dahsboard integration
function ModalPricingGeofence({ platforms, initPage, createGeofence }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("none");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [locations_step, setLocations_step] = useState(12);
  const [radius, setRadius] = useState(3000);
  const [step, setStep] = useState(3000);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Création de la géofence en cours..."
  );

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setName("");
    setComment("");
    setLat(null);
    setLng(null);
    setRadius(3000);
    setStep(3000);
    setError("");
    setLocations_step(12);
    setIsLoading(false);
    setDialogsOpen({ ...dialogsOpen, ModalPricingGeofence: false });
  };

  const handleSubmit = (e) => {
    if (name === "") {
      return setError("Veuillez indiquer un nom pour votre zone");
    }
    if (!lat) {
      return setError(
        "Veuillez indiquer une latitude pour le centre de votre zone"
      );
    }
    if (!lng) {
      return setError("Veuillez indiquer une longitude pour votre zone");
    }
    if (radius < 1) {
      return setError("Veuillez indiquer un rayon supérieur à 0");
    }
    if (radius > 15000) {
      return setError("Veuillez indiquer un rayon inférieur à 15km");
    }
    if (step < 1000) {
      return setError("Veuillez indiquer un pas supérieur à 1km");
    }
    if (step > radius) {
      return setError("Veuillez indiquer un pas inférieur au rayon de la zone");
    }
    setError("");
    createGeofence(name, comment, lat, lng, radius, step, locations_step);
    handleClose();
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  return (
    <Dialog open={dialogsOpen.ModalPricingGeofence} onClose={handleClose}>
      <DialogTitle>Nouvelle zone d'analyse</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            Créer une nouvelle zone d'analyse en y précisant le centre (latitude
            et longitude), le rayon et le pas d'analyse.
          </DialogContentText>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              className={classes.inputF}
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nom"
              name="name"
              autoComplete="name_pricing_job"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormControl>
          {/* ADD COMMENT FIELD */}
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              id={"locations_step"}
              required
              value={locations_step}
              onChange={(event) => setLocations_step(event.target.value)}
              label={"Locations step"}
              type={"number"}
              variant="standard"
            />
          </FormControl>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              id={"lat"}
              required
              value={lat}
              onChange={(event) => setLat(event.target.value)}
              label={"Latitude"}
              type={"number"}
              variant="standard"
            />
          </FormControl>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              required
              id={"lng"}
              value={lng}
              onChange={(event) => setLng(event.target.value)}
              label={"Longitude"}
              type={"number"}
              variant="standard"
            />
          </FormControl>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              required
              id={"radius"}
              value={radius}
              onChange={(event) => setRadius(event.target.value)}
              label={"Rayon (en metres)"}
              type={"number"}
              variant="standard"
            />
          </FormControl>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              required
              id={"step"}
              value={step}
              onChange={(event) => setStep(event.target.value)}
              label={"Pas (en metres)"}
              type={"number"}
              variant="standard"
            />
          </FormControl>
          <Typography component="div" className={classes.error}>
            <Box className={classes.error}>{error}</Box>
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
            >
              Créeer
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalPricingGeofence.propTypes = {};

export default ModalPricingGeofence;
