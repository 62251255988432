import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/fr";
//IMAGES AND ICONS
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
//MATERIAL UI COMPONENT IMPORTS
import {
  Avatar,
  Autocomplete,
  Badge,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  CircularProgress,
  Collapse,
  Divider,
  Menu,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  TextField,
  Typography,
  useMediaQuery,
  MobileStepper,
} from "@mui/material";
//INTERNAL COMPONENT OR SCRIPTS
import {
  unFormatActions,
  formatActions,
} from "../../js/MacroCampaignsActionFormatter";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  inactive: {
    backgroundColor: theme.palette.action.disabledBackground + " !important",
    color: theme.palette.action.disabled + " !important",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ********************* COMPONENT START ***********************

function CardMacroCampaign({
  initPage,
  macroCampaign,
  editMacroCampaign,
  deleteMacroCampaign,
  toggleActiveMacroCampaign,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [expanded, setExpanded] = useState(false);
  const [error, setError] = useState("");
  const [isCampaignActiveTogglingLoading, setIsCampaignActiveTogglingLoading] =
    useState(false);
  const [isActionsSavingLoading, setIsActionsSavingLoading] = useState(false);
  const [action, setAction] = useState({ actions: [] });
  const [completion, setCompletion] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside CardMacroCampaign");
    moment.locale("fr");
  }, []);

  useEffect(() => {
    var newActionsUnformatted = unFormatActions(
      JSON.parse(macroCampaign.actions),
      setError
    );
    setCompletion(calculateCompletion(newActionsUnformatted.actions));
    setAction(newActionsUnformatted);
  }, [macroCampaign]);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditMenuClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const toggleCampaignActive = () => {
    setIsCampaignActiveTogglingLoading(true);
  };

  const saveAction = (stepMove = 0) => {
    const currentActionItem =
      action && action.actions && action.actions.length > 0
        ? action.actions[activeStep]
        : null;
    if (currentActionItem.unsaved) {
      var newAction = formatActions(
        JSON.parse(macroCampaign.actions),
        action.actions,
        setError
      );
      setIsActionsSavingLoading(true);
      var stringifiedActions = JSON.stringify(newAction);
      POST(`/api/macrocampaigns/${macroCampaign.id}/actions/update`, {
        actions: stringifiedActions,
      })
        .then((result) => {
          setIsActionsSavingLoading(false);
          initPage();
          if (stepMove !== 0) {
            setActiveStep((prevActiveStep) => prevActiveStep + stepMove);
          }
        })
        .catch((err) => {
          displayNotification(
            "error",
            "Erreur durant la sauvegarde de la réponse",
            "top",
            "center"
          );
        });
    } else {
      if (stepMove !== 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + stepMove);
      }
    }
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const calculateCompletion = (localActions) => {
    var numerator = 0;
    var denominator = 0;
    localActions.map((Action) => {
      denominator = denominator + 1;
      if (Action.offer > 0 || Action.answer !== "") {
        numerator = numerator + 1;
      }
    });
    var newCompletion =
      denominator > 0 ? parseInt((numerator / denominator) * 100) : 0;
    return newCompletion;
  };

  const isActionSaved = () => {};

  //Unformat actions from database object structure to uniformalize frontendtreatment accross object structure versionning

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  const actionItem =
    action && action.actions && action.actions.length > 0
      ? action.actions[activeStep]
      : null;
  return (
    <Card className={!macroCampaign.active ? classes.inactive : ""} sx={{}}>
      <CardHeader
        avatar={<QuestionAnswerIcon color="text.secondary" />}
        action={
          <React.Fragment>
            <IconButton aria-label="settings" onClick={handleEditMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={() => {
                handleEditMenuClose();
              }}
              sx={{ mt: "45px" }}
            >
              <MenuItem
                onClick={() => {
                  editMacroCampaign(macroCampaign);
                  handleEditMenuClose();
                }}
              >
                Editer
              </MenuItem>
              <MenuItem
                onClick={() => {
                  toggleActiveMacroCampaign(macroCampaign);
                  handleEditMenuClose();
                }}
              >
                {macroCampaign.active ? "Mettre en pause" : "Réactiver"}
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  deleteMacroCampaign(macroCampaign);
                  handleEditMenuClose();
                }}
              >
                Supprimer
              </MenuItem>
            </Menu>
          </React.Fragment>
        }
        title={macroCampaign.name}
        subheader={"Créée le " + moment(macroCampaign.createdAt).format("LLL")}
      />
      <CardContent>
        {error !== "" ? (
          <Typography variant="body2" color="red">
            {error}
          </Typography>
        ) : (
          <React.Fragment>
            <Typography variant="body2" color="text.secondary">
              {macroCampaign.active
                ? `Cette campagne est actuellement active sur ${macroCampaign.queryResult.length} canaux.`
                : "Cette campagne est actuellement inactive"}
            </Typography>
          </React.Fragment>
        )}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="refresh">
          <Badge
            color={"primary"}
            //variant="dot"
            badgeContent={macroCampaign.queryResult.length}
            max={999}
            showZero
            //invisible={synchStatus.channels ? true : false}
          >
            <PlayArrowIcon onClick={toggleCampaignActive} />
            {isCampaignActiveTogglingLoading && (
              <CircularProgress
                sx={{
                  color: "primary",
                  position: "absolute",
                  top: -8,
                  left: -7,
                  zIndex: 1,
                }}
              />
            )}
          </Badge>
        </IconButton>
        <Typography ml={4} variant="body2" color="text.secondary">
          {`${completion}% des scénarios ont une réponse.`}
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid
            container
            spacing={1}
            mt={1}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <MobileStepper
              variant="progress"
              steps={action.actions.length}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1 }}
              nextButton={
                activeStep === action.actions.length - 1 ? (
                  <IconButton
                    aria-label="settings"
                    onClick={() => {
                      saveAction(0);
                    }}
                    color="primary"
                  >
                    <SaveIcon />
                  </IconButton>
                ) : (
                  <Button
                    size="small"
                    onClick={() => {
                      saveAction(1);
                    }}
                    disabled={activeStep >= action.actions.length - 1}
                  >
                    Suiv.
                    {theme.direction === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                )
              }
              backButton={
                <Button
                  size="small"
                  onClick={() => {
                    saveAction(-1);
                  }}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Préc.
                </Button>
              }
            />
            {actionItem ? (
              <Box
                key={macroCampaign.id + "|" + actionItem.id}
                m={1}
                p={1}
                sx={{
                  borderLeft: `thick solid ${
                    actionItem.unsaved
                      ? "red"
                      : actionItem.offer > 0 || actionItem.answer
                      ? "green"
                      : "lightGrey"
                  }`,
                  width: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">
                      {actionItem.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel labelid={`offer_${actionItem.id}`}>
                        Offrir une promotion sur la prochaine commande
                      </InputLabel>
                      <Select
                        labelid={`offer_${actionItem.id}`}
                        id="demo-simple-select"
                        value={actionItem.offer}
                        label="Age"
                        onChange={(event) => {
                          var newAction = JSON.parse(JSON.stringify(action));
                          newAction.actions.map((actionItem_2) => {
                            if (actionItem_2.id === actionItem.id) {
                              actionItem_2.offer = event.target.value;
                              actionItem_2.unsaved = true;
                            }
                          });
                          setAction(newAction);
                        }}
                      >
                        <MenuItem value={0}>Non</MenuItem>
                        <MenuItem value={5}>5€</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id={actionItem.id}
                      label="Votre réponse"
                      multiline
                      rows={6}
                      autoFocus
                      value={actionItem.answer}
                      onChange={(event) => {
                        var newAction = JSON.parse(JSON.stringify(action));
                        newAction.actions.map((actionItem_2) => {
                          if (actionItem_2.id === actionItem.id) {
                            actionItem_2.answer = event.target.value;
                            actionItem_2.unsaved = true;
                          }
                        });
                        setAction(newAction);
                      }}
                      sx={{ width: "100%" }}
                      variant="standard"
                    />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <div>{/*PUT SKELETON HERE*/}</div>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

CardMacroCampaign.propTypes = {};

// ********************* COMPONENT END ***********************

export default CardMacroCampaign;
