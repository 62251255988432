import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import _ from "lodash";
//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//MATERIAL UI COMPONENT IMPORTS
import {
  IconButton,
  Button,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ModalNewOrEditJetpackCampaign from "./ModalNewOrEditJetpackCampaign";

import ModalContinueOrCancel from "../generic/ModalContinueOrCancel";
import SkeletonFilterBar from "../../ui-components/Skeleton/SkeletonFilterBar";
import SkeletonJetpackAdminRow from "../../ui-components/Skeleton/SkeletonJetpackAdminRow";
import RowJetpackCampaign from "./RowJetpackCampaign";
import ModalJetpackCampaignErrors from "./ModalJetpackCampaignErrors";

//CONST AND INTERNAL IMPORT
const constraintTypes = [
  {
    id: "permanent",
    title: "Plein gaz",
    disabled: false,
    description:
      "Les campagnes marketing restent active constamment sur les restaurants choisis",
  },
  {
    id: "day_time",
    title: "Heures creuses",
    disabled: false,
    description:
      "Les campagnes marketing s'activent sur les créneaux horaires de votre choix",
  },
  {
    id: "performance",
    title: "Objectifs (bientôt disponible)",
    disabled: true,
    description:
      "Les campagnes marketing s'activent selon des critère d'objectifs de vente à atteindre",
  },
  {
    id: "competition",
    title: "Concurrence (bientôt disponible)",
    disabled: true,
    description:
      "Les campagnes marketing s'activent en fonction du comportement de vos concurrents",
  },
];

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  filterBar: {
    boxShadow: theme.shadows[1],
    zIndex: 10000,
    backgroundColor: theme.palette.common.white,
  },
}));

// ********************* COMPONENT START ***********************

function JetpackCampaignsAdminPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { displayNotification, dialogsOpen, setDialogsOpen,constants } =
    useContext(UserContext);
  const [jetpackCampaigns, setJetpackCampaigns] = useState([]);
  const [locations, setLocations] = useState([]);
  const [brands, setBrands] = useState([]);
  const [others, setOthers] = useState([]);
  const [channels, setChannels] = useState([]);
  const [selectedJetpackCampaign, setSelectedJetpackCampaign] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayEndedCampaigns, setDisplayEndedCampaigns] = useState(false);
  const [displayUnActiveCampaigns, setDisplayUnActiveCampaigns] =
    useState(false);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init(displayEndedCampaigns, displayUnActiveCampaigns);
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const editJetpackCampaign = (jetpackCampaign) => {
    setSelectedJetpackCampaign(jetpackCampaign);
    setEditMode(true);
    setDialogsOpen({ ...dialogsOpen, ModalNewOrEditJetpackCampaign: true });
  };

  const toggleActiveJetpackCampaign = (jetpackCampaign) => {
    var data = {
      id: jetpackCampaign.id,
      active: !jetpackCampaign.active,
    };
    POST("/api/jetpackcampaigns/updateactive", data)
      .then((response) => {
        init(displayEndedCampaigns, displayUnActiveCampaigns);
      })
      .catch((err) => {
        init(displayEndedCampaigns, displayUnActiveCampaigns);
        displayNotification(
          "error",
          "Erreur durant la modification de la campagne",
          "top",
          "center"
        );
      });
  };

  const deleteJetpackCampaignUnConfirmed = (jetpackCampaign) => {
    setSelectedJetpackCampaign(jetpackCampaign);
    setDialogsOpen({ ...dialogsOpen, ModalDeleteJetpackCampaign: true });
  };

  const deleteJetpackCampaignConfirmed = () => {
    var data = {
      id: selectedJetpackCampaign.id,
    };
    POST("/api/jetpackcampaigns/delete", data)
      .then((response) => {
        init(displayEndedCampaigns, displayUnActiveCampaigns);
      })
      .catch((err) => {
        init(displayEndedCampaigns, displayUnActiveCampaigns);
        displayNotification(
          "error",
          "Erreur durant la modification de la campagne",
          "top",
          "center"
        );
      });
  };

  const openJetpackErrors = (jetpackCampaign) => {
    setSelectedJetpackCampaign(jetpackCampaign);
    setDialogsOpen({ ...dialogsOpen, ModalJetpackCampaignErrors: true });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = (localDisplayEndedCampaigns, localDisplayUnActiveCampaigns) => {
    setIsLoading(true);
    POST("/api/pages/jetpackcampaignsadminpage/init", {
      displayEndedCampaigns: localDisplayEndedCampaigns,
      displayUnActiveCampaigns: localDisplayUnActiveCampaigns,
    })
      .then((response) => {
        setIsLoading(false);
        setJetpackCampaigns(response.data.jetpackCampaigns);
        setLocations(response.data.locations);
        setBrands(response.data.brands);
        setOthers(response.data.others);
        setChannels(response.data.channels);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      <Button
        variant="contained"
        onClick={() => {
          setEditMode(false);
          setDialogsOpen({
            ...dialogsOpen,
            ModalNewOrEditJetpackCampaign: true,
          });
        }}
        sx={{ mb: 2 }}
        startIcon={<AddCircleOutlineIcon />}
      >
        Créer
      </Button>
      {isLoading ? (
        <React.Fragment>
          <SkeletonFilterBar />
          <SkeletonJetpackAdminRow />
          <SkeletonJetpackAdminRow />
          <SkeletonJetpackAdminRow />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Toolbar sx={{ p: 2 }} className={classes.filterBar}>
            <FormControlLabel
              sx={{}}
              control={
                <Switch
                  checked={displayEndedCampaigns}
                  onChange={(e) => {
                    init(!displayEndedCampaigns, displayUnActiveCampaigns);
                    setDisplayEndedCampaigns(!displayEndedCampaigns);
                  }}
                />
              }
              label="Afficher les campagnes terminées"
              labelPlacement="end"
            />
            <FormControlLabel
              sx={{}}
              control={
                <Switch
                  checked={displayUnActiveCampaigns}
                  onChange={(e) => {
                    init(displayEndedCampaigns, !displayUnActiveCampaigns);
                    setDisplayUnActiveCampaigns(!displayUnActiveCampaigns);
                  }}
                />
              }
              label="Afficher les campagnes désactivées manuellement"
              labelPlacement="end"
            />
          </Toolbar>
          <Grid container mt={1}>
            {jetpackCampaigns.map((jetpackCampaign) => {
              return (
                <RowJetpackCampaign
                  key={"RowJetpackCampaign_" + jetpackCampaign.id}
                  constraintTypes={constraintTypes}
                  jetpack_actionTypes={constants.jetpack_actionTypes}
                  jetpackCampaign={jetpackCampaign}
                  initPage={() => {
                    init(displayEndedCampaigns, displayUnActiveCampaigns);
                  }}
                  editJetpackCampaign={editJetpackCampaign}
                  toggleActiveJetpackCampaign={toggleActiveJetpackCampaign}
                  deleteJetpackCampaign={deleteJetpackCampaignUnConfirmed}
                  openJetpackErrors={openJetpackErrors}
                />
              );
            })}
          </Grid>
        </React.Fragment>
      )}
      <ModalNewOrEditJetpackCampaign
        brands={brands}
        locations={locations}
        others={others}
        initPage={() => {
          init(displayEndedCampaigns, displayUnActiveCampaigns);
        }}
        constraintTypes={constraintTypes}
        jetpack_actionTypes={constants.jetpack_actionTypes}
        editMode={editMode}
        editInitValues={selectedJetpackCampaign}
        channels={channels}
      />
      <ModalJetpackCampaignErrors
        jetpackCampaign={selectedJetpackCampaign}
        initPage={() => {
          init(displayEndedCampaigns, displayUnActiveCampaigns);
        }}
      />
      <ModalContinueOrCancel
        title="Suppression de la campagne"
        content="Souhaitez-vous réellement supprimer cette campagne ? Vous perdrez toutes les informations attachées à cette dernière. Si vous souhaitez garder l'historique de données, préférez une désactivation."
        modalId="ModalDeleteJetpackCampaign"
        handleCancel={() => {}}
        handleContinue={() => {
          deleteJetpackCampaignConfirmed();
        }}
      />
    </div>
  );
}

JetpackCampaignsAdminPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default JetpackCampaignsAdminPage;
