import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST, POSTLONG } from "../../js/HTTPRequest";
import { makeComponentUniqueId } from "../../js/otherManager";
import { distanceBeetweenCoordinates } from "../../js/geoManager";
//IMAGES AND ICONS
import LinkIcon from "@mui/icons-material/Link";
import UploadIcon from "@mui/icons-material/Upload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TableContainer,
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ModalPricingGeofence from "./ModalPricingGeofence";
//CONST
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "10rem",
  col5: "10rem",
  col6: "20rem",
};

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//This component is a modal allowing creation (or modification ?) of dahsboard integration
function ModalPricingJob({ initPage, ownedRestaurants }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [name, setName] = useState("");
  const [recurrence, setReccurence] = useState("none");
  const [filtersRestaurants, setFiltersRestaurants] = useState("");
  const [geofences, setGeofences] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Création de l'analyse en cours..."
  );

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setName("");
    setReccurence("none");
    setFiltersRestaurants([]);
    setGeofences([]);
    setError("");
    setIsLoading(false);
    setDialogsOpen({ ...dialogsOpen, ModalPricingJob: false });
  };

  const handleSubmit = (e) => {
    if (name === "") {
      return setError("Veuillez indiquer un nom pour l'analyse");
    }
    if (recurrence === "") {
      return setError(
        "Veuillez indiquer une récurrence d'analyse pour l'analyse"
      );
    }
    if (geofences.length === 0) {
      return setError("Veuillez ajouter au moins une zone d'analyse");
    }
    setError("");
    setIsLoading(true);
    createJob();
  };

  const addGeofence = (
    name,
    comment,
    center_lat,
    center_lng,
    radius_meters,
    step_meters,
    locations_step
  ) => {
    var newGeofences = JSON.parse(JSON.stringify(geofences));
    newGeofences.push({
      name,
      comment,
      center_lat,
      center_lng,
      radius_meters,
      step_meters,
      locations_step,
    });
    setGeofences(newGeofences);
  };

  const importGeofencesFromOwnedRestaurants = () => {
    var newGeofences = JSON.parse(JSON.stringify(geofences));
    console.log("ownedRestaurants:", ownedRestaurants);
    ownedRestaurants.map((ownedRestaurant) => {
      var newGeofence = {
        name: ownedRestaurant.name,
        comment: "import",
        center_lat: ownedRestaurant.lat,
        center_lng: ownedRestaurant.lng,
        radius_meters: 3000,
        step_meters: 3000,
      };
      var indexSimilarGeofence = doesSimilarGeofenceExist(
        newGeofence,
        newGeofences
      );
      if (indexSimilarGeofence < 0) {
        newGeofences.push(newGeofence);
      } else {
        newGeofences[indexSimilarGeofence].name =
          newGeofences[indexSimilarGeofence].name + ", " + newGeofence.name;
      }
    });
    setGeofences(newGeofences);
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const createJob = () => {
    POST(
      "/api/radar/pricing/jobs/create",
      {
        name,
        recurrence,
        geofences,
        filtersRestaurants,
      },
      true,
      null,
      120000
    )
      .then((response) => {
        handleClose();
        initPage();
        displayNotification(
          "success",
          "L'analyse est en cours... 👌",
          "top",
          "right"
        );
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const doesSimilarGeofenceExist = (newGeofence, geofences) => {
    var toReturn = -1;
    if (!geofences || geofences.length < 1) {
      return toReturn;
    }
    geofences.map((geofence, index) => {
      if (
        Math.abs(newGeofence.radius_meters - geofence.radius_meters) < 1000 &&
        Math.abs(newGeofence.step_meters - geofence.step_meters) < 1000
      ) {
        var distance = distanceBeetweenCoordinates(
          newGeofence.center_lat,
          newGeofence.center_lng,
          geofence.center_lat,
          geofence.center_lng
        );
        if (distance < newGeofence.radius_meters / 3000) {
          toReturn = index;
        }
      }
    });
    return toReturn;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayGeofences = () => {
    if (!geofences || geofences.length === 0) {
      return <p>Aucune zone attachée à l'analyse</p>;
    }
    return (
      <div>
        <p>Zones crées : {geofences.length}</p>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: colWidths.col1 }}>Zone</TableCell>
                <TableCell style={{ width: colWidths.col2 }} align="center">
                  lat
                </TableCell>
                <TableCell style={{ width: colWidths.col3 }} align="center">
                  lng
                </TableCell>
                <TableCell style={{ width: colWidths.col4 }} align="center">
                  rayon
                </TableCell>
                <TableCell style={{ width: colWidths.col5 }} align="center">
                  pas
                </TableCell>
                <TableCell
                  style={{ width: colWidths.col6 }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {geofences.map((row) => {
                var randomId = makeComponentUniqueId();
                return (
                  <TableRow id={randomId}>
                    <TableCell style={{ width: colWidths.col1 }}>
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col2 }} align="center">
                      {row.center_lat}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col3 }} align="center">
                      {row.center_lng}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col4 }} align="center">
                      {row.radius_meters}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col5 }} align="center">
                      {row.step_meters}
                    </TableCell>
                    <TableCell
                      style={{ width: colWidths.col6, color: "red" }}
                      align="center"
                    ></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <Dialog open={dialogsOpen.ModalPricingJob} onClose={handleClose}>
      <DialogTitle>Nouvelle analyse pricing</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            Créer une nouvelle analyse de pricing en précisant les zones
            d'analyse ainsi que les filtres éventuels à appliquer sur les noms
            de restaurant.
          </DialogContentText>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              className={classes.inputF}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nom"
              name="name"
              autoComplete="name_pricing_job"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              id={"filter-query"}
              label="Filtres sur noms de restaurants (séparés par une virgule)"
              multiline
              rows={3}
              value={filtersRestaurants}
              onChange={(event) => setFiltersRestaurants(event.target.value)}
              sx={{ width: "100%" }}
              variant="outlined"
            />
          </FormControl>
          {/* ADD RECURRENCE PARAMETER */}
          <Divider sx={{ mt: 2 }}>Zone d'analyse</Divider>
          <Button
            variant="contained"
            onClick={() => {
              importGeofencesFromOwnedRestaurants();
            }}
            startIcon={<UploadIcon />}
            sx={{ mt: 2 }}
          >
            Importer les zones des restaurants Napaqaro existants
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setDialogsOpen({ ...dialogsOpen, ModalPricingGeofence: true });
            }}
            startIcon={<AddCircleOutlineIcon />}
            sx={{ mt: 2 }}
          >
            Créeer une zone
          </Button>
          {displayGeofences()}
          <Typography component="div" className={classes.error}>
            <Box className={classes.error}>{error}</Box>
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
            >
              Créeer
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
      <ModalPricingGeofence createGeofence={addGeofence} />
    </Dialog>
  );
}

ModalPricingJob.propTypes = {};

export default ModalPricingJob;
