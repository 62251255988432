import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
import classNames from "classnames";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
//IMAGES AND ICONS
import PanToolIcon from "@mui/icons-material/PanTool";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  CardContent,
  CardMedia,
  DialogContentText,
  TextField,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  OutlinedInput,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  FormControl,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import SchedulerFunc from "../../generic/scheduler/SchedulerFunc";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "secondary !important",
  },
  error: {
    color: "red",
  },
  activeCard: {
    backgroundColor:
      alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) +
      " !important",
  },
  disabledCard: {
    backgroundColor: theme.palette.action.disabledBackground + " !important",
    color: theme.palette.action.disabled + " !important",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ********************* COMPONENT START ***********************

function ModalNewOrEditJetpackCampaignStepChannelsScope({
  name,
  setName,
  explicitOptInRequired,
  setExplicitOptInRequired,
  description,
  setDescription,
  limitedPeriod,
  setLimitedPeriod,
  start,
  setStart,
  end,
  setEnd,
  error,
  locations,
  selectedLocations,
  brands,
  selectedBrands,
  others,
  selectedOthers,
  selectedExcludedOthers,
  handleAllTagSelected,
  setSelectedLocations,
  setSelectedBrands,
  setSelectedOthers,
  setSelectedExcludedOthers,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log(
      "Frontend Info - I am inside ModalNewOrEditJetpackCampaignStepChannelsScope"
    );
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  return (
    <Grid container spacing={2}>
      <Box m={6}>
        <Grid container spacing={2}>
          <DialogContentText>
            Indiquez sur quels restaurants s'applique cette campagne.
          </DialogContentText>
          <FormControl sx={{ m: 1, width: "100%", mt: 3 }}>
            <TextField
              autoFocus
              id={"filter-name"}
              value={name}
              onChange={(event) => setName(event.target.value)}
              label={"Nom de la campagne"}
              type={"text"}
              variant="standard"
            />
            <TextField
              id={"filter-description"}
              label="Description"
              multiline
              rows={3}
              autoFocus
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              sx={{ width: "100%" }}
              variant="standard"
            />
            <Autocomplete
              multiple
              id="filter-locations"
              value={selectedLocations}
              options={locations}
              getOptionLabel={(option) => {
                return option ? option.name : "";
              }}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedLocations(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les adressess"
                  placeholder="Ajouter une adresse"
                />
              )}
            />
            <Autocomplete
              multiple
              id="filter-brands"
              value={selectedBrands}
              options={brands}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedBrands(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les marques"
                  placeholder="Ajouter une marque"
                />
              )}
            />
            <Autocomplete
              multiple
              id="filter-others"
              value={selectedOthers}
              options={others}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedOthers(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les autres tag"
                  placeholder="Ajouter un tag"
                />
              )}
            />
            <Autocomplete
              multiple
              id="filter-exlude-others"
              value={selectedExcludedOthers}
              options={others}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedExcludedOthers(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Autres tags à exclure"
                  placeholder="Ajouter un tag"
                />
              )}
            />
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Switch
                  checked={explicitOptInRequired}
                  onChange={(e) => {
                    setExplicitOptInRequired(!explicitOptInRequired);
                  }}
                />
              }
              label="Opt-in restaurant requis"
              labelPlacement="end"
            />
            <FormControlLabel
              sx={{ mt: 2, mb: 2 }}
              control={
                <Switch
                  checked={limitedPeriod}
                  onChange={(e) => {
                    if (limitedPeriod) {
                      setStart(null);
                      setEnd(null);
                    }
                    setLimitedPeriod(!limitedPeriod);
                  }}
                />
              }
              label="Campagne limitée dans le temps"
              labelPlacement="end"
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateRangePicker
                disabled={!limitedPeriod}
                startText="Début"
                endText="Fin"
                value={[start, end]}
                //autoOk={true}
                //disableCloseOnSelect={true}
                onChange={(newValue) => {
                  console.log("NEW DATE VALUES:", newValue);
                  setStart(newValue[0]);
                  setEnd(newValue[1]);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField
                      size="small"
                      style={{ width: "120px" }}
                      {...startProps}
                    />
                    <Box sx={{ mx: 1 }}> au </Box>
                    <TextField
                      size="small"
                      style={{ width: "120px" }}
                      {...endProps}
                    />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
            <Typography component="div" className={classes.error}>
              <Box className={classes.error}>{error}</Box>
            </Typography>
          </FormControl>
        </Grid>
      </Box>
    </Grid>
  );
}

ModalNewOrEditJetpackCampaignStepChannelsScope.propTypes = {};

// ********************* COMPONENT END ***********************

export default ModalNewOrEditJetpackCampaignStepChannelsScope;
