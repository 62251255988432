import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Alert, Snackbar } from "@mui/material";

const useStyles = makeStyles((theme) => ({}));

export default function Notification({ type, message, vertical, horizontal }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user } = useContext(UserContext);
  const [open, setOpen] = React.useState(true);

  useEffect(() => {});
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
    >
      <Alert onClose={handleClose} sx={{ width: "100%" }} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
}
