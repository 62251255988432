import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import Logo from "../../img/logo.png";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  TableContainer,
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Tooltip,
  Typography,
  Paper,
  useMediaQuery,
} from "@mui/material";
//CONSTANTES
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "50rem",
  col5: "10rem",
};
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function AdminAnomaliesPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [filters, setFilters] = useState({
    channelsWithoutPlatformLogin: true,
    channelsWithPlatformLoginsInactive: true,
  });
  const [anomalies, setAnomalies] = useState([]);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const deactivateObject = (table, id) => {
    POST("/api/anomalies/deactivate", { table, id })
      .then((response) => {
        displayNotification(
          "success",
          table + " désactivé avec succès",
          "top",
          "right"
        );
        init();
      })
      .catch((err) => {
        displayNotification("error", err.response.data, "top", "center");
        /*displayNotification(
          "error",
          "Erreur durant la désactivation du " + table,
          "top",
          "center"
        );*/
      });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    POST("/api/pages/adminanomaliespage/init", { filters })
      .then((response) => {
        //displayNotification("success", "Chargement réussi", "top", "center");
        setAnomalies(response.data.anomalies);
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      {" "}
      {anomalies.length < 1 ? (
        <div></div>
      ) : (
        <div>
          <Typography variant="body2">{`Résultat : ${
            anomalies.length
          } anomalie${anomalies.length > 1 ? "s" : ""} détéctée${
            anomalies.length > 1 ? "s" : ""
          }`}</Typography>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: colWidths.col1 }}>Table</TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    Id
                  </TableCell>
                  <TableCell style={{ width: colWidths.col3 }} align="center">
                    Name
                  </TableCell>
                  <TableCell style={{ width: colWidths.col4 }} align="center">
                    Problem
                  </TableCell>
                  <TableCell
                    style={{ width: colWidths.col5 }}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {anomalies.map((row) => {
                  return (
                    <TableRow>
                      <TableCell style={{ width: colWidths.col1 }}>
                        {row.table}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col2 }}
                        align="center"
                      >
                        {row.objectId}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col3 }}
                        align="center"
                      >
                        {row.objectName}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4, color: "red" }}
                        align="center"
                      >
                        {row.problem}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col5 }}
                        align="center"
                      >
                        {row.cta && row.cta !== "" ? (
                          <Button
                            disabled={
                              user.status.indexOf("admin") >= 0 ? false : true
                            }
                            onClick={() => {
                              switch (row.cta) {
                                case "deactivate":
                                  deactivateObject(row.table, row.objectId);
                                  break;
                                default:
                                //nothing
                              }
                            }}
                            variant="outlined"
                            color="primary"
                            //endIcon={<NavigateBeforeIcon />}
                          >
                            Désactiver
                          </Button>
                        ) : (
                          <div></div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

AdminAnomaliesPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default AdminAnomaliesPage;
