import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import moment from "moment";
import _ from "lodash";
import { makeComponentUniqueId } from "../../js/otherManager";
import {
  generateAgendaDescriptionForJetpack,
  getCampaignsDetailsFromJetpack,
} from "../../js/jetpackManager";
import "moment/locale/fr";
//IMAGES AND ICONS
import SearchIcon from "@mui/icons-material/Search";

//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  InputAdornment,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Toolbar,
  TextField,
  Typography,
  useMediaQuery,
  TablePagination,
  TableContainer,
  TableHead,
  TableCell,
  TableSortLabel,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import WorkInProgressBanner from "../generic/WorkInProgressBanner";
import JetpackPerformanceRestaurantRow from "./JetpackPerformanceRestaurantRow";
import ModalJetpackCampaignChannelHistory from "./ModalJetpackCampaignChannelHistory";
import SkeletonJetpackPerformanceTable from "../../ui-components/Skeleton/SkeletonJetpackPerformanceTable";
//CONSTANTE
const colWidths = {
  col0: "1rem",
  col1: "1rem",
  col2: "15rem",
  col3: "20rem",
  col4: "10rem",
  col5: "10rem",
  col6: "10rem",
  col7: "10rem",
};
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function JetpackPerformanceRestaurantPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [jetpackCampaigns, setJetpackCampaigns] = useState([]);
  const [jetpacksUnfiltered, setJetpacksUnfiltered] = useState([]); //FILTERED

  const [isLoading, setIsLoading] = useState(false);
  //const [channels, setChannels] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const displayStatusChangeHistory = (channel) => {
    setSelectedChannel(channel);
    setDialogsOpen({
      ...dialogsOpen,
      ModalJetpackCampaignChannelHistory: true,
    });
  };

  const updateFilterSearch = (newSearch) => {
    console.log("jetpacksUnfiltered", jetpacksUnfiltered);
    var newJetpacks = filterJetpacks(jetpacksUnfiltered, newSearch);
    setSearch(newSearch);
    console.log("newJetpacks", newJetpacks);
    setJetpackCampaigns(newJetpacks);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    setIsLoading(true);
    GET("/api/pages/jetpackperformancerestaurantpagev2/init")
      .then((response) => {
        setIsLoading(false);
        var formattedJetpackCampaigns = formatJetpackCampaigns(
          response.data.jetpackCampaigns
        );
        console.log("formattedJetpackCampaigns", formattedJetpackCampaigns);
        setJetpacksUnfiltered(formattedJetpackCampaigns);
        var filteredJetpacks = filterJetpacks(
          formattedJetpackCampaigns,
          search
        );
        setJetpackCampaigns(filteredJetpacks);
        //setChannels(response.data.channels);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  const filterJetpacks = (localJetpacksUnfiltered, localSearch) => {
    if (!localSearch || search === "") {
      return localJetpacksUnfiltered;
    }
    var localJetpacksFiltered = [];
    try {
      localJetpacksUnfiltered.map((jetpack) => {
        if (
          jetpack.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
        ) {
          localJetpacksFiltered.push(jetpack);
        } else if (
          jetpack.actionType.toLowerCase().indexOf(localSearch.toLowerCase()) >=
          0
        ) {
          localJetpacksFiltered.push(jetpack);
        } else if (
          jetpack.customersTarget
            .toLowerCase()
            .indexOf(localSearch.toLowerCase()) >= 0
        ) {
          localJetpacksFiltered.push(jetpack);
        } else {
          //Check among tags
          var matchCriteria = false;
          jetpack.agendaDescriptions.map((row) => {
            if (row.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0) {
              matchCriteria = true;
            }
          });
          jetpack.actionDetails.map((row) => {
            if (row.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0) {
              matchCriteria = true;
            }
          });
          if (matchCriteria) {
            localJetpacksFiltered.push(jetpack);
          }
        }
      });
    } catch (err) {
      console.log("here", err);
    }

    return localJetpacksFiltered;
  };

  const formatJetpackCampaigns = (localJetpackCampaigns) => {
    localJetpackCampaigns.map((jetpackCampaign) => {
      var agendaDescriptions =
        generateAgendaDescriptionForJetpack(jetpackCampaign);
      jetpackCampaign.agendaDescriptions = agendaDescriptions;
      var { actionType, actionDetails, customersTarget } =
        getCampaignsDetailsFromJetpack(jetpackCampaign, constants);
      jetpackCampaign.actionType = actionType;
      jetpackCampaign.actionDetails = actionDetails;
      jetpackCampaign.customersTarget = customersTarget;
    });
    return localJetpackCampaigns;
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    var compA = a[orderBy];
    var compB = b[orderBy];
    if (orderBy.indexOf(".") >= 0) {
      var fields = orderBy.split(".");
      switch (fields.length) {
        case 2:
          compA = a[fields[0]] ? a[fields[0]][fields[1]] : null;
          compB = b[fields[0]] ? b[fields[0]][fields[1]] : null;
          break;
        case 3:
          compA =
            a[fields[0]] && a[fields[0]][fields[1]]
              ? a[fields[0]][fields[1]][fields[2]]
              : null;
          compB =
            b[fields[0]] && b[fields[0]][fields[1]]
              ? b[fields[0]][fields[1]][fields[2]]
              : null;
          break;
        case 4:
          compA =
            a[fields[0]] &&
            a[fields[0]][fields[1]] &&
            a[fields[0]][fields[1]][fields[2]]
              ? a[fields[0]][fields[1]][fields[2]][fields[3]]
              : null;
          compB =
            b[fields[0]] &&
            b[fields[0]][fields[1]] &&
            b[fields[0]][fields[1]][fields[2]]
              ? b[fields[0]][fields[1]][fields[2]][fields[3]]
              : null;
          break;
        default:
        //Nothing
      }
    } else {
      compA = a[orderBy];
      compB = b[orderBy];
    }
    if (!isNaN(b[orderBy] & !isNaN(a[orderBy]))) {
      compA = parseFloat(compA);
      compB = parseFloat(compB);
    }
    if (compB < compA) {
      return -1;
    }
    if (compB > compA) {
      return 1;
    }
    return 0;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      {isLoading ? (
        <SkeletonJetpackPerformanceTable />
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Tooltip title="Rechercher">
              <TextField
                className={classes.inputF}
                variant="outlined"
                margin="normal"
                fullWidth
                id="search"
                label={`Rechercher une campagne`}
                name="search"
                autoComplete="search"
                value={search}
                style={{ height: "80px", paddingTop: "9px" }}
                onChange={(e) => {
                  updateFilterSearch(e.target.value);
                }}
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </Toolbar>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: colWidths.col0 }}
                    align="center"
                  ></TableCell>
                  <TableCell
                    style={{ width: colWidths.col1 }}
                    align="center"
                  ></TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Type de promotion">
                      <Typography variant="body">Promotion</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Type de promotion">
                      <Typography variant="body">Détails</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col3 }} align="center">
                    <Tooltip title="Période d'activité de la campagne">
                      <Typography variant="body">Période</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Horaires durant lesquels la promotion est active">
                      <Typography variant="body">Horaires</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Tous clients / nouveaux clients">
                      <Typography variant="body">Cible</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    style={{ width: colWidths.col4 }}
                    padding={"normal"}
                    sortDirection={
                      orderBy === "optIn.ratioActive" ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "optIn.ratioActive"}
                      direction={
                        orderBy === "optIn.ratioActive" ? order : "asc"
                      }
                      onClick={createSortHandler("optIn.ratioActive")}
                    >
                      <Tooltip title="Pourcentage de temps durant lequel le ou les restaurants avaient cette campagne active">
                        <Typography variant="body">% opted-in</Typography>
                      </Tooltip>
                      {orderBy === "optIn.ratioActive" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    style={{ width: colWidths.col4 }}
                    padding={"normal"}
                    sortDirection={
                      orderBy === "performance.budgetUsed" ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "performance.budgetUsed"}
                      direction={
                        orderBy === "performance.budgetUsed" ? order : "asc"
                      }
                      onClick={createSortHandler("performance.budgetUsed")}
                    >
                      <Tooltip title="Montant publicitaire dépensé durant cette campagne">
                        <Typography variant="body">Dépenses</Typography>
                      </Tooltip>
                      {orderBy === "performance.budgetUsed" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    style={{ width: colWidths.col4 }}
                    padding={"normal"}
                    sortDirection={
                      orderBy === "performance.newCustomerCount" ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "performance.newCustomerCount"}
                      direction={
                        orderBy === "performance.newCustomerCount"
                          ? order
                          : "asc"
                      }
                      onClick={createSortHandler(
                        "performance.newCustomerCount"
                      )}
                    >
                      <Tooltip title="Nombre de nouveaux clients obtenus grâce à cette campagne">
                        <Typography variant="body">Nouveaux clients</Typography>
                      </Tooltip>
                      {orderBy === "performance.newCustomerCount" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    style={{ width: colWidths.col4 }}
                    padding={"normal"}
                    sortDirection={
                      orderBy === "performance.sales" ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "performance.sales"}
                      direction={
                        orderBy === "performance.sales" ? order : "asc"
                      }
                      onClick={createSortHandler("performance.sales")}
                    >
                      <Tooltip title="Chiffre d'affaires généré par cette campagne">
                        <Typography variant="body">Ventes générées</Typography>
                      </Tooltip>
                      {orderBy === "performance.sales" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    style={{ width: colWidths.col4 }}
                    padding={"normal"}
                    sortDirection={
                      orderBy === "performance.contrib_post_cogs"
                        ? order
                        : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "performance.contrib_post_cogs"}
                      direction={
                        orderBy === "performance.contrib_post_cogs"
                          ? order
                          : "asc"
                      }
                      onClick={createSortHandler(
                        "performance.contrib_post_cogs"
                      )}
                    >
                      <Tooltip title="Contribution de l'opération. i. Nette de TVA (calculée sur une moyenne de 10%), ii. nette de marketing, iii. nette de commission plateforme, iv. nette de coûts matières (calculé sur une base de 30% du chiffre d'affaires avant marketing HT), v. nette de coût RH et coûts de structure (16% en moyenne)">
                        <Typography variant="body">
                          Contribution nette
                        </Typography>
                      </Tooltip>
                      {orderBy === "performance.contrib_post_cogs" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(jetpackCampaigns, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((jetpackCampaign, index) => {
                    if (jetpackCampaign && jetpackCampaign.id) {
                      return (
                        <JetpackPerformanceRestaurantRow
                          key={
                            "JetpackPerformanceRestaurantRow_" +
                            jetpackCampaign.id
                          }
                          isRestaurant={false}
                          item={jetpackCampaign}
                          colWidths={colWidths}
                          displayStatusChangeHistory={
                            displayStatusChangeHistory
                          }
                        />
                      );
                    } else {
                      return <div key={makeComponentUniqueId()}></div>;
                    }
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 50, 100]}
            component="div"
            count={jetpackCampaigns.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Jetpack par page"
          />
        </Paper>
      )}
      <ModalJetpackCampaignChannelHistory channel={selectedChannel} />
    </div>
  );
}

JetpackPerformanceRestaurantPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default JetpackPerformanceRestaurantPage;
