import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import { CSVLink, CSVDownload } from "react-csv";
import _ from "lodash";
//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//MATERIAL UI COMPONENT IMPORTS
import {
  IconButton,
  Button,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  FormControlLabel,
  Switch,
} from "@mui/material";

import ModalContinueOrCancel from "../generic/ModalContinueOrCancel";
import SkeletonFilterBar from "../../ui-components/Skeleton/SkeletonFilterBar";
import SkeletonJetpackAdminRow from "../../ui-components/Skeleton/SkeletonJetpackAdminRow";
import RowJetpackCampaignPlatform from "./RowJetpackCampaignPlatform";
import ModalJetpackCampaignErrorsPlatform from "./ModalJetpackCampaignErrorsPlatform";

//CONST AND INTERNAL IMPORT
const constraintTypes = [
  {
    id: "permanent",
    title: "Plein gaz",
    disabled: false,
    description:
      "Les campagnes marketing restent active constamment sur les restaurants choisis",
  },
  {
    id: "day_time",
    title: "Heures creuses",
    disabled: false,
    description:
      "Les campagnes marketing s'activent sur les créneaux horaires de votre choix",
  },
  {
    id: "performance",
    title: "Objectifs (bientôt disponible)",
    disabled: true,
    description:
      "Les campagnes marketing s'activent selon des critère d'objectifs de vente à atteindre",
  },
  {
    id: "competition",
    title: "Concurrence (bientôt disponible)",
    disabled: true,
    description:
      "Les campagnes marketing s'activent en fonction du comportement de vos concurrents",
  },
];

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  filterBar: {
    boxShadow: theme.shadows[1],
    zIndex: 10000,
    backgroundColor: theme.palette.common.white,
  },
}));

// ********************* COMPONENT START ***********************

function JetpackCampaignsPlatformPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [jetpackCampaigns, setJetpackCampaigns] = useState([]);
  const [locations, setLocations] = useState([]);
  const [brands, setBrands] = useState([]);
  const [others, setOthers] = useState([]);
  const [channels, setChannels] = useState([]);
  const [selectedJetpackCampaign, setSelectedJetpackCampaign] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displayEndedCampaigns, setDisplayEndedCampaigns] = useState(false);
  const [displayUnActiveCampaigns, setDisplayUnActiveCampaigns] =
    useState(false);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init(displayEndedCampaigns, displayUnActiveCampaigns);
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const editJetpackCampaign = (jetpackCampaign) => {
    setSelectedJetpackCampaign(jetpackCampaign);
    setEditMode(true);
    setDialogsOpen({ ...dialogsOpen, ModalNewOrEditJetpackCampaign: true });
  };

  const toggleActiveJetpackCampaign = (jetpackCampaign) => {
    var data = {
      id: jetpackCampaign.id,
      active: !jetpackCampaign.active,
    };
    POST("/api/jetpackcampaigns/updateactive", data)
      .then((response) => {
        init(displayEndedCampaigns, displayUnActiveCampaigns);
      })
      .catch((err) => {
        init(displayEndedCampaigns, displayUnActiveCampaigns);
        displayNotification(
          "error",
          "Erreur durant la modification de la campagne",
          "top",
          "center"
        );
      });
  };

  const deleteJetpackCampaignUnConfirmed = (jetpackCampaign) => {
    setSelectedJetpackCampaign(jetpackCampaign);
    setDialogsOpen({ ...dialogsOpen, ModalDeleteJetpackCampaign: true });
  };

  const openJetpackErrors = (jetpackCampaign) => {
    setSelectedJetpackCampaign(jetpackCampaign);
    setDialogsOpen({
      ...dialogsOpen,
      ModalJetpackCampaignErrorsPlatform: true,
    });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = (localDisplayEndedCampaigns, localDisplayUnActiveCampaigns) => {
    setIsLoading(true);
    POST("/api/pages/jetpackcampaignsplatformpage/init", {   
    })
      .then((response) => {
        setIsLoading(false);
        setJetpackCampaigns(response.data.jetpackCampaigns);        
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      {isLoading ? (
        <React.Fragment>
          <SkeletonJetpackAdminRow />
          <SkeletonJetpackAdminRow />
          <SkeletonJetpackAdminRow />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Grid container mt={1}>
            {jetpackCampaigns.map((jetpackCampaign) => {
              return (
                <RowJetpackCampaignPlatform
                  key={"RowJetpackCampaignPlatform_" + jetpackCampaign.id}
                  constraintTypes={constraintTypes}
                  jetpack_actionTypes={constants.jetpack_actionTypes}
                  jetpackCampaign={jetpackCampaign}
                  initPage={() => {
                    init(displayEndedCampaigns, displayUnActiveCampaigns);
                  }}
                  editJetpackCampaign={editJetpackCampaign}
                  toggleActiveJetpackCampaign={toggleActiveJetpackCampaign}
                  deleteJetpackCampaign={deleteJetpackCampaignUnConfirmed}
                  openJetpackErrors={openJetpackErrors}
                />
              );
            })}
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
}

JetpackCampaignsPlatformPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default JetpackCampaignsPlatformPage;
