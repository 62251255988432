import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import _ from "lodash";
import moment from "moment";
//IMAGES AND ICONS
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
  Grid,
} from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import AutocompleteWithAddForm from "../generic/AutocompleteWithAddForm";

//CONST

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
  timeline: {},
  link: {
    textDecoration: "none !important",
    color: theme.palette.primary.main,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalJetpackCampaignChannelHistory({ channel }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {}, []);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setDialogsOpen({
      ...dialogsOpen,
      ModalJetpackCampaignChannelHistory: false,
    });
  };

  const handleSubmit = (e) => {};

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const listAndSortAllDates = () => {
    var dates = [];
    if (!channel) {
      return dates;
    }
    dates.push({
      date: moment(channel.jetpackStart),
      label: "Début de la campagne",
      author: null,
    });
    if (channel.history) {
      channel.history.map((event) => {
        dates.push({
          date: moment(event.date),
          label: event.optIn ? "Activation" : "Désactivation",
          color: event.optIn ? "success" : "warning",
          author: event.auhorName,
          authorEmail: event.auhorEmail,
          authorStatus: event.auhorStatus,
        });
      });
    }
    if (channel.jetpackEnd && new Date() - new Date(channel.jetpackEnd) > 0) {
      dates.push({
        date: moment(channel.jetpackEnd),
        label: "Fin de la campagne",
        author: null,
      });
    } else {
      dates.push({
        date: moment(),
        label: "Aujourd'hui",
        author: null,
      });
    }
    dates.sort(function compare(a, b) {
      var dateA = new Date(a.date);
      var dateB = new Date(b.date);
      return dateA - dateB;
    });

    return dates;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  if (!channel || !channel.name) {
    return null;
  }

  var dates = listAndSortAllDates();
  return (
    <Dialog
      open={dialogsOpen.ModalJetpackCampaignChannelHistory}
      onClose={handleClose}
    >
      <DialogTitle>
        Historique d'activation de la campagne sur le restaurant {channel.name}
      </DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Timeline position="alternate" className={classes.timeline}>
            {dates.map((event) => {
              return (
                <TimelineItem className={classes.timelineItem}>
                  <TimelineSeparator>
                    <TimelineDot color={event.color} />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="body2">
                      [{event.date.format("LLL")}] {event.label}{" "}
                      {event.author ? (
                        <div>
                          <a
                            className={classes.link}
                            href={"mailto:" + event.authorEmail}
                            target="_blank"
                          >
                            {`${event.author}`}
                          </a>
                          <span>{` (${event.authorStatus})`}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>

          {/*
           <Timeline position="alternate" className={classes.timeline}>
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body2">
                  [{moment(channel.jetpackStart).format("LLL")}] Début de la
                  campagne
                </Typography>
              </TimelineContent>
            </TimelineItem>
            {channel.history ? (
              channel.history.map((event) => {
                return (
                  <TimelineItem className={classes.timelineItem}>
                    <TimelineSeparator>
                      <TimelineDot
                        color={event.optIn ? "success" : "warning"}
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body2">
                        [{moment(event.date).format("LLL")}]{" "}
                        {event.optIn ? "Activation" : "Désactivation"}{" "}
                        {event.auhorName}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })
            ) : (
              <div></div>
            )}
            <TimelineItem className={classes.timelineItem}>
              <TimelineSeparator>
                <TimelineDot />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body2">
                  {channel.jetpackEnd &&
                  new Date() - new Date(channel.jetpackEnd) > 0
                    ? "[" +
                      moment(channel.jetpackEnd).format("LLL") +
                      "] Fin de la campagne"
                    : "Aujourd'hui"}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
         */}
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Fermer</Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalJetpackCampaignChannelHistory.propTypes = {};

export default ModalJetpackCampaignChannelHistory;
