export const numberWithSpaces = (x) => {
  if (!x || x === undefined) {
    return 0;
  }
  if (isNaN(x)) {
    return x;
  }
  var newX = parseFloat(x);
  var parts = newX.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
};
