import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./themes/theme";
import _ from "lodash";
import $ from "jquery";
import { v4 } from "uuid";
import { GET, POST, PATCH, PUT, DELETE } from "./js/HTTPRequest";
//MATERIAL UI COMPONENT IMPORTS
import useMediaQuery from "@mui/material/useMediaQuery"; //NEVER WORKED WITH ME... RETURNED ALWARS FALSE WHATVER THE BREAKPOINT I USED...  TO INVESTIGATE
//Containers & Components - BEGIN
import NavBar from "./containers/navbar/NavBar";
import Home from "./containers/landingPage/Home";
import BrokenPage from "./containers/errors/BrokenPage";
import LoginPage from "./containers/login/LoginPage";
import SignupPage from "./containers/signup/SignupPage";
import VerifyEmailPage from "./containers/signup/VerifyEmailPage";
import CockpitPage from "./containers/cockpit/cockpitPage";
//Containers & Components - END
import NotificationsContainer from "./containers/generic/NotificationsContainer";
import { isMobile } from "./js/designManager";

export const UserContext = React.createContext();
var userEmpty = {
  email: "",
  status: "",
  loggedIn: false,
  pictureURL: "",
};

/** - Description: Main container responsible for routing and dispatching information via props and discussing with the backend API.
 */
function App(props) {
  const [user, setUser] = useState(userEmpty);
  const [backupUser, setBackupUser] = useState(userEmpty);
  const [cockpitDrawerOpen, setCockpitDrawerOpen] = useState(false);
  const [cockpitView, setCockpitView] = useState("");
  const [environment, setEnvironment] = useState("");
  const [dialogsOpen, setDialogsOpen] = useState({
    ModalNewIntegration: false,
    ModalApplyTags: false,
    ModalApplyChannelsAccess: false,
    ModalNewOrEditMacroCampaign: false,
    ModalNewOrEditJetpackCampaign: false,
    ModalSchedulerAddTimeStampFunc: false,
    ModalDeleteChannelTag: false,
    ModalDeleteMacroCampaign: false,
    ModalDeleteJetpackCampaign: false,
    ModalDeleteUser: false,
    ModalGetDateForOrdersHistory: false,
    ModalEditUser: false,
    ModalJetpackCampaignChannelHistory: false,
    ModalSparkLineDetail: false,
    ModalJetpackCampaignErrors: false,
    ModalCustomerDetails: false,
    ModalRemoveChannels: false,
    ModalPricingJob: false,
    ModalPricingGeofence: false,
    ModalCompetitorsFilters: false,
  });
  const [constants, setConstants] = useState({});
  //const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false);
  const [notifications, setNotifications] = useState([]); //Array of objects {severity:"success" or "error" or ..., message:"hello world", vertical: "top", horizaontal:"center"}

  //FOR EVENT LISTENER - WE NEED TO USE POINTER AS EVENT LISTENER WOULD KEEP THE ORIGINAL STATE OTHERWISE - EXPLANATON HERE : https://file-translate.com/en/blog/react-state-in-event
  const userRef = React.useRef(user);
  const setUserRef = (user) => {
    userRef.current = user;
    setUser(user);
  };
  const cockpitDrawerOpenRef = React.useRef(cockpitDrawerOpen);
  const setCockpitDrawerOpenRef = (cockpitDrawerOpen) => {
    cockpitDrawerOpenRef.current = cockpitDrawerOpen;
    setCockpitDrawerOpen(cockpitDrawerOpen);
  };

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    getUserInfo();
    window.addEventListener("resize", handleResize);
  }, []);

  // *******************************************************
  // ********************** USER INFO **********************
  // *******************************************************

  const getUserInfo = () => {
    console.log("Frontend Info - Retrieving User info...");
    GET(`/api/credentials`)
      .then((response) => {
        console.log("Frontend Info - response /api/credentials");
        updateUser(response.data.user);
        setEnvironment(response.data.environment);
        setConstants(response.data.constants);
      })
      .catch((err) => {
        setUserRef(userEmpty); //For event listners (ex: window width listener)
        setCockpitDrawerOpen(false);
        console.log("Frontend Error - Error while retrieving user info", err);
      });
  };

  const loginAs = (userItem) => {
    console.log("userItem", userItem);
    GET("/api/users/loginas/" + userItem.id).then((response) => {
      displayNotification(
        "success",
        "Vous êtes maintenant login en tant que " + userItem.name,
        "top",
        "right"
      );
      setBackupUser(user);
      updateUser(response.data.user);
      setEnvironment(response.data.environment);
      setConstants(response.data.constants);
    });
  };

  const exitLoginAs = () => {
    setUser(backupUser);
    //DOES NOT WORK --- INDEED I. YOU'RE NOT ADMIN ANYMORE AND II.YOU'RE TRYING TO LOGIN AS AN ADMIN.... NEED TO FIND ANOTHER OUT
    GET("/api/users/loginas/" + backupUser.id).then((response) => {
      displayNotification(
        "success",
        "Vous êtes maintenant de retour en tant que " + backupUser.name,
        "top",
        "right"
      );
      updateUser(response.data.user);
      setEnvironment(response.data.environment);
      setConstants(response.data.constants);
    });
  };

  // *******************************************************
  // ********************** CALLBACKS **********************
  // *******************************************************

  const displayNotification = (type, message, vertical, horizontal) => {
    console.log("Frontend info - displayNotification fired!");
    var newNotifications = [...notifications];
    try {
      newNotifications.push({
        id: v4(),
        type,
        message,
        vertical,
        horizontal,
      });
    } catch (err) {
      console.log("Frontend Error - Error during notification display", err);
    }
    setNotifications(newNotifications);
  };

  const updateUser = (userUpdated) => {
    console.log("Frontend info - updateUser just fired", userUpdated);
    if (userUpdated) {
      userUpdated.loggedIn = true;
    }
    setUserRef(userUpdated && userUpdated.email ? userUpdated : userEmpty);
    if (userUpdated && userUpdated.email && !isMobile()) {
      setCockpitDrawerOpen(true);
    }
  };

  // *******************************************************
  // ******************** LANDING PAGE *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS ******************
  // *******************************************************

  const handleResize = (e) => {
    if (userRef.current && userRef.current.email && !isMobile()) {
      setCockpitDrawerOpenRef(true);
    } else {
      setCockpitDrawerOpenRef(false);
    }
  };

  // *******************************************************
  // *********************** RENDER ************************
  // *******************************************************
  return (
    <div key="app" id="app">
      <UserContext.Provider
        value={{
          user,
          backupUser,
          environment,
          updateUser,
          cockpitDrawerOpen,
          setCockpitDrawerOpen,
          cockpitView,
          setCockpitView,
          displayNotification,
          dialogsOpen,
          setDialogsOpen,
          constants,
          setEnvironment,
          setConstants,
          loginAs,
          exitLoginAs,
        }}
      >
        <NotificationsContainer notifications={notifications} />
        <Router key="Router">
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <NavBar />
            <Routes key="Switch">
              {/*------------- HOME PAGES ------------- */}
              <Route exact path="/" element={<Home />} />
              {/*-------------- LOGIN/SIGNUP ------------- */}
              <Route exact path="/login" element={<LoginPage />} />
              {/*<Route exact path="/signup" element={<SignupPage />} />*/}
              <Route
                path="/emailverification/:tempRoute"
                element={<VerifyEmailPage />}
              />
              {/*--------------- COCKPIT --------------- */}
              <Route exact path="/cockpit" element={<CockpitPage />} />
              {/*--------------- COMMON --------------- */}
              <Route path="/*" element={<BrokenPage />} />
            </Routes>
          </ThemeProvider>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
