import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { CSVLink, CSVDownload } from "react-csv";
import { GET, POST } from "../../../js/HTTPRequest";
import {
  GoogleMap,
  LoadScript,
  DrawingManager,
  ScriptLoaded,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";

//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DownloadIcon from "@mui/icons-material/Download";
import AutorenewIcon from "@mui/icons-material/Autorenew";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Card,
  Checkbox,
  Container,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  Paper,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  TableContainer,
  TableBody,
  TableHead,
  Table,
  Tabs,
  Tab,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ModalCompetitorsFilters from "./ModalCompetitorsFilters";

//CONST
const colWidths = {
  col1: "5rem",
  col2: "10rem",
  col3: "10rem",
  col4: "10rem",
  col5: "10rem",
  col6: "20rem",
};

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  img_marker: {
    height: "150px",
  },
  img_table: {
    height: "50px",
  },
}));

// ********************* COMPONENT START ***********************

function RadarLiveCompetitorsScrapping(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, constants } = useContext(UserContext);

  const [bounds, setBounds] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Scrapping en cours...");
  const [activeMarker, setActiveMarker] = useState(null);

  const [dialogsOpen, setDialogsOpen] = useState({
    ModalCompetitorsFilters: false,
  });

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside RadarLiveCompetitorsScrapping");
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const onLoad = (drawingManager) => {
    console.log(drawingManager);
  };

  const onRectangleComplete = (rectangle) => {
    var boundsLocal = rectangle.bounds;
    console.log("bounds", boundsLocal);
    var keys = Object.keys(boundsLocal);
    console.log("keys", keys);
    var lat_min = boundsLocal[keys[0]].lo;
    var lat_max = boundsLocal[keys[0]].hi;
    var lng_min = boundsLocal[keys[1]].lo;
    var lng_max = boundsLocal[keys[1]].hi;

    var distance_km = distanceBeetweenCoordinates(
      lat_min,
      lng_min,
      lat_max,
      lng_max
    );
    if (distance_km > 100) {
      displayNotification(
        "error",
        "The chosen area is too large, max diagonal distance is 100km...",
        "top",
        "center"
      );
      rectangle.setMap(null);
      return;
    }
    setBounds({ lat_min, lat_max, lng_min, lng_max });
    setDialogsOpen({ ...dialogsOpen, ModalCompetitorsFilters: true });
    rectangle.setMap(null);
  };

  const launchSearch = (search) => {
    setIsLoading(true);
    setRestaurants([]);
    POST("/api/pages/scrapping/zone/scrap", {
      lat_min: bounds.lat_min,
      lat_max: bounds.lat_max,
      lng_min: bounds.lng_min,
      lng_max: bounds.lng_max,
      search,
    })
      .then((response) => {
        setIsLoading(false);
        setRestaurants(response.data.restaurants);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le scrapping...",
          "top",
          "center"
        );
      });
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const init = () => {
    return;
  };

  const generateDataCSV = () => {
    var data = [];
    if (!restaurants || restaurants.length < 1) {
      return data;
    }

    restaurants.map((restaurant) => {
      var { name, lat, lng, rating, reviewCount } = restaurant;
      var orders = (2 * (reviewCount / 0.14)) / 90;
      data.push({
        name,
        lat,
        lng,
        rating,
        reviewCount,
        orders,
      });
    });
    return data;
  };

  const distanceBeetweenCoordinates = (lat1, lon1, lat2, lon2) => {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  };

  const calculateCenter = () => {
    var center = { lat: 48.864716, lng: 2.349014 };
    if (bounds && bounds.lat_min) {
      center = {
        lat: parseFloat((bounds.lat_min + bounds.lat_max) / 2),
        lng: parseFloat((bounds.lng_min + bounds.lng_max) / 2),
      };
    }
    return center;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  var dataCSV = generateDataCSV();
  var center = calculateCenter();
  return (
    <div className={classes.div}>
      {restaurants && restaurants.length > 0 ? (
        <Button
          variant="contained"
          onClick={() => {
            setRestaurants([]);
          }}
          color="primary"
          style={{ marginTop: 10, textDecoration: "none" }}
          startIcon={<AutorenewIcon />}
        >
          Nouvelle analyse
        </Button>
      ) : (
        <div></div>
      )}
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <LoadScript
          googleMapsApiKey="AIzaSyAyJhu4zfWDdfCAFF943vojR9OZ2tO397Y"
          libraries={["drawing"]}
        >
          <GoogleMap
            mapContainerStyle={{ height: "400px" }}
            center={center}
            onClick={() => setActiveMarker(null)}
            zoom={10}
          >
            {/* Child components, such as markers, info windows, etc. */}
            {restaurants && restaurants.length > 0 ? (
              restaurants.map((restaurant) => {
                /*const icon = {
                  url: restaurant.imageURL, // url
                  size: { width: 60, height: 100 },
                  anchor: { x: 15, y: 50 },
                  scaledSize: { width: 50, height: 30 },
                };*/
                return (
                  <Marker
                    //onLoad={onLoad}
                    position={{ lat: restaurant.lat, lng: restaurant.lng }}
                    onClick={() => handleActiveMarker(restaurant.platformUUID)}
                    //icon={icon}
                  >
                    {activeMarker === restaurant.platformUUID ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>
                          <img
                            className={classes.img_marker}
                            src={restaurant.imageURL}
                            alt={restaurant.name}
                          />
                          <div>{restaurant.name}</div>
                          <div>
                            {restaurant.rating && restaurant.rating > 0
                              ? `${restaurant.rating} ⭐`
                              : "No rating"}
                          </div>
                          <div>
                            {restaurant.reviewCount &&
                            restaurant.reviewCount > 0
                              ? `${restaurant.reviewCount} reviews`
                              : ""}
                          </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              })
            ) : (
              <DrawingManager
                onLoad={onLoad}
                drawingMode={"rectangle"}
                onRectangleComplete={onRectangleComplete}
                rectangleOptions={{
                  strokeColor: "#591083",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#591083",
                  fillOpacity: 0.35,
                }}
              />
            )}
          </GoogleMap>
        </LoadScript>
      )}

      {isLoading || !restaurants || restaurants.length < 1 ? (
        <div></div>
      ) : (
        <div>
          <CSVLink
            data={dataCSV}
            style={{ marginTop: 10, textDecoration: "none" }}
          >
            <Tooltip title="Download data in csv format">
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 10, textDecoration: "none" }}
                startIcon={<DownloadIcon />}
              >
                Download as csv
              </Button>
            </Tooltip>
          </CSVLink>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: colWidths.col1 }}>
                  Restaurant
                </TableCell>
                <TableCell style={{ width: colWidths.col2 }} align="center">
                  Restaurant
                </TableCell>
                <TableCell style={{ width: colWidths.col3 }} align="center">
                  lat
                </TableCell>
                <TableCell style={{ width: colWidths.col4 }} align="center">
                  lng
                </TableCell>
                <TableCell style={{ width: colWidths.col5 }} align="center">
                  rating
                </TableCell>
                <TableCell style={{ width: colWidths.col5 }} align="center">
                  reviews
                </TableCell>
                <TableCell style={{ width: colWidths.col5 }} align="center">
                  est. daily Uber Orders
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {restaurants.map((restaurant) => {
                return (
                  <TableRow
                    onClick={() => handleActiveMarker(restaurant.platformUUID)}
                  >
                    <TableCell style={{ width: colWidths.col1 }}>
                      <img
                        className={classes.img_table}
                        src={restaurant.imageURL}
                        alt={restaurant.name}
                      />
                    </TableCell>
                    <TableCell style={{ width: colWidths.col2 }} align="center">
                      {restaurant.name}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col3 }} align="center">
                      {restaurant.lat}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col3 }} align="center">
                      {restaurant.lng}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col4 }} align="center">
                      {restaurant.rating && restaurant.rating > 0
                        ? `${restaurant.rating} ⭐`
                        : "No rating"}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col5 }} align="center">
                      {restaurant.reviewCount && restaurant.reviewCount > 0
                        ? `${restaurant.reviewCount}`
                        : 0}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col5 }} align="center">
                      {restaurant.reviewCount && restaurant.reviewCount > 0
                        ? `${
                            restaurant.reviewCount === 200 ? ">" : ""
                          }${parseInt(restaurant.reviewCount / (0.15 * 90))}`
                        : "<5"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
      <ModalCompetitorsFilters
        launchSearch={launchSearch}
        dialogsOpen={dialogsOpen}
        setDialogsOpen={setDialogsOpen}
      />
    </div>
  );
}

RadarLiveCompetitorsScrapping.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarLiveCompetitorsScrapping;
