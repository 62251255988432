import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import classNames from "classnames";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import _ from "lodash";
import moment from "moment";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  Toolbar,
  TextField,
  useMediaQuery,
} from "@mui/material";
//INTERNAL IMPORT
import DashboardsCards from "../generic/DashboardsCards";
import ClassicChart from "../generic/ClassicChart";
import FilterBar from "../generic/FilterBar";
import { numberWithSpaces } from "../../js/numberManager";
//import Dashboards_FiltersBar from "../../components/data_dashboards/Dashboards_FiltersBar";
//ICONS
import RefreshIcon from "@mui/icons-material/Refresh";

// *******************************************************
// ************************ STYLE ************************
// *******************************************************
const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  overlay: {
    filter: "blur(3px)",
    //position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.54)",
    //color: "white",
    top: "0px",
    left: "0px",
    zIndex: 1000,
  },
  btnUpdate: {
    position: "fixed",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    top: "100px",
    zIndex: 1001,
  },
  hide: {
    display: "none!important",
  },
}));

//yFilters (array of arrays of filter)
//chartParameters ex: {x:'day'}

function RadarClassicPage({ page }) {
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // *********************** USE STATES ********************
  // *******************************************************

  const [isLoading, setIsLoading] = useState(true);
  const [currentChart, setCurrentChart] = useState(null);
  const [charts, setCharts] = useState([{}, {}, {}, {}]);
  const [chartParameters, setChartParameters] = useState(null);
  const [xFilters, setXFilters] = useState([]);
  const [yFilters, setYFilters] = useState([[]]);
  /*const [data, setData] = useState(() => {
    return [];
  });*/
  const [x, setX] = useState([]);
  const [y, setY] = useState([{ name: "init", data: [], type: "line" }]);
  const [totals, setTotals] = useState([]);
  const [needToRefresh, setNeedToRefresh] = useState(false);

  // *******************************************************
  // *********************** USE EFFECT ********************
  // *******************************************************

  useEffect(() => {
    refreshData();
  }, [page]); //Trigger only once

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const onXFilterUpdate = (newXFilters) => {
    setXFilters(newXFilters);
    setNeedToRefresh(true);
  };

  const onYFilterUpdate = (newSerieFilters, filterIndex) => {
    var newYFilters = JSON.parse(JSON.stringify(yFilters));
    newYFilters[filterIndex] = newSerieFilters;
    setYFilters(newYFilters);
    setNeedToRefresh(true);
  };

  const onChangeChart = (name, metric, suffix) => {
    var newY = JSON.parse(JSON.stringify(y));

    var newCurrentChart;
    charts.map((chart) => {
      if (chart.metric === metric) {
        newCurrentChart = JSON.parse(JSON.stringify(chart));
      }
    });
    var chart_type =
      y.length > 1 && newCurrentChart && newCurrentChart.chart_type
        ? newCurrentChart.chart_type_multiple
        : newCurrentChart && newCurrentChart.chart_type
        ? newCurrentChart.chart_type
        : "line";
    if (metric) {
      newY.map((serie) => {
        serie.data = serie[metric];
        serie.type = chart_type;
        return null;
      });
    }
    setCurrentChart(newCurrentChart);
    setY(newY);
  };

  //DEPRECATED
  const callBackStatusChange = (xAxis, series, status) => {};

  const addNewSerie = () => {
    var newYFilters = JSON.parse(JSON.stringify(yFilters));
    newYFilters.push(JSON.parse(JSON.stringify(newYFilters[0])));
    setYFilters(newYFilters);
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS ******************
  // *******************************************************

  const refreshData = () => {
    var xFiltersLocal = xFilters;
    setNeedToRefresh(false);
    setIsLoading(true);
    if (!xFiltersLocal || xFiltersLocal.length === 0) {
      xFiltersLocal = buildEmptyXFilters();
    }
    //We need to remove options before posting (otherwise JSON will be too large)
    var yFiltersLocal = JSON.parse(JSON.stringify(yFilters));
    yFiltersLocal.map((serie) => {
      serie.map((yFilterLocal) => {
        yFilterLocal.options = [];
      });
    });
    POST("/api/radar/charts/refresh", {
      xFilters: xFiltersLocal,
      yFilters: yFiltersLocal,
      chartParameters,
      page,
    }).then((response) => {
      console.log("RESPONSE:", response);
      var y = response.data.y;
      var currentChart =
        response.data.targetValues && response.data.targetValues.length > 0
          ? response.data.targetValues[0]
          : null;
      var chart_type =
        y.length > 1 && currentChart && currentChart.chart_type
          ? currentChart.chart_type_multiple
          : currentChart && currentChart.chart_type
          ? currentChart.chart_type
          : "line";
      if (currentChart && currentChart.metric) {
        y.map((serie) => {
          serie.data = serie[currentChart.metric];
          serie.type = chart_type;
          return null;
        });
      }

      setCharts(response.data.targetValues);
      setCurrentChart(currentChart);
      setXFilters(response.data.xFilters);
      setYFilters(response.data.yFilters);
      setX(response.data.x);
      setY(y);
      setTotals(response.data.totals);
      setIsLoading(false);
    });
  };

  const buildEmptyXFilters = () => {
    //YFilters is an array of series and a serie is an array of filters.
    var start_lastweek = new Date();
    start_lastweek.setHours(0, 0, 0, 0);
    start_lastweek.setDate(start_lastweek.getDate() - 7); //TO DO CHANGE 700 in 7
    return [
      /*{
        name: "Période",
        type: "date-range",
        id: "orders|completed_period",
        values: [start_lastweek, new Date()],
      },
      {
        name: "Affichage",
        type: "select",
        manual_population: true,
        id: "orders|frequency_period",
        options: [
          { value: "day", label: "Jour" },
          { value: "week", label: "Semaine" },
          { value: "month", label: "Mois" },
        ],
        selected: { value: "day", label: "Jour" },
      },*/
    ];
  };

  const calculateTrend = (y, metrics) => {
    if (!y || !y[metrics]) {
      return "flat";
    }
    var firstHalfTotal = 0;
    var secondHalfTotal = 0;
    var halfWayIndex = parseFloat(y[metrics].length / 2);
    y[metrics].map((DataPoint, Index) => {
      if (Index < halfWayIndex) {
        firstHalfTotal = firstHalfTotal + parseFloat(DataPoint);
      } else {
        secondHalfTotal = secondHalfTotal + parseFloat(DataPoint);
      }
    });
    if (secondHalfTotal > firstHalfTotal * 1.1) {
      return "up";
    } else if (secondHalfTotal < firstHalfTotal * 0.9) {
      return "down";
    } else {
      return "flat";
    }
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  var cardMdWidth = 3;
  if (y.length < 3) {
    //nothing
  } else if (y.length === 3) {
    cardMdWidth = 4;
  } else {
    cardMdWidth = 3;
  }
  console.log("x", x);
  console.log("y", y);

  return (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 0, mb: 2 }}
        spacing={2}
      >
        <Grid item xs={12}>
          <FilterBar
            filters={xFilters}
            filterIndex={0}
            isLoading={isLoading}
            setFilters={onXFilterUpdate}
            enableAddNewSerieButton={false}
          />
        </Grid>
        {yFilters.map((filters, index) => {
          return (
            <Grid item xs={12} key={`filter_bar_${page}_${index}`}>
              <FilterBar
                filters={filters}
                filterIndex={index}
                isLoading={isLoading}
                setFilters={onYFilterUpdate}
                enableAddNewSerieButton={index === yFilters.length - 1}
                addNewSerie={addNewSerie}
              />
            </Grid>
          );
        })}
      </Grid>
      <Fab
        onClick={refreshData}
        variant="extended"
        className={classNames(classes.btnUpdate, {
          [classes.hide]: !needToRefresh,
        })}
      >
        <RefreshIcon sx={{ mr: 1 }} />
        Mettre à jour
      </Fab>
      <Box
        className={classNames({
          [classes.overlay]: needToRefresh,
        })}
      >
        <Grid
          container
          sx={{ width: "100%" }}
          justify="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            spacing={2}
            sx={{ width: "100%" }}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            {charts.map((chart, indexChart) => {
              return y.map((serie, indexSerie) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={cardMdWidth}
                    key={y.label + "_" + indexSerie + "_" + indexChart}
                  >
                    <DashboardsCards
                      isLoading={isLoading}
                      refresh={refreshData}
                      primaryBGColor={
                        theme.palette.chart[
                          y.length > 1 ? indexSerie : indexChart
                        ]
                      }
                      primaryFontColor="white"
                      subtitle={
                        page === "conversion" &&
                        totals.length > 0 &&
                        totals[indexSerie] &&
                        totals[indexSerie]["impressions"] > 0
                          ? `${chart.label} [${
                              Math.round(
                                (totals[indexSerie][chart.metric] * 10000) /
                                  totals[indexSerie]["impressions"]
                              ) / 100
                            }%]`
                          : chart.label
                      }
                      metric={chart.metric}
                      suffix={chart.suffix}
                      value={
                        totals &&
                        totals.length > indexSerie &&
                        totals[indexSerie] &&
                        totals[indexSerie][chart.metric]
                          ? numberWithSpaces(totals[indexSerie][chart.metric]) +
                            chart.suffix
                          : "0" + chart.suffix
                      }
                      icon={chart.icon}
                      trend={calculateTrend(serie, chart.metric)}
                      onChangeChart={onChangeChart}
                    />
                  </Grid>
                );
              });
            })}
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 0 }}
            spacing={2}
          >
            <Grid item xs={12}>
              <ClassicChart
                key={currentChart ? currentChart.label : ""}
                title={currentChart ? currentChart.label : ""}
                mainValue={
                  currentChart &&
                  currentChart.metric &&
                  totals &&
                  totals.length > 0
                    ? totals.map((total, index) => {
                        return (
                          <Typography
                            variant="h4"
                            sx={{
                              color:
                                totals.length > 1
                                  ? theme.palette.chart[index]
                                  : "black",
                            }}
                          >
                            {numberWithSpaces(total[currentChart.metric]) +
                              currentChart.suffix}
                          </Typography>
                        );
                      })
                    : "0"
                }
                isLoading={isLoading}
                xAxis={x}
                series={y}
                status={[]}
                callBackStatusChange={callBackStatusChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

/*DashboardsPage.propTypes = {

}*/

export default RadarClassicPage;
