import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import _ from "lodash";
import moment from "moment";
//IMAGES AND ICONS
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SaveIcon from "@mui/icons-material/Save";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Grid,
} from "@mui/material";
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import AutocompleteWithAddForm from "../generic/AutocompleteWithAddForm";

//CONSTANTES
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "50rem",
  col5: "10rem",
};

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
  timeline: {},
  link: {
    textDecoration: "none !important",
    color: theme.palette.primary.main,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalJetpackCampaignErrors({ jetpackCampaign, saveErrors, initPage }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Mise à jour en cours..."
  );

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    var errorsLocal =
      jetpackCampaign && jetpackCampaign.errors
        ? JSON.parse(jetpackCampaign.errors)
        : [];
    setErrors(errorsLocal);
  }, [jetpackCampaign]);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setDialogsOpen({
      ...dialogsOpen,
      ModalJetpackCampaignErrors: false,
    });
  };

  const erraseError = (channelId) => {
    if (user.status.indexOf("admin") < 0) {
      displayNotification(
        "error",
        "Vous n'avez pas les droits pour effectuer cette action...",
        "top",
        "center"
      );
      return;
    }
    var newErrors = JSON.parse(JSON.stringify(errors));
    _.remove(newErrors, function (o) {
      return o.channelId === channelId;
    });
    setErrors(newErrors);
  };

  const handleSubmit = (e) => {
    var data = {
      jetpackCampaignId: jetpackCampaign.id,
      errors,
    };
    setIsLoading(true);
    POST("/api/jetpackcampaigns/errors/update", data)
      .then((response) => {
        setIsLoading(false);
        initPage();
        displayNotification(
          "success",
          "Rapport d'erreurs mis à jour",
          "top",
          "right"
        );
        setDialogsOpen({
          ...dialogsOpen,
          ModalJetpackCampaignErrors: false,
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setDialogsOpen({
          ...dialogsOpen,
          ModalJetpackCampaignErrors: false,
        });
        displayNotification(
          "error",
          "Erreur durant la modification de la campagne",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  return (
    <Dialog open={dialogsOpen.ModalJetpackCampaignErrors} onClose={handleClose}>
      <DialogTitle>Rapport d'erreurs de {jetpackCampaign.name}</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    Canal
                  </TableCell>
                  <TableCell style={{ width: colWidths.col3 }} align="center">
                    Erreurs
                  </TableCell>
                  <TableCell style={{ width: colWidths.col4 }} align="center">
                    Dernière erreur
                  </TableCell>
                  <TableCell
                    style={{ width: colWidths.col5 }}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errors.map((row) => {
                  return (
                    <TableRow>
                      <TableCell style={{ width: colWidths.col1 }}>
                        {row.channelName}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col2 }}
                        align="center"
                      >
                        {row.errorsCount}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col3 }}
                        align="center"
                      >
                        {moment(row.lastError).format("LLL")} {" - "}
                        {row.lastErrorType
                          ? row.lastErrorType
                          : "Unknown error"}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col5 }}
                        align="center"
                      >
                        <Button
                          disabled={
                            user.status.indexOf("admin") >= 0 ? false : true
                          }
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            erraseError(row.channelId);
                          }}
                          //endIcon={<NavigateBeforeIcon />}
                        >
                          Effacer
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Fermer</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
            >
              Sauvegarder
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalJetpackCampaignErrors.propTypes = {};

export default ModalJetpackCampaignErrors;
