import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST, POSTLONG } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import LinkIcon from "@mui/icons-material/Link";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
//CONST
const uber_ads_manager_id = "uber_ads_manager_id";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//This component is a modal allowing creation (or modification ?) of dahsboard integration
function ModalNewIntegration({ platforms, initPage }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [platform, setPlatform] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setError("");
    setIsLoading(false);
    setPlatform("");
    setEmail("");
    setPassword("");
    setDialogsOpen({ ...dialogsOpen, ModalNewIntegration: false });
  };

  const handleChangePlatform = (event) => {
    const {
      target: { value },
    } = event;
    setPlatform(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = (e) => {
    if (platform === "") {
      return setError("Veuillez indiquer une plateforme");
    }
    if (email === "") {
      return setError("Veuillez indiquer une adresse email");
    }
    if (password === "") {
      return setError("Veuillez indiquer un mot de passe");
    }
    setError("");
    setIsLoading(true);
    if (platform.type === "Ads Manager") {
      createAdPlatformLogins();
    } else if (platform.type === "Restaurant Manager") {
      createPlatformLogins();
    } else if (platform.type === "API") {
      createApiLogins();
    }
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const createPlatformLogins = () => {
    POST(
      "/api/platformlogins/create",
      {
        name: `${platform.name} | ${user.email} | ${email}`,
        label: platform.name,
        userGroupId: user.userGroupId,
        platformId: platform.id,
        login: email,
        pwd: password,
      },
      true,
      null,
      120000
    )
      .then((response) => {
        recursiveAPICallDuringLoginSynch(
          response.data.finished,
          response.data.message,
          response.data.nextURL
        );
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const createAdPlatformLogins = () => {
    POSTLONG(
      "/api/adsplatformlogins/create",
      {
        name: `${platform.name} | ${user.email} | ${email}`,
        label: platform.name,
        userGroupId: user.userGroupId,
        platformId: platform.id,
        login: email,
        pwd: password,
      },
      true,
      null,
      120000
    )
      .then((response) => {
        recursiveAPICallDuringLoginSynch(
          response.data.finished,
          response.data.message,
          response.data.nextURL
        );
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const createApiLogins = () => {
    POSTLONG(
      "/api/apilogins/create",
      {
        name: `${platform.name} | ${user.email} | ${email}`,
        label: platform.name,
        userGroupId: user.userGroupId,
        platformId: platform.id,
        clientId: email,
        secret: password,
      },
      true,
      null,
      120000
    )
      .then((response) => {
        recursiveAPICallDuringLoginSynch(
          response.data.finished,
          response.data.message,
          response.data.nextURL
        );
      })
      .catch((err) => {
        //setError(err);
        setIsLoading(false);
      });
  };

  const recursiveAPICallDuringLoginSynch = (finished, message, nextURL) => {
    if (finished) {
      handleClose();
      initPage();
      displayNotification(
        "success",
        "La plateforme est maintenant connectée 👌",
        "top",
        "right"
      );
    } else {
      setLoadingMessage(message);
      GET(nextURL, true, 120000)
        .then((response) => {
          recursiveAPICallDuringLoginSynch(
            response.data.finished,
            response.data.message,
            response.data.nextURL
          );
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Dialog open={dialogsOpen.ModalNewIntegration} onClose={handleClose}>
      <DialogTitle>Nouvelle intégration</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            Créer une nouvelle intégration plateforme en indiquant vos
            identifiants sur cette dernière (email et mot de passe).
          </DialogContentText>
          <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
            <Select
              displayEmpty
              value={platform}
              onChange={handleChangePlatform}
              input={<OutlinedInput />}
              required
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Choisissez la plateforme</em>;
                }
                var suffix =
                  selected.name && selected.name !== ""
                    ? " " + selected.type
                    : ""; //.join(", ");

                return selected.name + suffix;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>Restaurant manager</em>
              </MenuItem>
              {platforms.map((item) => (
                <MenuItem
                  key={item.id}
                  value={{ ...item, type: "Restaurant Manager" }}
                >
                  {item.name} restaurant manager
                </MenuItem>
              ))}
              <MenuItem disabled value="">
                <em>Ads manager</em>
              </MenuItem>
              {platforms.map((item) => (
                <MenuItem
                  key={item.id}
                  value={{ ...item, type: "Ads Manager" }}
                >
                  {item.name} ads manager
                </MenuItem>
              ))}
              <MenuItem disabled value="">
                <em>API</em>
              </MenuItem>
              {platforms.map((item) => (
                <MenuItem key={item.id} value={{ ...item, type: "API" }}>
                  {item.name} API
                </MenuItem>
              ))}
            </Select>

            <TextField
              className={classes.inputF}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={
                platform && platform.type === "API"
                  ? "ClientId"
                  : "Adresse email"
              }
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail color="disabled" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={
                platform && platform.type === "API" ? "Secret" : "Mot de passe"
              }
              type="text"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <Typography component="div" className={classes.error}>
              <Box className={classes.error}>{error}</Box>
            </Typography>
          </FormControl>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
            >
              Intégrer
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalNewIntegration.propTypes = {};

export default ModalNewIntegration;
