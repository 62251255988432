import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
//import { GoogleLogin } from "react-google-login";
import {  POST } from "../../js/HTTPRequest";

//Images & Icons
import Logo from "../../img/logo-banner.png";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";

//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

// Internal components

// *******************************************************
// ********************* LOCAL CONST *********************
// *******************************************************
//const clientId =
//  "964627512386-tpjv4au37tq4eljh59fhpvg60po3mrqm.apps.googleusercontent.com"; FOR GOOGLE AUTH - NOT AVAILABLE YET

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "block",
    width: "70px",
    margin: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    borderradius: "4px",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
  },
  inputF: {},
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  signup: {
    margin: theme.spacing(-2, 0, 2),
  },
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
  noDecoration: {
    textDecoration: "none !important",
  },
}));

/**
 *  - Description: Login page displaying either login or logout flow components
 *  - Parents: App.js
 *  - Props:
 *  None
 */
function SignupPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, updateUser } = useContext(UserContext);
  const [error, setError] = useState("");
  //const [errorGoogle, setErrorGoogle] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside loginPage");
  }, []);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  // --------------- LOGIN/PWD AUTH --------------

  const signupUser = (e) => {
    e.preventDefault();
    POST("/api/users/signup", { email, password })
      .then((response) => {
        console.log("Frontend Info - User login success");
        setError("");
        setSuccess(true);
        updateUser(response.data.user);
      })
      .catch((err) => {
        var error = "";
        switch (err.response.data) {
          case "User already exists":
            error = "Un compte a déjà été créé avec cette adresse.";
            break;
          default:
            error = "Une erreur est survenue. Inscription impossible";
            break;
        }
        setError(error);
      });
  };

  // ---------------- GOOGLE AUTH ----------------

  /*
  const onSuccess = (res) => {
    console.log("Login Success: currentUser:", res); //res.profileObj);
    POST("/api/google/auth", { idToken: res.tokenId })
      .then((result) => {
        //alert(
        //    `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
        //);
        setSuccess(true);
      })
      .catch((err) => {
        setError("Error during login, please contact your administrator.");
      });
    //refreshTokenSetup(res);
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
    setError("Error during login, please contact your administrator.");
  };*/

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  if (success || user.loggedIn) {
    const newpath = `/cockpit`;
    return <Navigate to={newpath} replace />; //Navigate replace Redirect in React-Router v6 : https://stackoverflow.com/questions/70005020/redirect-in-react-router-not-working-when-i-click-from-users-to-post/70005070
  }
  return (
    <Container maxWidth="sm">
      {" "}
      <div className={classes.paper}>
        <Grid
          container
          direction="column"
          spacing={2}
          justify="center"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="Logo" className={classes.logo} />
        </Grid>
        <Typography component="div">
          <Box fontSize={30} fontWeight={600} m={-2}>
            Inscription
          </Box>
        </Typography>
        <Typography
          component="div"
          justify="center"
          alignItems="center"
          justifyContent="center"
        >
          <Box fontSize={16} m={1} paddingT justify="center">
            Faites rentrer votre restaurant dans une autre dimension
          </Box>
        </Typography>
        <form className={classes.form} noValidate onSubmit={signupUser}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12} sm={9}>
              <TextField
                className={classes.inputF}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                m={0}
              >
                Inscription
              </Button>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <Typography component="div" className={classes.error}>
                <Box className={classes.error}>{error}</Box>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                href="/login"
                m={0}
                sx={{ mt: 1 }}
              >
                J'ai déjà un compte
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          justifyContent="center"
          spacing={0}
          sx={{ mt: 4 }}
        >
          {/*
          <Grid item xs={12}>
            <Divider>OU</Divider>
          </Grid>
          <Grid
            container
            sx={{ mt: 2 }}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <h6 className={classes.error}>{errorGoogle}</h6>
            <GoogleLogin
              clientId={clientId}
              buttonText="INSCRIPTION VIA GMAIL"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              //cookiePolicy={'http://localhost:8000'}
              className="btn-login"
              //isSignedIn={true}
            />
          </Grid>
           */}
        </Grid>
      </div>
    </Container>
  );
}

SignupPage.propTypes = {};

export default SignupPage;
