import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import moment from "moment";
import _ from "lodash";
import { makeComponentUniqueId } from "../../js/otherManager";
import { CSVLink, CSVDownload } from "react-csv";
import {
  generateAgendaDescriptionForJetpack,
  getCampaignsDetailsFromJetpack,
} from "../../js/jetpackManager";
import "moment/locale/fr";
//IMAGES AND ICONS
import SearchIcon from "@mui/icons-material/Search";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DownloadIcon from "@mui/icons-material/Download";

//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  InputAdornment,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Toolbar,
  TextField,
  Typography,
  useMediaQuery,
  TablePagination,
  TableContainer,
  TableHead,
  TableCell,
  TableSortLabel,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import WorkInProgressBanner from "../generic/WorkInProgressBanner";
import JetpackPerformanceRestaurantV2Row from "./JetpackPerformanceRestaurantV2Row";
import ModalJetpackCampaignChannelHistory from "./ModalJetpackCampaignChannelHistory";
import SkeletonJetpackPerformanceTable from "../../ui-components/Skeleton/SkeletonJetpackPerformanceTable";
//CONSTANTE
const colWidths = {
  col0: "1rem",
  col1: "1rem",
  col2: "15rem",
  col3: "20rem",
  col4: "10rem",
  col5: "10rem",
  col6: "10rem",
  col7: "10rem",
};
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },

  "@global": {
    ":root": {
      "--primary": theme.palette.primary.main,
      "--greyLight": theme.palette.primary.main,
      "--greyLight-2": "#cbe0dd",
      "--greyDark": "#2d4848",
    },
    "*": {
      margin: 0,
      padding: 0,
      boxSizing: "inherit",
    },
  },
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "var(--greyLight-2)",
    color: "var(--greyDark)",
  },
  ul: {
    listStyleType: "none",
  },
  itemsList: {
    maxWidth: "90vw",
    margin: "2rem",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: "3rem",
    justifyContent: "center",
    alignContent: "center",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  item: {
    width: "10rem",
    height: "10rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "var(--greyDark)",
    cursor: "pointer",
    "& span": {
      background: "#ffffff",
      boxShadow: "0 0.8rem 2rem rgba(90, 97, 129, 0.05)",
      borderRadius: "0.6rem",
      padding: "2rem",

      transition: "all 0.3s ease",
    },
    "&:hover span": {
      transform: "scale(1.2)",
      color: "var(--primary)",
    },
    "& p": {
      marginTop: "1rem",
      color: "var(--greyLight)",
    },
  },
  page: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "5rem",

    borderRadius: "0.6rem",

    "&__numbers, &__btn, &__dots": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0.8rem",
      cursor: "pointer",
    },
    "&__dots": {
      width: "2.6rem",
      height: "2.6rem",
      color: "var(--greyLight)",
      cursor: "initial",
    },
    "&__numbers": {
      width: "2.6rem",
      height: "2.6rem",
      borderRadius: "0.4rem",
      "&:hover": {
        color: "var(--primary)",
      },
      "&.active": {
        color: "#ffffff",
        background: "var(--primary)",
        fontWeight: 600,
        border: "1px solid var(--primary)",
      },
    },
    "&__btn": {
      color: "var(--greyLight)",
    },
  },
}));

// ********************* COMPONENT START ***********************

function JetpackPerformanceRestaurantV2Page(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [jetpackCampaigns, setJetpackCampaigns] = useState([]);
  const [jetpacksUnfiltered, setJetpacksUnfiltered] = useState([]); //FILTERED

  const [isLoading, setIsLoading] = useState(false);
  //const [channels, setChannels] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const displayStatusChangeHistory = (channel) => {
    setSelectedChannel(channel);
    setDialogsOpen({
      ...dialogsOpen,
      ModalJetpackCampaignChannelHistory: true,
    });
  };

  const updateFilterSearch = (newSearch) => {
    console.log("jetpacksUnfiltered", jetpacksUnfiltered);
    var newJetpacks = filterJetpacks(jetpacksUnfiltered, newSearch);
    setSearch(newSearch);
    console.log("newJetpacks", newJetpacks);
    setJetpackCampaigns(newJetpacks);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onPageClick = (number) => {
    setPage(number);
    init(number);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = (localPage = page) => {
    setIsLoading(true);
    GET(`/api/pages/jetpackperformancerestaurantpagev2/init/${localPage}`)
      .then((response) => {
        console.log("MY RESPONSE:", response.data);
        setIsLoading(false);
        /*var formattedJetpackCampaigns = formatJetpackCampaigns(response.data);
        console.log("formattedJetpackCampaigns", formattedJetpackCampaigns);
        setJetpacksUnfiltered(formattedJetpackCampaigns);
        var filteredJetpacks = filterJetpacks(
          formattedJetpackCampaigns,
          search
        );*/
        setJetpackCampaigns(
          response.data && response.data.length > 0 ? response.data : []
        );
        //setChannels(response.data.channels);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  /** DEPRECATED */
  const filterJetpacks = (localJetpacksUnfiltered, localSearch) => {
    if (!localSearch || search === "") {
      return localJetpacksUnfiltered;
    }
    var localJetpacksFiltered = [];
    try {
      localJetpacksUnfiltered.map((jetpack) => {
        if (
          jetpack.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
        ) {
          localJetpacksFiltered.push(jetpack);
        } else if (
          jetpack.actionType.toLowerCase().indexOf(localSearch.toLowerCase()) >=
          0
        ) {
          localJetpacksFiltered.push(jetpack);
        } else if (
          jetpack.customersTarget
            .toLowerCase()
            .indexOf(localSearch.toLowerCase()) >= 0
        ) {
          localJetpacksFiltered.push(jetpack);
        } else {
          //Check among tags
          var matchCriteria = false;
          jetpack.agendaDescriptions.map((row) => {
            if (row.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0) {
              matchCriteria = true;
            }
          });
          jetpack.actionDetails.map((row) => {
            if (row.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0) {
              matchCriteria = true;
            }
          });
          if (matchCriteria) {
            localJetpacksFiltered.push(jetpack);
          }
        }
      });
    } catch (err) {
      console.log("here", err);
    }

    return localJetpacksFiltered;
  };

  /**DEPRECATED */
  const formatJetpackCampaigns = (localJetpackCampaigns) => {
    localJetpackCampaigns.map((jetpackCampaign) => {
      var agendaDescriptions =
        generateAgendaDescriptionForJetpack(jetpackCampaign);
      jetpackCampaign.agendaDescriptions = agendaDescriptions;
      var { actionType, actionDetails, customersTarget } =
        getCampaignsDetailsFromJetpack(jetpackCampaign, constants);
      jetpackCampaign.actionType = actionType;
      jetpackCampaign.actionDetails = actionDetails;
      jetpackCampaign.customersTarget = customersTarget;
    });
    return localJetpackCampaigns;
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    var compA = a[orderBy];
    var compB = b[orderBy];
    if (orderBy.indexOf(".") >= 0) {
      var fields = orderBy.split(".");
      switch (fields.length) {
        case 2:
          compA = a[fields[0]] ? a[fields[0]][fields[1]] : null;
          compB = b[fields[0]] ? b[fields[0]][fields[1]] : null;
          break;
        case 3:
          compA =
            a[fields[0]] && a[fields[0]][fields[1]]
              ? a[fields[0]][fields[1]][fields[2]]
              : null;
          compB =
            b[fields[0]] && b[fields[0]][fields[1]]
              ? b[fields[0]][fields[1]][fields[2]]
              : null;
          break;
        case 4:
          compA =
            a[fields[0]] &&
            a[fields[0]][fields[1]] &&
            a[fields[0]][fields[1]][fields[2]]
              ? a[fields[0]][fields[1]][fields[2]][fields[3]]
              : null;
          compB =
            b[fields[0]] &&
            b[fields[0]][fields[1]] &&
            b[fields[0]][fields[1]][fields[2]]
              ? b[fields[0]][fields[1]][fields[2]][fields[3]]
              : null;
          break;
        default:
        //Nothing
      }
    } else {
      compA = a[orderBy];
      compB = b[orderBy];
    }
    if (!isNaN(b[orderBy] & !isNaN(a[orderBy]))) {
      compA = parseFloat(compA);
      compB = parseFloat(compB);
    }
    if (compB < compA) {
      return -1;
    }
    if (compB > compA) {
      return 1;
    }
    return 0;
  };

  const displayDotsPaginer = () => {
    if (page < 3) {
      return (
        <React.Fragment>
          <li className={`${classes.page}__dots`}>...</li>
        </React.Fragment>
      );
    }
    if (page == 3) {
      return (
        <React.Fragment>
          <li
            className={`${classes.page}__numbers active`}
            onClick={(e) => onPageClick(3)}
          >
            4
          </li>
          <li className={`${classes.page}__dots`}>...</li>
        </React.Fragment>
      );
    }
    if (page > 3) {
      return (
        <React.Fragment>
          <li className={`${classes.page}__dots`}>...</li>
          <li
            className={`${classes.page}__numbers active`}
            onClick={(e) => onPageClick(page)}
          >
            {page + 1}
          </li>
          <li className={`${classes.page}__dots`}>...</li>
        </React.Fragment>
      );
    }
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      {isLoading ? (
        <SkeletonJetpackPerformanceTable />
      ) : (
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/*<Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Tooltip title="Rechercher">
              <TextField
                className={classes.inputF}
                variant="outlined"
                margin="normal"
                fullWidth
                id="search"
                label={`Rechercher une campagne`}
                name="search"
                autoComplete="search"
                value={search}
                style={{ height: "80px", paddingTop: "9px" }}
                onChange={(e) => {
                  updateFilterSearch(e.target.value);
                }}
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
              </Toolbar> */}

          <ul className={`${classes.page} page`}>
            <li className={`${classes.page}__btn`}>
              <ChevronLeftIcon
                onClick={(e) => onPageClick(Math.max(0, page - 1))}
              />
            </li>

            <li
              className={`${classes.page}__numbers ${
                page == 0 ? "active" : ""
              }`}
              onClick={(e) => onPageClick(0)}
            >
              1
            </li>
            <li
              className={`${classes.page}__numbers ${
                page == 1 ? "active" : ""
              }`}
              onClick={(e) => onPageClick(1)}
            >
              2
            </li>
            <li
              className={`${classes.page}__numbers ${
                page == 2 ? "active" : ""
              }`}
              onClick={(e) => onPageClick(2)}
            >
              3
            </li>
            {displayDotsPaginer()}
            <li
              className={`${classes.page}__btn`}
              onClick={(e) => onPageClick(page + 1)}
            >
              <ChevronRightIcon onClick={(e) => onPageClick(page + 1)} />
            </li>
          </ul>
          <CSVLink data={jetpackCampaigns} className="btn btn-primary">
            <Tooltip title="Télécharger les données de la page en format CSV">
              <DownloadIcon style={{ color: theme.palette.primary.main }} />
            </Tooltip>
          </CSVLink>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ width: colWidths.col0 }}
                    align="center"
                  ></TableCell>
                  <TableCell
                    style={{ width: colWidths.col1 }}
                    align="center"
                  ></TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Type de promotion">
                      <Typography variant="body">Promotion</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Type de promotion">
                      <Typography variant="body">Statut</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col3 }} align="center">
                    <Tooltip title="Période d'activité de la campagne">
                      <Typography variant="body">Période</Typography>
                    </Tooltip>
                  </TableCell>
                  {/*<TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Horaires durant lesquels la promotion est active">
                      <Typography variant="body">Horaires</Typography>
                    </Tooltip>
            </TableCell>*/}
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Tous clients / nouveaux clients">
                      <Typography variant="body">Cible</Typography>
                    </Tooltip>
                  </TableCell>
                  {/*<TableCell
                    align={"center"}
                    style={{ width: colWidths.col4 }}
                    padding={"normal"}
                    sortDirection={
                      orderBy === "optIn.ratioActive" ? order : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "optIn.ratioActive"}
                      direction={
                        orderBy === "optIn.ratioActive" ? order : "asc"
                      }
                      onClick={createSortHandler("optIn.ratioActive")}
                    >
                      <Tooltip title="Pourcentage de temps durant lequel le ou les restaurants avaient cette campagne active">
                        <Typography variant="body">% opted-in</Typography>
                      </Tooltip>
                      {orderBy === "optIn.ratioActive" ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                      </TableCell>*/}
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Montant offert au client TTC.">
                      <Typography variant="body">Montant offert</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Clients qui n'avaient jamais commandé dans le restaurant acquis grâce à la promotion">
                      <Typography variant="body">Nouveaux clients</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: colWidths.col2 }} align="center">
                    <Tooltip title="Ventes générées TTC">
                      <Typography variant="body">Ventes générées</Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jetpackCampaigns.map((jetpackCampaign, index) => {
                  if (jetpackCampaign) {
                    return (
                      <JetpackPerformanceRestaurantV2Row
                        key={
                          "JetpackPerformanceRestaurantRow_" +
                          jetpackCampaign.marketingCampaignId
                        }
                        isRestaurant={false}
                        item={jetpackCampaign}
                        colWidths={colWidths}
                        displayStatusChangeHistory={displayStatusChangeHistory}
                      />
                    );
                  } else {
                    return <div key={makeComponentUniqueId()}></div>;
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      <ModalJetpackCampaignChannelHistory channel={selectedChannel} />
    </div>
  );
}

JetpackPerformanceRestaurantV2Page.propTypes = {};

// ********************* COMPONENT END ***********************

export default JetpackPerformanceRestaurantV2Page;
