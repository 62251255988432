import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { GET, POST } from "../../js/HTTPRequest";
import { generateColorFromString, hexToRgb } from "../../js/designManager";
import classNames from "classnames";
//IMAGES AND ICONS
import Logo_UberEats from "../../img/logo-cercle-ubereats.png";
import Logo_Deliveroo from "../../img/logo-cercle-deliveroo.png";
import StyleIcon from "@mui/icons-material/Style";
import RefreshIcon from "@mui/icons-material/Refresh";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Checkbox,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Fab,
  TableRow,
} from "@mui/material";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import ModalApplyTags from "./ModalApplyTags";
import ModalContinueOrCancel from "../generic/ModalContinueOrCancel";

// HEAD CELLS OF THE TABLE
const headCells = [
  {
    id: "name",
    left: true,
    disablePadding: true,
    label: "Canaux",
  },
  {
    id: "platform",
    left: false,
    disablePadding: false,
    label: "Plateforme",
  },
  {
    id: "tags_locations",
    left: false,
    disablePadding: false,
    label: "Adresse",
  },
  {
    id: "tags_brands",
    left: false,
    disablePadding: false,
    label: "Marque",
  },
  {
    id: "users",
    left: false,
    disablePadding: false,
    label: "Utilisateurs",
  },
  {
    id: "tags_others",
    left: false,
    disablePadding: false,
    label: "Autres tags",
  },
];

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    width: "100%",
  },
  overlay: {
    filter: "blur(3px)",
    //position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.54)",
    //color: "white",
    top: "0px",
    left: "0px",
    zIndex: 1000,
  },
  btnUpdate: {
    position: "fixed",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    top: "100px",
    zIndex: 1001,
  },
  hide: {
    display: "none!important",
  },
}));

// ********************* COMPONENT START ***********************

function AdminRestaurantPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [channels, setChannels] = useState([]); //FILTERED
  const [channelsUnfiltered, setchannelsUnfiltered] = useState([]); //FILTERED
  const [locations, setLocations] = useState([]);
  const [brands, setBrands] = useState([]);
  const [others, setOthers] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("Besancon");
  const [selectedTagging, setSelectedTagging] = useState({});
  const [selectedUserAssociation, setSelectedUserAssociation] = useState({});
  const [needToRefresh, setNeedToRefresh] = useState(false);
  const [tagSQLModeLive, setTagSQLModeLive] = useState(false);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = channels.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = _.findIndex(selected, function (o) {
      return o.id === row.id;
    });
    var newSelecteds = selected ? JSON.parse(JSON.stringify(selected)) : [];
    if (selectedIndex < 0) {
      newSelecteds = [...newSelecteds, row];
    } else {
      _.remove(newSelecteds, function (o) {
        return o.id === row.id;
      });
    }
    setSelected(newSelecteds);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) =>
    _.findIndex(selected, function (o) {
      return o.id === row.id;
    }) >= 0;

  const onClickMultiApplyIcon = (sqlModeLive) => {
    setTagSQLModeLive(sqlModeLive);
    setDialogsOpen({ ...dialogsOpen, ModalApplyTags: true });
  };

  const updateFilterSearch = (newSearch) => {
    var newChannels = filterChannels(channelsUnfiltered, newSearch);
    setNeedToRefresh(true);
    setSearch(newSearch);
    setChannels(newChannels);
  };

  const handleDeleteOtherTagUnconfirmed = (tagging) => {
    setSelectedTagging(tagging);
    setDialogsOpen({ ...dialogsOpen, ModalDeleteChannelTag: true });
  };

  const handleUserUnconfirmed = (userItem) => {
    //NO MODAL CONFIRMATION ASKED FOR THE MOMENT
    /*setSelectedUserAssociation(userItem);
    setDialogsOpen({ ...dialogsOpen, ModalDeleteChannelTag: true });*/
    POST("/api/users/channelsassociations/delete", {
      id: userItem.userAssociationId,
    })
      .then((response) => {
        init();
        displayNotification(
          "success",
          "L'utilisateur a bien été dissocié du restaurant 👌",
          "top",
          "right"
        );
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la désassociation de l'utilisateur...",
          "top",
          "center"
        );
      });
  };

  const handleDeleteOtherTagConfirmed = () => {
    POST("/api/taggingschannels/delete", {
      taggingId: selectedTagging.taggingId,
    })
      .then((response) => {
        init();
        displayNotification(
          "success",
          "Le tag a bien été retiré 👌",
          "top",
          "right"
        );
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la suppression de votre tag...",
          "top",
          "center"
        );
      });
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    POST("/api/pages/adminrestaurantspage/init", { search })
      .then((response) => {
        //displayNotification("success", "Chargement réussi", "top", "center");
        var newChannelsUnfiltered = formatChannelsListForTable(
          response.data.channels
        );
        var newChannels = filterChannels(newChannelsUnfiltered, search);
        setSearch(search);
        setChannels(newChannels);
        setchannelsUnfiltered(newChannelsUnfiltered);
        setLocations(response.data.locations);
        setBrands(response.data.brands);
        setOthers(response.data.others);
        setNeedToRefresh(false);
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  const formatChannelsListForTable = (channelsList) => {
    var formattedChannel = [];
    if (!channelsList || channelsList.length < 1) {
      return formattedChannel;
    }

    channelsList.map((Channel) => {
      //Check if location available
      var locations = [];
      var brands = [];
      var others = [];
      var users = [];
      if (
        Channel &&
        Channel.taggings_channels &&
        Channel.taggings_channels.length > 0
      ) {
        Channel.taggings_channels.map((tagging) => {
          if (tagging && tagging.Tags_Location) {
            var tmpLocation = tagging.Tags_Location;
            tmpLocation.taggingId = tagging.id;
            locations.push(tmpLocation);
          }
          if (tagging && tagging.Tags_Brand) {
            var tmpBrand = tagging.Tags_Brand;
            tmpBrand.taggingId = tagging.id;
            brands.push(tmpBrand);
          }
          if (tagging && tagging.Tags_Other) {
            var tmpOther = tagging.Tags_Other;
            tmpOther.taggingId = tagging.id;
            others.push(tmpOther);
          }
        });
      }
      if (
        Channel &&
        Channel.usersAssociations &&
        Channel.usersAssociations.length > 0
      ) {
        Channel.usersAssociations.map((association) => {
          var tmpUser = association.User;
          tmpUser.userAssociationId = association.id; //to ease deleting
          users.push(tmpUser);
          return null;
        });
      }
      formattedChannel.push({
        id: Channel.id,
        name: Channel.name,
        platform: Channel.Platform.name,
        locations,
        brands,
        others,
        users,
      });
    });

    return formattedChannel;
  };

  const filterChannels = (localChannelsUnfiltered, localSearch) => {
    var localChannelFiltered = [];
    localChannelsUnfiltered.map((channel) => {
      if (channel.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0) {
        localChannelFiltered.push(channel);
      } else {
        //Check among tags
        var matchCriteria = false;
        channel.locations.map((tagging) => {
          if (
            tagging.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        channel.brands.map((tagging) => {
          if (
            tagging.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        channel.others.map((tagging) => {
          if (
            tagging.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        channel.users.map((userItem) => {
          if (
            userItem.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        if (matchCriteria) {
          localChannelFiltered.push(channel);
        }
      }
    });
    return localChannelFiltered;
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - channels.length) : 0;

  return (
    <Box className={classes.boxWrapper}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title="Sélectionnez des canaux pour appliquer vos tags"
          multiApplyIcon={<StyleIcon />}
          multiApplyLabel="Taguer"
          onClickMultiApplyIcon={onClickMultiApplyIcon}
          search={search}
          updateFilterSearch={updateFilterSearch}
        />
        <Fab
          onClick={init}
          variant="extended"
          className={classNames(classes.btnUpdate, {
            [classes.hide]: !needToRefresh,
          })}
        >
          <RefreshIcon sx={{ mr: 1 }} />
          Mettre à jour
        </Fab>
        <Box
          className={classNames({
            [classes.overlay]: needToRefresh,
          })}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={channels.length}
                headCells={headCells}
              />
              <TableBody>
                {/* This method is created for cross-browser compatibility (IE11) */}
                {stableSort(channels, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="center">
                          <img
                            style={{ width: 30, height: 30 }}
                            alt={row.platform}
                            src={
                              row.platform === "Uber Eats" //DIRTY : MOVE IMAGE URL INTO DATABASE
                                ? Logo_UberEats
                                : Logo_Deliveroo
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          {row.locations.map((location) => {
                            var chipColor =
                              "#" +
                              generateColorFromString(
                                location.name ? location.name : "empty"
                              );
                            return (
                              <Chip
                                key={location.id}
                                sx={{
                                  backgroundColor:
                                    alpha(chipColor, 0.3) + "!important",
                                }}
                                label={location.name}
                                onDelete={() => {
                                  handleDeleteOtherTagUnconfirmed(location);
                                }}
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {row.brands.map((brand) => {
                            var chipColor =
                              "#" +
                              generateColorFromString(
                                brand.name ? brand.name : "empty"
                              );
                            return (
                              <Chip
                                key={brand.id}
                                sx={{
                                  backgroundColor:
                                    alpha(chipColor, 0.3) + "!important",
                                }}
                                label={brand.name}
                                onDelete={() => {
                                  handleDeleteOtherTagUnconfirmed(brand);
                                }}
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {row.users.map((userItem) => {
                            var chipColor =
                              "#" +
                              generateColorFromString(
                                userItem.status ? userItem.status : "empty"
                              );
                            return (
                              <Chip
                                key={userItem.userAssociationId}
                                size="small"
                                sx={{
                                  margin: "2px",
                                  backgroundColor:
                                    alpha(chipColor, 0.3) + "!important",
                                }}
                                label={userItem.name + ` [${userItem.status}]`}
                                onDelete={() => {
                                  handleUserUnconfirmed(userItem);
                                }}
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {row.others.map((other) => {
                            var chipColor =
                              "#" +
                              generateColorFromString(
                                other.name ? other.name : "empty"
                              );
                            return (
                              <Chip
                                key={other.id}
                                sx={{
                                  margin: "2px",
                                  backgroundColor:
                                    alpha(chipColor, 0.3) + "!important",
                                }}
                                label={other.name}
                                size="small"
                                onDelete={() => {
                                  handleDeleteOtherTagUnconfirmed(other);
                                }}
                              />
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 50, 100]}
            component="div"
            count={channels.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Canaux par page"
          />
        </Box>
      </Paper>
      <ModalApplyTags
        initPage={init}
        numSelected={selected.length}
        channels={selected}
        locations={locations}
        brands={brands}
        others={others}
        sqlModeLive={tagSQLModeLive}
      />
      <ModalContinueOrCancel
        title="Suppression du tag"
        content="Souhaitez-vous réellement supprimer ce tag ?"
        modalId="ModalDeleteChannelTag"
        handleCancel={() => {}}
        handleContinue={() => {
          handleDeleteOtherTagConfirmed();
        }}
      />
    </Box>
  );
}

AdminRestaurantPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default AdminRestaurantPage;
