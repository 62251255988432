import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/fr";
import _ from "lodash";
//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CardJetpackCampaignRestaurant from "./CardJetpackCampaignRestaurant";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function JetpackCampaignsRestaurantPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [jetpackCampaigns, setJetpackCampaigns] = useState([]);
  const [channels, setChannels] = useState(null);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************
  const init = () => {
    GET("/api/pages/jetpackcampaignsrestaurantpage/init")
      .then((response) => {
        setJetpackCampaigns(response.data.jetpackCampaigns);
        setChannels(response.data.channels);
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  var currentJetpackCampaigns = _.filter(
    jetpackCampaigns,
    function (jetpackCampaign) {
      var isCurrentJetpack = true;
      if (jetpackCampaign.start) {
        if (moment(jetpackCampaign.start).isAfter(moment(new Date()))) {
          isCurrentJetpack = false;
        }
      }
      if (jetpackCampaign.end) {
        if (moment(jetpackCampaign.end).isBefore(moment(new Date()))) {
          isCurrentJetpack = false;
        }
      }
      return isCurrentJetpack;
    }
  );
  var futureJetpackCampaigns = _.filter(
    jetpackCampaigns,
    function (jetpackCampaign) {
      var isFutureJetpack = false;
      if (jetpackCampaign.start) {
        if (moment(jetpackCampaign.start).isAfter(moment(new Date()))) {
          isFutureJetpack = true;
        }
      }
      return isFutureJetpack;
    }
  );

  return (
    <div className={classes.div}>
      <Grid container spacing={2}>
        <Grid item mt={0} xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Campagnes en cours
            </Typography>
          </Grid>
          {currentJetpackCampaigns && currentJetpackCampaigns.length > 0 ? (
            currentJetpackCampaigns.map((jetpackCampaign) => {
              if (jetpackCampaign && jetpackCampaign.id) {
                return (
                  <Grid
                    key={
                      "Grid_CardJetpackCampaignRestaurant" + jetpackCampaign.id
                    }
                    item
                    xs={12}
                    sx={{ mt: 2 }}
                  >
                    <CardJetpackCampaignRestaurant
                      jetpackCampaign={jetpackCampaign}
                      initPage={init}
                    />
                  </Grid>
                );
              } else {
                return <div></div>;
              }
            })
          ) : (
            <Typography variant="body2" sx={{ mb: 2 }}>
              Aucune campagne en cours pour le moment
            </Typography>
          )}
        </Grid>
        <Grid item mt={0} xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Campagnes futures
            </Typography>
          </Grid>
          {futureJetpackCampaigns && futureJetpackCampaigns.length > 0 ? (
            futureJetpackCampaigns.map((jetpackCampaign) => {
              return (
                <Grid key={jetpackCampaign.id} item xs={12} sx={{ mt: 2 }}>
                  <CardJetpackCampaignRestaurant
                    jetpackCampaign={jetpackCampaign}
                    initPage={init}
                  />
                </Grid>
              );
            })
          ) : (
            <Typography variant="body2" sx={{ mb: 2 }}>
              Aucune campagne plannifiée pour le moment
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

JetpackCampaignsRestaurantPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default JetpackCampaignsRestaurantPage;
