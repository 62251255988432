import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import { numberWithSpaces } from "../../js/numberManager";
//IMAGES AND ICONS
import Logo from "../../img/logo.png";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import SkeletonDashboardRow from "../../ui-components/Skeleton/SkeletonDashboardRow";
//CONSTANTE

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  variationDiv: {
    fontSize: 12,
    backgroundColor: theme.palette.background.default,
    width: "fit-content",
    padding: "2px 7px",
    minWidth: "50px",
    textAlign: "center",
  },
}));

// ********************* COMPONENT START ***********************

function RadarLeaderBoardRow({
  ranking,
  row,
  columns,
  colWidths,
  suffix,
  happy_when_increase,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <React.Fragment>
      {isLoading ? (
        <TableRow>
          <TableCell style={{ padding: 0, margin: 0 }} colSpan={8}>
            <SkeletonDashboardRow />
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell style={{ width: colWidths.col1 }} align="left">
            <Tooltip title="Nom du restaurant">
              <Typography variant="body">
                #{ranking} {row.channelName}
              </Typography>
            </Tooltip>
          </TableCell>
          {columns.map((column, index) => {
            if (column.hide) {
              return null;
            }
            var variation =
              parseFloat(row[columns[index - 1].id]) !== 0
                ? parseInt(
                    (((row[column.id] - row[columns[index - 1].id]) * 100) /
                      row[columns[index - 1].id]) *
                      10
                  ) /
                    10 +
                  "%"
                : "-";

            return (
              <TableCell style={{ width: colWidths.col2 }} align="center">
                <Typography variant="body">{}</Typography>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item align="right" xs={6}>
                    {numberWithSpaces(row[column.id])}
                    {suffix}
                  </Grid>
                  <Grid
                    item
                    align="left"
                    xs={6}
                    style={{
                      color:
                        parseFloat(row[columns[index - 1].id]) !== 0
                          ? parseFloat(row[column.id]) >=
                            parseFloat(row[columns[index - 1].id])
                            ? happy_when_increase
                              ? "green"
                              : "red"
                            : happy_when_increase
                            ? "red"
                            : "green"
                          : "grey",
                    }}
                  >
                    <div className={classes.variationDiv}>
                      {parseFloat(row[columns[index - 1].id] !== 0) &&
                      parseFloat(row[column.id]) >=
                        parseFloat(row[columns[index - 1].id])
                        ? "+"
                        : ""}
                      {variation}
                    </div>
                  </Grid>
                </Grid>
              </TableCell>
            );
          })}
        </TableRow>
      )}
    </React.Fragment>
  );
}

RadarLeaderBoardRow.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarLeaderBoardRow;
