import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import StyleIcon from "@mui/icons-material/Style";
import BugReportIcon from "@mui/icons-material/BugReport";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import AutocompleteWithAddForm from "../generic/AutocompleteWithAddForm";
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  testResultBox: {
    backgroundColor: "lightgrey",
    padding: "5px",
  },
}));

// ********************* COMPONENT START ***********************

function ModalApplyTags({
  initPage,
  numSelected,
  locations,
  brands,
  others,
  channels,
  sqlModeLive,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  //const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [testResult, setTestResult] = useState([]);
  const [testResultKeys, setTestResultKeys] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");

  const [location, setLocation] = useState("");
  const [brand, setBrand] = useState("");
  const [other, setOther] = useState([]);
  const [query, setQuery] = useState("");
  const [
    mustDeleteTagOnUntargettedChannels,
    setMustDeleteTagOnUntargettedChannels,
  ] = useState(false);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setLocation("");
    setBrand("");
    setOther([]);
    setError("");
    setWarning("");
    setTestResult([]);
    setTestResultKeys([]);
    setQuery("");
    setMustDeleteTagOnUntargettedChannels(false);
    setDialogsOpen({ ...dialogsOpen, ModalApplyTags: false });
  };

  const handleSubmitQuery = (isTest) => {
    var taggings = buidTaggingPayload(null);
    if (!taggings || taggings.length < 1) {
      setError("Vous n'avez listé aucun tag à appliquer.");
      setTestResult([]);
      setTestResultKeys([]);
      return;
    }
    setIsLoading(true);
    POST("/api/channels/tag/query", {
      isTest,
      taggings,
      query,
      mustDeleteTagOnUntargettedChannels,
    })
      .then((response) => {
        setIsLoading(false);
        setError(response.data.error);
        setWarning(response.data.warning);
        if (response && response.data && response.data.testResult) {
          setTestResult(response.data.testResult);
          if (response.data.testResult.length > 0) {
            setTestResultKeys(Object.keys(response.data.testResult[0]));
          }
        }
        if (isTest) {
          return;
        } else {
          displayNotification(
            "success",
            response.data.notification,
            "top",
            "right"
          );
          initPage();
          setLocation("");
          setBrand("");
          setOther([]);
          setError("");
          setWarning("");
          setTestResult([]);
          setTestResultKeys([]);
          setQuery("");
          setMustDeleteTagOnUntargettedChannels(false);
          setDialogsOpen({ ...dialogsOpen, ModalApplyTags: false });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant l'envoi de la requête",
          "top",
          "center"
        );
      });
  };

  const handleSubmit = (e) => {
    var taggings = [];
    channels.map((channel) => {
      taggings = taggings.concat(buidTaggingPayload(channel));
      return null;
    });
    setIsLoading(true);
    POST("/api/channels/tag", taggings)
      .then((response) => {
        setIsLoading(false);
        initPage();
        setLocation("");
        setBrand("");
        setOther([]);
        setError("");
        setQuery("");
        setMustDeleteTagOnUntargettedChannels(false);
        setWarning("");
        setTestResult([]);
        setTestResultKeys([]);
        setDialogsOpen({ ...dialogsOpen, ModalApplyTags: false });
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant l'application des tags",
          "top",
          "center"
        );
      });
  };

  const createNewLocation = (locationLocal) => {
    locationLocal.userGroupId = user.userGroupId;
    POST("/api/locations/create", locationLocal)
      .then((response) => {
        setLocation(response.data);
        initPage();
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la création de l'adresse",
          "top",
          "center"
        );
      });
  };

  const createNewBrand = (brandLocal) => {
    brandLocal.userGroupId = user.userGroupId;
    POST("/api/brands/create", brandLocal)
      .then((response) => {
        setBrand(response.data);
        initPage();
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la création de la marque",
          "top",
          "center"
        );
      });
  };

  const createNewOther = (otherLocal) => {
    otherLocal.userGroupId = user.userGroupId;
    POST("/api/othertags/create", otherLocal)
      .then((response) => {
        setOther([...other, response.data]);
        initPage();
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la création du tag",
          "top",
          "center"
        );
      });
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  //Build payload for API cal
  const buidTaggingPayload = (channel) => {
    var taggings = [];
    if (location && location !== "") {
      if (sqlModeLive) {
        taggings.push({
          tagName: location.name,
          tags_locationId: location.id,
        });
      } else {
        taggings.push({
          name: `Tag ${location.name} - ${channel.name}`,
          channelId: channel.id,
          tags_locationId: location.id,
        });
      }
    }
    if (brand && brand !== "") {
      if (sqlModeLive) {
        taggings.push({
          tagName: brand.name,
          tags_brandId: brand.id,
        });
      } else {
        taggings.push({
          name: `Tag ${brand.name} - ${channel.name}`,
          channelId: channel.id,
          tags_brandId: brand.id,
        });
      }
    }
    if (other && other.length > 0) {
      other.map((Tag) => {
        if (sqlModeLive) {
          taggings.push({
            tagName: Tag.name,
            tags_otherId: Tag.id,
          });
        } else {
          taggings.push({
            name: `Tag ${Tag.name} - ${channel.name}`,
            channelId: channel.id,
            tags_otherId: Tag.id,
          });
        }
        return null;
      });
    }
    return taggings;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Dialog open={dialogsOpen.ModalApplyTags} onClose={handleClose}>
      <DialogTitle>
        {sqlModeLive
          ? "Appliquez vos tags à partir d'une requête SQL"
          : "Appliquez vos tags à " +
            numSelected +
            " " +
            (numSelected > 1 ? "canaux" : "canal")}
      </DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            Taguez vos canaux pour simplifier la création de vos campagnes et
            l'analyse de vos performances. Tag à appliquer
          </DialogContentText>
          <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
            {/* -------------------- ADDRESS -------------------- */}
            <AutocompleteWithAddForm
              options={locations}
              multiple={false}
              value={location}
              setValue={setLocation}
              createNewEntry={createNewLocation}
              title={"Adresse"}
              mainField="name"
              fields={[
                {
                  id: "name",
                  label: "Nom",
                  type: "text",
                  prefill: true,
                  placeholder: "Ex: Paris 10",
                },
                {
                  id: "address",
                  label: "Adresse",
                  type: "text",
                  autofocus: true,
                  placeholder: "Ex: 48, rue de Chabrol 75010 Paris",
                },
                {
                  id: "address_comment",
                  label: "Indication livreurs",
                  type: "text",
                  placeholder: "Ex: Restaurant avec l'enseigne jaune.",
                },
              ]}
            />
            {/* -------------------- BRAND -------------------- */}
            <AutocompleteWithAddForm
              options={brands}
              multiple={false}
              value={brand}
              setValue={setBrand}
              createNewEntry={createNewBrand}
              title={"Marque"}
              mainField="name"
              fields={[
                {
                  id: "name",
                  label: "Nom",
                  type: "text",
                  prefill: true,
                  placeholder: "Ex: Bun Meat Bun",
                },
                {
                  id: "description",
                  label: "Description",
                  type: "text",
                  autofocus: true,
                  placeholder:
                    "Ex: Simplement de vrais burgers basiques mais tellement bons, inspirés des recettes originales des US",
                },
              ]}
            />
            {/* -------------------- OTHER TAGS -------------------- */}
            <AutocompleteWithAddForm
              options={others}
              multiple={true}
              value={other}
              setValue={setOther}
              createNewEntry={createNewOther}
              title={"Autres tags"}
              mainField="name"
              fields={[
                {
                  id: "name",
                  label: "Nom",
                  type: "text",
                  prefill: true,
                  placeholder: "Ex: Testing_Magnet_02_2022_GroupA",
                },
                {
                  id: "type",
                  label: "Type",
                  autofocus: true,
                  type: "test",
                  restrictedValue: [
                    { value: "business", label: "Business" },
                    { value: "experiment", label: "Expérience" },
                    { value: "geographic", label: "Géographique" },
                    { value: "other", label: "Autres" },
                  ],
                },
                {
                  id: "description",
                  label: "Description",
                  type: "text",
                  placeholder:
                    "Ex: Groupe de contrôle lors de l'expérience sur la performance de Magnet.",
                },
              ]}
            />
          </FormControl>
          {sqlModeLive ? (
            <div>
              <DialogContentText sx={{ mt: 2 }}>
                Indiquer votre requête SQL. Votre resultat doit contenir une
                colonne "channelId" et une colonne "channelName"
              </DialogContentText>
              <TextField
                id={"filter-query"}
                label="Requête"
                multiline
                rows={3}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                sx={{ width: "100%" }}
                variant="standard"
              />
              <FormControlLabel
                label="Supprimer les tags sur les canaux non ciblés par la requête"
                control={
                  <Checkbox
                    color="primary"
                    checked={mustDeleteTagOnUntargettedChannels}
                    onChange={(e) => {
                      setMustDeleteTagOnUntargettedChannels(e.target.checked);
                    }}
                    inputProps={{
                      "aria-labelledby": "labelMustDeleteTag",
                    }}
                  />
                }
              />
            </div>
          ) : (
            <div></div>
          )}
          <Typography variant="body2" color="red">
            {error}
          </Typography>
          <Typography variant="body2" color={"red"}>
            {warning}
          </Typography>
          {testResult && testResult.length > 0 ? (
            <Box className={classes.testResultBox}>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      {testResultKeys.map((key) => {
                        return <TableCell key={key}>{key}</TableCell>;
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {testResult.map((row) => {
                      return (
                        <TableRow key={row[testResultKeys[0]]}>
                          {testResultKeys.map((key) => {
                            return <TableCell>{row[key]}</TableCell>;
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <div></div>
          )}
        </DialogContent>
      )}

      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            {sqlModeLive ? (
              <Button
                onClick={() => {
                  handleSubmitQuery(true);
                }}
                variant="outlined"
                color="primary"
                startIcon={<BugReportIcon />}
              >
                Tester
              </Button>
            ) : (
              <div></div>
            )}
            <Button
              onClick={() => {
                if (sqlModeLive) {
                  handleSubmitQuery(false);
                } else {
                  handleSubmit();
                }
              }}
              variant="contained"
              color="primary"
              startIcon={<StyleIcon />}
            >
              Appliquer
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalApplyTags.propTypes = {
  initPage: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  locations: PropTypes.array.isRequired,
  brands: PropTypes.array.isRequired,
  others: PropTypes.array.isRequired,
  channels: PropTypes.array.isRequired,
};

// ********************* COMPONENT END ***********************

export default ModalApplyTags;
