import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import CompareIcon from "@mui/icons-material/Compare";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  TableContainer,
  TableBody,
  TableHead,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableRow,
  useMediaQuery,
} from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function DebugPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [ordersCheck, setOrdersCheck] = useState([]);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************
  const launchCrossCheckOrders = (start, end) => {
    console.log("start", start);
    console.log("end", end);
    POST("/api/orders/check", { start, end }).then((result) => {
      setOrdersCheck(result.data);
    });
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      <Button
        variant="contained"
        onClick={(e) => {
          var prevMonday = new Date();
          prevMonday.setDate(prevMonday.getDate() - 7);
          prevMonday.setDate(
            prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7)
          );
          var prevSunday = new Date();
          prevSunday.setDate(prevMonday.getDate() + 7);
          launchCrossCheckOrders(prevMonday, prevSunday);
        }}
        size="small"
        startIcon={<CompareIcon />}
      >
        Lancer le script de vérification sur la semaine dernière
      </Button>
      {ordersCheck.length < 1 ? (
        <div></div>
      ) : (
        <TableContainer component={Paper} sx={{mt:2}}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Channel</TableCell>
                <TableCell align="center">Orders (as per dash)</TableCell>
                <TableCell align="center">Orders (as per db)</TableCell>
                <TableCell align="center">
                  Gross Revenues (as per dash)
                </TableCell>
                <TableCell align="center">Gross Revenues (as per db)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersCheck.map((row) => {
                var dashboard_orders = row.dashboard_orders
                  ? row.dashboard_orders
                  : 0;
                var db_orders = row.db_orders ? row.db_orders : 0;
                var dashboard_amount_gross = row.dashboard_amount_gross
                  ? row.dashboard_amount_gross
                  : 0;
                var db_amount_gross = row.db_amount_gross
                  ? row.db_amount_gross
                  : 0;
                return (
                  <TableRow>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="center">{dashboard_orders}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor:
                          Math.abs(db_orders - dashboard_orders) > 0
                            ? "red"
                            : "white",
                      }}
                    >
                      {db_orders}
                    </TableCell>
                    <TableCell align="center">
                      {dashboard_amount_gross}€
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor:
                          Math.abs(db_amount_gross - dashboard_amount_gross) > 3
                            ? "red"
                            : "white",
                      }}
                    >
                      {db_amount_gross}€
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

DebugPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default DebugPage;
