import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
//IMAGES AND ICONS
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AutocompleteWithAddForm from "../../generic/AutocompleteWithAddForm";
import ClassicChart from "../../generic/ClassicChart";

//CONST

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalSparkLineDetailGeneric({
  title,
  x,
  y,
  dialogsOpen,
  setDialogsOpen,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification } = useContext(UserContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    //initModal();
  }, []);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setDialogsOpen({ ...dialogsOpen, ModalSparkLineDetailGeneric: false });
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const initModal = () => {
    setError("");
    setIsLoading(false);
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  
  return (
    <Dialog
      open={dialogsOpen.ModalSparkLineDetailGeneric}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      {x && x.length > 0 && y && y.length > 0 ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <ClassicChart
                mainValue=""
                isLoading={isLoading}
                xAxis={x}
                series={[
                  {
                    name: "sparkline",
                    data: y,
                    type: "line",
                  },
                ]}
                status={[]}
              />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Fermer</Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalSparkLineDetailGeneric.propTypes = {};

export default ModalSparkLineDetailGeneric;
