import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST, POSTLONG } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import LinkIcon from "@mui/icons-material/Link";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ModalContinueOrCancel from "../generic/ModalContinueOrCancel";
//CONST
const statusList = [
  { value: "portal", name: "portal" },
  { value: "der", name: "der" },
  { value: "dm/dso", name: "dm/dso" },
  { value: "coach", name: "coach" },
  { value: "cgo", name: "cgo" },
  { value: "director", name: "director" },
  { value: "franchise", name: "franchise" },
  { value: "marketing", name: "marketing" },
  { value: "bi", name: "bi" },
  { value: "admin", name: "admin" },
  { value: "uber", name: "uber" },
  { value: "si", name: "si" },
];
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//This component is a modal allowing creation (or modification ?) of dahsboard integration
function ModalEditUser({ initPage, userItem, brands, geos }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");
  const [editMode, setEditMode] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedGeos, setSelectedGeos] = useState([]);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    if (userItem && userItem.name) {
      var selectedItems = getSelectedItemsFromUserRights(userItem.rights);
      setEditMode(true);
      setError("");
      setIsLoading(false);
      setFirstName(userItem.firstName);
      setLastName(userItem.lastName);
      setEmail(userItem.email);
      setPassword(userItem.password);
      setStatus({ value: userItem.status, name: userItem.status });
      setSelectedBrands(selectedItems.brands);
      setSelectedGeos(selectedItems.geos);
      setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false });
    } else {
      setError("");
      setIsLoading(false);
      setEditMode(false);
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setStatus("");
      setSelectedBrands([]);
      setSelectedGeos([]);
      setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false });
    }
  }, [userItem]);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setError("");
    setIsLoading(false);
    setEditMode(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setStatus("");
    setSelectedBrands([]);
    setSelectedGeos([]);
    setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false });
    setDialogsOpen({ ...dialogsOpen, ModalEditUser: false });
  };

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmit = (e) => {
    if (firstName === "") {
      return setError("Veuillez indiquer le prénom de l'utilisateur");
    }
    if (lastName === "") {
      return setError("Veuillez indiquer le nom de l'utilisateur");
    }
    if (email === "") {
      return setError("Veuillez indiquer une adresse email");
    }
    if (password === "" && !editMode) {
      return setError("Veuillez indiquer un mot de passe temporaire");
    }
    if (!status || status.value === "") {
      return setError("Veuillez indiquer un statut");
    }
    setError("");
    if (password && password !== "") {
      setDialogsOpen({ ...dialogsOpen, ModalSendEmail: true });
      return;
    } else {
      createOrEditUser(false);
    }
  };

  const createOrEditUser = (shouldSendEmail) => {
    setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false });
    if (editMode) {
      setLoadingMessage("Édition de l'utilisateur en cours...");
      var rights = getUserRightsFromSelectedItems(selectedBrands, selectedGeos);
      var data = {
        id: userItem.id,
        firstName,
        lastName,
        name: firstName + " " + lastName,
        userGroupId: user.userGroupId,
        email: email.toLowerCase(),
        rights,
        status: status.value,
      };
      if (password && password !== "") {
        data.password = password;
      }
      POST("/api/users/edit", { data, shouldSendEmail }, true, null)
        .then((response) => {
          handleClose();
          initPage();
          displayNotification(
            "success",
            "L'utilisateur a bien été édité 👌",
            "top",
            "right"
          );
        })
        .catch((err) => {
          displayNotification("error", err.response.data, "top", "center");
          setIsLoading(false);
        });
    } else {
      setLoadingMessage("Création de l'utilisateur en cours...");
      var rights = getUserRightsFromSelectedItems(selectedBrands, selectedGeos);
      var data = {
        firstName,
        lastName,
        name: firstName + " " + lastName,
        userGroupId: user.userGroupId,
        email: email.toLowerCase(),
        password,
        rights,
        status: status.value,
      };
      POST("/api/users/create", { data, shouldSendEmail }, true, null)
        .then((response) => {
          handleClose();
          initPage();
          displayNotification(
            "success",
            "L'utilisateur a bien été créé 👌",
            "top",
            "right"
          );
        })
        .catch((err) => {
          displayNotification("error", err.response.data, "top", "center");
          setIsLoading(false);
        });
    }
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const handleAllTagSelected = (newValue) => {
    var adjustedNewValue = newValue;
    if (newValue.length > 1) {
      //If there are at least 2 items selected. Check that "all" item is not selected. If it :
      //> that's the last item > remove other items.
      //> that's not the last item > remove "all" item
      var isAllSelected = false;
      var isAllSelectedLast = false;
      newValue.map((value, Index) => {
        if (value.id === "all") {
          isAllSelected = true;
          if (Index === newValue.length - 1) {
            isAllSelectedLast = true;
          }
        }
      });
      if (isAllSelected) {
        adjustedNewValue = [newValue[newValue.length - 1]];
      }
    }
    return adjustedNewValue;
  };

  const getUserRightsFromSelectedItems = (localBrands, localGeos) => {
    console.log("localBrands", localBrands);
    console.log("localGeos", localGeos);
    var rights = { brands: localBrands, geos: localGeos };
    return JSON.stringify(rights);
  };

  const getSelectedItemsFromUserRights = (userRightsAsString) => {
    var rights =
      userRightsAsString && userRightsAsString !== ""
        ? JSON.parse(userRightsAsString)
        : { brands: [], geos: [] };
    return { brands: rights.brands, geos: rights.geos };
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Dialog open={dialogsOpen.ModalEditUser} onClose={handleClose}>
      <DialogTitle>Nouvel utilisateur</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>Créer une nouvel utilisateur</DialogContentText>
          <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
            <TextField
              className={classes.inputF}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label={"Prénom"}
              name="firstName"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputF}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label={"Nom de famille"}
              name="firstName"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.inputF}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={"Adresse email"}
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail color="disabled" />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={"Mot de passe"}
              type="text"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              displayEmpty
              value={status}
              onChange={handleChangeStatus}
              input={<OutlinedInput />}
              required
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Choisissez le statut de l'utilisateur</em>;
                }
                return selected.name;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {statusList.map((item) => (
                <MenuItem key={"MenuItem_" + item.id} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Autocomplete
              multiple
              id="filter-brands"
              value={selectedBrands}
              options={brands}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedBrands(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les marques"
                  placeholder="Ajouter une marque"
                />
              )}
            />
            <Autocomplete
              multiple
              id="filter-others"
              value={selectedGeos}
              options={geos}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                var adjustedNewValue = handleAllTagSelected(newValue);
                setSelectedGeos(adjustedNewValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filtre sur les régions"
                  placeholder="Ajouter une région"
                />
              )}
            />
            <Typography component="div" className={classes.error}>
              <Box className={classes.error}>{error}</Box>
            </Typography>
          </FormControl>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<LinkIcon />}
            >
              {editMode ? "Mettre à jour" : "Créer"}
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
      <ModalContinueOrCancel
        title="Envoi d'un email à l'utilisateur"
        content={`Souhaitez-vous envoyer automatiquement un email à l'utilisateur avec ses ${
          editMode ? "nouveaux" : ""
        } accès ?`}
        modalId="ModalSendEmail"
        handleCancel={() => {
          createOrEditUser(false);
        }}
        handleContinue={() => {
          createOrEditUser(true);
        }}
        labelCancel="Non"
        labelContinue="Oui"
      />
    </Dialog>
  );
}

ModalEditUser.propTypes = {};

export default ModalEditUser;
