import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import { isMobile } from "../../js/designManager";
import moment from "moment";
import "moment/locale/fr";
import _ from "lodash";
import { generateColorFromString, hexToRgb } from "../../js/designManager";
import { numberWithSpaces } from "../../js/numberManager";
import SkeletonJetpackChannelsPerformanceTable from "../../ui-components/Skeleton/SkeletonJetpackChannelsPerformanceTable";
import {
  isBuffaloGrill,
  isCourtepaille,
  isBunMeatBun,
  isPopeyes,
  isArlette,
} from "../../js/brandsManager";
import { CSVLink, CSVDownload } from "react-csv";
import { makeComponentUniqueId } from "../../js/otherManager";
//IMAGES AND ICONS
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HistoryIcon from "@mui/icons-material/History";
import logo_bg from "../../img/logo-bg.png";
import logo_bmb from "../../img/logo-bmb.png";
import logo_ctp from "../../img/logo-ctp.png";
import logo_pp from "../../img/logo-pp.png";
import logo_aj from "../../img/logo-aj.png";
import DownloadIcon from "@mui/icons-material/Download";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  AvatarGroup,
  Avatar,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Collapse,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import WorkInProgressBanner from "../generic/WorkInProgressBanner";
//CONSTANTE

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  restaurant: {
    fontSize: "11px!important",
  },
  jetpackCampaign: {
    fontSize: "14px!important",
  },
  iconHistory: {
    fontSize: "20px!important",
    marginRight: "5px",
    paddingTop: "2px",
  },
  historyLinkContainer: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  agendaDescription: {
    fontSize: "11px!important",
  },
  date: {
    fontSize: "11px!important",
  },
}));

// ********************* COMPONENT START ***********************

function JetpackPerformanceRestaurantRow({
  isRestaurant,
  item,
  colWidths,
  displayStatusChangeHistory,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [
    hasChannelsRequestBeenAlreadySent,
    setHasChannelsRequestBeenAlreadySent,
  ] = React.useState(false);
  const [channels, setChannels] = React.useState(null);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************
  const generateBrandLogos = () => {
    var logos = [];
    var gotBuffalo = false;
    var gotCourtepaille = false;
    var gotBunMeatBun = false;
    var gotPopepyes = false;
    var gotArlette = false;
    if (isRestaurant) {
      if (isBuffaloGrill(item.name)) {
        gotBuffalo = true;
      }
      if (isCourtepaille(item.name)) {
        gotCourtepaille = true;
      }
      if (isBunMeatBun(item.name)) {
        gotBunMeatBun = true;
      }
      if (isPopeyes(item.name)) {
        gotPopepyes = true;
      }
      if (isArlette(item.name)) {
        gotArlette = true;
      }
    } else {
      if (channels && channels.length > 0) {
        channels.map((channel) => {
          if (isBuffaloGrill(channel.name)) {
            gotBuffalo = true;
          }
          if (isCourtepaille(channel.name)) {
            gotCourtepaille = true;
          }
          if (isBunMeatBun(channel.name)) {
            gotBunMeatBun = true;
          }
          if (isPopeyes(channel.name)) {
            gotPopepyes = true;
          }
          if (isArlette(channel.name)) {
            gotArlette = true;
          }
        });
      } else {
        gotBuffalo = true;
        gotCourtepaille = true;
        gotBunMeatBun = true;
        gotPopepyes = true;
        gotArlette = true;
      }
    }
    if (gotBuffalo) {
      logos.push({ alt: "Buffalo Grill", src: logo_bg });
    }
    if (gotCourtepaille) {
      logos.push({ src: logo_ctp, alt: "Courtepaille" });
    }
    if (gotBunMeatBun) {
      logos.push({ src: logo_bmb, alt: "Bun Meat Bun" });
    }
    if (gotPopepyes) {
      logos.push({ src: logo_pp, alt: "Popeyes" });
    }
    if (gotArlette) {
      logos.push({ src: logo_aj, alt: "Arlette et Jean" });
    }
    return logos;
  };

  const generateGeoTags = () => {
    var toReturn = [];
    if (isRestaurant) {
      var rights =
        user.rights && user.rights !== ""
          ? JSON.parse(user.rights)
          : { brands: [], geos: [] };
      if (rights.geos && rights.geos.length > 1) {
        //We tag only if user is attached to more than one region
        if (
          item &&
          item.taggings_channels &&
          item.taggings_channels.length > 0
        ) {
          item.taggings_channels.map((tagging) => {
            if (tagging && tagging.Tags_Other && tagging.Tags_Other.name) {
              toReturn.push(tagging.Tags_Other.name);
            }
          });
        }
      }
      return toReturn;
    } else {
      return toReturn;
    }
  };

  const generateDataCSV = () => {
    var data = [];
    if (isRestaurant) {
      return data;
    }

    /*if (!item || !item.channels || item.channels.length < 1) {
      return data;
    }*/
    if (!channels) {
      return data;
    }
    channels.map((channel, subIndex) => {
      var { name } = channel;
      data.push({
        name,
        campagne: item.name,
        campagne_type: item.actionType,
        campagne_cible: item.customersTarget,
        campagne_horaires: item.agendaDescriptions,
        campagne_debut: item.start,
        campagne_fin: item.end,
        campagne_details: JSON.stringify(item.actionDetails),
        date_extraction_donnees: new Date(),
        donnnees_restaurant_optin_jours_actifs: channel.optIn
          ? channel.optIn.activeDays
          : 0,
        donnnees_restaurant_optin_jours_inactifs: channel.optIn
          ? channel.optIn.inactiveDays
          : 0,
        donnnees_restaurant_optin_ratio_actif: channel.optIn
          ? channel.optIn.ratioActive
          : 0,
        donnnees_restaurant_performance_budget_utilise: channel.performance
          ? channel.performance.budgetUsed
          : 0,
        donnnees_restaurant_performance_nouveaux_clients: channel.performance
          ? channel.performance.newCustomerCount
          : 0,
        donnnees_restaurant_performance_ventes: channel.performance
          ? channel.performance.sales
          : 0,
        donnnees_restaurant_performance_utilisations: channel.performance
          ? channel.performance.totalRedemptions
          : 0,
        donnnees_restaurant_performance_estimate_ventes_hors_tva_hors_marketing:
          channel.performance ? channel.performance.sales_net_excl_vat : 0,
        donnnees_restaurant_performance_commission_plateforme_hors_tva:
          channel.performance ? channel.performance.commission_fee : 0,
        donnnees_restaurant_performance_cogs: channel.performance
          ? channel.performance.cogs
          : 0,
        donnnees_restaurant_performance_contribution: channel.performance
          ? channel.performance.contrib_post_cogs
          : 0,
      });
    });
    return data;
  };

  const loadChannels = () => {
    if(hasChannelsRequestBeenAlreadySent){
      return
    }
    setHasChannelsRequestBeenAlreadySent(true);
     GET(`/api/jetpackcampaigns/${item.id}/channels`)
       .then((response) => {         
         setChannels(response.data.channels);
       })
       .catch((err) => {
         displayNotification(
           "error",
           "Erreur durant le chargement des restaurant",
           "top",
           "center"
         );
       });
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayChannels = () => {
    if (channels) {
      return channels.map((channel, subIndex) => {
        return (
          <JetpackPerformanceRestaurantRow
            key={
              "JetpackPerformanceRestaurantRow_Channel_" +
              item.id +
              "_" +
              channel.id
            }
            isRestaurant={true}
            item={channel}
            colWidths={colWidths}
            displayStatusChangeHistory={displayStatusChangeHistory}
          />
        );
      });
    } else {
      return (
        <React.Fragment>
          <TableRow
            sx={{
              "& > *": {
                borderBottom: "unset",
              },
            }}
          >
            <TableCell colSpan={12}>
              <SkeletonJetpackChannelsPerformanceTable />
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    }
  };

  var logos = generateBrandLogos();
  var geoChips = generateGeoTags();
  var dataCSV = generateDataCSV();
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell
          style={{
            width: colWidths.col0,
          }}
        >
          {!isRestaurant ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
                loadChannels();
              }}
              style={{ width: colWidths.col1 }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <div style={{ width: colWidths.col1 }}></div>
          )}
        </TableCell>
        <TableCell style={{ width: colWidths.col1 }}>
          {logos && logos.length > 1 ? (
            <AvatarGroup
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              {logos.map((logo) => {
                var randomId = makeComponentUniqueId();
                return (
                  <Avatar
                    alt={logo.alt}
                    key={randomId}
                    src={logo.src}
                    sx={{
                      width: isMobile() ? 20 : "1.5rem",
                      height: isMobile() ? 20 : "1.5rem",
                    }}
                  />
                );
              })}
            </AvatarGroup>
          ) : (
            <Avatar
              alt={logos[0].alt}
              key={makeComponentUniqueId()}
              src={logos[0].src}
              sx={{
                width: isMobile() ? 20 : "1.5rem",
                height: isMobile() ? 20 : "1.5rem",
              }}
            />
          )}
        </TableCell>
        {isRestaurant ? (
          <TableCell
            style={{ width: colWidths.col2 }}
            align="left"
            className={
              isRestaurant ? classes.restaurant : classes.jetpackCampaign
            }
          >
            <Typography
              className={
                isRestaurant ? classes.restaurant : classes.jetpackCampaign
              }
            >
              {item.name}
              {geoChips.map((geoTag) => {
                var chipColor = "#" + generateColorFromString(geoTag);
                var randomId = makeComponentUniqueId();
                return (
                  <Chip
                    key={randomId}
                    size="small"
                    sx={{
                      margin: "2px",
                      backgroundColor: alpha(chipColor, 0.3) + "!important",
                    }}
                    label={geoTag}
                  />
                );
              })}
            </Typography>
          </TableCell>
        ) : (
          <React.Fragment>
            <TableCell style={{ width: colWidths.col2 }} align="center">
              {item && item.actions && item.actions.length > 0
                ? item.actions.map((action) => {
                    var actionLabel = _.find(constants.jetpack_actionTypes, [
                      "id",
                      action.type,
                    ]);
                    var randomId = makeComponentUniqueId();
                    var chipColor =
                      "#" +
                      generateColorFromString(
                        actionLabel && actionLabel.title
                          ? actionLabel.title
                          : "empty"
                      );

                    return (
                      <Chip
                        style={{ margin: "3px" }}
                        size={"small"}
                        sx={{
                          backgroundColor: alpha(chipColor, 0.3) + "!important",
                        }}
                        key={`Chip_${randomId}`}
                        label={
                          actionLabel && actionLabel.title
                            ? actionLabel.title
                            : ""
                        }
                      />
                    );
                  })
                : null}
            </TableCell>
            <TableCell style={{ width: colWidths.col2 }} align="center">
              {item && item.actionDetails.length > 0
                ? item.actionDetails.map((actionDetail) => {
                    var chipColor = "#" + generateColorFromString(actionDetail);
                    var randomId = makeComponentUniqueId();
                    return (
                      <Chip
                        style={{ margin: "3px" }}
                        size={"small"}
                        sx={{
                          backgroundColor: alpha(chipColor, 0.3) + "!important",
                        }}
                        key={`Chip_${randomId}`}
                        label={actionDetail}
                      />
                    );
                  })
                : null}
            </TableCell>
          </React.Fragment>
        )}
        <TableCell
          style={{ width: colWidths.col3 }}
          className={
            isRestaurant ? classes.restaurant : classes.jetpackCampaign
          }
        >
          {!isRestaurant ? (
            <Typography className={classes.jetpackCampaign}>
              {item.end
                ? `Du ${moment(item.start).format("LL")} au ${moment(
                    item.end
                  ).format("LL")}`
                : `Campagne crée le ${moment(item.createdAt).format("LL")}`}
            </Typography>
          ) : (
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.historyLinkContainer}
              onClick={(e) => {
                displayStatusChangeHistory(item);
              }}
            >
              <Grid item sm={4} textAlign="right">
                <HistoryIcon className={classes.iconHistory} />
              </Grid>
              <Grid item sm={8} textAlign="left">
                <Typography className={classes.restaurant}>
                  voir historique
                </Typography>
              </Grid>
            </Grid>
          )}
        </TableCell>
        {!isRestaurant ? (
          <React.Fragment>
            <TableCell style={{ width: colWidths.col2 }} align="center">
              {item.agendaDescriptions ? (
                item.agendaDescriptions.map((row, index) => {
                  var randomId = makeComponentUniqueId();
                  return (
                    <Typography
                      className={classes.agendaDescription}
                      key={randomId}
                      textAlign="center"
                    >
                      {row}
                    </Typography>
                  );
                })
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </TableCell>
            <TableCell style={{ width: colWidths.col2 }} align="center">
              <Typography variant="body">{item.customersTarget}</Typography>
            </TableCell>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <TableCell
          style={{ width: colWidths.col4 }}
          align="center"
          className={
            isRestaurant ? classes.restaurant : classes.jetpackCampaign
          }
        >
          <Typography
            className={
              isRestaurant ? classes.restaurant : classes.jetpackCampaign
            }
          >
            {item.optIn ? item.optIn.ratioActive : 0}%
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col5 }}
          align="center"
          className={
            isRestaurant ? classes.restaurant : classes.jetpackCampaign
          }
        >
          <Typography
            className={
              isRestaurant ? classes.restaurant : classes.jetpackCampaign
            }
          >
            {item.performance
              ? numberWithSpaces(
                  Math.round(item.performance.budgetUsed * 10) / 10
                )
              : 0}
            €
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col6 }}
          align="center"
          className={
            isRestaurant ? classes.restaurant : classes.jetpackCampaign
          }
        >
          <Typography
            className={
              isRestaurant ? classes.restaurant : classes.jetpackCampaign
            }
          >
            {item.performance
              ? numberWithSpaces(item.performance.newCustomerCount)
              : 0}
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col7 }}
          align="center"
          className={
            isRestaurant ? classes.restaurant : classes.jetpackCampaign
          }
        >
          <Typography
            className={
              isRestaurant ? classes.restaurant : classes.jetpackCampaign
            }
          >
            {item.performance
              ? numberWithSpaces(Math.round(item.performance.sales * 10) / 10)
              : 0}
            €
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col7 }}
          align="center"
          className={
            isRestaurant ? classes.restaurant : classes.jetpackCampaign
          }
        >
          <Typography
            className={
              isRestaurant ? classes.restaurant : classes.jetpackCampaign
            }
          >
            {item.performance && !isNaN(item.performance.contrib_post_cogs)
              ? numberWithSpaces(
                  Math.round(item.performance.contrib_post_cogs * 10) / 10
                )
              : 0}
            €
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, margin: 0 }} colSpan={12}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{ padding: 0, margin: 0 }}
          >
            {!isRestaurant ? (
              <Table aria-label="subrows" style={{ padding: 0, margin: 0 }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: colWidths.col0 }} align="center">
                      <CSVLink data={dataCSV}>
                        <Tooltip title="Télécharger les données de la compagne en format CSV">
                          <DownloadIcon
                            style={{ color: theme.palette.primary.main }}
                          />
                        </Tooltip>
                      </CSVLink>
                    </TableCell>
                    <TableCell
                      style={{ width: colWidths.col1 }}
                      align="center"
                    ></TableCell>
                    <TableCell style={{ width: colWidths.col2 }} align="left">
                      <Tooltip title="Nom de la campagne">
                        <Typography variant="body">Campagne</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col3 }} align="center">
                      <Tooltip title="Période d'activité de la campagne">
                        <Typography variant="body">Activation</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col4 }} align="center">
                      <Tooltip title="Pourcentage de temps durant lequel le ou les restaurants avaient cette campagne active">
                        <Typography variant="body">% opted-in</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col5 }} align="center">
                      <Tooltip title="Montant publicitaire dépensé durant cette campagne">
                        <Typography variant="body">Dépenses</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col6 }} align="center">
                      <Tooltip title="Nombre de nouveaux clients obtenus grâce à cette campagne">
                        <Typography variant="body">Nouveaux clients</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col7 }} align="center">
                      <Tooltip title="Chiffre d'affaires généré par cette campagne">
                        <Typography variant="body">Ventes générées</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col7 }} align="center">
                      <Tooltip title="Contribution de l'opération. i. Nette de TVA (calculée sur une moyenne de 10%), ii. nette de marketing, iii. nette de commission plateforme, iv. nette de coûts matières (calculé sur une base de 30% du chiffre d'affaires avant marketing HT)">
                        <Typography variant="body">
                          Contribution nette
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ padding: 0, margin: 0 }}>
                  {displayChannels()}
                </TableBody>
              </Table>
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

JetpackPerformanceRestaurantRow.propTypes = {};

// ********************* COMPONENT END ***********************

export default JetpackPerformanceRestaurantRow;
