import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CampaignIcon from "@mui/icons-material/Campaign";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stepper,
  Step,
  StepLabel,
  breadcrumbsClasses,
} from "@mui/material";
import AutocompleteWithAddForm from "../generic/AutocompleteWithAddForm";
//CONST & LOCAL IMPORT
import ModalNewOrEditJetpackCampaignStepConstraintsType from "./ModalNewOrEditJetpackCampaignsSteps/ModalNewOrEditJetpackCampaignStepConstraintsType";
import ModalNewOrEditJetpackCampaignStepConstraintsDetails from "./ModalNewOrEditJetpackCampaignsSteps/ModalNewOrEditJetpackCampaignStepConstraintsDetails";
import ModalNewOrEditJetpackCampaignStepActionsType from "./ModalNewOrEditJetpackCampaignsSteps/ModalNewOrEditJetpackCampaignStepActionsType";
import ModalNewOrEditJetpackCampaignStepActionsDetails from "./ModalNewOrEditJetpackCampaignsSteps/ModalNewOrEditJetpackCampaignStepActionsDetails";
import ModalNewOrEditJetpackCampaignStepChannelsScope from "./ModalNewOrEditJetpackCampaignsSteps/ModalNewOrEditJetpackCampaignStepChannelsScope";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalNewOrEditJetpackCampaign({
  initPage,
  locations,
  brands,
  others,
  constraintTypes,
  channels,
  editMode = false,
  editInitValues = null,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");
  const [activeStep, setActiveStep] = useState(0);

  //--------- STEPS CONSTRAINTS TYPE ------------
  const [constraintType, setConstraintType] = useState("");
  const [constraintValueObject, setConstraintValueObject] = useState({});

  //--------- STEPS ACTIONS TYPE ------------
  const [actionType, setActionType] = useState("");
  const [actionValueObject, setActionValueObject] = useState({});
  //------------ TO MOVE ? ----------
  const [name, setName] = useState("");
  const [explicitOptInRequired, setExplicitOptInRequired] = useState(false);
  const [description, setDescription] = useState("");
  const [limitedPeriod, setLimitedPeriod] = useState(false);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [location, setLocation] = useState("");
  const [brand, setBrand] = useState("");
  const [other, setOther] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedOthers, setSelectedOthers] = useState([]);
  const [selectedExcludedOthers, setSelectedExcludedOthers] = useState([]);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    initModal();
  }, [locations, brands, others]);

  useEffect(() => {
    if (editMode) {
      console.log("!editInitValues", editInitValues);
      //init values
      setName(editInitValues.name ? editInitValues.name : "");
      setExplicitOptInRequired(
        editInitValues.explicitOptInRequired
          ? editInitValues.explicitOptInRequired
          : false
      );
      setDescription(
        editInitValues.description ? editInitValues.description : ""
      );
      if (editInitValues.start && editInitValues.end) {
        setLimitedPeriod(true);
        setStart(editInitValues.start);
        setEnd(editInitValues.end);
      } else {
        setLimitedPeriod(false);
        setStart(null);
        setEnd(null);
      }
      var targetedTags = editInitValues.targetedTags
        ? JSON.parse(editInitValues.targetedTags)
        : {};
      setSelectedLocations(
        targetedTags.locations ? targetedTags.locations : []
      );
      setSelectedBrands(targetedTags.brands ? targetedTags.brands : []);
      setSelectedOthers(targetedTags.others ? targetedTags.others : []);
      setSelectedExcludedOthers(
        targetedTags.excluded_others ? targetedTags.excluded_others : []
      );
      if (
        editInitValues &&
        editInitValues.constraints &&
        editInitValues.constraints.length > 0
      ) {
        setConstraintType(editInitValues.constraints[0].type);
        var editConstraintValueObject = editInitValues.constraints[0]
          .valueObject
          ? JSON.parse(editInitValues.constraints[0].valueObject)
          : {};
        setConstraintValueObject(editConstraintValueObject);
      }
      if (
        editInitValues &&
        editInitValues.actions &&
        editInitValues.actions.length > 0
      ) {
        setActionType(editInitValues.actions[0].type);
        var editActionValueObject = editInitValues.actions[0].valueObject
          ? JSON.parse(editInitValues.actions[0].valueObject)
          : {};
        setActionValueObject(editActionValueObject);
      }

      /*
      setError("");
      setSelectedLocations([locations[0]]);
      setSelectedBrands([brands[0]]);
      setSelectedOthers([others[0]]);
      setName("");
      setIsLoading(false);*/
    } else {
      initModal();
    }
  }, [editInitValues, editMode]);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    initModal();
    setDialogsOpen({ ...dialogsOpen, ModalNewOrEditJetpackCampaign: false });
  };

  const handleSubmit = (e) => {
    if (!name || name === "") {
      setError("Vous devez saisir un nom pour la campagne.");
      displayNotification(
        "error",
        "Vous devez saisir un nom pour la campagne.",
        "top",
        "center"
      );
      return;
    }
    console.log("selectedBrands", selectedBrands);
    if (
      !selectedBrands ||
      selectedBrands.length < 1 ||
      selectedBrands[0].id === "all"
    ) {
      setError(
        "Vous devez choisir sur quelle(s) marque(s) s'applique cette campagne."
      );
      displayNotification(
        "error",
        "Vous devez choisir sur quelle(s) marque(s) s'applique cette campagne.",
        "top",
        "center"
      );
      return;
    }
    setError("");
    setIsLoading(true);
    if (editMode) {
      updateJetpackCampaign();
    } else {
      createJetpackCampaign();
    }
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const initModal = () => {
    setActiveStep(0);
    setConstraintType("");
    setConstraintValueObject({});
    setActionType("");
    setActionValueObject({});
    setError("");
    setSelectedLocations([locations[0]]);
    setSelectedBrands([brands[0]]);
    setSelectedOthers([others[0]]);
    setSelectedExcludedOthers([]);
    setName("");
    setExplicitOptInRequired(false);
    setDescription("");
    setLimitedPeriod(false);
    setStart(null);
    setEnd(null);
    setIsLoading(false);
  };

  const createJetpackCampaign = () => {
    var data = {
      name,
      explicitOptInRequired,
      description,
      start,
      end,
      locations: selectedLocations,
      brands: selectedBrands,
      others: selectedOthers,
      excluded_others: selectedExcludedOthers,
      userGroupId: user.userGroupId,
      constraintType,
      constraintValueObject,
      actionType,
      actionValueObject,
    };
    POST("/api/jetpackcampaigns/create", data)
      .then((response) => {
        initPage();
        initModal();
        handleClose();
        displayNotification("success", "Campagne créee !", "top", "center");
        /*
        POST("/api/marketing/jetpackcampaigns/run", {
          run_marketing: true,
          run_sponsoring: false,
        })
          .then((result) => {
            //nothing
          })
          .catch((err) => {
            displayNotification(
              "error",
              "Erreur durant la synchronisation des nouvelles campagnes",
              "top",
              "center"
            );
          });
          */
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const updateJetpackCampaign = () => {
    var data = {
      id: editInitValues.id,
      name,
      explicitOptInRequired,
      description,
      start,
      end,
      locations: selectedLocations,
      brands: selectedBrands,
      others: selectedOthers,
      excluded_others: selectedExcludedOthers,
      userGroupId: user.userGroupId,
      constraintType,
      constraintValueObject,
      actionType,
      actionValueObject,
    };
    POST("/api/jetpackcampaigns/update", data)
      .then((response) => {
        initPage();
        initModal();
        handleClose();
        displayNotification(
          "success",
          "Campagne mise à jour !",
          "top",
          "center"
        );
        /*
        POST("/api/marketing/jetpackcampaigns/run", {
          run_marketing: true,
          run_sponsoring: false,
        })
          .then((result) => {
            //nothing
          })
          .catch((err) => {
            displayNotification(
              "error",
              "Erreur durant la synchronisation des nouvelles campagnes",
              "top",
              "center"
            );
          });
          */
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
      });
  };

  const handleAllTagSelected = (newValue) => {
    var adjustedNewValue = newValue;
    if (newValue.length > 1) {
      //If there are at least 2 items selected. Check that "all" item is not selected. If it :
      //> that's the last item > remove other items.
      //> that's not the last item > remove "all" item
      var isAllSelected = false;
      var isAllSelectedLast = false;
      newValue.map((value, Index) => {
        if (value.id === "all") {
          isAllSelected = true;
          if (Index === newValue.length - 1) {
            isAllSelectedLast = true;
          }
        }
      });
      if (isAllSelected) {
        adjustedNewValue = [newValue[newValue.length - 1]];
      }
    }
    return adjustedNewValue;
  };

  const isNextAvailable = () => {
    switch (activeStep) {
      case 0:
        return constraintType && constraintType !== "";
      case 1:
        return (
          constraintType === "permanent" ||
          (constraintValueObject &&
            Object.keys(constraintValueObject).length > 0)
        );

      case 2:
        return actionType && actionType !== "";

      case 3:
        switch (actionType) {
          case "discount":
            return (
              actionValueObject &&
              actionValueObject.target &&
              actionValueObject.target !== "" &&
              actionValueObject.discount_type &&
              actionValueObject.discount_type !== ""
            );
          case "sponsoring":
            return (
              actionValueObject &&
              actionValueObject.target &&
              actionValueObject.target !== "" &&
              actionValueObject.sponsoring_budget &&
              actionValueObject.sponsoring_budget > 0
            );
          case "bogo":
            return (
              actionValueObject &&
              actionValueObject.target &&
              actionValueObject.target !== "" &&
              actionValueObject.bogoItems &&
              actionValueObject.bogoItems.length > 0 &&
              actionValueObject.bogoItems[0].title &&
              actionValueObject.bogoItems[0].title !== ""
            );
          case "free_item":
            return (
              actionValueObject &&
              actionValueObject.target &&
              actionValueObject.target !== "" &&
              actionValueObject.minAmount &&
              actionValueObject.minAmount > 0 &&
              actionValueObject.freeItems &&
              actionValueObject.freeItems.length > 0 &&
              actionValueObject.freeItems[0].title &&
              actionValueObject.freeItems[0].title !== ""
            );
          case "free_delivery":
            return (
              actionValueObject &&
              actionValueObject.target &&
              actionValueObject.target !== "" &&
              actionValueObject.free_delivery_max_budget
            );
          case "discount_item":
            return (
              actionValueObject &&
              actionValueObject.target &&
              actionValueObject.target !== "" &&
              ((actionValueObject.discount_item_or_category === "CATEGORY" &&
                actionValueObject.discount_category_id &&
                actionValueObject.discount_category_id !== "" &&
                actionValueObject.discount_category_percent &&
                actionValueObject.discount_category_percent !== "" &&
                actionValueObject.discount_category_items &&
                actionValueObject.discount_category_items.length > 0) ||
                (actionValueObject.discountItems &&
                  actionValueObject.discountItems.length > 0 &&
                  actionValueObject.discountItems[0].title &&
                  actionValueObject.discountItems[0].title !== "" &&
                  actionValueObject.discountItems[0].discount &&
                  actionValueObject.discountItems[0].discount > 0))
            );
          default:
            return false;
        }

      case 4:
        break;
      default:
        return false;
    }
  };

  const setEndMiddleWare = (localEnd) => {
    if (!localEnd) {
      setEnd(null);
      return;
    }
    //Upcoming lines ensure the inclusivity of the end day.
    //Ex: if user choose 01/01/2022 to 07/01/2022. User expects 07 to be actually taken into account.
    //Since date range picker displayed by default midnight in the morning when selecting a day we need to add manually 23h59min
    console.log("localEnd", localEnd);
    var endDate = new Date(localEnd);
    //Adding 23h59min to end date
    endDate.setTime(endDate.getTime() + 1439 * 60000);
    console.log("endDate", endDate);
    setEnd(endDate);
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayActiveStep = () => {
    switch (activeStep) {
      case 0:
        return (
          <ModalNewOrEditJetpackCampaignStepConstraintsType
            constraintType={constraintType}
            setConstraintType={setConstraintType}
            constraintTypes={constraintTypes}
          />
        );
      case 1:
        return (
          <ModalNewOrEditJetpackCampaignStepConstraintsDetails
            constraintType={constraintType}
            constraintValueObject={constraintValueObject}
            setConstraintValueObject={setConstraintValueObject}
          />
        );
      case 2:
        return (
          <ModalNewOrEditJetpackCampaignStepActionsType
            actionType={actionType}
            setActionType={setActionType}
            jetpack_actionTypes={constants.jetpack_actionTypes}
          />
        );
      case 3:
        return (
          <ModalNewOrEditJetpackCampaignStepActionsDetails
            actionType={actionType}
            actionValueObject={actionValueObject}
            setActionValueObject={setActionValueObject}
            channels={channels}
          />
        );
      case 4:
        return (
          <ModalNewOrEditJetpackCampaignStepChannelsScope
            name={name}
            setName={setName}
            explicitOptInRequired={explicitOptInRequired}
            setExplicitOptInRequired={setExplicitOptInRequired}
            description={description}
            setDescription={setDescription}
            limitedPeriod={limitedPeriod}
            start={start}
            end={end}
            setLimitedPeriod={setLimitedPeriod}
            setStart={setStart}
            setEnd={setEndMiddleWare}
            error={error}
            locations={locations}
            selectedLocations={selectedLocations}
            brands={brands}
            selectedBrands={selectedBrands}
            others={others}
            selectedOthers={selectedOthers}
            selectedExcludedOthers={selectedExcludedOthers}
            handleAllTagSelected={handleAllTagSelected}
            setSelectedLocations={setSelectedLocations}
            setSelectedBrands={setSelectedBrands}
            setSelectedOthers={setSelectedOthers}
            setSelectedExcludedOthers={setSelectedExcludedOthers}
          />
        );
      default:
        return <React.Fragment></React.Fragment>;
    }
  };

  return (
    <Dialog
      open={dialogsOpen.ModalNewOrEditJetpackCampaign}
      onClose={handleClose}
    >
      <DialogTitle>
        {editMode ? "Edition de votre" : "Nouvelle"} campagne d'automatisation
        des promotions marketing
      </DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <Box sx={{ width: "100%", mb: 2 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel>Quand</StepLabel>
              </Step>
              <Step>
                <StepLabel>plus précisément...</StepLabel>
              </Step>
              <Step>
                <StepLabel>Quoi</StepLabel>
              </Step>
              <Step>
                <StepLabel>plus précisément...</StepLabel>
              </Step>
              <Step>
                <StepLabel>Où</StepLabel>
              </Step>
            </Stepper>
          </Box>
          {displayActiveStep()}
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>

            <Button
              onClick={() => {
                if (activeStep - 1 >= 0) {
                  setActiveStep(activeStep - 1);
                }
              }}
              variant="outlined"
              color="primary"
              disabled={activeStep < 1}
              endIcon={<NavigateBeforeIcon />}
            >
              Précédent
            </Button>

            {activeStep === 4 ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                startIcon={<CampaignIcon />}
              >
                {editMode ? "Sauvegarder" : "Créér"}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (isNextAvailable()) setActiveStep(activeStep + 1);
                }}
                variant="contained"
                color="primary"
                disabled={!isNextAvailable()}
                endIcon={<NavigateNextIcon />}
              >
                Suivant
              </Button>
            )}
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalNewOrEditJetpackCampaign.propTypes = {};

export default ModalNewOrEditJetpackCampaign;
