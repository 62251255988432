import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
//import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import SearchIcon from "@mui/icons-material/Search";
import CodeIcon from "@mui/icons-material/Code";
//MATERIAL UI COMPONENT IMPORTS
import {
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function EnhancedTableToolbar({
  numSelected,
  title,
  multiApplyIcon,
  multiApplyLabel,
  onClickMultiApplyIcon,
  search,
  updateFilterSearch,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  //const { user, displayNotification, dialogsOpen, setDialogsOpen } =
  //  useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {`${numSelected} sélectionné${numSelected > 1 ? "s" : ""}`}
        </Typography>
      ) : (
        <React.Fragment>
          {" "}
          {/*<Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>*/}
        </React.Fragment>
      )}

      {numSelected > 0 ? (
        <Tooltip title={multiApplyLabel}>
          <IconButton
            onClick={(e) => {
              onClickMultiApplyIcon(false);
            }}
          >
            {multiApplyIcon}
          </IconButton>
        </Tooltip>
      ) : (
        <React.Fragment>
          <Tooltip title="Rechercher">
            <TextField
              className={classes.inputF}
              variant="outlined"
              margin="normal"
              fullWidth
              id="search"
              label={`Rechercher et ${title.toLowerCase()}`}
              name="search"
              autoComplete="search"
              value={search}
              style={{ height: "80px", paddingTop: "9px" }}
              onChange={(e) => {
                updateFilterSearch(e.target.value);
              }}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="disabled" />
                  </InputAdornment>
                ),
              }}
            />
            {/*         
              <IconButton>
            <FilterListIcon />
          </IconButton>
        <*/}
          </Tooltip>
          <Button
            variant="contained"
            style={{
              minWidth: "180px",
              marginLeft: "10px",
              height: "54px",
            }}
            onClick={(e) => {
              onClickMultiApplyIcon(true);
            }}
            startIcon={<CodeIcon />}
          >
            Tag avec query
          </Button>
        </React.Fragment>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  multiApplyIcon: PropTypes.element.isRequired,
  multiApplyLabel: PropTypes.string.isRequired,
  onClickMultiApplyIcon: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  updateFilterSearch: PropTypes.func.isRequired,
};

// ********************* COMPONENT END ***********************

export default EnhancedTableToolbar;
