import React from "react";

// material-ui
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid } from "@mui/material";
import { Skeleton } from "@mui/material";

// style constant
const useStyles = makeStyles({
  cardHeading: {
    marginRight: "8px",
    marginTop: "18px",
    marginBottom: "14px",
  },
  card: {
    marginTop: "14px",
    marginBottom: "14px",
    //width: "auto",
    //minWidth: "210px",
    //height: "210px",
  },
});

//-----------------------|| SKELETON EARNING CARD ||-----------------------//

const SkeletonMagnetRestaurantTable = (animation) => {
  const classes = useStyles();
  const col1 = 4;
  const col2 = 2;
  const col3 = 2;
  const col4 = 3;
  const col5 = 1;
  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={30} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col1}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col2}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col3}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col4}>
              <Skeleton variant="rect" height={30} />
            </Grid>
            <Grid item xs={col5}>
              <Skeleton variant="rect" height={30} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default SkeletonMagnetRestaurantTable;
