import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//import theme from "../../themes/theme";
import {
  AppBar,
  Divider,
  Toolbar,
  Typography,
  Button,
  ButtonGroup,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  ListItemIcon,
} from "@mui/material";
//CONTEXT
import { UserContext } from "../../App";
//ICONS
import MenuIcon from "@mui/icons-material/Menu";
import SideBarIcon from "@mui/icons-material/MenuOpen";
import HomeIcon from "@mui/icons-material/Home";
import BugReportIcon from "@mui/icons-material/BugReport";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import BookIcon from "@mui/icons-material/Book";
import LoginIcon from "@mui/icons-material/Login";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import LogoutIcon from "@mui/icons-material/Logout";
import logoSquare from "../../img/logo-square.png";
import logoBanner from "../../img/logo-banner.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import VideocamIcon from "@mui/icons-material/Videocam";

import { isMobile } from "../../js/designManager";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "42px",
    marginRight: "10px",
  },
  appBar: {
    boxShadow: theme.shadows[6],
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.common.white,
  },
  menuButtonText: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
  },
  menuLink: {
    textDecoration: "none !important",
    color: "inherit",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
  },
  noDecoration: {
    textDecoration: "none !important",
  },
}));

function NavBar(props) {
  const {
    openRegisterDialog,
    openLoginDialog,
    handleMobileDrawerOpen,
    handleMobileDrawerClose,
    //mobileDrawerOpen,
  } = props;
  const {
    user,
    backupUser,
    exitLoginAs,
    updateUser,
    cockpitDrawerOpen,
    setCockpitDrawerOpen,
    displayNotification,
    environment,
  } = useContext(UserContext);
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuItems, setMenuItems] = React.useState([]);
  //const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    console.log("Frontend Info - Navbar console, user: ", user);
    var newMenuItems = [];
    switch (user.status) {
      case "admin":
        newMenuItems = [
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/97776f8d7f464a519b0b07bf5e5c9ee5",
            label: "Formation au module Admin",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/1f683db3663045e0b2654a5e377ed107",
            label: "Formation au module Radar",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/a6199d25f3e9449bb73b6374280fa078",
            label: "Formation au module Jetpack (admin) 1/2",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/a87790cfbca242d3904e4198997e7f85",
            label: "Formation au module Jetpack (admin) 2/2",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/3be5f970310c4dd891169fcfc48b4b39",
            label: "Formation au module Jetpack (restaurant)",
          },
        ];
        break;
      case "marketing":
        newMenuItems = [
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/97776f8d7f464a519b0b07bf5e5c9ee5",
            label: "Formation au module Admin",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/1f683db3663045e0b2654a5e377ed107",
            label: "Formation au module Radar",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/a6199d25f3e9449bb73b6374280fa078",
            label: "Formation au module Jetpack (admin) 1/2",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/a87790cfbca242d3904e4198997e7f85",
            label: "Formation au module Jetpack (admin) 2/2",
          },
        ];
        break;
      default:
        newMenuItems = [
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/e6994e24dbfd469c973a2423deb9fa0f",
            label: "Formation au module Radar (sponsoring restaurant)",
          },
          {
            icon: <VideocamIcon />,
            link: "https://www.loom.com/share/3be5f970310c4dd891169fcfc48b4b39",
            label: "Formation au module Jetpack (restaurant)",
          },
        ];
    }
    setMenuItems(newMenuItems);
  }, [user.email, user.status]);

  // *******************************************************
  // ************************ CALLBACKS ********************
  // *******************************************************

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    GET(`/api/users/logout`)
      .then((response) => {
        updateUser(response.data.user);
        displayNotification("success", "Déconnexion réussie", "top", "right");
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Une erreur est survenue pendant la déconnexion",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container justifyContent="left">
            {isMobile() ? (
              <img className={classes.logo} src={logoSquare} alt="Logo" />
            ) : (
              <img className={classes.logo} src={logoBanner} alt="Logo" />
            )}
            <IconButton
              sx={{
                display: user.loggedIn ? "block" : "none",
              }}
              className={classes.menuButton}
              onClick={() => {
                setCockpitDrawerOpen(!cockpitDrawerOpen);
              }}
              aria-label="Open Side Menu"
            >
              <SideBarIcon color="primary" />
            </IconButton>
          </Grid>
          <Grid container justifyContent="center">
            <Typography color="primary" variant="h6">
              {environment !== "production" ? `${environment}` : ""}
            </Typography>
          </Grid>
          <Grid container justifyContent="right">
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="primary"
              >
                <AccountCircle />
              </IconButton>
              {user.loggedIn ? (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ mt: "45px" }}
                >
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <BugReportIcon />
                    </ListItemIcon>
                    <a
                      href="https://forms.gle/2gGb7kkzH3zEvqii9"
                      className={classes.menuLink}
                    >
                      Signaler un bug
                    </a>
                  </MenuItem>
                  {/*<MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <ModelTrainingIcon />
                    </ListItemIcon>
                    <a
                      href="https://trello.com/invite/b/bqMrnpec/2cbf7fc54b90789b10522643297ba5d8/%F0%9F%9A%80jarvis-roadmap"
                      className={classes.menuLink}
                    >
                      Roadmap produit
                    </a>
                </MenuItem>*/}
                  <Divider />
                  {menuItems.map((menuItem) => {
                    return (
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>{menuItem.icon}</ListItemIcon>
                        <a
                          href={menuItem.link}
                          className={classes.menuLink}
                          target="_blank"
                        >
                          {menuItem.label}
                        </a>
                      </MenuItem>
                    );
                  })}
                  {/*backupUser && backupUser.id ? (
                    <React.Fragment>
                      <Divider />
                      <MenuItem
                        onClick={() => {
                          exitLoginAs();
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        Exit login as
                      </MenuItem>
                    </React.Fragment>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )*/}
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      logout();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    Déconnexion
                  </MenuItem>
                </Menu>
              ) : (
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{ mt: "45px" }}
                >
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <Link to="/" className={classes.menuLink}>
                      Accueil
                    </Link>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <LoginIcon />
                    </ListItemIcon>
                    <Link to="/login" className={classes.menuLink}>
                      Connexion
                    </Link>
                  </MenuItem>
                  {/*
                  <MenuItem onClick={handleClose}>
                    <Link to="/signup" className={classes.menuLink}>
                      Inscription
                    </Link>
                  </MenuItem>
                   */}
                </Menu>
              )}
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

NavBar.propTypes = {
  handleMobileDrawerOpen: PropTypes.func,
  handleMobileDrawerClose: PropTypes.func,
  mobileDrawerOpen: PropTypes.bool,
  //openRegisterDialog: PropTypes.func.isRequired,
  //openLoginDialog: PropTypes.func.isRequired,
};

export default NavBar;
