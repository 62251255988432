import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST, POSTLONG } from "../../../js/HTTPRequest";
import { makeComponentUniqueId } from "../../../js/otherManager";
import { distanceBeetweenCoordinates } from "../../../js/geoManager";
//IMAGES AND ICONS
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TableContainer,
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

//CONST
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "10rem",
  col5: "10rem",
  col6: "20rem",
};

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//This component is a modal allowing creation (or modification ?) of dahsboard integration
function ModalCompetitorsFilters({
  launchSearch,
  dialogsOpen,
  setDialogsOpen,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, constants } = useContext(UserContext);
  const [name, setName] = useState("");
  const [recurrence, setReccurence] = useState("none");
  const [filtersRestaurants, setFiltersRestaurants] = useState("");
  const [geofences, setGeofences] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Création de l'analyse en cours..."
  );

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setName("");
    setReccurence("none");
    setFiltersRestaurants("");
    setGeofences([]);
    setError("");
    setIsLoading(false);
    setDialogsOpen({ ...dialogsOpen, ModalCompetitorsFilters: false });
  };

  const handleSubmit = (e) => {
    if (filtersRestaurants === "") {
      return setError("Veuillez indiquer un filtre pour la recherche");
    }
    setError("");
    launchSearch(filtersRestaurants);
    handleClose();
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  return (
    <Dialog open={dialogsOpen.ModalCompetitorsFilters} onClose={handleClose}>
      <DialogTitle>New live scrapping</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            You're about to look for Uber Eats restaurants in this area matching
            the following words (not case sensitive)
          </DialogContentText>
          <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
            <TextField
              id={"filter-query"}
              label="Filter on restaurants name"
              multiline
              rows={3}
              value={filtersRestaurants}
              onChange={(event) => setFiltersRestaurants(event.target.value)}
              sx={{ width: "100%" }}
              variant="outlined"
            />
          </FormControl>
          <Typography component="div" className={classes.error}>
            <Box className={classes.error}>{error}</Box>
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalCompetitorsFilters.propTypes = {};

export default ModalCompetitorsFilters;
