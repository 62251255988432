import React from "react";

// material-ui
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid } from "@mui/material";
import { Skeleton } from "@mui/material";

// style constant
const useStyles = makeStyles({
  card: {
    width: "100%",
  },
});

//-----------------------|| SKELETON EARNING CARD ||-----------------------//

const SkeletonFilterBar = (sx) => {
  const classes = useStyles();
  return (
    <Card sx={{ mb: 2, width: "100%" }}>
      <CardContent sx={{ widt: "100%" }}>
        <Grid item xs={12}>
          <Skeleton variant="rect" height={15} sx={{ widt: "100%" }} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SkeletonFilterBar;
