import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
//import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS
import { Typography } from "@mui/material";

//CONST AND INTERNAL IMPORT
import _ from "lodash";
import ModalSchedulerAddTimeStampFunc from "./ModalSchedulerAddTimeStampFunc";
import { generateColorFromString, hexToRgb } from "../../../js/designManager";
import AvatarFromInitials from "./AvatarFromInitials";
import "./style.css";
const days = [
  //List of days
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

// WARNING !! WORKS BUT NEEDS TO BE COMMENTED > ABSOLUTLY UNREADABLE FOR THE MOMENT

function SchedulerFunc({
  schedule, //Array of objects {attached:{id,name,picture},schedule:[{dayofweek,time_periods:[]}]}
  setSchedule,
  watermark, //Display a schedule as watermark (ex: you want to display the opening hours of a restaurant while editing employees planning)
  viewOnly, //Cannot edit the scheduler
  show, //false to hide the scheduler
  items, //items you want to put schedule on (ex: list of employees)
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { dialogsOpen, setDialogsOpen } = useContext(UserContext);
  const [hours, setHours] = useState([]); //List of hours
  const [dragging, setDragging] = useState(false);
  const [recordedTimeRange, setRecordedTimeRange] = useState([]); //An array of all recorded cells in this format : {attached: [array of list items attached to this cell], minHour: 12, maxHour: 12, minDay: 3, maxDay: 3}
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    //An object with all 4 corners of our selected rectangle : minHour,maxHour,minDay,maxDay (initialize to contain no cells) and a field : attached: [array of list items attached to this cell]
    minHour: 23 * 4,
    maxHour: 0,
    minDay: 6,
    maxDay: 0,
  });
  const [initialSelection, setInitialSelection] = useState(null);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    var newHours = [];
    for (var i = 0; i < 24; ++i) {
      if (i % 2 == 0) {
        newHours.push(i + "h");
      } else {
        newHours.push("");
      }
      newHours.push("");
      newHours.push("");
      newHours.push("");
    }
    setHours(newHours);
  }, []); //Initialize hours [0h, 1h, 2h, 3h,...] once

  useEffect(() => {
    var newRecordedTimeRange = initiateRecordedTimeRange(schedule, days, hours);
    setRecordedTimeRange(newRecordedTimeRange ? newRecordedTimeRange : []);
  }, [schedule, hours]);

  // *******************************************************
  // ******************** EVENTS HANDLER *******************
  // *******************************************************

  const onMouseDown = (e) => {
    if (viewOnly) {
      return;
    }
    var newSelectedTimeRange = JSON.parse(JSON.stringify(selectedTimeRange));
    var daysHourObject = e.target.id.split("_");
    daysHourObject[0] = parseInt(daysHourObject[0]);
    daysHourObject[1] = parseInt(daysHourObject[1]);
    if (!daysHourObject || daysHourObject.length < 1) {
      return;
    }
    if (daysHourObject[0] > newSelectedTimeRange.maxDay) {
      newSelectedTimeRange.maxDay = parseInt(daysHourObject[0]);
    }
    if (daysHourObject[0] < newSelectedTimeRange.minDay) {
      newSelectedTimeRange.minDay = parseInt(daysHourObject[0]);
    }
    if (daysHourObject[1] > newSelectedTimeRange.maxHour) {
      newSelectedTimeRange.maxHour = parseInt(daysHourObject[1]);
    }
    if (daysHourObject[1] < newSelectedTimeRange.minHour) {
      newSelectedTimeRange.minHour = parseInt(daysHourObject[1]);
    }

    setDragging(true);
    setSelectedTimeRange(newSelectedTimeRange);
    setInitialSelection({
      day: parseInt(daysHourObject[0]),
      hour: parseInt(daysHourObject[1]),
    });
  };

  //onMouseLeave(e) {}

  const onMouseEnter = (e) => {
    if (viewOnly) {
      return;
    }
    if (dragging) {
      var daysHourObject = e.target.id.split("_");
      daysHourObject[0] = parseInt(daysHourObject[0]);
      daysHourObject[1] = parseInt(daysHourObject[1]);
      if (!daysHourObject || daysHourObject.length < 1) {
        return;
      }
      var newSelectedTimeRange = JSON.parse(JSON.stringify(selectedTimeRange)); //Clone currently selected TimeRange

      if (daysHourObject[0] > newSelectedTimeRange.maxDay) {
        newSelectedTimeRange.maxDay = parseInt(daysHourObject[0]);
      }
      if (daysHourObject[0] < selectedTimeRange.minDay) {
        newSelectedTimeRange.minDay = parseInt(daysHourObject[0]);
      }
      if (daysHourObject[1] > newSelectedTimeRange.maxHour) {
        newSelectedTimeRange.maxHour = parseInt(daysHourObject[1]);
      }
      if (daysHourObject[1] < newSelectedTimeRange.minHour) {
        newSelectedTimeRange.minHour = parseInt(daysHourObject[1]);
      }

      if (
        daysHourObject[0] < newSelectedTimeRange.maxDay &&
        daysHourObject[0] >= newSelectedTimeRange.minDay &&
        daysHourObject[0] >= initialSelection.day
      ) {
        //We're shortening selection for days
        newSelectedTimeRange.maxDay = parseInt(daysHourObject[0]);
      }
      if (
        daysHourObject[0] <= newSelectedTimeRange.maxDay &&
        daysHourObject[0] > newSelectedTimeRange.minDay &&
        daysHourObject[0] <= initialSelection.day
      ) {
        //We're shortening selection for days
        newSelectedTimeRange.minDay = parseInt(daysHourObject[0]);
      }

      if (
        daysHourObject[1] < newSelectedTimeRange.maxHour &&
        daysHourObject[1] >= newSelectedTimeRange.minHour &&
        daysHourObject[1] >= initialSelection.hour
      ) {
        //We're shortening selection for days
        newSelectedTimeRange.maxHour = parseInt(daysHourObject[1]);
      }
      if (
        daysHourObject[1] <= newSelectedTimeRange.maxHour &&
        daysHourObject[1] > newSelectedTimeRange.minHour &&
        daysHourObject[1] <= initialSelection.hour
      ) {
        //We're shortening selection for days
        newSelectedTimeRange.minHour = parseInt(daysHourObject[1]);
      }

      setSelectedTimeRange(newSelectedTimeRange);
    }
  };

  const onMouseUp = (e) => {
    if (viewOnly) {
      return;
    }
    setDragging(false);
    setDialogsOpen({ ...dialogsOpen, ModalSchedulerAddTimeStampFunc: true });
  };

  // *******************************************************
  // ********************** CALLBACKS **********************
  // *******************************************************
  const newTimeStamp = (attachedArray) => {
    var newRecordedTimeRange = JSON.parse(JSON.stringify(recordedTimeRange));
    var newSelectedTimeRange = JSON.parse(JSON.stringify(selectedTimeRange));
    newSelectedTimeRange.attached = attachedArray;
    newRecordedTimeRange.push(newSelectedTimeRange);

    var newSchedule = buildScheduleObject(newRecordedTimeRange);
    newRecordedTimeRange = initiateRecordedTimeRange(newSchedule);
    setRecordedTimeRange(newRecordedTimeRange);
    setSchedule(newSchedule);
    setSelectedTimeRange({ minHour: 23 * 4, maxHour: 0, minDay: 6, maxDay: 0 });
  };

  const cancelTimeStamp = () => {
    setSelectedTimeRange({ minHour: 23 * 4, maxHour: 0, minDay: 6, maxDay: 0 });
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  function initiateRecordedTimeRange(tmpSchedule) {
    var newRecordedTimeRange = [];
    days.map((Day, IndexDay) => {
      hours.map((Hour, IndexHour) => {
        var attached = isSlotRecordedInEndFormatSchedule(
          IndexDay,
          IndexHour,
          tmpSchedule,
          hours
        );
        if (!attached) {
          //Nothing
        } else {
          newRecordedTimeRange.push({
            attached,
            minHour: IndexHour,
            maxHour: IndexHour,
            minDay: IndexDay,
            maxDay: IndexDay,
          });
        }
        return null;
      });
      return null;
    });

    return newRecordedTimeRange;
  }

  const isSlotRecordedInEndFormatSchedule = (
    IndexDay,
    IndexHour,
    tmpSchedule
  ) => {
    var attached = [];
    var strDay = getDayOfWeek(IndexDay);
    tmpSchedule.map((AttachedObject) => {
      AttachedObject.schedule.map((Day) => {
        if (Day.day_of_week == strDay) {
          Day.time_periods.map((TimePeriod) => {
            var minHour = calculateIndexFromHour(TimePeriod.start_time, hours);
            var maxHour =
              calculateIndexFromHour(TimePeriod.end_time, hours) - 1;
            if (IndexHour >= minHour && IndexHour <= maxHour) {
              attached.push(AttachedObject.attached);
            }
            return null;
          });
        }
        return null;
      });
      return null;
    });
    if (attached && attached.length > 0) {
      return attached;
    } else {
      return false;
    }
  };

  const buildScheduleObject = (newRecordedTimeRange) => {
    if (!newRecordedTimeRange) {
      newRecordedTimeRange = JSON.parse(JSON.stringify(recordedTimeRange));
    }
    var SchduleObject = [];
    var attached = [];
    newRecordedTimeRange.map((TimeRange) => {
      if (TimeRange.attached && TimeRange.attached.length > 0) {
        TimeRange.attached.map((AttachedObject) => {
          var isAlreadyListed = false;
          attached.map((AlreadyAttachedObject) => {
            if (AlreadyAttachedObject.id == AttachedObject.id) {
              isAlreadyListed = true;
            }
            return null;
          });
          if (!isAlreadyListed) {
            attached.push(AttachedObject);
          }
          return null;
        });
      }
      return null;
    });

    attached.map((Attached) => {
      var tempSchedule = [];
      days.map((Day, IndexDay) => {
        var strDay = getDayOfWeek(IndexDay);
        var timerangesForTheDay = [];
        var writing = false;
        hours.map((Hour, IndexHour) => {
          var attachedToTR = isSlotRecorded(
            IndexDay,
            IndexHour,
            newRecordedTimeRange
          );

          var isAttached = false;
          if (attachedToTR && attachedToTR.length > 0) {
            attachedToTR.map((AttachObjectToTR) => {
              if (AttachObjectToTR.id === Attached.id) {
                isAttached = true;
              }
              return null;
            });
          }

          if (!isAttached) {
            writing = false;
          } else {
            if (writing) {
              timerangesForTheDay[timerangesForTheDay.length - 1].end_time =
                calculateHourFromIndex(IndexHour + 1);
            } else {
              timerangesForTheDay.push({
                start_time: calculateHourFromIndex(IndexHour),
                end_time: calculateHourFromIndex(IndexHour + 1),
              });
            }
            writing = true;
          }
          return null;
        });
        tempSchedule.push({
          day_of_week: strDay,
          time_periods: timerangesForTheDay,
        });
        return null;
      });
      SchduleObject.push({
        attached: {
          id: Attached.id,
          name: Attached.name,
          color: Attached.color ? Attached.color : null,
        },
        schedule: tempSchedule,
      });
      return null;
    });
    return SchduleObject;
  };

  const isTimeSlotSelectedFunc = (IndexDay, IndexHour) => {
    if (
      IndexDay >= selectedTimeRange.minDay &&
      IndexDay <= selectedTimeRange.maxDay &&
      IndexHour >= selectedTimeRange.minHour &&
      IndexHour <= selectedTimeRange.maxHour
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isSlotRecorded = (
    IndexDay,
    IndexHour,
    localRecordedTimeRange = recordedTimeRange
  ) => {
    var toReturn = false;
    localRecordedTimeRange.map((TimeRange) => {
      if (
        IndexDay >= TimeRange.minDay &&
        IndexDay <= TimeRange.maxDay &&
        IndexHour >= TimeRange.minHour &&
        IndexHour <= TimeRange.maxHour
      ) {
        toReturn = TimeRange.attached;
      }
    });

    return toReturn;
  };

  const isWatermark = (IndexDay, IndexHour) => {
    if (!watermark || watermark.length === 0) {
      return false;
    }
    var strDay = getDayOfWeek(IndexDay);
    var isWatermark = false;
    watermark.map((Day) => {
      if (Day.day_of_week === strDay) {
        Day.time_periods.map((TimePeriod) => {
          var minHour = calculateIndexFromHour(TimePeriod.start_time, hours);
          var maxHour = calculateIndexFromHour(TimePeriod.end_time, hours);
          if (IndexHour >= minHour && IndexHour <= maxHour) {
            isWatermark = true;
          }
          return null;
        });
      }
    });
    return isWatermark;
  };

  const getDayOfWeek = (intDate) => {
    switch (intDate) {
      case 6:
        return "sunday";
      case 0:
        return "monday";
      case 1:
        return "tuesday";
      case 2:
        return "wednesday";
      case 3:
        return "thursday";
      case 4:
        return "friday";
      case 5:
        return "saturday";
      default:
        return "noday";
    }
  };

  const calculateHourFromIndex = (IndexHour) => {
    var indexStepInMinutes = 1440 / hours.length;
    var timeInMinutes = IndexHour * indexStepInMinutes;
    var localHours = Math.floor(timeInMinutes / 60);
    var localMinutes = timeInMinutes % 60;

    if (localMinutes < 10) {
      return localHours + ":0" + localMinutes;
    } else {
      return localHours + ":" + localMinutes;
    }
  };

  const calculateIndexFromHour = (strHour) => {
    var splittedTime = strHour.split(":");
    var totalhours = parseInt(splittedTime[0]);
    var minutes = parseInt(splittedTime[1]);
    var indexStepInMinutes = 1440 / hours.length;
    var timeInMinutes = totalhours * 60 + minutes;
    var IndexHour = timeInMinutes / indexStepInMinutes;
    return IndexHour;
  };

  const calculateTotalHoursFromSchedule = (tmpSchedule) => {
    var totalMinutes = 0;
    tmpSchedule.map((Day) => {
      Day.time_periods.map((TP) => {
        var IndexMinHour = calculateIndexFromHour(TP.start_time);
        var IndexMaxHour = calculateIndexFromHour(TP.end_time);
        var indexStepInMinutes = 1440 / hours.length;
        return (totalMinutes =
          totalMinutes + indexStepInMinutes * (IndexMaxHour - IndexMinHour));
      });
    });
    var totalHours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;

    if (minutes < 10) {
      return totalHours + ":0" + minutes;
    } else {
      return totalHours + ":" + minutes;
    }
  };

  const addInHoursFormat = (a, b) => {
    var splittedA = a.split(":");
    var splittedB = b.split(":");
    var hoursA = parseInt(splittedA[0]);
    var hoursB = parseInt(splittedB[0]);
    var minutesA = parseInt(splittedA[1]);
    var minutesB = parseInt(splittedB[1]);

    var totalMinutes = hoursA * 60 + hoursB * 60 + minutesA + minutesB;
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;

    if (minutes < 10) {
      return hours + ":0" + minutes;
    } else {
      return hours + ":" + minutes;
    }
  };

  // ******************************************************
  // ******************* DISPLAY FUNCTIONS *****************
  // *******************************************************

  const displayLegend = () => {
    //List all attached item we get
    var attached = [];
    var totalWeekTimeAllAttached = "0:00";
    schedule.map((AttachedObject) => {
      var totalWeekTime = calculateTotalHoursFromSchedule(
        AttachedObject.schedule
      );
      totalWeekTimeAllAttached = addInHoursFormat(
        totalWeekTimeAllAttached,
        totalWeekTime
      );
      attached.push({ object: AttachedObject.attached, totalWeekTime });
      return null;
    });

    return (
      <div>
        <Typography ml={4} variant="body2">
          Total heures : {totalWeekTimeAllAttached}
        </Typography>
        {attached.map((LegendObject) => {
          if (attached.length < 2) {
            return;
          }
          return (
            <div
              className="Scheduler-legend-row"
              key={LegendObject.object.name}
            >
              <AvatarFromInitials
                color={LegendObject.object.color}
                className="smaller-avatar mr-2"
                initials={""}
                fullname={LegendObject.object.name}
              ></AvatarFromInitials>
              <Typography ml={4} variant="body2">
                {LegendObject.object.name} - Total: {LegendObject.totalWeekTime}
              </Typography>
            </div>
          );
        })}
      </div>
    );
  };

  const displayStyles = (isTimeSlotSelected, styles, id) => {
    if (isTimeSlotSelected) {
      return <div id={id}></div>;
    } else {
      return styles.map((Style, index) => {
        return (
          <div
            id={id}
            key={"Style_" + index}
            className="scheduler-subContent"
            style={Style}
          ></div>
        );
      });
    }
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  if (!show) {
    return <span></span>;
  }  
  return (
    <div className="scheduler-container" id="">
      <div className="schedule-legend">{displayLegend()}</div>
      <div className="scheduler-row-header">
        <div className="scheduler-hour"></div>
        {days.map((Day, index) => {
          return (
            <div
              className="scheduler-day text-center text-truncate"
              key={"Day_" + index}
            >
              {Day}
            </div>
          );
        })}
      </div>
      {hours.map((Hour, IndexHour) => {
        return (
          <div className="scheduler-row" key={"Hour_" + IndexHour}>
            <div className="scheduler-hour text-right">{Hour}</div>
            {days.map((Day, IndexDay) => {
              var attached = isSlotRecorded(IndexDay, IndexHour);
              var isTimeSlotSelected = isTimeSlotSelectedFunc(
                IndexDay,
                IndexHour
              );
              var styles = [];
              if (!isTimeSlotSelected && attached && attached.length >= 0) {
                attached.map((AttachedObject, Index) => {
                  var color = AttachedObject.color
                    ? AttachedObject.color
                    : "#" + generateColorFromString(AttachedObject.name);
                  var width = 100 / attached.length;
                  styles.push({
                    backgroundColor: color,
                    flex: width + "%",
                    height: "100%",
                  });
                  return null;
                });
              } else {
                styles = [{}];
              }
              return (
                <div
                  id={`${IndexDay}_${IndexHour}`}
                  key={`${IndexDay}_${IndexHour}`}
                  onMouseDown={onMouseDown}
                  onMouseUp={onMouseUp}
                  onMouseEnter={onMouseEnter}
                  style={{
                    backgroundColor: isTimeSlotSelected
                      ? alpha(
                          theme.palette.primary.main,
                          theme.palette.action.activatedOpacity
                        )
                      : "",
                  }}
                  className={`scheduler-content ${
                    Hour !== "" ? "scheduler-content-round-hour" : ""
                  } ${isWatermark(IndexDay, IndexHour) ? "watermark" : ""}`}
                >
                  {displayStyles(
                    isTimeSlotSelected,
                    styles,
                    `${IndexDay}_${IndexHour}`
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
      <ModalSchedulerAddTimeStampFunc
        items={items}
        cancel={cancelTimeStamp}
        handleSubmit={newTimeStamp}
      />
    </div>
  );
}

SchedulerFunc.propTypes = {};

// ********************* COMPONENT END ***********************

export default SchedulerFunc;
