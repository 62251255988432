import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import ZoomInIcon from "@mui/icons-material/ZoomIn";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  TableContainer,
  TableBody,
  TableHead,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableRow,
  useMediaQuery,
  Chip,
} from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function RadarCutomersRow({ customerItem, colWidths, editCustomer }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [users, setUsers] = useState([]);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <TableRow>
      <TableCell style={{ width: colWidths.col1 }}>
        {customerItem.name}
      </TableCell>
      <TableCell style={{ width: colWidths.col2 }} align="center">
        {customerItem.email && customerItem.email !== ""
          ? customerItem.email
          : "****@****"}
      </TableCell>
      <TableCell style={{ width: colWidths.col3 }} align="center">
        {customerItem.orders_recorded}
      </TableCell>
      <TableCell style={{ width: colWidths.col4 }} align="center">
        {customerItem.amount_gross}€
      </TableCell>
      <TableCell style={{ width: colWidths.col5 }} align="center">
        {customerItem.discount}€
      </TableCell>
      <TableCell style={{ width: colWidths.col6 }} align="center">
        {customerItem.amount_gross_menu_discount}€
      </TableCell>
      <TableCell style={{ width: colWidths.col7 }} align="center">
        <IconButton
          aria-label="settings"
          onClick={() => {
            editCustomer(customerItem);
          }}
        >
          <ZoomInIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

RadarCutomersRow.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarCutomersRow;
