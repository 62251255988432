import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
//import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import ImageLandingPage from "../../img/girl-using-iphone-6-and-imac-at-office.png"; // Import using relative path
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

//MATERIAL UI COMPONENT IMPORTS
import { Box, Button, Grid, Typography } from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  title: {},
  container: {
    background: "white",
  },
  leftPanel: {
    padding: "40px",
    minHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rightPanel: {
    backgroundImage: `url(${ImageLandingPage})`,
    backgroundSize: "cover",
    minHeight: "94vh",
  },
  cta: {
    marginTop: "30px !important",
  },
}));

function Home(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user } = useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  if (user.loggedIn) {
    const newpath = `/cockpit`;
    return <Navigate to={newpath} replace />; //Navigate replace Redirect in React-Router v6 : https://stackoverflow.com/questions/70005020/redirect-in-react-router-not-working-when-i-click-from-users-to-post/70005070
  }
  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={5} md={4}>
          <Box className={classes.leftPanel}>
            <Typography
              className={classes.title}
              justify="center"
              alignItems="center"
              justifyContent="center"
              color="primary"
              sx={{
                fontSize: {
                  xs: "2rem !important",
                  sm: "2rem !important",
                  md: "2rem !important",
                },
              }}
            >
              Les règles du jeu ont changé
            </Typography>
            <Typography
              className={classes.title}
              justify="center"
              alignItems="center"
              justifyContent="center"
              sx={{
                fontSize: {
                  xs: "2rem !important",
                  sm: "2rem !important",
                  md: "3rem !important",
                },
              }}
            >
              Vos outils aussi !
            </Typography>
            <Button
              variant="contained"
              href="/login"
              size="large"
              className={classes.cta}
              startIcon={<RocketLaunchIcon />}
              sx={{
                fontSize: {
                  sm: "15px !important",
                  md: "20px !important",
                },
              }}
            >
              Démarrer la partie
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          style={{ padding: 0, marginTop: "-15px" }}
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },
          }}
        >
          <div className={classes.rightPanel}></div>
        </Grid>
      </Grid>
    </div>
  );
}

Home.propTypes = {};

export default Home;
