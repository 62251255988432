import PropTypes from "prop-types";
import React from "react";

// material-ui
import { Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material";

// third-party
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";

// project imports
import SkeletonClassicChart from "../../ui-components/Skeleton/SkeletonClassicChart";
import MainCard from "../../ui-components/cards/MainCard";
import { numberWithSpaces } from "../../js/numberManager";
const gridSpacing = 3;

//-----------------------|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||-----------------------//

const ClassicChart = ({
  isLoading,
  title,
  mainValue,
  xAxis,
  series,
  status,
  callBackStatusChange,
}) => {
  const [value, setValue] = React.useState(status[0] ? status[0].value : null);
  const [seriesLocal, setSeriesLocal] = React.useState(series);
  const [xAxisLocal, setXAxisLocal] = React.useState(xAxis);
  // chart data

  var chartData = {
    height: 280,
    type: "bar",
    options: {
      chart: {
        id: "classic-chart",
        stacked: false,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
        },
      },
      xaxis: {
        type: "category",
        categories: xAxisLocal,
      },
      legend: {
        show: true,
        fontSize: "14px",
        fontFamily: `'Roboto', sans-serif`,
        position: "bottom",
        offsetX: 20,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 16,
          height: 16,
          radius: 5,
        },
        itemMargin: {
          horizontal: 15,
          vertical: 8,
        },
      },
      fill: {
        type: "solid",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
      },
      stroke: {
        curve: "straight",
        width:
          seriesLocal &&
          seriesLocal.length > 0 &&
          seriesLocal[0].type === "line"
            ? 3
            : 0,
      },
      markers: {
        size: 4,
      },
    },
    series: seriesLocal,
  };

  const theme = useTheme();

  const primary = "black";
  const grey200 = "#d3d3d3";

  const primary200 = "grey";
  const primaryDark = "black";
  const secondaryMain = "blue";
  const secondaryLight = "green";
  const grey500 = "grey";

  React.useEffect(() => {
    const newChartData = {
      ...chartData.options,
      colors: [
        theme.palette.chart[0],
        theme.palette.chart[1],
        theme.palette.chart[2],
        theme.palette.chart[3],
        theme.palette.chart[4],
      ],
      yaxis: {
        labels: {
          style: {
            colors: [primary],
          },
        },
      },
      xaxis: {
        type: "category",
        categories: xAxis,
      },
      stroke: {
        curve: "straight",
        width: series && series.length > 0 && series[0].type === "line" ? 3 : 0,
      },
      grid: {
        borderColor: grey200,
      },
      tooltip: {
        theme: "light",
      },
      legend: {
        labels: {
          colors: grey500,
        },
      },
    };
    // do not load chart when loading
    if (!isLoading) {
      ApexCharts.exec(`classic-chart`, "updateOptions", newChartData);
    }
  }, [
    primary200,
    primaryDark,
    secondaryMain,
    secondaryLight,
    primary,
    grey200,
    isLoading,
    grey500,
  ]);

  React.useEffect(() => {
    setSeriesLocal(series);
    setXAxisLocal(xAxis);
    const newChartData = {
      ...chartData.options,
      xaxis: {
        type: "category",
        categories: xAxis,
      },
      stroke: {
        curve: "straight",
        width: series && series.length > 0 && series[0].type === "line" ? 3 : 0,
      },
      series: series,
    };
    // do not load chart when loading
    if (!isLoading) {
      ApexCharts.exec(`classic-chart`, "updateOptions", newChartData);
    }
  }, [xAxis, series]);

  const onStatusChange = (e) => {
    var newObject = callBackStatusChange(xAxis, series, e.target.value); //IMPORTANT TO SEND ORIGINAL XAXIS AND SERIES (ALWAYS THE SAME SO PARENT COMPONENT CALL BACK KNOWS INITIAL FORMAT)
    setValue(e.target.value);
    setSeriesLocal(newObject.series);
    setXAxisLocal(newObject.xAxis);
  };
  console.log("mainValue.length", mainValue.toString().length);
  return (
    <React.Fragment>
      {isLoading ? (
        <SkeletonClassicChart />
      ) : (
        <MainCard style={{ borderRadius: "20px" }}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography variant="subtitle2">{title}</Typography>
                    </Grid>
                    <Grid item>{mainValue}</Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {status && status.length > 0 ? (
                    <TextField
                      id="textfield-status"
                      select
                      value={value}
                      onChange={onStatusChange}
                    >
                      {status.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Chart {...chartData} />
            </Grid>
          </Grid>
        </MainCard>
      )}
    </React.Fragment>
  );
};

ClassicChart.propTypes = {
  isLoading: PropTypes.bool,
};

export default ClassicChart;
