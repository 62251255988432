import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS
import { useMediaQuery } from "@mui/material";
import CockpitDrawer from "./cockpitDrawer";
import CockpitMain from "./cockpitMain";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

function CockpitPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user } = useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  if (!user.loggedIn) {
    const newpath = `/`;
    return <Navigate to={newpath} replace />; //Navigate replace Redirect in React-Router v6 : https://stackoverflow.com/questions/70005020/redirect-in-react-router-not-working-when-i-click-from-users-to-post/70005070
  }
  return (
    <div className={classes.div}>
      <CockpitDrawer />
      <CockpitMain />
    </div>
  );
}

CockpitPage.propTypes = {};

export default CockpitPage;
