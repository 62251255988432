import React from "react";

// material-ui
import { Card, CardContent, Grid } from "@mui/material";
import { Skeleton } from "@mui/material";

// project imports
const gridSpacing = 3;

//-----------------------|| SKELETON TOTAL GROWTH BAR CHART ||-----------------------//

const SkeletonClassicChart = () => {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={gridSpacing}
            >
              <Grid item xs zeroMinWidth>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Skeleton variant="text" />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rect" height={20} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Skeleton variant="rect" height={50} width={80} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rect" height={320} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SkeletonClassicChart;
