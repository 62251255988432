import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/fr";
import _ from "lodash";
import {
  isBuffaloGrill,
  isCourtepaille,
  isBunMeatBun,
  isPopeyes,
  isArlette,
} from "../../js/brandsManager";
import { makeComponentUniqueId } from "../../js/otherManager";
import {
  generateGlobalScheduleDescriptionForJetpack,
  generateAgendaDescriptionForJetpack,
} from "../../js/jetpackManager";
//IMAGES AND ICONS
import logo_bg from "../../img/logo-bg.png";
import logo_bmb from "../../img/logo-bmb.png";
import logo_ctp from "../../img/logo-ctp.png";
import logo_pp from "../../img/logo-pp.png";
import logo_aj from "../../img/logo-aj.png";
//MATERIAL UI COMPONENT IMPORTS
import {
  Badge,
  AvatarGroup,
  Avatar,
  Box,
  Card,
  Grid,
  CardHeader,
  CardContent,
  CardActions,
  Chip,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
//INTERNAL COMPONENT OR SCRIPTS

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  inactive: {
    backgroundColor: theme.palette.action.disabledBackground + " !important",
    color: theme.palette.action.disabled + " !important",
  },
  agendaDescription: {
    fontSize: "9px!important",
  },
  date: {
    fontSize: "11px!important",
  },
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

// ********************* COMPONENT START ***********************

function CardJetpackCampaignRestaurant({ initPage, jetpackCampaign }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    moment.locale("fr");
  }, []);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const toggleChannelOptIn = (optIn, channelId, channelName) => {
    POST("/api/jetpackcampaigns/optin", {
      name: `${channelName} - ${jetpackCampaign.name}`,
      optIn,
      channelId,
      jetpackCampaignId: jetpackCampaign.id,
    })
      .then((response) => {
        initPage();
        displayNotification(
          "success",
          optIn
            ? "La campagne est bien active, le changement sera effectif d'ici une à deux heures."
            : "La campagne est bien désactivée, le changement sera effectif d'ici une à deux heures.",
          "top",
          "right"
        );
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Une erreur s'est produite durant la modification du statut de la campagne",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const generatePromoTypeDescription = () => {
    var description = "";
    if (
      jetpackCampaign &&
      jetpackCampaign.actions &&
      jetpackCampaign.actions.length > 0
    ) {
      switch (jetpackCampaign.actions[0].type) {
        case "free_item":
          description = "Article gratuit";
          break;
        case "discount_item":
          description = "Réductions sur certains plats";
          break;
        case "free_delivery":
          description = "Livraison offerte";
          break;
        case "bogo":
          description = "Bogo";
          break;
        case "discount":
          var valueObject = jetpackCampaign.actions[0].valueObject
            ? JSON.parse(jetpackCampaign.actions[0].valueObject)
            : {};

          if (valueObject.discount_type === "FLAT_15_3") {
            description = "3€ offerts pour 15€ dépensés";
          } else if (valueObject.discount_type === "FLAT_20_5") {
            description = "5€ offerts pour 20€ dépensés";
          } else if (valueObject.discount_type === "FLAT_30_8") {
            description = "8€ offerts pour 30€ dépensés";
          } else {
            description = "Discount";
          }

          break;
        case "sponsoring":
          description = "Sponsoring";
          break;
        default:
      }
      return description;
    }
  };

  const generateBrandLogos = () => {
    var logos = [];
    var gotBuffalo = false;
    var gotCourtepaille = false;
    var gotBunMeatBun = false;
    var gotPopepyes = false;
    var gotArlette = false;
    if (
      jetpackCampaign &&
      jetpackCampaign.channels &&
      jetpackCampaign.channels.length > 0
    ) {
      jetpackCampaign.channels.map((channel) => {
        if (isBuffaloGrill(channel.name)) {
          gotBuffalo = true;
        }
        if (isCourtepaille(channel.name)) {
          gotCourtepaille = true;
        }
        if (isBunMeatBun(channel.name)) {
          gotBunMeatBun = true;
        }
        if (isPopeyes(channel.name)) {
          gotPopepyes = true;
        }
        if (isArlette(channel.name)) {
          gotArlette = true;
        }
      });
    }
    if (gotBuffalo) {
      logos.push({ alt: "Buffalo Grill", src: logo_bg });
    }
    if (gotCourtepaille) {
      logos.push({ src: logo_ctp, alt: "Courtepaille" });
    }
    if (gotBunMeatBun) {
      logos.push({ src: logo_bmb, alt: "Bun Meat Bun" });
    }
    if (gotPopepyes) {
      logos.push({ src: logo_pp, alt: "Popeyes" });
    }
    if (gotArlette) {
      logos.push({ src: logo_aj, alt: "Arlette et Jean" });
    }
    return logos;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  var promoTypeDescription = generatePromoTypeDescription();
  var agendaDescriptions = generateAgendaDescriptionForJetpack(jetpackCampaign);
  var globalScheduleDescription =
    generateGlobalScheduleDescriptionForJetpack(jetpackCampaign);
  var logos = generateBrandLogos();

  return (
    <Card
      className={
        !jetpackCampaign.active || !jetpackCampaign.explicitOptInRequired
          ? classes.inactive
          : ""
      }
    >
      {/*
    <CardHeader
        avatar={<CampaignIcon color="text.secondary" />}
        title={jetpackCampaign.name}
        sx={{ mb: 0 }}
        subheader={
          jetpackCampaign.end
            ? `Campagne prévue du ${moment(jetpackCampaign.start).format(
                "LL"
              )} au ${moment(jetpackCampaign.end).format("LL")}`
            : "Campagne non délimitée dans le temps"
        }
      />
      */}

      <Grid container spacing={2} sx={{ mt: 1, p: 1 }}>
        <Grid
          item
          xs={3}
          textAlign="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AvatarGroup>
            {logos.map((logo) => {
              var randomId = makeComponentUniqueId();
              return <Avatar alt={logo.alt} key={randomId} src={logo.src} />;
            })}
          </AvatarGroup>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" textAlign="center">
            <b>{promoTypeDescription}</b>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography className={classes.date} textAlign="center">
            <i>
              {jetpackCampaign.end
                ? `Du ${moment(jetpackCampaign.start).format("LL")} au ${moment(
                    jetpackCampaign.end
                  ).format("LL")}`
                : "Non délimitée dans le temps"}
            </i>
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          textAlign="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" textAlign="center">
            {globalScheduleDescription}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          textAlign="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {jetpackCampaign.name}
        </Grid>
        <Grid
          item
          xs={12}
          textAlign="center"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {agendaDescriptions.map((row, index) => {
              var randomId = makeComponentUniqueId();
              return (
                <Typography
                  className={classes.agendaDescription}
                  key={randomId}
                  textAlign="center"
                >
                  {row}
                </Typography>
              );
            })}
          </div>
        </Grid>
      </Grid>
      <CardContent sx={{ mt: 0 }}>
        <Typography
          variant="body2"
          sx={{ mb: 2 }}
          dangerouslySetInnerHTML={{ __html: jetpackCampaign.description }}
        ></Typography>
        {!jetpackCampaign.explicitOptInRequired ? (
          <Chip
            sx={{
              mt: 2,
              backgroundColor: theme.palette.primary.main,
              color: "white",
            }}
            label="Participation automatique"
          />
        ) : (
          <div></div>
        )}
        <div>
          {jetpackCampaign.channels.map((channel) => {
            var optIn = false;
            if (!jetpackCampaign.explicitOptInRequired) {
              optIn = true;
            } else {
              jetpackCampaign.optIns.map((optInItem) => {
                if (optInItem.channelId === channel.id) {
                  optIn = optInItem.optIn;
                }
              });
            }
            var src = logo_bg;
            if (isBuffaloGrill(channel.name)) {
              src = logo_bg;
            } else if (isCourtepaille(channel.name)) {
              src = logo_ctp;
            } else if (isBunMeatBun(channel.name)) {
              src = logo_bmb;
            } else if (isPopeyes(channel.name)) {
              src = logo_pp;
            } else if (isArlette(channel.name)) {
              src = logo_aj;
            }
            return (
              <Grid
                item
                key={
                  "Grid_jetpackCampaign_Channel_CardJetpackCampaignRestaurant" +
                  jetpackCampaign.id +
                  "_" +
                  channel.id
                }
                justify="center"
                alignItems="center"
                justifyContent="center"
              >
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!jetpackCampaign.explicitOptInRequired}
                      checked={optIn}
                      onChange={(e) => {
                        toggleChannelOptIn(!optIn, channel.id, channel.name);
                      }}
                    />
                  }
                  label={
                    <CardHeader
                      sx={{ p: 0 }}
                      avatar={
                        <Avatar
                          alt="logo"
                          src={src}
                          sx={{ width: 20, height: 20 }}
                        />
                      }
                      title={
                        <Typography variant="body2">{channel.name}</Typography>
                      }
                    />
                  }
                  labelPlacement="end"
                />
              </Grid>
            );
          })}
        </div>
      </CardContent>
      {/*<CardActions disableSpacing>      
        <Box ml={4} variant="body2" color="text.secondary">
          {error !== "" ? (
            <Typography variant="body2" color="red">
              {error}
            </Typography>
          ) : (
            <React.Fragment>
              <Typography variant="body2" color="text.secondary"></Typography>
            </React.Fragment>
          )}
        </Box>
          </CardActions>*/}
    </Card>
  );
}

CardJetpackCampaignRestaurant.propTypes = {};

// ********************* COMPONENT END ***********************

export default CardJetpackCampaignRestaurant;
