import React from "react";

// material-ui
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid } from "@mui/material";
import { Skeleton } from "@mui/material";

// style constant
const useStyles = makeStyles({
  card: {
    width: "100%",
  },
});

//-----------------------|| SKELETON EARNING CARD ||-----------------------//

const SkeletonJetpackAdminRow = (sx) => {
  const classes = useStyles();
  const col1 = 4;
  const col2 = 2;
  const col3 = 2;
  const col4 = 3;
  const col5 = 1;
  return (
    <Card sx={{ mb: 2, width: "100%" }}>
      <CardContent sx={{ widt: "100%" }}>        
        <Grid container spacing={2}>    
          <Grid item xs={col1}>
            <Skeleton variant="rect" height={30} />
          </Grid>
          <Grid item xs={col2}>
            <Skeleton variant="rect" height={30} />
          </Grid>
          <Grid item xs={col3}>
            <Skeleton variant="rect" height={30} />
          </Grid>
          <Grid item xs={col4}>
            <Skeleton variant="rect" height={30} />
          </Grid>
          <Grid item xs={col5}>
            <Skeleton variant="rect" height={30} />
          </Grid>
          </Grid>
      </CardContent>
    </Card>
  );
};

export default SkeletonJetpackAdminRow;
