import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import classNames from "classnames";
import { useTheme, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
import _ from "lodash";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import ModalSparkLineDetailGeneric from "./ModalSparkLineDetailGeneric";

import {
  GoogleMap,
  LoadScript,
  DrawingManager,
  ScriptLoaded,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";

//ICONS
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import DownloadIcon from "@mui/icons-material/Download";

//MATERIAL UI COMPONENT IMPORTS
import {
  AppBar,
  Autocomplete,
  TableContainer,
  FormGroup,
  FormControlLabel,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Button,
  ButtonGroup,
  ListItem,
  ListItemIcon,
  Drawer,
  Card,
  Checkbox,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  ListItemText,
  Toolbar,
  TextField,
  useMediaQuery,
} from "@mui/material";

//import Dashboards_FiltersBar from "../../components/data_dashboards/Dashboards_FiltersBar";
//ICONS

import RadarCompetitorsExpansionRow from "./RadarCompetitorsExpansionRow";
import SkeletonDashboardTable from "../../../ui-components/Skeleton/SkeletonDashboardTable";
// ---------- CONST -----------
export const drawerWidth = "40vw";
export const drawerWidthMobile = 80;
// *******************************************************
// ************************ STYLE ************************
// *******************************************************
const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  overlay: {
    filter: "blur(3px)",
    //position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.54)",
    //color: "white",
    top: "0px",
    left: "0px",
    zIndex: 1000,
  },
  noMargin: {
    marginTop: "0px!important",
    marginBottom: "0px!important",
    paddingTop: "0px!important",
    paddingBottom: "0px!important",
  },
  btnUpdate: {
    position: "fixed",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    top: "100px",
    zIndex: 1001,
  },
  hide: {
    display: "none!important",
  },
  activeGroupButton: {
    backgroundColor: theme.palette.primary.main + "!important",
    color: "white!important",
  },
  img_marker: {
    height: "150px",
  },
  img_table: {
    height: "50px",
  },
}));
//CONSTANTE
const colWidths = {
  col1: "7rem",
  col2: "20rem",
  col3: "20rem",
  col4: "20rem",
  col5: "20rem",
  col6: "20rem",
};
//yFilters (array of arrays of filter)
//chartParameters ex: {x:'day'}

function RadarCompetitorsExpansion({}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // *********************** USE STATES ********************
  // *******************************************************
  const { user, displayNotification, constants } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [needToRefresh, setNeedToRefresh] = useState(false);
  //const [selectedRow, setSelectedRow] = useState(null);
  const [titleToDraw, setTitleToDraw] = useState("no title");
  const [xToDraw, setXToDraw] = useState([]);
  const [yToDraw, setYToDraw] = useState([]);

  const [period, setPeriod] = useState("d");
  const [dialogsOpen, setDialogsOpen] = useState({
    ModalSparkLineDetailGeneric: false,
  });

  const [uncollapsedRow, setUncollapsedRow] = useState("");

  const [activeRestaurants, setActiveRestaurants] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);
  const [mapDrawerOpen, setMapDrawerOpen] = useState(false);

  // *******************************************************
  // *********************** USE EFFECT ********************
  // *******************************************************

  useEffect(() => {
    console.log("USE EFFECT!!");
    if (!data || data.length === 0) {
      refreshData();
    }
  }, []); //Trigger only once

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleSparklineClick = (title, x, y) => {
    console.log("title", title);
    console.log("x", x);
    console.log("y", y);
    setTitleToDraw(title);
    setXToDraw(x);
    setYToDraw(y);
    setDialogsOpen({ ...dialogsOpen, ModalSparkLineDetailGeneric: true });
  };

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS ******************
  // *******************************************************

  //if categoriesToRefresh is empty then we consider we should only refresh
  const refreshData = () => {
    setIsLoading(true);
    POST("/api/radar/competitors/refresh", {}).then((response) => {
      console.log("RESPONSE:", response);
      setData(response.data);
      setIsLoading(false);
    });
  };

  const calculateCenter = (restaurantsList) => {
    var center = { lat: 48.864716, lng: 2.349014 };
    if (!restaurantsList || restaurantsList.length < 1) {
      return center;
    }
    var lat_numerator = 0;
    var lat_denominator = 0;
    var lng_numerator = 0;
    var lng_denominator = 0;
    restaurantsList.map((restaurant) => {
      lat_numerator = lat_numerator + restaurant.lat;
      lat_denominator = lat_denominator + 1;
      lng_numerator = lng_numerator + restaurant.lng;
      lng_denominator = lng_denominator + 1;
    });

    return {
      lat: parseFloat(lat_numerator / lat_denominator),
      lng: parseFloat(lng_numerator / lng_denominator),
    };
  };

  const generateDataCSV = () => {
    var dataCSV = [];
    if (!data || data.length < 1) {
      return dataCSV;
    }
    data.map((brand) => {
      if (!brand.restaurantsList || brand.restaurantsList.length < 1) {
        return;
      }
      brand.restaurantsList.map((restaurant) => {
        dataCSV.push({
          brand: brand.brand,
          name: restaurant.name,
          lat: restaurant.lat,
          lng: restaurant.lng,
          period: period,
          rating: restaurant.rating,
          estimated_daily_uber_orders: restaurant.orders,
          reviewCount: restaurant.reviewCount,
          data_rating_period_7: restaurant["data_rating_" + period][0],
          data_rating_period_6: restaurant["data_rating_" + period][1],
          data_rating_period_5: restaurant["data_rating_" + period][2],
          data_rating_period_4: restaurant["data_rating_" + period][3],
          data_rating_period_3: restaurant["data_rating_" + period][4],
          data_rating_period_2: restaurant["data_rating_" + period][5],
          data_rating_period_1: restaurant["data_rating_" + period][6],
          data_rating_period_0: restaurant["data_rating_" + period][0],
          data_reviewCount_period_7:
            restaurant["data_reviewCount_" + period][0],
          data_reviewCount_period_6:
            restaurant["data_reviewCount_" + period][1],
          data_reviewCount_period_5:
            restaurant["data_reviewCount_" + period][2],
          data_reviewCount_period_4:
            restaurant["data_reviewCount_" + period][3],
          data_reviewCount_period_3:
            restaurant["data_reviewCount_" + period][4],
          data_reviewCount_period_2:
            restaurant["data_reviewCount_" + period][5],
          data_reviewCount_period_1:
            restaurant["data_reviewCount_" + period][6],
          data_reviewCount_period_0:
            restaurant["data_reviewCount_" + period][0],
          data_orders_period_7: restaurant["data_orders_" + period][0],
          data_orders_period_6: restaurant["data_orders_" + period][1],
          data_orders_period_5: restaurant["data_orders_" + period][2],
          data_orders_period_4: restaurant["data_orders_" + period][3],
          data_orders_period_3: restaurant["data_orders_" + period][4],
          data_orders_period_2: restaurant["data_orders_" + period][5],
          data_orders_period_1: restaurant["data_orders_" + period][6],
          data_orders_period_0: restaurant["data_orders_" + period][0],
        });
      });
    });
    return dataCSV;
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  var center = calculateCenter(activeRestaurants);
  var dataCSV = generateDataCSV();
  return (
    <React.Fragment>
      <p className={classes.noMargin}>
        Jarvis is capturing competitors data on a daily basis in the following
        areas :
      </p>
      <List dense={false} className={classes.noMargin}>
        <ListItem className={classes.noMargin}>
          <ListItemIcon>
            <LocationCityIcon />
          </ListItemIcon>
          <ListItemText
            primary="Since 2022/10/08"
            secondary="Paris, Lille, Lyon, Marseille"
          />
        </ListItem>
        <ListItem className={classes.noMargin}>
          <ListItemIcon>
            <LocationCityIcon />
          </ListItemIcon>
          <ListItemText
            primary="Since 2022/10/12"
            secondary=" Bordeaux, Nantes, Nice, Rouen, Saint-Etienne, Strasbourg, Toulouse"
          />
        </ListItem>
      </List>
      {isLoading ? (
        <SkeletonDashboardTable />
      ) : (
        <Box
          className={classNames({
            [classes.overlay]: needToRefresh,
          })}
        >
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={5}
            style={{ zIndex: 10 }}
          ></Paper>

          <Grid
            container
            sx={{ width: "100%", mb: "200px" }}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
              <Grid
                container
                sx={{}}
                justify="left"
                alignItems="left"
                justifyContent="left"
              >
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  sx={{ ml: 2 }}
                >
                  <Button
                    className={classNames({
                      [classes.activeGroupButton]: period === "d",
                    })}
                    onClick={() => setPeriod("d")}
                  >
                    Day
                  </Button>
                  <Button
                    className={classNames({
                      [classes.activeGroupButton]: period === "w",
                    })}
                    onClick={() => setPeriod("w")}
                  >
                    Week
                  </Button>
                  <Button
                    className={classNames({
                      [classes.activeGroupButton]: period === "m",
                    })}
                    onClick={() => setPeriod("m")}
                  >
                    Month
                  </Button>
                </ButtonGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={mapDrawerOpen}
                        onChange={(e) => {
                          setMapDrawerOpen(!mapDrawerOpen);
                        }}
                      />
                    }
                    label={
                      <PublicIcon
                        style={{
                          color: theme.palette.primary.main,
                          marginTop: "6px!important",
                        }}
                      />
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
                <CSVLink data={dataCSV} style={{ textDecoration: "none" }}>
                  <Tooltip title="Download data as re file">
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        marginLeft: "10px",
                        textDecoration: "none",
                      }}
                      startIcon={<DownloadIcon />}
                    >
                      Download as csv
                    </Button>
                  </Tooltip>
                </CSVLink>
              </Grid>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: colWidths.col1 }}></TableCell>
                    <TableCell style={{ width: colWidths.col2 }}>
                      Brand
                    </TableCell>
                    <TableCell style={{ width: colWidths.col4 }} align="center">
                      <Tooltip
                        title={
                          "Restaurants count as of today in the scrapped zones."
                        }
                      >
                        <Typography variant="body">Restaurants</Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col3 }} align="center">
                      <Tooltip
                        title={
                          "Average rating as of today in the scrapped restaurants"
                        }
                      >
                        <Typography variant="body">Rating</Typography>
                      </Tooltip>
                    </TableCell>

                    <TableCell style={{ width: colWidths.col5 }} align="center">
                      <Tooltip
                        title={
                          "Number of reviews as of today in the scrapped restaurants"
                        }
                      >
                        <Typography variant="body">Reviews count</Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <RadarCompetitorsExpansionRow
                      colWidths={colWidths}
                      key={"RadarMainDashTableRow_ROW" + row.brand}
                      uncollapsedRow={uncollapsedRow}
                      setUncollapsedRow={setUncollapsedRow}
                      period={period}
                      row={row}
                      collapseAllowed={true}
                      handleSparklineClick={handleSparklineClick}
                      setActiveRestaurants={setActiveRestaurants}
                      handleActiveMarker={handleActiveMarker}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      )}
      {/* -------- MODAL ---------- */}
      <ModalSparkLineDetailGeneric
        title={titleToDraw}
        x={xToDraw}
        y={yToDraw}
        dialogsOpen={dialogsOpen}
        setDialogsOpen={setDialogsOpen}
      />
      {/* -------- DRAWER ---------- */}
      <Drawer
        anchor="right"
        variant="persistent"
        transitionDuration={{ enter: 500, exit: 500 }}
        //onClose={handleToggle}
        ModalProps={{
          keepMounted: true,
        }}
        open={mapDrawerOpen}
        PaperProps={{
          sx: {
            width: drawerWidth,
            marginTop: "65px",
            zIndex: 10,
            boxShadow: 3,
          },
        }}
      >
        <LoadScript
          googleMapsApiKey="AIzaSyAyJhu4zfWDdfCAFF943vojR9OZ2tO397Y"
          libraries={["drawing"]}
        >
          <GoogleMap
            mapContainerStyle={{ height: "100%" }}
            center={center}
            onClick={() => setActiveMarker(null)}
            zoom={4}
          >
            {/* Child components, such as markers, info windows, etc. */}
            {activeRestaurants && activeRestaurants.length > 0 ? (
              activeRestaurants.map((restaurant) => {
                /*const icon = {
                  url: restaurant.imageURL, // url
                  size: { width: 60, height: 100 },
                  anchor: { x: 15, y: 50 },
                  scaledSize: { width: 50, height: 30 },
                };*/
                return (
                  <Marker
                    //onLoad={onLoad}
                    position={{
                      lat: restaurant.lat,
                      lng: restaurant.lng,
                    }}
                    onClick={() => handleActiveMarker(restaurant.platformUUID)}
                    //icon={icon}
                  >
                    {activeMarker === restaurant.platformUUID ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>
                          <img
                            className={classes.img_marker}
                            src={restaurant.imageURL}
                            alt={restaurant.name}
                          />
                          <div>{restaurant.name}</div>
                          <div>
                            {restaurant.rating && restaurant.rating > 0
                              ? `${restaurant.rating} ⭐`
                              : "No rating"}
                          </div>
                          <div>
                            {restaurant.reviewCount &&
                            restaurant.reviewCount > 0
                              ? `${restaurant.reviewCount} reviews`
                              : ""}
                          </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                );
              })
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </GoogleMap>
        </LoadScript>
        <div className={classes.wrapper}>
          <List>
            <ListItem
              button
              key={"close"}
              className={`${classes.listItem}`}
              onClick={() => {
                setMapDrawerOpen(!mapDrawerOpen);
              }}
            ></ListItem>
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default RadarCompetitorsExpansion;
