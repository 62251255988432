import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import { GET, POST } from "../../js/HTTPRequest";
import moment from "moment";
import _ from "lodash";
//ICONS
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Paper,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardHeader,
  Avatar,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
} from "@mui/material";
import { red } from "@mui/material/colors";
//CONST
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "50rem",
  col5: "10rem",
};

export default function ModalCustomerDetails({ customer }) {
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    "Chargement en cours..."
  );
  const [detailedCustomer, setDetailedCustomer] = useState(null);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    getDetails(customer);
  }, [customer]);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const getPicture = (customerLocal) => {
    setIsLoading(true);
    if (!customerLocal || !customerLocal.id) {
      return;
    }
    GET("/api/radar/customer/" + customerLocal.id + "/collect/picture")
      .then((response) => {
        setIsLoading(false);
        setDetailedCustomer(response.data.customer);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  const getFullName = (customerLocal) => {
    setIsLoading(true);
    if (!customerLocal || !customerLocal.id) {
      return;
    }
    GET("/api/radar/customer/" + customerLocal.id + "/collect/fullname")
      .then((response) => {
        setIsLoading(false);
        setDetailedCustomer(response.data.customer);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const getDetails = (customerLocal) => {
    setIsLoading(true);
    if (!customerLocal || !customerLocal.id) {
      return;
    }
    GET("/api/radar/customer/" + customerLocal.id)
      .then((response) => {
        setIsLoading(false);
        setDetailedCustomer(response.data.customer);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const handleClose = () => {
    //var newDialogsOpen = JSON.parse(JSON.stringify(dialogsOpen));
    //newDialogsOpen[modalId] = false;
    //setDialogsOpen(newDialogsOpen);
    setDetailedCustomer(null);
    setDialogsOpen({ ...dialogsOpen, ModalCustomerDetails: false });
  };

  return (
    <Dialog
      open={dialogsOpen.ModalCustomerDetails}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Fiche client</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <CardHeader
              avatar={
                !detailedCustomer ||
                !detailedCustomer.pictureURL ||
                detailedCustomer.pictureURL === "" ? (
                  <Avatar
                    onClick={() => {
                      getPicture(customer);
                    }}
                    sx={{ width: 90, height: 90, cursor: "pointer" }}
                    aria-label="recipe"
                  >
                    <AccountCircleIcon sx={{ width: 110, height: 110 }} />
                  </Avatar>
                ) : (
                  <Avatar
                    sx={{ width: 90, height: 90 }}
                    aria-label="recipe"
                    src={detailedCustomer.pictureURL}
                  ></Avatar>
                )
              }
              title={
                detailedCustomer &&
                detailedCustomer.fullName &&
                detailedCustomer.fullName !== "" ? (
                  <p>{detailedCustomer.fullName}</p>
                ) : (
                  <p
                    onClick={() => {
                      getFullName(customer);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {customer ? customer.name : "(inconnu)"}
                  </p>
                )
              }
              subheader={
                <React.Fragment>
                  <p style={{ margin: 0, padding: 0 }}>Mail: *****@******</p>
                  <p style={{ margin: 0, padding: 0 }}>Tel: 06********</p>
                  <p style={{ margin: 0, padding: 0 }}>Adresse: ********</p>
                </React.Fragment>
              }
            />
            {!detailedCustomer ||
            !detailedCustomer.orders ||
            detailedCustomer.orders.length < 1 ? (
              <div></div>
            ) : (
              <div>
                <Typography variant="body2">{`${
                  detailedCustomer.orders.length
                } commande${
                  detailedCustomer.orders.length > 1 ? "s" : ""
                } chez Napaqaro`}</Typography>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: colWidths.col1 }}>
                          Date
                        </TableCell>
                        <TableCell
                          style={{ width: colWidths.col2 }}
                          align="center"
                        >
                          Restaurant
                        </TableCell>
                        <TableCell
                          style={{ width: colWidths.col3 }}
                          align="center"
                        >
                          Promotion
                        </TableCell>
                        <TableCell
                          style={{ width: colWidths.col4 }}
                          align="center"
                        >
                          € (hors promo)
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {detailedCustomer.orders.map((row) => {
                        return (
                          <TableRow>
                            <TableCell style={{ width: colWidths.col1 }}>
                              {moment(row.requestedAt).format("LLL")}
                            </TableCell>
                            <TableCell
                              style={{ width: colWidths.col2 }}
                              align="center"
                            >
                              {row.Channel ? row.Channel.name : "(inconnu)"}
                            </TableCell>
                            <TableCell
                              style={{ width: colWidths.col3 }}
                              align="center"
                            >
                              {parseInt(row.discount) / 100}€
                            </TableCell>
                            <TableCell
                              style={{ width: colWidths.col4 }}
                              align="center"
                            >
                              {(parseInt(row.amount_gross) +
                                parseInt(row.discount)) /
                                100}
                              €
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
