import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import moment from "moment";
//import Moment from "react-moment";
import "moment/locale/fr";
//IMAGES AND ICONS
import Logo_UberEats from "../../img/logo-cercle-ubereats.png";
import Logo_Deliveroo from "../../img/logo-cercle-deliveroo.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StorefrontIcon from "@mui/icons-material/Storefront";
import StarIcon from "@mui/icons-material/Star";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import CampaignIcon from "@mui/icons-material/Campaign";
import HighlightIcon from "@mui/icons-material/Highlight";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
//MATERIAL UI COMPONENT IMPORTS
import {
  Avatar,
  Badge,
  Card,
  CardHeader,
  CircularProgress,
  IconButton,
  ListItemIcon,
  CardContent,
  Typography,
} from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "60px !important",
    height: "auto !important",
  },
}));

//This component is a card displaying dahsboard integrations (Uber, Deliveroo) on the AdminDashboardIntegrationPage
function CardIntegrations({
  platformLogin,
  initPage,
  loginType,
  handleSelectedPlatformLogin,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { displayNotification, setDialogsOpen, dialogsOpen } =
    useContext(UserContext);
  const [isReconnectLoading, setIsReconnectLoading] = useState(false);
  const [isChannelsLoading, setIsChannelsLoading] = useState(false);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isOrderHistoryLoading, setIsOrderHistoryLoading] = useState(false);
  const [isReviewsLoading, setIsReviewsLoading] = useState(false);
  const [isMarketingCampaignsLoading, setIsMarketingCampaignsLoading] =
    useState(false);
  const [isSponsoringsLoading, setIsSponsoringsLoading] = useState(false);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    moment.locale("fr");
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS ******************
  // *******************************************************

  const reconnect = () => {
    if (isReconnectLoading) {
      return;
    }
    var url = `/api/platformlogins/${platformLogin.id}/reconnect`;
    switch (loginType) {
      case "API":
        url = `/api/apilogins/${platformLogin.id}/reconnect`;
        break;
      case "Restaurant manager":
        url = `/api/platformlogins/${platformLogin.id}/reconnect`;
        break;
      case "AdsManager":
        url = `/api/adsplatformlogins/${platformLogin.id}/reconnect`;
        break;
      default:
        url = `/api/platformlogins/${platformLogin.id}/reconnect`;
    }

    setIsReconnectLoading(true);
    GET(url, true, 120000)
      .then((result) => {
        setIsReconnectLoading(false);
        initPage();
      })
      .catch((err) => {
        setIsReconnectLoading(false);
        displayNotification(
          "error",
          "Une erreur est survenue pendant la reconnexion de votre dashboard",
          "top",
          "center"
        );
      });
  };

  const synchChannels = () => {
    if (isChannelsLoading) {
      return;
    }
    if (loginType === "Restaurant manager") {
      setIsChannelsLoading(true);
      GET(
        `/api/platformlogins/${platformLogin.id}/restaurants/synch`,
        true,
        120000
      )
        .then((result) => {
          setIsChannelsLoading(false);
          initPage();
        })
        .catch((err) => {
          setIsChannelsLoading(false);
          displayNotification(
            "error",
            "Une erreur est survenue pendant la synchronisation des restaurants",
            "top",
            "center"
          );
        });
    } else if (loginType === "AdsManager") {
      setIsChannelsLoading(true);
      GET(
        `/api/adsplatformlogins/${platformLogin.id}/channels/synch`,
        true,
        120000
      )
        .then((result) => {
          setIsChannelsLoading(false);
          initPage();
        })
        .catch((err) => {
          setIsChannelsLoading(false);
          displayNotification(
            "error",
            "Une erreur est survenue pendant la synchronisation des restaurants",
            "top",
            "center"
          );
        });
    } else if (loginType === "API") {
      setIsChannelsLoading(true);
      GET(`/api/apilogins/${platformLogin.id}/channels/synch`, true, 120000)
        .then((result) => {
          setIsChannelsLoading(false);
          initPage();
        })
        .catch((err) => {
          setIsChannelsLoading(false);
          displayNotification(
            "error",
            "Une erreur est survenue pendant la synchronisation des restaurants",
            "top",
            "center"
          );
        });
    }
  };

  const synchOrders = () => {
    if (isOrdersLoading) {
      return;
    }
    if (loginType === "API") {
      setIsOrdersLoading(true);
      GET(`/api/apilogins/${platformLogin.id}/orders/synch`, true, 120000)
        .then((result) => {
          setIsOrdersLoading(false);
          initPage();
        })
        .catch((err) => {
          setIsOrdersLoading(false);
          displayNotification(
            "error",
            "Une erreur est survenue pendant la synchronisation des commandes",
            "top",
            "center"
          );
        });
    } else {
      setIsOrdersLoading(true);
      GET(`/api/platformlogins/${platformLogin.id}/orders/synch`, true, 120000)
        .then((result) => {
          setIsOrdersLoading(false);
          initPage();
        })
        .catch((err) => {
          setIsOrdersLoading(false);
          displayNotification(
            "error",
            "Une erreur est survenue pendant la synchronisation des commandes",
            "top",
            "center"
          );
        });
    }
  };

  const synchReviews = () => {
    if (isReviewsLoading) {
      return;
    }
    setIsReviewsLoading(true);
    GET(`/api/platformlogins/${platformLogin.id}/reviews/synch`, true, 120000)
      .then((result) => {
        setIsReviewsLoading(false);
        initPage();
      })
      .catch((err) => {
        setIsReviewsLoading(false);
        displayNotification(
          "error",
          "Une erreur est survenue pendant la synchronisation des avis clients",
          "top",
          "center"
        );
      });
  };

  const synchMarketingCampaigns = () => {
    if (isMarketingCampaignsLoading) {
      return;
    }
    setIsMarketingCampaignsLoading(true);
    GET(
      `/api/platformlogins/${platformLogin.id}/marketingcampaigns/synch`,
      true,
      120000
    )
      .then((result) => {
        setIsMarketingCampaignsLoading(false);
        initPage();
      })
      .catch((err) => {
        setIsMarketingCampaignsLoading(false);
        displayNotification(
          "error",
          "Une erreur est survenue pendant la synchronisation des campagnes marketing",
          "top",
          "center"
        );
      });
  };

  const synchSponsorings = () => {
    if (isSponsoringsLoading) {
      return;
    }
    setIsSponsoringsLoading(true);
    GET(
      `/api/adsplatformlogins/${platformLogin.id}/sponsorings/synch`,
      true,
      120000
    )
      .then((result) => {
        setIsSponsoringsLoading(false);
        initPage();
      })
      .catch((err) => {
        setIsSponsoringsLoading(false);
        displayNotification(
          "error",
          "Une erreur est survenue pendant la synchronisation des campagnes de sponsoring",
          "top",
          "center"
        );
      });
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const synchStatus = JSON.parse(platformLogin.synchStatus);
  const listOfModulesToSynch = [
    /*{
      types: ["Restaurant manager"],
      icon: (
        <FastfoodIcon
          key={"FastfoodIcon_" + platformLogin.id + "_orders"}
          id={"FastfoodIcon_" + platformLogin.id + "_orders"}
        />
      ),
      onClick: synchOrders,
      loadingVariable: isOrdersLoading,
      synchStatusId: "orders",
    },*/
    {
      types: ["Restaurant manager"],
      icon: (
        <StarIcon
          key={"StarIcon_" + platformLogin.id + "_review"}
          id={"StarIcon_" + platformLogin.id + "_reviews"}
        />
      ),
      onClick: synchReviews,
      loadingVariable: isReviewsLoading,
      synchStatusId: "reviews",
    },
    {
      types: ["Restaurant manager"],
      icon: (
        <CampaignIcon
          key={"CampaignIcon_" + platformLogin.id + "_marketingCampaigns"}
          id={"CampaignIcon_" + platformLogin.id + "_marketingCampaigns"}
        />
      ),
      onClick: synchMarketingCampaigns,
      loadingVariable: isMarketingCampaignsLoading,
      synchStatusId: "marketingCampaigns",
    },
    {
      types: ["Restaurant manager"],
      icon: (
        <HistoryToggleOffIcon
          key={"HistoryToggleOffIcon_" + platformLogin.id + "_ordersHistory"}
          id={"HistoryToggleOffIcon_" + platformLogin.id + "_ordersHistory"}
        />
      ),
      onClick: () => {
        console.log("handleSelectedPlatformLogin", platformLogin);
        handleSelectedPlatformLogin(platformLogin);
        setDialogsOpen({ ...dialogsOpen, ModalGetDateForOrdersHistory: true });
      },
      loadingVariable: isOrderHistoryLoading,
      synchStatusId: "ordersHistory",
    },
    {
      types: ["AdsManager"],
      icon: (
        <HighlightIcon
          key={"HighlightIcon_" + platformLogin.id + "_sponsorings"}
          id={"HighlightIcon_" + platformLogin.id + "_sponsorings"}
        />
      ),
      onClick: synchSponsorings,
      loadingVariable: isSponsoringsLoading,
      synchStatusId: "sponsorings",
    },
    {
      types: ["API"],
      icon: (
        <FastfoodIcon
          key={"FastfoodIcon_" + platformLogin.id + "_orders"}
          id={"FastfoodIcon_" + platformLogin.id + "_orders"}
        />
      ),
      onClick: synchOrders,
      loadingVariable: isOrdersLoading,
      synchStatusId: "orders",
    },
  ];

  return (
    <Card sx={{ maxWidth: 500 }}>
      <CardHeader
        avatar={
          <Avatar
            src={
              platformLogin.platformId ===
              "7efb1bcb-ddf9-49f9-ad3c-a1c7d7efd0aa"
                ? Logo_UberEats
                : Logo_Deliveroo
            } //Move asap logic inside database with public url stored inside platform table
            aria-label="platform"
            className={classes.avatar}
          ></Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={platformLogin.label}
        subheader={platformLogin.login}
      />
      <CardContent mt={0}>
        <ListItemIcon
          className={classes.listItemIcon}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <IconButton aria-label="refresh" onClick={reconnect}>
            <VpnKeyIcon />
            {isReconnectLoading && (
              <CircularProgress
                sx={{
                  color: "primary",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              />
            )}
          </IconButton>
          <Typography variant="body2" color="text.secondary" ml={2}>
            {platformLogin && platformLogin.lastSuccessfullLogin
              ? moment(platformLogin.lastSuccessfullLogin).format("LLL")
              : "non synchronisé"}
          </Typography>
        </ListItemIcon>
        <ListItemIcon
          className={classes.listItemIcon}
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <IconButton aria-label="refresh" onClick={synchChannels}>
            <Badge
              color={"primary"}
              //variant="dot"
              badgeContent={platformLogin.channels.length}
              max={999}
              showZero
              //invisible={synchStatus.channels ? true : false}
            >
              <StorefrontIcon />
              {isChannelsLoading && (
                <CircularProgress
                  sx={{
                    color: "primary",
                    position: "absolute",
                    top: -8,
                    left: -7,
                    zIndex: 1,
                  }}
                />
              )}
            </Badge>
          </IconButton>
          <Typography variant="body2" color="text.secondary" ml={2}>
            {synchStatus.channels && synchStatus.channels.status === "ok"
              ? moment(synchStatus.channels.lastUpdate).format("LLL")
              : "non synchronisé"}
          </Typography>
        </ListItemIcon>
        {listOfModulesToSynch.map((module) => {
          if (module.types.indexOf(loginType) < 0) {
            return (
              <div key={platformLogin.id + "_" + module.synchStatusId}></div>
            );
          }
          return (
            <ListItemIcon
              key={platformLogin.id + "_" + module.synchStatusId}
              id={
                "ListItemIcon" + platformLogin.id + "_" + module.synchStatusId
              }
              className={classes.listItemIcon}
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <IconButton
                aria-label="refresh"
                key={
                  "IconButton" + platformLogin.id + "_" + module.synchStatusId
                }
                id={
                  "IconButton" + platformLogin.id + "_" + module.synchStatusId
                }
                onClick={module.onClick}
              >
                {module.icon}
                {module.loadingVariable && (
                  <CircularProgress
                    key={
                      "CircularProgress_" +
                      platformLogin.id +
                      "_" +
                      module.synchStatusId
                    }
                    id={
                      "CircularProgress_" +
                      platformLogin.id +
                      "_" +
                      module.synchStatusId
                    }
                    sx={{
                      color: "primary",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                    }}
                  />
                )}
              </IconButton>
              <Typography variant="body2" color="text.secondary" ml={2}>
                {synchStatus[module.synchStatusId] &&
                synchStatus[module.synchStatusId].status === "ok"
                  ? moment(synchStatus[module.synchStatusId].lastUpdate).format(
                      "LLL"
                    )
                  : "non synchronisé"}
              </Typography>
            </ListItemIcon>
          );
        })}
      </CardContent>
    </Card>
  );
}

CardIntegrations.propTypes = {};

export default CardIntegrations;
