import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import classNames from "classnames";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import _ from "lodash";
import moment from "moment";
import ModalSparkLineDetail from "./ModalSparkLineDetail";

//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  Toolbar,
  TextField,
  useMediaQuery,
} from "@mui/material";
//INTERNAL IMPORT
import DashboardsCards from "../generic/DashboardsCards";
import ClassicChart from "../generic/ClassicChart";
import FilterBar from "../generic/FilterBar";
import { numberWithSpaces } from "../../js/numberManager";
//import Dashboards_FiltersBar from "../../components/data_dashboards/Dashboards_FiltersBar";
//ICONS
import RefreshIcon from "@mui/icons-material/Refresh";
import RadarMainDashTableRow from "./RadarMainDashTableRow";
import SkeletonDashboardTable from "../../ui-components/Skeleton/SkeletonDashboardTable";
import WarningMessageBanner from "../generic/WarningMessageBanner";

// *******************************************************
// ************************ STYLE ************************
// *******************************************************
const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  overlay: {
    filter: "blur(3px)",
    //position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.54)",
    //color: "white",
    top: "0px",
    left: "0px",
    zIndex: 1000,
  },
  btnUpdate: {
    position: "fixed",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    top: "100px",
    zIndex: 1001,
  },
  hide: {
    display: "none!important",
  },
}));
//CONSTANTE
const colWidths = {
  col1: "7rem",
  col2: "20rem",
  col3: "20rem",
  col4: "20rem",
  col5: "20rem",
  col6: "20rem",
};
//yFilters (array of arrays of filter)
//chartParameters ex: {x:'day'}

function RadarMainDashPage({}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // *********************** USE STATES ********************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [data, setData] = useState([]);
  const [needToRefresh, setNeedToRefresh] = useState(false);
  const [headers, setHeaders] = useState({
    header1: "",
    header2: "",
    header3: "",
    header4: "",
    tooltip1: "",
    tooltip2: "",
    tooltip3: "",
    tooltip4: "",
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsLoaded, setRowsLoaded] = useState([]);

  // *******************************************************
  // *********************** USE EFFECT ********************
  // *******************************************************

  useEffect(() => {
    refreshData();
  }, []); //Trigger only once

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const onFilterUpdate = (newFilters, filterIndex) => {
    setFilters(newFilters);
    setNeedToRefresh(true);
  };

  const handleSparklineClick = (row) => {
    setSelectedRow(row);
    setDialogsOpen({ ...dialogsOpen, ModalSparkLineDetail: true });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS ******************
  // *******************************************************

  //if categoriesToRefresh is empty then we consider we should only refresh
  const refreshData = (categoriesToRefresh = []) => {
    console.log("categoriesToRefresh", categoriesToRefresh);
    console.log("rowsLoaded", rowsLoaded);
    //Check if we're not trying to load a category already loaded (re-opening a category for instance)
    var blockRefresh = categoriesToRefresh.length < 1 ? false : true;
    categoriesToRefresh.map((category) => {
      rowsLoaded.map((row) => {
        if (row.category === category && !row.loaded) {
          blockRefresh = false;
        }
      });
    });
    if (blockRefresh) {
      return;
    }
    setNeedToRefresh(false);
    setIsLoading(categoriesToRefresh.length < 1 ? true : false);
    //We need to remove options before posting (otherwise JSON will be too large)
    POST("/api/radar/dashboard/refresh", {
      filters,
      categoriesToRefresh,
    }).then((response) => {
      console.log("RESPONSE:", response);
      setFilters(response.data.filters);
      //Complete data with loading rows
      if (categoriesToRefresh.length < 1) {
      } else {
      }
      var newData = JSON.parse(JSON.stringify(data));
      var newRowsLoaded = JSON.parse(JSON.stringify(rowsLoaded));

      var keys = newData && newData.length > 0 ? Object.keys(newData[0]) : [];
      response.data.data.map((row) => {
        var foundInData = false;
        var foundInRowsLoaded = false;
        newData.map((existingRow) => {
          if (row.kpi === existingRow.kpi) {
            foundInData = true;
            if (
              categoriesToRefresh.length < 1 ||
              existingRow.lazy_loading === "TRUE"
            ) {
              //WE DO NOT OVERWRITTE DATA IF ALREADY LOADED BEFORE EXPECT IF INITING WITH categoriesToRefresh empty
              keys.map((key) => {
                existingRow[key] = row[key]; //WE NEED TO USE THIS TECHNIQUE AS SIMPLY OVERWRITTING ALL OBJECT IS NOT WORKING... Might be a pointer issue
              });
            }
          }
        });
        newRowsLoaded.map((existingRowLoaded) => {
          if (row.kpi === existingRowLoaded.kpi) {
            foundInRowsLoaded = true;
            if (
              categoriesToRefresh.length < 1 ||
              !existingRowLoaded.loaded //WE DO NOT OVERWRITTE DATA IF ALREADY LOADED BEFORE EXPECT IF INITING WITH categoriesToRefresh empty
            ) {
              existingRowLoaded.loaded = row.lazy_loading === "FALSE";
            }
          }
        });
        if (!foundInData) {
          newData.push(row);
        }
        if (!foundInRowsLoaded) {
          newRowsLoaded.push({
            kpi: row.kpi,
            category: row.category,
            loaded: row.lazy_loading === "FALSE",
          });
        }
      });
      console.log("#AAA newData", newData);
      setData(newData);
      setRowsLoaded(newRowsLoaded);
      console.log("#AAA newRowsLoaded", newRowsLoaded);
      setIsLoading(false);
      var newHeaders = getHeaders(response.data.filters);
      setHeaders(newHeaders);
    });
  };

  const getHeaders = (newFilters) => {
    var header1 = "Last completed Week";
    var tooltip1 =
      "Données sur la dernière semaine écoulée complète (lundi matin au dimanche soir). La variation correspond à la variation avec la semaine précédente.";
    var header2 = "WTD";
    var tooltip2 =
      "Données sur la semaine en cours. De lundi matin à aujourd'hui. La variation correspond à la variation avec la situation la semaine dernière au même jour.";
    var header3 = "6-w trend + l7d";
    var tooltip3 =
      "Évolution les 6 dernières semaines avec comme dernier point pour la semaine en cours (non écoulée donc) : les 7 derniers jours.";
    var header4 = "l7d";
    var tooltip4 =
      "Données sur les 7 derniers jours glissant. La variation correspond à la variation avec les 7 jours précédents.";
    newFilters.map((filter) => {
      if (filter.id === "orders|frequency_period") {
        switch (filter.selected.value) {
          case "day":
            header1 = "Last completed Day";
            header2 = "Today";
            header3 = "6-d trend + l24h";
            header4 = "l24h";
            tooltip1 =
              "Données sur la dernière journée écoulée complète (de 0h à minuit). La variation correspond à la variation avec la journée précédente.";
            tooltip2 =
              "Données sur la journée en cours. De ce matin minuit à maintenant. La variation correspond à la variation avec la situation hier à la même heure.";
            tooltip3 =
              "Évolution les 6 dernières jours avec comme dernier point pour la journée en cours (non écoulée donc) : les 24 dernières heures.";
            tooltip4 =
              "Données sur les 24 dernieres heures glissantes. La variation correspond à la variation avec les 24h précédentes.";
            break;
          case "month":
            header1 = "Last completed Month";
            header2 = "MTD";
            header3 = "6-m trend + l30d";
            header4 = "l30d";
            tooltip1 =
              "Données sur le dernier mois écoulée complet. La variation correspond à la variation avec le mois précédent.";
            tooltip2 =
              "Données sur le mois en cours. La variation correspond à la variation avec la situation le mois dernier au même jour.";
            tooltip3 =
              "Évolution les 6 dernières mois avec comme dernier point pour le mois en cours (non écoulé donc) : les 30 derniers jours.";
            tooltip4 =
              "Données sur les 30 dernieres jours glissants. La variation correspond à la variation avec les 30 jours précédents.";
            break;
          case "week":
          default:
          //nothing
        }
      }
    });
    console.log({ header1, header2, header3, header4 });
    return {
      header1,
      header2,
      header3,
      header4,
      tooltip1,
      tooltip2,
      tooltip3,
      tooltip4,
    };
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  return (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 0, mb: 2 }}
        spacing={2}
      >
        <Grid item xs={12} key={`filter_bar_radar_dashboard_0`}>
          <FilterBar
            filters={filters}
            filterIndex={0}
            isLoading={isLoading}
            setFilters={onFilterUpdate}
            enableAddNewSerieButton={false}
          />
        </Grid>
      </Grid>
      <Fab
        onClick={(e) => {
          refreshData([]);
        }}
        variant="extended"
        className={classNames(classes.btnUpdate, {
          [classes.hide]: !needToRefresh,
        })}
      >
        <RefreshIcon sx={{ mr: 1 }} />
        Mettre à jour
      </Fab>
      {isLoading ? (
        <SkeletonDashboardTable />
      ) : (
        <Box
          className={classNames({
            [classes.overlay]: needToRefresh,
          })}
        >
          <WarningMessageBanner
            message={constants.RADAR_DASHBOARD_WARNING_MESSAGE}
          />
          <Grid
            container
            sx={{ width: "100%" }}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: colWidths.col1 }}></TableCell>
                    <TableCell style={{ width: colWidths.col2 }}></TableCell>
                    <TableCell style={{ width: colWidths.col3 }} align="center">
                      <Tooltip title={headers.tooltip1}>
                        <Typography variant="body">
                          {headers.header1}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col4 }} align="center">
                      <Tooltip title={headers.tooltip2}>
                        <Typography variant="body">
                          {headers.header2}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col5 }} align="center">
                      <Tooltip title={headers.tooltip3}>
                        <Typography variant="body">
                          {headers.header3}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ width: colWidths.col6 }} align="center">
                      <Tooltip title={headers.tooltip4}>
                        <Typography variant="body">
                          {headers.header4}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) =>
                    row.order === 0 ? (
                      <RadarMainDashTableRow
                        colWidths={colWidths}
                        key={"RadarMainDashTableRow_ROW" + row.kpi}
                        data={data}
                        index={index}
                        collapseAllowed={true}
                        refreshData={refreshData}
                        handleSparklineClick={handleSparklineClick}
                      />
                    ) : null
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Box>
      )}
      {/* -------- MODAL ---------- */}
      <ModalSparkLineDetail title={headers.header3} row={selectedRow} />
    </React.Fragment>
  );
}

/*DashboardsPage.propTypes = {

}*/

export default RadarMainDashPage;
