import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import _ from "lodash";
//IMAGES AND ICONS
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import PublicIcon from "@mui/icons-material/Public";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ScienceIcon from "@mui/icons-material/Science";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditAttributesIcon from "@mui/icons-material/EditAttributes";
import StyleIcon from "@mui/icons-material/Style";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  Toolbar,
  TextField,
  useMediaQuery,
} from "@mui/material";
import SkeletonFilterBar from "../../ui-components/Skeleton/SkeletonFilterBar";
import DateRangePicker from "@mui/lab/DateRangePicker";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  filterBar: {
    boxShadow: theme.shadows[1],
    zIndex: 10000,
    backgroundColor: theme.palette.common.white,
  },
  smallFont: {
    fontSize: "4px !important",
  },
  "& .MuiPaper-root": {
    width: "500px!important",
    borderColor: "#C52328",
  },

  searchBarStyle: {
    height: "40px!important",
    fontSize: 9,
    //width: "200px",
    margin: "0 0 0 0",
    float: "right",
    "& .Mui-focused ": {
      //fontSize: "7px",
      // width: "300px!important",
      // marginRight: "300px!important",
      // position: "absolute",
      //zIndex: "1",
      backgroundColor: theme.palette.common.white + "!important",
      //borderColor: "#C52328",
      //borderWidth: "2px",
    },
    "& .MuiPaper-root": {
      width: "500px!important",
    },
    underline: { textDecoration: "underline" },
  },
}));

// ********************* COMPONENT START ***********************

function FilterBar({
  filters,
  setFilters,
  isLoading,
  enableAddNewSerieButton,
  addNewSerie,
  filterIndex,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside FilterBar");
    init();
  }, []);

  /*useEffect(() => {
    console.log("Frontend Info - I am inside FilterBar", filters);
    var newLocalFilters = JSON.parse(JSON.stringify(filters));
    setLocalFilters(newLocalFilters);
  }, [filters]);*/

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    /*POST("/api/pages/filterbar/init", { filters: filtersEmpty }).then(
      (result) => {
        setFilters(result.data);
      }
    );*/
  };

  const handleAllTagSelected = (newValue) => {
    var adjustedNewValue = newValue;
    if (!adjustedNewValue || adjustedNewValue.length === 0) {
      adjustedNewValue = [{ value: "all", label: "Tous" }];
    } else if (newValue.length > 1) {
      //If there are at least 2 items selected. Check that "all" item is not selected. If it :
      //> that's the last item > remove other items.
      //> that's not the last item > remove "all" item
      var isAllSelected = false;
      newValue.map((value, Index) => {
        if (value.value === "all") {
          isAllSelected = true;
        }
      });
      if (isAllSelected) {
        adjustedNewValue = [newValue[newValue.length - 1]];
      }
    }
    return adjustedNewValue;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const getIcon = (strIcon, sx) => {
    switch (strIcon) {
      case "world":
        return <PublicIcon sx={sx} />;
      case "store":
        return <StoreMallDirectoryIcon sx={sx} />;
      case "location":
        return <LocationOnIcon sx={sx} />;
      case "business":
        return <BusinessCenterIcon sx={sx} />;
      case "lab":
        return <ScienceIcon sx={sx} />;
      case "burger":
        return <LunchDiningIcon sx={sx} />;
      case "delivery":
        return <DeliveryDiningIcon sx={sx} />;
      case "phone":
        return <PhoneAndroidIcon sx={sx} />;
      case "status":
        return <EditAttributesIcon sx={sx} />;
      case "tag":
        return <StyleIcon sx={sx} />;
      default:
        return <FilterAltIcon sx={sx} />;
    }
  };

  const displayFilter = (filter) => {
    var selected = filter
      ? filter.selected
      : filter.type === "list"
      ? []
      : null;

    console.log("filter.size ", filter.size);
    switch (filter.type) {
      case "list":
      case "select":
        return (
          <Autocomplete
            multiple={filter.type === "list"}
            limitTags={0}
            className={classes.searchBarStyle}
            id={"filter_" + filterIndex + "_" + filter.id}
            value={selected}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={
                    <Typography
                      style={{
                        whiteSpace: "normal",
                        padding: "4px",
                        fontSize: 9,
                      }}
                    >
                      {option.label}
                    </Typography>
                  }
                  {...getTagProps({ index })}
                  style={{ height: "100%", borderRadius: "5px" }}
                  size="small"
                />
              ))
            }
            onChange={(e, newValues) => {
              var adjustedNewValue = handleAllTagSelected(newValues);
              var name = filter.id;
              var newFilters = JSON.parse(JSON.stringify(filters));
              newFilters.map((tmpFilter) => {
                if (tmpFilter.id === name) {
                  tmpFilter.selected = adjustedNewValue;
                }
                return null;
              });
              setFilters(newFilters, filterIndex);
            }}
            getLimitTagsText={(more) => {
              var values = selected;
              if (values && values.length > 0 && values[0].value === "all") {
                return null;
              }
              return (
                <Grid container>
                  <Typography
                    sx={{
                      m: 0,
                      color: theme.palette.primary.main + "!important",
                    }}
                  >
                    {`+${values.length}`}
                  </Typography>
                </Grid>
              );
            }}
            size="small"
            options={filter.options}
            fullWidth={true}
            sx={{ hasClearIcon: false }}
            disableClearable={true}
            getOptionLabel={(option) => {
              return option.label;
            }}
            renderOption={(props, option) => {
              return (
                <li {...props} style={{ fontSize: 9 }}>{`${option.label}`}</li>
              );
            }}
            renderInput={(params) => (
              <Tooltip title={filter.name}>
                <TextField
                  {...params}
                  label={getIcon(filter.icon)}
                  style={{
                    width:
                      filter && filter.size === "large" ? "200px" : "100px",
                    marginRight: "0px",
                    fontSize: 9,
                  }}
                />
              </Tooltip>
            )}
          />
        );
      case "date-range":
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateRangePicker
              startText="Début"
              endText="Fin"
              value={filter.values}
              //autoOk={true}
              //disableCloseOnSelect={true}
              onChange={(newValue) => {
                var name = filter.id;
                var newFilters = JSON.parse(JSON.stringify(filters));
                newFilters.map((tmpFilter) => {
                  if (tmpFilter.id === name) {
                    tmpFilter.values = newValue;
                  }
                  return null;
                });
                setFilters(newFilters, filterIndex);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    size="small"
                    style={{ width: "120px" }}
                    {...startProps}
                  />
                  <Box sx={{ mx: 1 }}> au </Box>
                  <TextField
                    size="small"
                    style={{ width: "120px" }}
                    {...endProps}
                  />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        );
      case "radio":
        return (
          <Grid container spacing={1} sx={{ mt: 0, pt: 0 }}>
            {filter.options.map((Option) => {
              return (
                <Grid
                  item
                  key={
                    "filter_" +
                    filterIndex +
                    "_" +
                    filter.id +
                    "_options_" +
                    Option.value
                  }
                >
                  <Typography
                    onClick={(e) => {
                      var name = filter.id;
                      var newFilters = JSON.parse(JSON.stringify(filters));
                      newFilters.map((tmpFilter) => {
                        if (tmpFilter.id === name) {
                          tmpFilter.options.map((tmpOption) => {
                            if (tmpOption.value === Option.value) {
                              tmpFilter.selected = Option;
                            }
                          });
                        }
                        return null;
                      });
                      setFilters(newFilters, filterIndex);
                    }}
                    className={classes.underline}
                    style={{
                      cursor: "pointer",
                      color:
                        filter.selected.value === Option.value
                          ? theme.palette.primary.main
                          : "black",
                    }}
                  >
                    {Option.label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        );
      default:
        return <div>[?]</div>;
    }
  };

  return isLoading ? (
    <SkeletonFilterBar sx={{ ml: 2 }} />
  ) : (
    <Toolbar sx={{ p: 2 }} className={classes.filterBar}>
      <React.Fragment>
        {enableAddNewSerieButton ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: theme.palette.chart[filterIndex] }}
            onClick={addNewSerie}
          >
            <AddCircleIcon />
          </IconButton>
        ) : (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, color: theme.palette.chart[filterIndex] }}
          >
            <FilterListIcon />
          </IconButton>
        )}
        <Grid container spacing={1}>
          {filters.map((filter) => {
            if (!filter.invisible) {
              return (
                <Grid item key={"filter_" + filterIndex + "_" + filter.id}>
                  {displayFilter(filter)}
                </Grid>
              );
            } else {
              return <React.Fragment></React.Fragment>;
            }
          })}
        </Grid>
      </React.Fragment>
    </Toolbar>
  );
}

FilterBar.propTypes = {};

// ********************* COMPONENT END ***********************

export default FilterBar;
