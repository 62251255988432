import React, { useState, useEffect, useContext } from "react";
import { useParams, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS
import { Container } from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    borderradius: "4px",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
  },
}));

/** - Description: Page handling email verification after user clicked on the email link he received after his signup.
 *  - Parents: App.js
 * - Props:
 */
function VerifyEmailPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification } = useContext(UserContext);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const { tempRoute } = useParams();
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside VerifyEmailPage");
    API_VerifyEmail();
  }, []);
  // *******************************************************
  // ********************** LOCAL FUNCTIONS ****************
  // *******************************************************
  const API_VerifyEmail = () => {
    GET(`/api/users/emailverification/${tempRoute}`)
      .then(() => {
        setMessage("Merci. Votre adresse est maintenant vérifiée !");
        setSuccess(true);
        displayNotification(
          "success",
          "Merci. Votre adresse est maintenant vérifiée !",
          "top",
          "right"
        );
      })
      .catch((err) => {
        setMessage("Désolé, ce lien est expiré...");
        setSuccess(false);
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  if (success) {
    const newpath = `/cockpit`;
    return <Navigate to={newpath} replace />; //Navigate replace Redirect in React-Router v6 : https://stackoverflow.com/questions/70005020/redirect-in-react-router-not-working-when-i-click-from-users-to-post/70005070
  }
  return (
    <Container maxWidth="sm">
      <div className={classes.paper}>{message}</div>
    </Container>
  );
}

VerifyEmailPage.propTypes = {};

export default VerifyEmailPage;
