import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  TableContainer,
  TableBody,
  TableHead,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  TablePagination,
  Tooltip,
  Toolbar,
  Typography,
  Paper,
  TableCell,
  TableRow,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import AdminUsersRow from "./AdminUsersRow";
import ModalEditUser from "./ModalEditUser";
import ModalContinueOrCancel from "../generic/ModalContinueOrCancel";
import ModalApplyChannelsAccess from "./ModalApplyChannelsAccess";
import SkeletonUsersManagementTable from "../../ui-components/Skeleton/SkeletonUsersManagementTable";
//CONSTANTES
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "50rem",
  col5: "10rem",
};
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function AdminUsersPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const {
    user,
    displayNotification,
    dialogsOpen,
    setDialogsOpen,
    constants,
    loginAs,
  } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [usersUnfiltered, setUsersUnfiltered] = useState([]); //FILTERED
  const [channels, setChannels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [geos, setGeos] = useState([]);
  const [editedUser, setEditedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const editUser = (userItem) => {
    setEditedUser(userItem);
    setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false });
    setDialogsOpen({ ...dialogsOpen, ModalEditUser: true });
  };

  const openDeleteUserModal = (userItem) => {
    setEditedUser(userItem);
    setDialogsOpen({ ...dialogsOpen, ModalDeleteUser: true });
  };

  const deleteUser = () => {
    setIsLoading(true);
    POST("/api/users/delete", {
      id: editedUser.id,
    }).then((response) => {
      init();
      setIsLoading(false);
      displayNotification(
        "success",
        "L'utilisateur a bien été supprimé 👌",
        "top",
        "right"
      );
    });
  };

  const addChannels = (userItem) => {
    setEditedUser(userItem);
    setDialogsOpen({ ...dialogsOpen, ModalApplyChannelsAccess: true });
  };

  const deleteChannelAssociation = (association) => {
    setIsLoading(true);
    POST("/api/users/channelsassociations/delete", {
      id: association.id,
    }).then((response) => {
      init();
      setIsLoading(false);
      displayNotification(
        "success",
        "Le restaurant a bien été dissocié de l'utilisateur 👌",
        "top",
        "right"
      );
    });
  };

  const updateFilterSearch = (newSearch) => {
    var newUsers = filterUsers(usersUnfiltered, newSearch);
    setSearch(newSearch);
    setUsers(newUsers);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    setIsLoading(true);
    GET("/api/pages/adminuserspage/init")
      .then((response) => {
        setIsLoading(false);
        var newUsers = filterUsers(response.data.users, search);
        //displayNotification("success", "Chargement réussi", "top", "center");
        setUsers(newUsers);
        setUsersUnfiltered(response.data.users);
        setChannels(response.data.channels);
        setGeos(response.data.geos);
        setBrands(response.data.brands);
        setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false }); //PROBLEM WITH THIS WINDOW AUTOMATICALLY OPENING WHEN MODALUSER IS OPENING IF OPENED LAST TIME. KIND OF DESPARATELY PUSHING THIS VARIABLE AT FALSE EVERYWHERE...
        setEditedUser(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false }); //PROBLEM WITH THIS WINDOW AUTOMATICALLY OPENING WHEN MODALUSER IS OPENING IF OPENED LAST TIME. KIND OF DESPARATELY PUSHING THIS VARIABLE AT FALSE EVERYWHERE...
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  const filterUsers = (localUsersUnfiltered, localSearch) => {
    var localUsersFiltered = [];
    localUsersUnfiltered.map((user) => {
      if (user.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0) {
        localUsersFiltered.push(user);
      } else if (
        user.email.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
      ) {
        localUsersFiltered.push(user);
      } else if (
        user.status.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
      ) {
        localUsersFiltered.push(user);
      } else {
        //Check among tags
        var matchCriteria = false;
        user.channelsAssociations.map((tagging) => {
          if (
            tagging.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        if (matchCriteria) {
          localUsersFiltered.push(user);
        }
      }
    });
    return localUsersFiltered;
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      <Button
        variant="contained"
        sx={{ mb: 2 }}
        onClick={() => {
          setEditedUser(null);
          setDialogsOpen({ ...dialogsOpen, ModalSendEmail: false });
          setDialogsOpen({ ...dialogsOpen, ModalEditUser: true });
        }}
        startIcon={<AddCircleOutlineIcon />}
      >
        Ajouter
      </Button>

      {isLoading ? (
        <SkeletonUsersManagementTable />
      ) : (
        <React.Fragment>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Tooltip title="Rechercher">
                <TextField
                  className={classes.inputF}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="search"
                  label={`Rechercher un utilisateur`}
                  name="search"
                  autoComplete="search"
                  value={search}
                  style={{ height: "80px", paddingTop: "9px" }}
                  onChange={(e) => {
                    updateFilterSearch(e.target.value);
                  }}
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            </Toolbar>
            {users.length < 1 ? (
              <div></div>
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: colWidths.col1 }}>
                        Nom
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col2 }}
                        align="center"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col3 }}
                        align="center"
                      >
                        Statut
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Restaurants
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col5 }}
                        align="center"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(users, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        return (
                          <AdminUsersRow
                            key={row.id}
                            userItem={row}
                            editUser={editUser}
                            deleteUser={openDeleteUserModal}
                            loginAs={loginAs}
                            colWidths={colWidths}
                            addChannels={addChannels}
                            deleteChannelAssociation={deleteChannelAssociation}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 25, 50, 100]}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Canaux par page"
            />
          </Paper>
        </React.Fragment>
      )}

      {/* ----- MODALS ------- */}
      <ModalEditUser
        userItem={editedUser}
        initPage={init}
        brands={brands}
        geos={geos}
      />
      <ModalContinueOrCancel
        title="Suppression de l'utilisateur"
        content="Souhaitez-vous réellement supprimer cet utilisateur ? Cette action est irréversible."
        modalId="ModalDeleteUser"
        handleCancel={() => {}}
        handleContinue={() => {
          deleteUser();
        }}
      />
      <ModalApplyChannelsAccess
        initPage={init}
        channels={channels}
        userItem={editedUser}
      />
    </div>
  );
}

AdminUsersPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default AdminUsersPage;
