import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { GET, POST } from "../../js/HTTPRequest";
import { generateColorFromString, hexToRgb } from "../../js/designManager";
//IMAGES AND ICONS
import Logo_UberEats from "../../img/logo-cercle-ubereats.png";
import Logo_Deliveroo from "../../img/logo-cercle-deliveroo.png";
import StyleIcon from "@mui/icons-material/Style";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Checkbox,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableHead,
  Switch,
} from "@mui/material";
import ModalContinueOrCancel from "../generic/ModalContinueOrCancel";

// HEAD CELLS OF THE TABLE
//CONSTANTES
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "50rem",
  col5: "10rem",
};

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  boxWrapper: {
    width: "100%",
  },
}));

// ********************* COMPONENT START ***********************

function AdminSettingsRestaurantPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [channels, setChannels] = useState([]); //FILTERED
  const [channelsUnfiltered, setchannelsUnfiltered] = useState([]); //FILTERED
  const [locations, setLocations] = useState([]);
  const [brands, setBrands] = useState([]);
  const [others, setOthers] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [selectedTagging, setSelectedTagging] = useState({});
  const [selectedUserAssociation, setSelectedUserAssociation] = useState({});
  const [tagSQLModeLive, setTagSQLModeLive] = useState(false);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const toggleJetpackDefaultOptin = (channelId, newValue) => {
    POST(`/api/jetpack/${channelId}/toggledefaultoptin`, {
      jetpackDefaultOptin: newValue,
    })
      .then((response) => {
        init();
        displayNotification(
          "success",
          newValue
            ? "L'optin par defaut est maintenant actif pour ce restaurant 👌"
            : "L'optin par defaut est maintenant désactivé pour ce restaurant 😴",
          "top",
          "right"
        );
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la désassociation de l'utilisateur...",
          "top",
          "center"
        );
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = channels.map((n) => n);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = _.findIndex(selected, function (o) {
      return o.id === row.id;
    });
    var newSelecteds = selected ? JSON.parse(JSON.stringify(selected)) : [];
    if (selectedIndex < 0) {
      newSelecteds = [...newSelecteds, row];
    } else {
      _.remove(newSelecteds, function (o) {
        return o.id === row.id;
      });
    }
    setSelected(newSelecteds);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) =>
    _.findIndex(selected, function (o) {
      return o.id === row.id;
    }) >= 0;

  const handleUserUnconfirmed = (userItem) => {
    //NO MODAL CONFIRMATION ASKED FOR THE MOMENT
    /*setSelectedUserAssociation(userItem);
    setDialogsOpen({ ...dialogsOpen, ModalDeleteChannelTag: true });*/
    POST("/api/users/channelsassociations/delete", {
      id: userItem.userAssociationId,
    })
      .then((response) => {
        init();
        displayNotification(
          "success",
          "L'utilisateur a bien été dissocié du restaurant 👌",
          "top",
          "right"
        );
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la désassociation de l'utilisateur...",
          "top",
          "center"
        );
      });
  };

  const handleDeleteOtherTagConfirmed = () => {
    POST("/api/taggingschannels/delete", {
      taggingId: selectedTagging.taggingId,
    })
      .then((response) => {
        init();
        displayNotification(
          "success",
          "Le tag a bien été retiré 👌",
          "top",
          "right"
        );
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant la suppression de votre tag...",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    GET("/api/pages/adminsettingsrestaurantspage/init")
      .then((response) => {
        //displayNotification("success", "Chargement réussi", "top", "center");
        var newChannelsUnfiltered = formatChannelsListForTable(
          response.data.channels
        );
        var newChannels = filterChannels(newChannelsUnfiltered, search);
        setSearch(search);
        setChannels(newChannels);
        setchannelsUnfiltered(newChannelsUnfiltered);
        setLocations(response.data.locations);
        setBrands(response.data.brands);
        setOthers(response.data.others);
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  const formatChannelsListForTable = (channelsList) => {
    var formattedChannel = [];
    if (!channelsList || channelsList.length < 1) {
      return formattedChannel;
    }

    channelsList.map((Channel) => {
      //Check if location available
      var locations = [];
      var brands = [];
      var others = [];
      var users = [];
      if (
        Channel &&
        Channel.taggings_channels &&
        Channel.taggings_channels.length > 0
      ) {
        Channel.taggings_channels.map((tagging) => {
          if (tagging && tagging.Tags_Location) {
            var tmpLocation = tagging.Tags_Location;
            tmpLocation.taggingId = tagging.id;
            locations.push(tmpLocation);
          }
          if (tagging && tagging.Tags_Brand) {
            var tmpBrand = tagging.Tags_Brand;
            tmpBrand.taggingId = tagging.id;
            brands.push(tmpBrand);
          }
          if (tagging && tagging.Tags_Other) {
            var tmpOther = tagging.Tags_Other;
            tmpOther.taggingId = tagging.id;
            others.push(tmpOther);
          }
        });
      }
      if (
        Channel &&
        Channel.usersAssociations &&
        Channel.usersAssociations.length > 0
      ) {
        Channel.usersAssociations.map((association) => {
          var tmpUser = association.User;
          tmpUser.userAssociationId = association.id; //to ease deleting
          users.push(tmpUser);
          return null;
        });
      }
      formattedChannel.push({
        id: Channel.id,
        name: Channel.name,
        platform: Channel.Platform.name,
        jetpackDefaultOptin: Channel.jetpackDefaultOptin,
        jetpackDefaultOptin_history: Channel.jetpackDefaultOptin_history,
        locations,
        brands,
        others,
        users,
      });
    });

    return formattedChannel;
  };

  const filterChannels = (localChannelsUnfiltered, localSearch) => {
    var localChannelFiltered = [];
    localChannelsUnfiltered.map((channel) => {
      if (channel.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0) {
        localChannelFiltered.push(channel);
      } else {
        //Check among tags
        var matchCriteria = false;
        channel.locations.map((tagging) => {
          if (
            tagging.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        channel.brands.map((tagging) => {
          if (
            tagging.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        channel.others.map((tagging) => {
          if (
            tagging.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        channel.users.map((userItem) => {
          if (
            userItem.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
          ) {
            matchCriteria = true;
          }
        });
        if (matchCriteria) {
          localChannelFiltered.push(channel);
        }
      }
    });
    return localChannelFiltered;
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - channels.length) : 0;

  return (
    <Box className={classes.boxWrapper}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: colWidths.col1 }}>Nom</TableCell>
                <TableCell style={{ width: colWidths.col3 }} align="center">
                  Plateforme
                </TableCell>
                <TableCell style={{ width: colWidths.col4 }} align="center">
                  Utilisateurs
                </TableCell>
                <TableCell style={{ width: colWidths.col4 }} align="center">
                  Activer toutes les campagnes jetpack par defaut
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* This method is created for cross-browser compatibility (IE11) */}
              {stableSort(channels, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {                  
                  const labelId = `enhanced-table-checkbox-${index}`;
                  console.log("row", row);
                  console.log("jetpackDefaultOptin", row.jetpackDefaultOptin);
                  return (
                    <TableRow>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col3 }}
                        align="center"
                      >
                        <img
                          style={{ width: 30, height: 30 }}
                          alt={row.platform}
                          src={
                            row.platform === "Uber Eats" //DIRTY : MOVE IMAGE URL INTO DATABASE
                              ? Logo_UberEats
                              : Logo_Deliveroo
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {row.users.map((userItem) => {
                          var chipColor =
                            "#" +
                            generateColorFromString(
                              userItem.status ? userItem.status : "empty"
                            );
                          return (
                            <Chip
                              key={userItem.userAssociationId}
                              size="small"
                              sx={{
                                margin: "2px",
                                backgroundColor:
                                  alpha(chipColor, 0.3) + "!important",
                              }}
                              label={userItem.name + ` [${userItem.status}]`}
                              /*onDelete={() => {
                                handleUserUnconfirmed(userItem);
                              }}*/
                            />
                          );
                        })}
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        <Switch
                          checked={row.jetpackDefaultOptin}
                          onChange={(e) => {
                            toggleJetpackDefaultOptin(
                              row.id,
                              !row.jetpackDefaultOptin
                            );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 25, 50, 100]}
          component="div"
          count={channels.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Canaux par page"
        />
      </Paper>
      <ModalContinueOrCancel
        title="Retirer ce restaurant"
        content="Souhaitez-vous réellement retirer ce restaurant de votre scope ?"
        modalId="ModalRemoveChannels"
        handleCancel={() => {}}
        handleContinue={() => {}}
      />
    </Box>
  );
}

AdminSettingsRestaurantPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default AdminSettingsRestaurantPage;
