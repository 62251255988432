import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import moment from "moment";
import { GET, POST } from "../../js/HTTPRequest";
import { isMobile } from "../../js/designManager";
import _ from "lodash";
import {
  isBuffaloGrill,
  isCourtepaille,
  isBunMeatBun,
  isPopeyes,
  isArlette,
} from "../../js/brandsManager";
import { makeComponentUniqueId } from "../../js/otherManager";
import SendIcon from "@mui/icons-material/Send";
import CampaignIcon from "@mui/icons-material/Campaign";
import PercentIcon from "@mui/icons-material/Percent";
//IMAGES AND ICONS
import logo_bg from "../../img/logo-bg.png";
import logo_bmb from "../../img/logo-bmb.png";
import logo_ctp from "../../img/logo-ctp.png";
import logo_pp from "../../img/logo-pp.png";
import logo_aj from "../../img/logo-aj.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ErrorIcon from "@mui/icons-material/Error";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  AvatarGroup,
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  container: {
    backgroundColor: "white",
  },
  inactive: {
    backgroundColor: theme.palette.action.disabledBackground + " !important",
    color: theme.palette.action.disabled + " !important",
  },
  inactiveAvatar: {
    opacity: 0.5,
  },
}));

// ********************* COMPONENT START ***********************

function RowJetpackCampaignPlatform({
  initPage,
  jetpackCampaign,
  constraintTypes,
  editJetpackCampaign,
  deleteJetpackCampaign,
  toggleActiveJetpackCampaign,
  openJetpackErrors,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const handleEditMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditMenuClose = () => {
    setAnchorEl(null);
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const generateBrandLogos = () => {
    var logos = [];
    var gotBuffalo = false;
    var gotCourtepaille = false;
    var gotBunMeatBun = false;
    var gotPopepyes = false;
    var gotArlette = false;
    if (
      jetpackCampaign &&
      jetpackCampaign.targetedTags &&
      jetpackCampaign.targetedTags.length > 0
    ) {
      var targetedTags = JSON.parse(jetpackCampaign.targetedTags);
      targetedTags.brands.map((brand) => {
        if (brand.id === "all") {
          gotBuffalo = true;
          gotCourtepaille = true;
          gotBunMeatBun = true;
          gotPopepyes = true;
          gotArlette = true;
        }
        if (isBuffaloGrill(brand.name)) {
          gotBuffalo = true;
        }
        if (isCourtepaille(brand.name)) {
          gotCourtepaille = true;
        }
        if (isBunMeatBun(brand.name)) {
          gotBunMeatBun = true;
        }
        if (isPopeyes(brand.name)) {
          gotPopepyes = true;
        }
        if (isArlette(brand.name)) {
          gotArlette = true;
        }
      });
    } else {
      gotBuffalo = true;
      gotCourtepaille = true;
      gotBunMeatBun = true;
      gotPopepyes = true;
      gotArlette = true;
    }
    if (gotBuffalo) {
      logos.push({ alt: "Buffalo Grill", src: logo_bg });
    }
    if (gotCourtepaille) {
      logos.push({ src: logo_ctp, alt: "Courtepaille" });
    }
    if (gotBunMeatBun) {
      logos.push({ src: logo_bmb, alt: "Bun Meat Bun" });
    }
    if (gotPopepyes) {
      logos.push({ src: logo_pp, alt: "Popeyes" });
    }
    if (gotArlette) {
      logos.push({ src: logo_aj, alt: "Arlette et Jean" });
    }
    return logos;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  var logos = generateBrandLogos();
  var errors =
    jetpackCampaign && jetpackCampaign.errors
      ? JSON.parse(jetpackCampaign.errors)
      : [];
  var errorsCount = 0;
  errors.map((error) => {
    return (errorsCount = errorsCount + parseInt(error.errorsCount));
  });
  return (
    <Grid
      item
      xs={12}
      sx={{ mt: 1, pl: 2, pt: 1, pb: 1 }}
      className={classNames(classes.container, {
        [classes.inactive]: !jetpackCampaign.active,
      })}
    >
      <Grid container spacing={2}>
        <Grid
          item
          xs={3}
          lg={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          className={classNames({
            [classes.inactiveAvatar]: !jetpackCampaign.active,
          })}
        >
          <AvatarGroup>
            {logos.map((logo) => {
              var randomId = makeComponentUniqueId();
              return (
                <Avatar
                  alt={logo.alt}
                  key={"Avatar_" + randomId}
                  src={logo.src}
                  sx={{
                    width: isMobile() ? 20 : "1.5rem",
                    height: isMobile() ? 20 : "1.5rem",
                  }}
                />
              );
            })}
          </AvatarGroup>
        </Grid>
        <Grid
          item
          xs={9}
          lg={3}
          sx={{ pl: 2 }}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {jetpackCampaign.name}
          {errorsCount > 0 ? (
            <IconButton
              aria-label="refresh"
              onClick={() => {
                openJetpackErrors(jetpackCampaign);
              }}
            >
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    backgroundColor: "red",
                  },
                }}
                //variant="dot"
                badgeContent={errorsCount}
                max={999}
                showZero
                //invisible={synchStatus.channels ? true : false}
              >
                <ErrorIcon />
              </Badge>
            </IconButton>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={2}
          textAlign="left"
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Typography variant="body2">
            {jetpackCampaign.end
              ? `Du ${moment(jetpackCampaign.start).format("LL")} au ${moment(
                  jetpackCampaign.end
                ).format("LL")}`
              : "Non délimitée dans le temps"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {jetpackCampaign.constraints.map((constraint) => {
            return (
              <Chip
                style={{ margin: "3px" }}
                key={`Chip_${jetpackCampaign.id}|${constraint.id}`}
                label={_.find(constraintTypes, ["id", constraint.type]).title}
                className={classNames({
                  [classes.inactive]: !jetpackCampaign.active,
                })}
              />
            );
          })}
          {jetpackCampaign.actions.map((action) => {
            return (
              <Chip
                style={{ margin: "3px" }}
                key={`Chip_${jetpackCampaign.id}|${action.id}`}
                label={
                  _.find(constants.jetpack_actionTypes, ["id", action.type])
                    .title
                }
                className={classNames({
                  [classes.inactive]: !jetpackCampaign.active,
                })}
              />
            );
          })}
          {jetpackCampaign.explicitOptInRequired ? (
            <Chip
              style={{ margin: "3px" }}
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: "white",
              }}
              label="Opt-in requis"
              className={classNames({
                [classes.inactive]: !jetpackCampaign.active,
              })}
            />
          ) : (
            <div></div>
          )}
        </Grid>
        <Grid
          item
          xs={10}
          lg={1}
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            color: theme.palette.grey[500],
          }}
        >
          <Tooltip title="Canaux ayant reçu la campagne">
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <SendIcon style={{ width: "18px" }} />
              <span>{jetpackCampaign.channels_candidates}</span>
            </div>
          </Tooltip>
          <Tooltip title="Canaux avec la campagne active">
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <CampaignIcon style={{ width: "18px" }} />
              <span>{jetpackCampaign.channels_live}</span>
            </div>
          </Tooltip>
          <Tooltip title="% d'opt-in">
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <PercentIcon style={{ width: "18px" }} />
              <span>
                {jetpackCampaign.channels_candidates > 0
                  ? Math.round(
                      (100 * jetpackCampaign.channels_live) /
                        jetpackCampaign.channels_candidates
                    ) + "%"
                  : "0%"}
              </span>
            </div>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={2}
          lg={1}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="settings" onClick={handleEditMenu}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={() => {
              handleEditMenuClose();
            }}
            sx={{ mt: "45px" }}
          >
            <MenuItem
              onClick={() => {
                editJetpackCampaign(jetpackCampaign);
                handleEditMenuClose();
              }}
            >
              Télécharger liste restaurants en format csv
            </MenuItem>          
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
}

RowJetpackCampaignPlatform.propTypes = {};

// ********************* COMPONENT END ***********************

export default RowJetpackCampaignPlatform;
