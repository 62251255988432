import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
//IMAGES AND ICONS
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { numberWithSpaces } from "../../js/numberManager";

//MATERIAL UI COMPONENT IMPORTS
import {
  TableRow,
  TableCell,
  Collapse,
  TableHead,
  Chip,
  TableBody,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SkeletonDashboardRow from "../../ui-components/Skeleton/SkeletonDashboardRow";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  variationDiv: {
    fontSize: 12,
    backgroundColor: theme.palette.background.default,
    width: "fit-content",
    padding: "2px 7px",
    minWidth: "50px",
    textAlign: "center",
  },
  img_marker: {
    height: "150px",
  },
  img_table: {
    height: "50px",
  },
}));

//CONSTANTE
const colWidths_subrow = {
  col1: "7rem",
  col2: "20rem",
  col3: "20rem",
  col4: "20rem",
  col5: "20rem",
  col6: "20rem",
};

// ********************* COMPONENT START ***********************

function RadarMarketSharesRow({
  row,
  colWidths,
  collapseAllowed,
  handleSparklineClick,
  period,
  onClick,
  uncollapsedRow,
  setUncollapsedRow,
  setActiveRestaurants,
  handleActiveMarker,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const calculateVariation = (type) => {
    var currentValue = row[type][row[type].length - 1];
    var previousValue = row[type][row[type].length - 2];
    if (previousValue === 0) {
      return "n/a";
    }
    return parseFloat(currentValue - previousValue);
  };

  const displayCell = (column, fixed, suffix, maxValue = null) => {
    var variation = calculateVariation(column);
    return (
      <TableCell style={{ width: colWidths.col3 }} align="center">
        <Grid
          container
          justify="center"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item align="right" xs={3}>
            {isNaN(row[column][row[column].length - 1])
              ? row[column][row[column].length - 1]
              : parseFloat(row[column][row[column].length - 1]).toFixed(fixed) +
                suffix}
          </Grid>
          <Grid
            item
            align="left"
            xs={3}
            style={{
              color: variation === 0 ? "grey" : variation > 0 ? "green" : "red",
            }}
          >
            <div className={classes.variationDiv}>
              {variation > 0 ? "+" : ""}
              {variation.toFixed(1)}
            </div>
          </Grid>
          <Grid
            item
            align="center"
            xs={6}
            onClick={() => {
              handleSparklineClick(
                `${getCleanColumnName(column)} - ${row.city}`,
                row.weeks,
                row[column]
              );
            }}
          >
            <Sparklines data={row[column]} width={100} height={20}>
              <SparklinesLine
                color={theme.palette.primary.main}
                style={{ fill: "none" }}
              />
              <SparklinesSpots
                spotColors={{
                  "-1": "red",
                  0: "black",
                  1: "#008000",
                }}
              />
            </Sparklines>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  const getCleanColumnName = (columnName) => {
    var clean_name = columnName;
    switch (columnName) {
      case "lunch_sales_share":
        clean_name = "Part de marché déjeuner";
        break;
      case "snack_sales_share":
        clean_name = "Part de marché après-midi";
        break;
      case "dinner_sales_share":
        clean_name = "Part de marché dîner";
        break;
      case "late_dinner_sales_share":
        clean_name = "Part de marché soirée";
        break;
      default:
        break;
    }
    return clean_name;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  console.log("uncollapsedRow", uncollapsedRow);
  var open = row.brand === uncollapsedRow ? true : false;

  const displayContent = () => {
    return (
      <React.Fragment>
        {" "}
        <TableRow
          sx={{
            "& > *": {
              borderBottom: "unset",
              fontSize: collapseAllowed ? "14px" : "11px!important",
            },
          }}
          onClick={() => {
            onClick(row.platformUUID);
          }}
        >
          <TableCell style={{ width: colWidths.col2 }}>{row.city}</TableCell>
          {displayCell("lunch_sales_share", 2, "%")}
          {displayCell("snack_sales_share", 2, "%")}
          {displayCell("dinner_sales_share", 2, "%")}
          {displayCell("late_dinner_sales_share", 2, "%")}
        </TableRow>
      </React.Fragment>
    );
  };

  return <React.Fragment>{displayContent()}</React.Fragment>;
}

RadarMarketSharesRow.propTypes = {};

// ********************* COMPONENT END ***********************
// ********************* SUB ROW COMPONENT *******************

export default RadarMarketSharesRow;
