import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
import classNames from "classnames";
import _ from "lodash";
import { generateColorFromString } from "../../../js/designManager";
//IMAGES AND ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  CardContent,
  CardMedia,
  Chip,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  TextField,
  Select,
  Slider,
  OutlinedInput,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  FormControl,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import SchedulerFunc from "../../generic/scheduler/SchedulerFunc";

//CONST AND INTERNAL IMPORT
import CardBogoItemSelection from "./CardBogoItemSelection";

const discounts = [
  { id: 20, label: "20%" },
  { id: 30, label: "30%" },
  { id: 40, label: "40%" },
  { id: 50, label: "50%" },
  { id: 75, label: "75%" },
];

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "secondary !important",
  },
  activeCard: {
    backgroundColor:
      alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) +
      " !important",
  },
  disabledCard: {
    backgroundColor: theme.palette.action.disabledBackground + " !important",
    color: theme.palette.action.disabled + " !important",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ********************* COMPONENT START ***********************

function ModalNewOrEditJetpackCampaignStepActionsDetails({
  actionType,
  actionValueObject,
  setActionValueObject,
  channels,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);

  const {
    discount_on_what,
    jetpack_customersTargets,
    jetpack_customersTargetsAov,
    jetpack_discountTypes,
    jetpack_sponsoringBudgetTypes,
    jetpack_freeDeliveryBudgetTypes,
  } = constants;
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log(
      "Frontend Info - I am inside ModalNewOrEditJetpackCampaignStepActionsDetails"
    );
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const setTarget = (target) => {
    setActionValueObject({ ...actionValueObject, target });
  };

  const setDiscount_item_or_category = (discount_item_or_category) => {
    setActionValueObject({ ...actionValueObject, discount_item_or_category });
  };

  const setTargetAov = (targetAov) => {
    setActionValueObject({ ...actionValueObject, targetAov });
  };

  const setMinAmount = (minAmount) => {
    setActionValueObject({
      ...actionValueObject,
      minAmount: parseInt(minAmount),
    });
  };

  const setTDiscountType = (discount_type) => {
    setActionValueObject({ ...actionValueObject, discount_type });
  };

  const setSponsoringBudget = (sponsoring_budget) => {
    setActionValueObject({ ...actionValueObject, sponsoring_budget });
  };

  const setFreeDeliveryBudget = (free_delivery_max_budget) => {
    setActionValueObject({ ...actionValueObject, free_delivery_max_budget });
  };

  const setBogoOrFreeItem = (item, index, key = "bogoItems") => {
    item.menuChannelId = actionValueObject.menuChannelId;
    item.menuChannelName = actionValueObject.menuChannelName;
    console.log(
      "actionValueObject.menuChannelName",
      actionValueObject.menuChannelName
    );
    var newBogoItems =
      actionValueObject && actionValueObject[key]
        ? JSON.parse(JSON.stringify(actionValueObject[key]))
        : [];
    var alreadyExisting = false;
    var indexExistingItem = -1;
    newBogoItems.map((existingItem, indexIterate) => {
      if (existingItem.uuid === item.uuid) {
        alreadyExisting = true;
        indexExistingItem = indexIterate;
      }
    });
    if (!alreadyExisting) {
      if (newBogoItems.length > index) {
        newBogoItems[index] = item;
      } else {
        newBogoItems.push(item);
      }
      console.log("newBogoItems", newBogoItems);
      if (key === "bogoItems") {
        setActionValueObject({ ...actionValueObject, bogoItems: newBogoItems });
      } else if (key === "discountItems") {
        setActionValueObject({
          ...actionValueObject,
          discountItems: newBogoItems,
        });
      } else {
        setActionValueObject({ ...actionValueObject, freeItems: newBogoItems });
      }
    } else if (key === "discountItems") {
      newBogoItems[indexExistingItem] = item;
      setActionValueObject({
        ...actionValueObject,
        discountItems: newBogoItems,
      });
    }
  };

  const addNewBogoOrFreeItems = (key = "bogoItems") => {
    var newBogoItems =
      actionValueObject && actionValueObject[key]
        ? JSON.parse(JSON.stringify(actionValueObject[key]))
        : [];
    newBogoItems.push({});
    setActionValueObject({
      ...actionValueObject,
      [key]: newBogoItems,
    });
  };

  const deleteBogoOrFreeItem = (index, key = "bogoItems") => {
    var newBogoItems =
      actionValueObject && actionValueObject[key]
        ? JSON.parse(JSON.stringify(actionValueObject[key]))
        : [];
    if (newBogoItems.length < 2) {
      return;
    }
    newBogoItems.splice(index, 1);
    setActionValueObject({
      ...actionValueObject,
      [key]: newBogoItems,
    });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const listMenuItemFromOneChannelId = (channelId) => {
    GET(`/api/jetpackcampaigns/${channelId}/getitems`).then((response) => {
      //For discount items, we add a default discount field with 20 as default value
      var itemsLocal = [];
      var categoriesLocal = [];
      if (response.data.items && response.data.items.length > 0) {
        response.data.items.map((item) => {
          var tmpItem = item;
          tmpItem.discount = 20;
          itemsLocal.push(tmpItem);
        });
      }
      if (response.data.categories && response.data.categories.length > 0) {
        response.data.categories.map((category) => {
          categoriesLocal.push(category);
        });
      }
      itemsLocal = _.sortBy(itemsLocal, (o) => {
        return o.title;
      });
      categoriesLocal = _.sortBy(categoriesLocal, (o) => {
        return o.title;
      });
      console.log("itemsLocal", itemsLocal);
      setItems(itemsLocal);
      setCategories(categoriesLocal);
    });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayContent = () => {
    switch (actionType) {
      case "discount":
        return (
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
              <FormControl sx={{ m: 2, width: 300, mt: 3 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.target ? actionValueObject.target : ""
                  }
                  onChange={(e) => setTarget(e.target.value.id)}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Choisissez votre cible</em>;
                    }
                    var label = "";
                    jetpack_customersTargets.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre cible</em>
                  </MenuItem>
                  {jetpack_customersTargets.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {jetpack_discountTypes.map((type) => {
                return (
                  <Grid item key={type.id} sx={{ width: "100%" }}>
                    <Card
                      className={classNames(classes.card, {
                        [classes.activeCard]:
                          actionValueObject &&
                          type.id === actionValueObject.discount_type,
                        [classes.disabledCard]: type.disabled,
                      })}
                      sx={{ display: "flex" }}
                      onClick={() => {
                        if (!type.disabled) {
                          setTDiscountType(type.id);
                        }
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography component="div" variant="h6">
                            {type.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="div"
                          >
                            {type.description}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );
      case "sponsoring":
        return (
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
              <FormControl sx={{ m: 2, width: 300, mt: 3 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.target ? actionValueObject.target : ""
                  }
                  onChange={(e) => setTarget(e.target.value.id)}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Choisissez votre cible</em>;
                    }
                    var label = "";
                    jetpack_customersTargets.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre cible</em>
                  </MenuItem>
                  {jetpack_customersTargets.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 2, width: 300, mt: 3 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.targetAov
                      ? actionValueObject.targetAov
                      : ""
                  }
                  onChange={(e) => setTargetAov(e.target.value.id)}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Choisissez votre panier moyen cible</em>;
                    }
                    var label = "";
                    jetpack_customersTargetsAov.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre panier moyen cible</em>
                  </MenuItem>
                  {jetpack_customersTargetsAov.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {jetpack_sponsoringBudgetTypes.map((type) => {
                return (
                  <Grid item key={type.id} sx={{ width: "100%" }}>
                    <Card
                      className={classNames(classes.card, {
                        [classes.activeCard]:
                          actionValueObject &&
                          type.id === actionValueObject.sponsoring_budget,
                        [classes.disabledCard]: type.disabled,
                      })}
                      sx={{ display: "flex" }}
                      onClick={() => {
                        if (!type.disabled) {
                          setSponsoringBudget(type.id);
                        }
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography component="div" variant="h6">
                            {type.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="div"
                          >
                            {type.description}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );
      case "bogo":
        return (
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
              <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.target ? actionValueObject.target : ""
                  }
                  onChange={(e) => {
                    setTarget(e.target.value.id);
                  }}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Choisissez votre cible</em>;
                    }
                    var label = "";
                    jetpack_customersTargets.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre cible</em>
                  </MenuItem>
                  {jetpack_customersTargets.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 0, width: "100%", mt: 2, mb: 2 }}>
                <Typography
                  component="div"
                  variant="body2"
                  align="justify"
                  sx={{ m: 0, mb: 2 }}
                >
                  Choisissez un restaurant pour en extraire le menu afin de
                  choisir les plats sur lesquels vous souhaitez effectuer un
                  BoGo. Le BoGo sera alors effectué sur les produits ayant le
                  même nom lorsque ce dernier est disponible sur le canal.
                </Typography>
                <Select
                  displayEmpty
                  value={actionValueObject.menuChannelId}
                  onChange={(e) => {
                    listMenuItemFromOneChannelId(e.target.value);

                    var label = "";
                    channels.map((channel) => {
                      if (channel.id === e.target.value) {
                        label = channel.name;
                      }
                    });
                    setActionValueObject({
                      ...actionValueObject,
                      bogoItems:
                        !actionValueObject.bogoItems ||
                        actionValueObject.bogoItems.length < 1 ||
                        !actionValueObject.bogoItems[0].uuid
                          ? [{}]
                          : actionValueObject.bogoItems,
                      menuChannelId: e.target.value,
                      menuChannelName: label,
                    });
                  }}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (
                      !selected ||
                      selected === undefined ||
                      selected.length === 0
                    ) {
                      return <em>Extraire le menu d'un restaurant</em>;
                    }
                    var label = "";
                    channels.map((channel) => {
                      if (channel.id === selected) {
                        label = channel.name;
                      }
                    });
                    return label;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre restaurant</em>
                  </MenuItem>
                  {channels.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                        {item.taggings_channels.map((tag) => {
                          var tagName =
                            tag && tag.Tags_Other && tag.Tags_Other.name
                              ? tag.Tags_Other.name
                              : "(unknown)";
                          var chipColor =
                            "#" + generateColorFromString(tagName);
                          return (
                            <Chip
                              key={tag.id}
                              sx={{
                                backgroundColor:
                                  alpha(chipColor, 0.3) + "!important",
                                marginLeft: "10px",
                              }}
                              label={tagName}
                            />
                          );
                        })}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {displayBogoMenuItemSelector("bogoItems")}
            </Grid>
          </Box>
        );

      case "free_item":
        return (
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
              <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.target ? actionValueObject.target : ""
                  }
                  onChange={(e) => {
                    setTarget(e.target.value.id);
                  }}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Choisissez votre cible</em>;
                    }
                    var label = "";
                    jetpack_customersTargets.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre cible</em>
                  </MenuItem>
                  {jetpack_customersTargets.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                <TextField
                  id={"filter-minAmount"}
                  value={
                    actionValueObject.minAmount
                      ? parseInt(actionValueObject.minAmount)
                      : 0
                  }
                  onChange={(event) => setMinAmount(event.target.value)}
                  label={"Minimum de commande €"}
                  type={"number"}
                  variant="standard"
                />
              </FormControl>
              <FormControl sx={{ m: 0, width: "100%", mt: 2, mb: 2 }}>
                <Typography
                  component="div"
                  variant="body2"
                  align="justify"
                  sx={{ m: 0, mb: 2 }}
                >
                  Choisissez un restaurant pour en extraire le menu afin de
                  choisir les plats que vous souhaitez offrir. Le Free item sera
                  alors effectué sur les produits ayant le même nom lorsque ce
                  dernier est disponible sur le canal.
                </Typography>
                <Select
                  displayEmpty
                  value={actionValueObject.menuChannelId}
                  onChange={(e) => {
                    /*listMenuItemFromOneChannelId(e.target.value);
                    setActionValueObject({
                      ...actionValueObject,
                      //freeItems: [{}],
                      menuChannelId: e.target.value,
                    });*/
                    listMenuItemFromOneChannelId(e.target.value);

                    var label = "";
                    channels.map((channel) => {
                      if (channel.id === e.target.value) {
                        label = channel.name;
                      }
                    });

                    setActionValueObject({
                      ...actionValueObject,
                      freeItems:
                        !actionValueObject.bogoItems ||
                        actionValueObject.bogoItems.length < 1 ||
                        !actionValueObject.bogoItems[0].uuid
                          ? [{}]
                          : actionValueObject.bogoItems,
                      menuChannelId: e.target.value,
                      menuChannelName: label,
                    });
                  }}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (
                      !selected ||
                      selected === undefined ||
                      selected.length === 0
                    ) {
                      return <em>Extraire le menu d'un restaurant</em>;
                    }
                    var label = "";
                    channels.map((channel) => {
                      if (channel.id === selected) {
                        label = channel.name;
                      }
                    });
                    return label;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre restaurant</em>
                  </MenuItem>
                  {channels.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {displayBogoMenuItemSelector("freeItems")}
            </Grid>
          </Box>
        );
      case "free_delivery":
        return (
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
              <FormControl sx={{ m: 2, width: 300, mt: 3 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.target ? actionValueObject.target : ""
                  }
                  onChange={(e) => setTarget(e.target.value.id)}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Choisissez votre cible</em>;
                    }
                    var label = "";
                    jetpack_customersTargets.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre cible</em>
                  </MenuItem>
                  {jetpack_customersTargets.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {jetpack_freeDeliveryBudgetTypes.map((type) => {
                return (
                  <Grid item key={type.id} sx={{ width: "100%" }}>
                    <Card
                      className={classNames(classes.card, {
                        [classes.activeCard]:
                          actionValueObject &&
                          type.id ===
                            actionValueObject.free_delivery_max_budget,
                        [classes.disabledCard]: type.disabled,
                      })}
                      sx={{ display: "flex" }}
                      onClick={() => {
                        if (!type.disabled) {
                          setFreeDeliveryBudget(type.id);
                        }
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent sx={{ flex: "1 0 auto" }}>
                          <Typography component="div" variant="h6">
                            {type.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="div"
                          >
                            {type.description}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        );
        break;

      case "discount_item":
        return (
          <Box sx={{ m: 2 }}>
            <Grid container spacing={2}>
              <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.target ? actionValueObject.target : ""
                  }
                  onChange={(e) => {
                    setTarget(e.target.value.id);
                  }}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Choisissez votre cible</em>;
                    }
                    var label = "";
                    jetpack_customersTargets.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre cible</em>
                  </MenuItem>
                  {jetpack_customersTargets.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 0, width: "100%", mt: 2, mb: 2 }}>
                <Typography
                  component="div"
                  variant="body2"
                  align="justify"
                  sx={{ m: 0, mb: 2 }}
                >
                  Choisissez un restaurant pour en extraire le menu afin de
                  choisir les plats que vous souhaitez offrir. La réduction sera
                  alors effectuée sur les produits ayant le même nom lorsque ce
                  dernier est disponible sur le canal.
                  <p>
                    <b>
                      Attention, pour l'instant le système Sequoia ne gère pas
                      ce type de promotion et le système CSI ne fonctionne
                      qu'avec les promotions sur une catégorie
                    </b>
                  </p>
                </Typography>
                <Select
                  displayEmpty
                  value={actionValueObject.menuChannelId}
                  onChange={(e) => {
                    /*listMenuItemFromOneChannelId(e.target.value);
                    setActionValueObject({
                      ...actionValueObject,
                      menuChannelId: e.target.value,
                    });*/

                    listMenuItemFromOneChannelId(e.target.value);
                    var label = "";
                    channels.map((channel) => {
                      if (channel.id === e.target.value) {
                        label = channel.name;
                      }
                    });
                    setActionValueObject({
                      ...actionValueObject,
                      discountItems:
                        !actionValueObject.bogoItems ||
                        actionValueObject.bogoItems.length < 1 ||
                        !actionValueObject.bogoItems[0].uuid
                          ? [{}]
                          : actionValueObject.bogoItems,
                      menuChannelId: e.target.value,
                      menuChannelName: label,
                    });
                  }}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (
                      !selected ||
                      selected === undefined ||
                      selected.length === 0
                    ) {
                      return <em>Extraire le menu d'un restaurant</em>;
                    }
                    var label = "";
                    channels.map((channel) => {
                      if (channel.id === selected) {
                        label = channel.name;
                      }
                    });
                    return label;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Choisissez votre restaurant</em>
                  </MenuItem>
                  {channels.map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 0, width: "100%", mt: 0 }}>
                <Select
                  displayEmpty
                  value={
                    actionValueObject.discount_item_or_category
                      ? actionValueObject.discount_item_or_category
                      : ""
                  }
                  onChange={(e) => {
                    setDiscount_item_or_category(e.target.value.id);
                  }}
                  input={<OutlinedInput />}
                  required
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Sur quoi ?</em>;
                    }
                    var label = "";
                    discount_on_what.map((target) => {
                      if (target.id === selected) {
                        label = target.label;
                      }
                    });
                    return label; //.join(", ");
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Sur quoi appliquer la promotion</em>
                  </MenuItem>
                  {discount_on_what.map((item) => (
                    <MenuItem key={item.id} value={item}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {actionValueObject &&
              actionValueObject.discount_item_or_category &&
              actionValueObject.discount_item_or_category === "CATEGORY"
                ? displayCategorySelector()
                : displayBogoMenuItemSelector("discountItems")}
            </Grid>
          </Box>
        );
      default:
        return (
          <Box m={6}>
            <Grid container spacing={2}>
              <Typography>Oula... comment suis-je arrivé ici.</Typography>{" "}
            </Grid>
          </Box>
        );
    }
  };

  const displayBogoMenuItemSelector = (key) => {
    console.log("displayBogoMenuItemSelector. key:", key);
    if (
      ((!items || items.length === 0) && !actionValueObject) ||
      !actionValueObject[key] ||
      actionValueObject[key].length === 0
    ) {
      return null;
    }
    if (
      key === "discountItems" &&
      (!actionValueObject ||
        !actionValueObject.discount_item_or_category ||
        actionValueObject.discount_item_or_category === "")
    ) {
      return null;
    }
    console.log("actionValueObject[key]:", actionValueObject[key]);
    var selectedItemCount = 0;
    if (
      actionValueObject &&
      actionValueObject[key] &&
      actionValueObject[key].length > 0
    ) {
      actionValueObject[key].map((item) => {
        if (item.title && item.title.length !== "") {
          selectedItemCount = selectedItemCount + 1;
        }
      });
      return (
        <Grid container spacing={0} sx={{ width: "100%" }}>
          <Typography
            component="div"
            variant="body2"
            align="justify"
            sx={{ ml: 2, mt: 2 }}
          >
            {selectedItemCount} option{selectedItemCount > 1 ? "s" : ""}{" "}
            selectionnée{selectedItemCount > 1 ? "s" : ""}
          </Typography>
          {actionValueObject[key].map((item, index) => {
            return (
              <Grid item sx={{ width: "100%" }}>
                <CardBogoItemSelection
                  items={items}
                  item={item}
                  keyField={key}
                  setItem={setBogoOrFreeItem}
                  deleteItem={deleteBogoOrFreeItem}
                  index={index}
                  discounts={discounts}
                />
              </Grid>
            );
          })}
          <Grid item sx={{ width: "100%" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ color: theme.palette.primary.main }}
              onClick={(e) => {
                addNewBogoOrFreeItems(key);
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Grid>
        </Grid>
      );
    }
  };

  const displayCategorySelector = () => {
    return (
      <React.Fragment>
        <FormControl sx={{ m: 0, width: "100%", mt: 2 }}>
          <Select
            displayEmpty
            value={actionValueObject.discount_category_id}
            onChange={(e) => {
              console.log("I AM HERE");
              var label = "";
              var discount_category_items = [];
              categories.map((category) => {
                console.log("category", category);
                if (category.uuid === e.target.value) {
                  label = category.title;
                  if (category.items && category.items.length > 0) {
                    category.items.map((item) => {
                      discount_category_items.push({
                        title: item.title,
                        id: item.uuid,
                        categoryTitles: item.categoryTitles,
                        price: item.price,
                      });
                    });
                  }
                }
              });
              console.log("label", label);
              setActionValueObject({
                ...actionValueObject,
                discount_category_id: e.target.value,
                discount_category_label: label,
                discount_category_items,
              });
            }}
            input={<OutlinedInput />}
            required
            renderValue={(selected) => {
              if (
                !selected ||
                selected === undefined ||
                selected.length === 0
              ) {
                return <em>Choix de la catégorie</em>;
              }
              var label = "";
              categories.map((category) => {
                if (category.uuid === selected) {
                  label = category.title;
                }
              });
              if (label === "") {
                return actionValueObject.discount_category_label;
              }
              return label;
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choix de la catégorie</em>
            </MenuItem>
            {categories && categories.length > 0 ? (
              categories.map((category, index) => {
                return (
                  <MenuItem key={category.uuid} value={category.uuid}>
                    {category.title}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value={actionValueObject.discount_category_id}>
                {actionValueObject.discount_category_label}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <Typography
          component="div"
          variant="body2"
          align="justify"
          sx={{ m: 0, mb: 0 }}
        >
          {actionValueObject && actionValueObject.discount_category_items
            ? "La catégorie contient " +
              actionValueObject.discount_category_items.length +
              " items"
            : ""}
          <ul>
            {actionValueObject &&
            actionValueObject.discount_category_items &&
            actionValueObject.discount_category_items.length > 0
              ? actionValueObject.discount_category_items.map((item) => {
                  return <li>{item.title}</li>;
                })
              : null}
          </ul>
        </Typography>
        <FormControl sx={{ m: 0, width: "100%", mt: 2 }}>
          <Select
            displayEmpty
            value={
              actionValueObject && actionValueObject.discount_category_percent
                ? actionValueObject.discount_category_percent
                : ""
            }
            onChange={(e) => {
              setActionValueObject({
                ...actionValueObject,
                discount_category_percent: e.target.value,
              });
            }}
            input={<OutlinedInput />}
            required
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Choisissez votre réduction</em>;
              }
              var label = "test";
              console.log("discounts", discounts);
              console.log("selected", selected);
              discounts.map((discount) => {
                if (discount.id === selected.id) {
                  label = discount.label;
                }
              });
              return label; //.join(", ");
            }}
            sx={{ width: "100%" }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choisissez votre réduction</em>
            </MenuItem>
            {discounts.map((item) => (
              <MenuItem key={"discountItems_percent_" + item.id} value={item}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={0} sx={{ m: 0, p: 0 }}>
      {displayContent()}
    </Grid>
  );
}

ModalNewOrEditJetpackCampaignStepActionsDetails.propTypes = {
  actionType: PropTypes.string.isRequired,
};

// ********************* COMPONENT END ***********************

export default ModalNewOrEditJetpackCampaignStepActionsDetails;
