/*global google*/

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import classNames from "classnames";
import { CSVLink, CSVDownload } from "react-csv";
import ModalSparkLineDetailGeneric from "../radar_competitors/expansion/ModalSparkLineDetailGeneric";

/*import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  Rectangle,
  DrawingManager,
} from "react-google-maps";*/
//import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";

//IMAGES AND ICONS
//ICONS
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PublicIcon from "@mui/icons-material/Public";
import DownloadIcon from "@mui/icons-material/Download";
import SkeletonDashboardTable from "../../ui-components/Skeleton/SkeletonDashboardTable";

//MATERIAL UI COMPONENT IMPORTS
import {
  AppBar,
  Autocomplete,
  TableContainer,
  FormGroup,
  FormControlLabel,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Paper,
  Button,
  ButtonGroup,
  ListItem,
  ListItemIcon,
  Drawer,
  Card,
  Checkbox,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  ListItemText,
  Toolbar,
  TextField,
  useMediaQuery,
} from "@mui/material";
import RadarMarketSharesRow from "./RadarMarketSharesRow";

//import ModalPricingJob from "./ModalPricingJob";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  overlay: {
    filter: "blur(3px)",
    //position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.54)",
    //color: "white",
    top: "0px",
    left: "0px",
    zIndex: 1000,
  },
  noMargin: {
    marginTop: "0px!important",
    marginBottom: "0px!important",
    paddingTop: "0px!important",
    paddingBottom: "0px!important",
  },
  btnUpdate: {
    position: "fixed",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    top: "100px",
    zIndex: 1001,
  },
  hide: {
    display: "none!important",
  },
  activeGroupButton: {
    backgroundColor: theme.palette.primary.main + "!important",
    color: "white!important",
  },
  img_marker: {
    height: "150px",
  },
  img_table: {
    height: "50px",
  },
}));
//CONSTANTE
const colWidths = {
  col1: "7rem",
  col2: "20rem",
  col3: "20rem",
  col4: "20rem",
  col5: "20rem",
  col6: "20rem",
};

// ********************* COMPONENT START ***********************

function RadarMarketSharesPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [needToRefresh, setNeedToRefresh] = useState(false);
  //const [selectedRow, setSelectedRow] = useState(null);
  const [titleToDraw, setTitleToDraw] = useState("no title");
  const [xToDraw, setXToDraw] = useState([]);
  const [yToDraw, setYToDraw] = useState([]);

  const [period, setPeriod] = useState("d");
  const [dialogsOpen, setDialogsOpen] = useState({
    ModalSparkLineDetailGeneric: false,
  });
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("USE EFFECT!!");
    if (!data || data.length === 0) {
      refreshData();
    }
  }, []); //Trigger only once
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleSparklineClick = (title, x, y) => {
    console.log("title", title);
    console.log("x", x);
    console.log("y", y);
    setTitleToDraw(title);
    setXToDraw(x);
    setYToDraw(y);
    setDialogsOpen({ ...dialogsOpen, ModalSparkLineDetailGeneric: true });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  //if categoriesToRefresh is empty then we consider we should only refresh
  const refreshData = () => {
    setIsLoading(true);
    POST("/api/radar/marketshares/refresh", {}).then((response) => {
      console.log("RESPONSE:", response);
      setData(response.data);
      setIsLoading(false);
    });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <div className={classes.div}>
      <React.Fragment>
        {isLoading ? (
          <SkeletonDashboardTable />
        ) : (
          <Box
            className={classNames({
              [classes.overlay]: needToRefresh,
            })}
          >
            <Paper
              sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
              elevation={5}
              style={{ zIndex: 10 }}
            ></Paper>

            <Grid
              container
              sx={{ width: "100%", mb: "200px" }}
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
                <Grid
                  container
                  sx={{}}
                  justify="left"
                  alignItems="left"
                  justifyContent="left"
                >
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                    sx={{ ml: 2 }}
                  ></ButtonGroup>
                  <CSVLink data={data} style={{ textDecoration: "none" }}>
                    <Tooltip title="Download data as re file">
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{
                          marginLeft: "10px",
                          textDecoration: "none",
                        }}
                        startIcon={<DownloadIcon />}
                      >
                        Download as csv
                      </Button>
                    </Tooltip>
                  </CSVLink>
                </Grid>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: colWidths.col2 }}>
                        Ville
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        <Tooltip
                          title={
                            "Parts de marché de la marque dans la ville en chiffre d'affaires au moment du déjeuner. Cette donnée (plus précise) peut légèrement varier de la donnée recalculée à l'échelle du restaurant."
                          }
                        >
                          <Typography variant="body">
                            Parts de marché DÉJEUNER
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        <Tooltip
                          title={
                            "Parts de marché de la marque dans la ville en chiffre d'affaires dans l'après-midi. Cette donnée (plus précise) peut légèrement varier de la donnée recalculée à l'échelle du restaurant."
                          }
                        >
                          <Typography variant="body">
                            Parts de marché APREM
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        <Tooltip
                          title={
                            "Parts de marché de la marque dans la ville en chiffre d'affaires au moment du diner. Cette donnée (plus précise) peut légèrement varier de la donnée recalculée à l'échelle du restaurant."
                          }
                        >
                          <Typography variant="body">
                            Parts de marché DINER
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        <Tooltip
                          title={
                            "Parts de marché de la marque dans la ville en chiffre d'affaires en soirée. Cette donnée (plus précise) peut légèrement varier de la donnée recalculée à l'échelle du restaurant."
                          }
                        >
                          <Typography variant="body">
                            Parts de marché SOIRÉE
                          </Typography>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => {
                      return (
                        <RadarMarketSharesRow
                          colWidths={colWidths}
                          key={"RadarMainDashTableRow_ROW" + row.city}
                          row={row}
                          index={index}
                          handleSparklineClick={handleSparklineClick}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Box>
        )}
        {/* -------- MODAL ---------- */}
        <ModalSparkLineDetailGeneric
          title={titleToDraw}
          x={xToDraw}
          y={yToDraw}
          dialogsOpen={dialogsOpen}
          setDialogsOpen={setDialogsOpen}
        />
      </React.Fragment>
    </div>
  );
}

RadarMarketSharesPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarMarketSharesPage;
