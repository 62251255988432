import React, { Component } from "react";
import { Redirect } from "react-router-dom";
//import {GET,POST} from '../../js/HTTPRequest'
//import Error from "../../components/errors/Error"
//import {handleError} from '../../js/ErrorManager'
//import {toast} from '../../js/Toasting'
import $ from "jquery";
import _ from "lodash";
import { generateColorFromString, hexToRgb } from "../../../js/designManager";
//CONST & INTERNAL IMPORT
import "./style.css";
import { Avatar } from "@mui/material";

/** - Description:
 *  - Parents:
 *  - Props:
 *
 */
class AvatarFromInitials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
    };
  }

  // *******************************************************
  // ******************** EVENTS HANDLER *******************
  // *******************************************************

  // *******************************************************
  // ********************** CALLBACKS **********************
  // *******************************************************

  // *******************************************************
  // ******************* LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ******************* DISPLAY FUNCTIONS *****************
  // *******************************************************

  render() {    
    var color = this.props.color
      ? this.props.color
      : "#" +
        generateColorFromString(
          this.props.fullname ? this.props.fullname : "empty"
        );
    var rgb = hexToRgb(`${color}`);
    const brightness = Math.round(
      (parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) /
        1000
    );
    const textColour = brightness > 125 ? "black" : "white";
    return (
      <Avatar
        id={this.props.id}
        className={` central-text ${this.props.className}`}
        sx={{ backgroundColor: `${color}`, color: textColour }}
      >
        {this.props.initials}
      </Avatar>
    );
  }
}

export default AvatarFromInitials;
