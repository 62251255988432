import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import { isMobile } from "../../js/designManager";
import moment from "moment";
import "moment/locale/fr";
import _ from "lodash";
import { generateColorFromString, hexToRgb } from "../../js/designManager";
import { numberWithSpaces } from "../../js/numberManager";
import SkeletonJetpackChannelsPerformanceTable from "../../ui-components/Skeleton/SkeletonJetpackChannelsPerformanceTable";
import {
  isBuffaloGrill,
  isCourtepaille,
  isBunMeatBun,
  isPopeyes,
  isArlette,
} from "../../js/brandsManager";
import { CSVLink, CSVDownload } from "react-csv";
import { makeComponentUniqueId } from "../../js/otherManager";
//IMAGES AND ICONS
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HistoryIcon from "@mui/icons-material/History";
import logo_bg from "../../img/logo-bg.png";
import logo_bmb from "../../img/logo-bmb.png";
import logo_ctp from "../../img/logo-ctp.png";
import logo_pp from "../../img/logo-pp.png";
import logo_aj from "../../img/logo-aj.png";
import DownloadIcon from "@mui/icons-material/Download";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  AvatarGroup,
  Avatar,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Collapse,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
} from "@mui/material";
import WorkInProgressBanner from "../generic/WorkInProgressBanner";
//CONSTANTE

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  restaurant: {
    fontSize: "11px!important",
  },
  jetpackCampaign: {
    fontSize: "14px!important",
  },
  iconHistory: {
    fontSize: "20px!important",
    marginRight: "5px",
    paddingTop: "2px",
  },
  historyLinkContainer: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  agendaDescription: {
    fontSize: "11px!important",
  },
  date: {
    fontSize: "11px!important",
  },
}));

// ********************* COMPONENT START ***********************

function JetpackPerformanceRestaurantV2Row({
  isRestaurant,
  item,
  colWidths,
  displayStatusChangeHistory,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [
    hasChannelsRequestBeenAlreadySent,
    setHasChannelsRequestBeenAlreadySent,
  ] = React.useState(false);
  const [channels, setChannels] = React.useState(null);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************
  const generateBrandLogos = () => {
    var logos = [];
    var gotBuffalo = false;
    var gotCourtepaille = false;
    var gotBunMeatBun = false;
    var gotPopepyes = false;
    var gotArlette = false;

    if (item.restaurantName.toLowerCase().includes("buffalo")) {
      gotBuffalo = true;
    }
    if (item.restaurantName.toLowerCase().includes("bun meat bun")) {
      gotBunMeatBun = true;
    }

    if (gotBuffalo) {
      logos.push({ alt: "Buffalo Grill", src: logo_bg });
    }
    if (gotCourtepaille) {
      logos.push({ src: logo_ctp, alt: "Courtepaille" });
    }
    if (gotBunMeatBun) {
      logos.push({ src: logo_bmb, alt: "Bun Meat Bun" });
    }
    if (gotPopepyes) {
      logos.push({ src: logo_pp, alt: "Popeyes" });
    }
    if (gotArlette) {
      logos.push({ src: logo_aj, alt: "Arlette et Jean" });
    }
    return logos;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  var logos = generateBrandLogos();

  const displayAudience = (audience) => {
    switch (audience) {
      case "ALL_CUSTOMERS":
        var chipColor = "#" + generateColorFromString(audience);
        var randomId = makeComponentUniqueId();
        return (
          <Chip
            key={randomId}
            size="small"
            sx={{
              margin: "2px",
              backgroundColor: alpha(chipColor, 0.3) + "!important",
            }}
            label={"Tous clients"}
          />
        );
      case "NEW_CUSTOMERS":
        var chipColor = "#" + generateColorFromString(audience);
        var randomId = makeComponentUniqueId();
        return (
          <Chip
            key={randomId}
            size="small"
            sx={{
              margin: "2px",
              backgroundColor: alpha(chipColor, 0.3) + "!important",
            }}
            label={"Nouveaux clients"}
          />
        );
      case "FIRST_TIME_CUSTOMER":
        var chipColor = "#" + generateColorFromString(audience);
        var randomId = makeComponentUniqueId();
        return (
          <Chip
            key={randomId}
            size="small"
            sx={{
              margin: "2px",
              backgroundColor: alpha(chipColor, 0.3) + "!important",
            }}
            label={"Nouveaux clients plateforme"}
          />
        );
      default:
        break;
    }
  };

  const displayState = (state) => {
    var new_name = state;
    var chipColor = "#" + generateColorFromString(new_name);
    switch (state) {
      case "ACTIVE":
        chipColor = "#4CAF50";
        new_name = "Active";
        break;
      case "NOT_STARTED":
        chipColor = "#FFEB3B";
        new_name = "Programmée";
        break;
      case "COMPLETED":
        chipColor = "#9E9E9E";
        new_name = "Terminée";
        break;
      case "ENDED":
        chipColor = "#F44336";
        new_name = "Interrompue";
        break;
      default:
        break;
    }

    var randomId = makeComponentUniqueId();
    return (
      <Chip
        key={randomId}
        size="small"
        sx={{
          margin: "2px",
          backgroundColor: alpha(chipColor, 0.3) + "!important",
        }}
        label={new_name}
      />
    );
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell style={{ width: colWidths.col1 }}>
          {logos && logos.length > 1 ? (
            <AvatarGroup
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              {logos.map((logo) => {
                var randomId = makeComponentUniqueId();
                return (
                  <Avatar
                    alt={logo?.alt}
                    key={randomId}
                    src={logo?.src}
                    sx={{
                      width: isMobile() ? 20 : "1.5rem",
                      height: isMobile() ? 20 : "1.5rem",
                    }}
                  />
                );
              })}
            </AvatarGroup>
          ) : (
            <Avatar
              alt={logos[0]?.alt}
              key={makeComponentUniqueId()}
              src={logos[0]?.src}
              sx={{
                width: isMobile() ? 20 : "1.5rem",
                height: isMobile() ? 20 : "1.5rem",
              }}
            />
          )}
        </TableCell>
        <TableCell
          style={{ width: colWidths.col2 }}
          align="left"
          className={
            isRestaurant ? classes.restaurant : classes.jetpackCampaign
          }
        >
          <Typography
            className={
              isRestaurant ? classes.restaurant : classes.jetpackCampaign
            }
          >
            {item.restaurantName}
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col2 }}
          align="left"
          className={classes.jetpackCampaign}
        >
          <Typography className={classes.jetpackCampaign}>
            {item.jetpackCampaignName &&
            item.jetpackCampaignName.indexOf("GARBAGE") < 0
              ? item.jetpackCampaignName
              : item.name}{" "}
            {item.promoType}
          </Typography>
        </TableCell>
        <React.Fragment></React.Fragment>
        <TableCell
          style={{ width: colWidths.col4 }}
          align="center"
          className={classes.jetpackCampaign}
        >
          <Typography className={classes.jetpackCampaign}>
            {displayState(item.state)}
          </Typography>
        </TableCell>

        <TableCell
          style={{ width: colWidths.col4 }}
          align="center"
          className={classes.jetpackCampaign}
        >
          <Typography className={classes.jetpackCampaign}>
            {`Du ${moment(item.start).format("LL")} au ${moment(
              item.end
            ).format("LL")}`}
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col4 }}
          align="center"
          className={classes.jetpackCampaign}
        >
          <Typography className={classes.jetpackCampaign}>
            {displayAudience(item.audience)}
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col5 }}
          align="center"
          className={classes.jetpackCampaign}
        >
          <Typography className={classes.jetpackCampaign}>
            {item.budgetUsed
              ? numberWithSpaces(Math.round(item.budgetUsed * 10) / 10)
              : 0}
            €
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col6 }}
          align="center"
          className={classes.jetpackCampaign}
        >
          <Typography className={classes.jetpackCampaign}>
            {item.newCustomerCount
              ? numberWithSpaces(item.newCustomerCount)
              : 0}
          </Typography>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col7 }}
          align="center"
          className={classes.jetpackCampaign}
        >
          <Typography className={classes.jetpackCampaign}>
            {item.sales
              ? numberWithSpaces(Math.round(item.sales * 10) / 10)
              : 0}
            €
          </Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

JetpackPerformanceRestaurantV2Row.propTypes = {};

// ********************* COMPONENT END ***********************

export default JetpackPerformanceRestaurantV2Row;
