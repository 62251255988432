import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  Paper,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  TableContainer,
  TableBody,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ModalPricingJob from "./ModalPricingJob";
//CONST
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "10rem",
  col5: "10rem",
  col6: "20rem",
};

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function RadarScrappingsPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [jobs, setJobs] = useState([]);
  const [ownedRestaurants, setOwnedRestaurants] = useState([]);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside RadarScrappingPage");
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    GET("/api/pages/radarscrappingspage/init")
      .then((response) => {
        setJobs(response.data.jobs);
        setOwnedRestaurants(response.data.ownedRestaurants);
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayJobs = () => {
    if (!jobs || jobs.length === 0) {
      return <p>Aucune analyse créée</p>;
    }
    return (
      <div>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: colWidths.col1 }}>Analyse</TableCell>
                <TableCell style={{ width: colWidths.col2 }} align="center">
                  Progression
                </TableCell>
                <TableCell
                  style={{ width: colWidths.col3 }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((row) => {
                return (
                  <TableRow id={row.id}>
                    <TableCell style={{ width: colWidths.col1 }}>
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: colWidths.col2 }} align="center">
                      {row.progress ? row.progress + "%" : "0%"}
                    </TableCell>
                    <TableCell
                      style={{ width: colWidths.col3, color: "red" }}
                      align="center"
                    ></TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <div className={classes.div}>
      <Button
        variant="contained"
        onClick={() => {
          setDialogsOpen({ ...dialogsOpen, ModalPricingJob: true });
        }}
        startIcon={<AddCircleOutlineIcon />}
      >
        Créer analyse
      </Button>
      <Divider sx={{ mt: 2 }}>Analyses</Divider>
      {displayJobs()}
      <ModalPricingJob initPage={init} ownedRestaurants={ownedRestaurants} />
    </div>
  );
}

RadarScrappingsPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarScrappingsPage;
