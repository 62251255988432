import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
//IMAGES AND ICONS
import DeleteIcon from "@mui/icons-material/Delete";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  OutlinedInput,
  FormControl,
  MenuItem,
  Card,
  CardContent,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  CardHeader,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// ********************* COMPONENT START ***********************

function CardBogoItemSelection({
  items,
  item,
  setItem,
  deleteItem,
  index,
  keyField,
  discounts,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [discount, setDiscount] = useState(20); //used only by keyFied = "discountItems"
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    console.log("Frontend Info - I am inside CardBogoItemSelection");
  }, []);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const displayParameters = () => {
    if (
      item &&
      item.price &&
      item.categoryTitles &&
      item.categoryTitles.length
    ) {
      return (
        <Grid container sx={{ width: "100%" }}>
          <Grid item sx={{ width: "100%" }}>
            <Typography variant="body2" color="text.secondary" component="div">
              Prix : {item.price / 100}€
            </Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Typography variant="body2" color="text.secondary" component="div">
              Catégories : {item.categoryTitles.map((category) => category)}
            </Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Typography variant="body2" color="text.secondary" component="div">
              Menu : {item.menuChannelName}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const displayAdditionnalInputs = () => {
    if (keyField === "discountItems" && item && item.title) {
      return (
        <FormControl sx={{ m: 2, width: "100%" }}>
          <Select
            displayEmpty
            value={item && item.discount ? item.discount : ""}
            onChange={(e) => {
              var newItem = JSON.parse(JSON.stringify(item));
              newItem.discount = e.target.value.id;
              setItem(newItem, index, keyField);
            }}
            input={<OutlinedInput />}
            required
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>Choisissez votre réduction</em>;
              }
              var label = "";
              discounts.map((discount) => {
                if (discount.id === selected) {
                  label = discount.label;
                }
              });
              return label; //.join(", ");
            }}
            sx={{ width: "90%" }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choisissez votre réduction</em>
            </MenuItem>
            {discounts.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return null;
    }
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Card sx={{ width: "100%", mb: 2 }}>
      <CardHeader
        action={
          <IconButton
            aria-label="delete"
            onClick={(e) => {
              deleteItem(index, keyField);
            }}
          >
            <DeleteIcon />
          </IconButton>
        }
        //title="Plat bogo"
        subheader={`Plat #${index + 1}`}
      />

      <CardContent sx={{ width: "100%", flex: "1 0 auto" }}>
        <FormControl sx={{ m: 2, width: "100%", mt: 0 }}>
          <Select
            displayEmpty
            value={item}
            sx={{ width: "90%" }}
            onChange={(e) => {
              setItem(e.target.value, index, keyField);
            }}
            input={<OutlinedInput />}
            required
            renderValue={(selected) => {
              if (
                !selected ||
                selected === undefined ||
                selected.length === 0 ||
                !selected.uuid
              ) {
                return (
                  <em>
                    Choisissez le plat à mettre en{" "}
                    {keyField === "bogoItems"
                      ? "bogo"
                      : keyField === "freeItems"
                      ? "cadeau"
                      : keyField === "discountItems"
                      ? "réduction"
                      : ""}
                  </em>
                );
              }
              return selected.title;
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem disabled value="">
              <em>Choisissez votre plat</em>
            </MenuItem>
            {items.map((item, index) => {
              return (
                <MenuItem key={item.uuid} value={item}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {displayParameters()}
        {displayAdditionnalInputs()}
      </CardContent>
    </Card>
  );
}

CardBogoItemSelection.propTypes = {};

// ********************* COMPONENT END ***********************

export default CardBogoItemSelection;
