import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import StyleIcon from "@mui/icons-material/Style";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Tooltip,
  TextField,
  Select,
  Typography,
} from "@mui/material";
import AutocompleteWithAddForm from "../generic/AutocompleteWithAddForm";
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function ModalApplyChannelsAccess({ initPage, userItem, channels }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  //const theme = useTheme();
  //const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  //const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");
  const [selected, setSelected] = useState([]);
  //const [access, setAccess] = useState("");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    var newSelected = [];
    if (
      userItem &&
      userItem.channelsAssociations &&
      userItem.channelsAssociations.length > 0
    ) {
      userItem.channelsAssociations.map((association) => {
        newSelected.push(association.Channel);
      });
    }
    console.log("newSelected", newSelected);
    setSelected(newSelected);
  }, [userItem]);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setDialogsOpen({ ...dialogsOpen, ModalApplyChannelsAccess: false });
  };

  const handleSubmit = (e) => {
    console.log("SELECTED", selected);
    var data = createAssociationObjects();
    var constant = "userId";
    var constant_value = userItem.id;
    setIsLoading(true);
    POST("/api/users/channelsassociations/create", {
      constant,
      constant_value,
      data,
    })
      .then((response) => {
        setIsLoading(false);
        initPage();
        setSelected([]);
        setDialogsOpen({ ...dialogsOpen, ModalApplyChannelsAccess: false });
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant l'association des restaurants avec l'utilisateur",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const createAssociationObjects = () => {
    var toReturn = [];
    selected.map((selectedItem) => {
      toReturn.push({
        channelId: selectedItem.id,
        userId: userItem.id,
        name: userItem.name + " - " + selectedItem.name,
        access: "reader",
      });
    });
    return toReturn;
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Dialog
      open={dialogsOpen.ModalApplyChannelsAccess}
      onClose={handleClose}
      style={{ maxHeight: "70vh" }}
    >
      <DialogTitle>
        Ajoutez des restaurants à {userItem ? userItem.name : ""}
      </DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            Ajouter les restaurants auquel l'utilisateur aura accès
          </DialogContentText>
          <FormControl sx={{ m: 0, width: "100%", mt: 3 }}>
            <Autocomplete
              multiple={true}
              //className={classes.searchBarStyle}
              id={"autocomplete_channels"}
              value={selected}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={
                      <Typography
                        style={{
                          whiteSpace: "normal",
                          padding: "4px",
                          fontSize: 11,
                        }}
                      >
                        {option.name + ` [${option.Platform.name}]`}
                      </Typography>
                    }
                    {...getTagProps({ index })}
                    style={{ height: "100%", borderRadius: "5px" }}
                    size="small"
                  />
                ))
              }
              onChange={(e, newValues) => {
                setSelected(newValues);
              }}
              options={channels}
              fullWidth={true}
              sx={{ width: "100%" }}
              //disableClearable={true}
              getOptionLabel={(option) => {
                return option.name + ` [${option.Platform.name}]`;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} style={{ fontSize: 11 }}>
                    {option.name + ` [${option.Platform.name}]`}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField {...params} label={"Restaurants"} size="small" />
              )}
            />
            {/**TO DO - IN THE FUTURE - ADD ACCESS TYPE VARIABLE
          <Select
              displayEmpty
              value={status}
              onChange={handleChangeStatus}
              input={<OutlinedInput />}
              required
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Choisissez le statut de l'utilisateur</em>;
                }
                return selected.name;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {statusList.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
           */}
          </FormControl>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              startIcon={<StyleIcon />}
            >
              Ajouter
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalApplyChannelsAccess.propTypes = {};

// ********************* COMPONENT END ***********************

export default ModalApplyChannelsAccess;
