import axios from "axios";

export const GET = (address, withCredentials = true, timeout = 30000) => {
  console.log(`GET ${address}`);
  return axios
    .get(address, { withCredentials, timeout })
    .then(function (response) {
      //We hook the promise to print answer on the console
      // if(process.env!=="production"){
      //     console.log(`GET ${address}: `,response.data);
      // }
      console.log(`RESULT GET ${address}: `, response.data);
      return Promise.resolve(response);
    });
};

export const DELETE = (address, withCredentials = true, timeout = 30000) => {
  return axios
    .delete(address, { withCredentials, timeout })
    .then(function (response) {
      //We hook the promise to print answer on the console
      // if(process.env!=="production"){
      //     console.log(`DELETE ${address}: `,response.data);
      // }
      return Promise.resolve(response);
    });
};

export const POST = (
  address,
  body,
  withCredentials = true,
  responseType = null,
  timeout = 30000
) => {
  console.log(`POST ${address}: `, body);
  var headers = {
    "Content-Type": "application/json",
  };
  return axios
    .post(address, body, { headers, withCredentials, responseType, timeout })
    .then(function (response) {
      //We hook the promise to print answer on the console
      // if(process.env!=="production"){
      console.log(`RESULT POST ${address}: `, response.data);
      // }
      return Promise.resolve(response);
    });
};

export const POSTLONG = (
  address,
  body,
  withCredentials = true,
  responseType = null,
  timeout = 120000
) => {
  console.log(`POSTLONG ${address}: `, body);
  var headers = {
    "Content-Type": "application/json",
  };
  return axios({
    method: "post",
    data: body,
    url: address,
    headers: headers,
    withCredentials: withCredentials,
    responseType: responseType,
    timeout: timeout,
  }).then(function (response) {
    //We hook the promise to print answer on the console
    // if(process.env!=="production"){
    console.log(`RESULT POSTLONG ${address}: `, response.data);
    // }
    return Promise.resolve(response);
  });
};

export const PATCH = (
  address,
  body,
  withCredentials = true,
  timeout = 30000
) => {
  var headers = {
    "Content-Type": "application/json",
  };
  return axios
    .patch(address, body, { headers, withCredentials, timeout })
    .then(function (response) {
      //We hook the promise to print answer on the console
      if (process.env !== "production") {
        console.log(`PATCH ${address}: `, response.data);
      }
      return Promise.resolve(response);
    });
};

export const PUT = (address, withCredentials = true, timeout = 30000) => {
  return axios
    .put(address, { withCredentials, timeout })
    .then(function (response) {
      //We hook the promise to print answer on the console
      // if(process.env!=="production"){
      //     console.log(`PUT ${address}: `,response.data);
      // }
      return Promise.resolve(response);
    });
};

export const PUT_FILE = (address, file, timeout = 30000) => {
  //formData.append("locationId", {locationId});
  console.log(file);
  return axios
    .put(address, file, {
      headers: {
        "Content-Type": file.type,
      },
      timeout,
    })
    .then(function (response) {
      //We hook the promise to print answer on the console
      // if(process.env!=="production"){
      console.log(`RESULT PUT FILE : ${response.data}`);
      // }
      return Promise.resolve(response);
    })
    .catch((err) => {
      console.log(err.data);
      return Promise.reject(err);
    });
};
