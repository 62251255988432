import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AutocompleteWithAddForm from "../generic/AutocompleteWithAddForm";
import ClassicChart from "../generic/ClassicChart";

//CONST

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ModalSparkLineDetail({ title, row }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  useEffect(() => {
    initModal();
  }, []);

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setDialogsOpen({ ...dialogsOpen, ModalSparkLineDetail: false });
  };

  const handleSubmit = (e) => {};

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const initModal = () => {
    setError("");
    setIsLoading(false);
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  
  return (
    <Dialog open={dialogsOpen.ModalSparkLineDetail} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      {row && row.label ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <ClassicChart
                title={
                  row.label +
                  (row.suffix && row.suffix !== "" ? ` (${row.suffix})` : "")
                }
                mainValue=""
                isLoading={isLoading}
                xAxis={[
                  "lcp-6",
                  "lcp-5",
                  "lcp-4",
                  "lcp-3",
                  "lcp-2",
                  "lcp-1",
                  "lcp",
                  "lp",
                ]}
                series={[
                  {
                    name: "sparkline",
                    data: [
                      row["lcp-6"],
                      row["lcp-5"],
                      row["lcp-4"],
                      row["lcp-3"],
                      row["lcp-2"],
                      row["lcp-1"],
                      row["lcp"],
                      row["lp"],
                    ],
                    type: "line",
                  },
                ]}
                status={[]}
              />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <div></div>
      )}

      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Fermer</Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalSparkLineDetail.propTypes = {};

export default ModalSparkLineDetail;
