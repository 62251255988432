import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
//IMAGES AND ICONS
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { numberWithSpaces } from "../../js/numberManager";
//MATERIAL UI COMPONENT IMPORTS
import {
  TableRow,
  TableCell,
  Collapse,
  TableHead,
  Chip,
  TableBody,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SkeletonDashboardRow from "../../ui-components/Skeleton/SkeletonDashboardRow";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  variationDiv: {
    fontSize: 12,
    backgroundColor: theme.palette.background.default,
    width: "fit-content",
    padding: "2px 7px",
    minWidth: "50px",
    textAlign: "center",
  },
}));

// ********************* COMPONENT START ***********************

function RadarMainDashTableRow({
  data,
  index,
  collapseAllowed,
  colWidths,
  handleSparklineClick,
  refreshData,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  var row = data[index];
  var lcpVariation =
    parseFloat(row["lcp-1"]) !== 0
      ? parseInt((((row["lcp"] - row["lcp-1"]) * 100) / row["lcp-1"]) * 10) /
          10 +
        "%"
      : "%";
  var ptdVariation =
    parseFloat(row["ptd-1"]) !== 0
      ? parseInt((((row["ptd"] - row["ptd-1"]) * 100) / row["ptd-1"]) * 10) /
          10 +
        "%"
      : "%";
  var lpVariation =
    parseFloat(row["lp-1"]) !== 0
      ? parseInt((((row["lp"] - row["lp-1"]) * 100) / row["lp-1"]) * 10) / 10 +
        "%"
      : "%";
  if (row && row.lazy_loading && row.lazy_loading === "TRUE") {
    return (
      <TableRow>
        <TableCell style={{ padding: 0, margin: 0 }} colSpan={6}>
          <SkeletonDashboardRow />
        </TableCell>
      </TableRow>
    );
  }
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            fontSize: collapseAllowed ? "14px" : "11px!important",
          },
        }}
      >
        <TableCell style={{ width: colWidths.col1 }}>
          {collapseAllowed ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                refreshData([row.category]);
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <div style={{ width: "55px" }} />
          )}
        </TableCell>

        <TableCell style={{ width: colWidths.col2 }}>{row.label}</TableCell>
        <TableCell style={{ width: colWidths.col3 }} align="center">
          <Grid
            container
            justify="center"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item align="right" xs={6}>
              {numberWithSpaces(row.lcp)}
              {row.suffix}
            </Grid>
            <Grid
              item
              align="left"
              xs={6}
              style={{
                color:
                  parseFloat(row["lcp-1"]) !== 0
                    ? parseFloat(row["lcp"]) >= parseFloat(row["lcp-1"])
                      ? row.happy_when_increase === "TRUE"
                        ? "green"
                        : "red"
                      : row.happy_when_increase === "TRUE"
                      ? "red"
                      : "green"
                    : "grey",
              }}
            >
              <div className={classes.variationDiv}>
                {parseFloat(row["lcp-1"]) !== 0 &&
                parseFloat(row["lcp"]) >= parseFloat(row["lcp-1"])
                  ? "+"
                  : ""}
                {lcpVariation}
              </div>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell style={{ width: colWidths.col4 }} align="center">
          <Grid
            container
            justify="center"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item align="right" xs={6}>
              {numberWithSpaces(row.ptd)}
              {row.suffix}
            </Grid>
            <Grid
              item
              align="left"
              xs={6}
              style={{
                color:
                  parseFloat(row["ptd-1"]) !== 0
                    ? parseFloat(row["ptd"]) >= parseFloat(row["ptd-1"])
                      ? row.happy_when_increase === "TRUE"
                        ? "green"
                        : "red"
                      : row.happy_when_increase === "TRUE"
                      ? "red"
                      : "green"
                    : "grey",
              }}
            >
              <div className={classes.variationDiv}>
                {parseFloat(row["ptd-1"]) !== 0 &&
                parseFloat(row["ptd"]) >= parseFloat(row["ptd-1"])
                  ? "+"
                  : ""}
                {ptdVariation}
              </div>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell
          style={{ width: colWidths.col5, cursor: "pointer" }}
          align="center"
          onClick={() => {
            handleSparklineClick(row);
          }}
        >
          <Sparklines
            data={[
              row["lcp-6"],
              row["lcp-5"],
              row["lcp-4"],
              row["lcp-3"],
              row["lcp-2"],
              row["lcp-1"],
              row["lcp"],
              row["lp"],
            ]}
            width={100}
            height={20}
          >
            <SparklinesLine
              color={theme.palette.primary.main}
              style={{ fill: "none" }}
            />
            <SparklinesSpots
              spotColors={{
                "-1": row.happy_when_increase === "TRUE" ? "red" : "#008000",
                0: "black",
                1: row.happy_when_increase === "TRUE" ? "#008000" : "red",
              }}
            />
          </Sparklines>
        </TableCell>
        <TableCell style={{ width: colWidths.col6 }} align="center">
          <Grid
            container
            justify="center"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Grid item align="right" xs={6}>
              {numberWithSpaces(row.lp)}
              {row.suffix}
            </Grid>
            <Grid
              item
              align="left"
              xs={6}
              style={{
                color:
                  parseFloat(row["lp-1"]) !== 0
                    ? parseFloat(row["lp"]) >= parseFloat(row["lp-1"])
                      ? row.happy_when_increase === "TRUE"
                        ? "green"
                        : "red"
                      : row.happy_when_increase === "TRUE"
                      ? "red"
                      : "green"
                    : "grey",
              }}
            >
              <div className={classes.variationDiv}>
                {parseFloat(row["lp-1"]) !== 0 &&
                parseFloat(row["lp"]) >= parseFloat(row["lp-1"])
                  ? "+"
                  : ""}
                {lpVariation}
              </div>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, margin: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            style={{ padding: 0, margin: 0 }}
          >
            {collapseAllowed ? (
              <Table aria-label="subrows" style={{ padding: 0, margin: 0 }}>
                <TableHead></TableHead>
                <TableBody style={{ padding: 0, margin: 0 }}>
                  {data.map((subRow, subIndex) => {
                    if (
                      subRow.category === row.category &&
                      subRow.order !== 0
                    ) {
                      return (
                        <RadarMainDashTableRow
                          colWidths={colWidths}
                          key={"RadarMainDashTableRow_SUBROW_" + subRow.kpi}
                          index={subIndex}
                          data={data}
                          collapseAllowed={false}
                          handleSparklineClick={handleSparklineClick}
                          refreshData={refreshData}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </TableBody>
              </Table>
            ) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

RadarMainDashTableRow.propTypes = {};

// ********************* COMPONENT END ***********************
// ********************* SUB ROW COMPONENT *******************

export default RadarMainDashTableRow;
