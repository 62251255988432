import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ModalNewOrEditMacroCampaign from "./ModalNewOrEditMacroCampaign";
import CardMacroCampaign from "./CardMacroCampaign";
import ModalContinueOrCancel from "../generic/ModalContinueOrCancel";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function MagnetMacroCampaignsPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [macroCampaigns, setMacroCampaigns] = useState([]);
  const [locations, setLocations] = useState([]);
  const [brands, setBrands] = useState([]);
  const [others, setOthers] = useState([]);
  const [selectedMacroCampaign, setSelectedMacroCampaign] = useState({});
  const [editMode, setEditMode] = useState(false);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside MagnetMacroCampaignsPage");
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const editMacroCampaign = (macroCampaign) => {
    setSelectedMacroCampaign(macroCampaign);
    setEditMode(true);
    setDialogsOpen({ ...dialogsOpen, ModalNewOrEditMacroCampaign: true });
  };

  const toggleActiveMacroCampaign = (macroCampaign) => {
    var data = {
      id: macroCampaign.id,
      active: !macroCampaign.active,
    };
    POST("/api/macrocampaigns/updateactive", data)
      .then((response) => {
        init();
      })
      .catch((err) => {
        init();
        displayNotification(
          "error",
          "Erreur durant la modification de la campagne",
          "top",
          "center"
        );
      });
  };

  const deleteMacroCampaignUnConfirmed = (macroCampaign) => {
    setSelectedMacroCampaign(macroCampaign);
    setDialogsOpen({ ...dialogsOpen, ModalDeleteMacroCampaign: true });
  };

  const deleteMacroCampaignConfirmed = () => {
    var data = {
      id: selectedMacroCampaign.id,
    };
    POST("/api/macrocampaigns/delete", data)
      .then((response) => {
        init();
      })
      .catch((err) => {
        init();
        displayNotification(
          "error",
          "Erreur durant la modification de la campagne",
          "top",
          "center"
        );
      });
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    GET("/api/pages/magnetmacrocampaignspage/init")
      .then((response) => {
        setMacroCampaigns(response.data.macroCampaigns);
        setLocations(response.data.locations);
        setBrands(response.data.brands);
        setOthers(response.data.others);
      })
      .catch((err) => {
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      <Button
        variant="contained"
        onClick={() => {
          setEditMode(false);
          setDialogsOpen({ ...dialogsOpen, ModalNewOrEditMacroCampaign: true });
        }}
        startIcon={<AddCircleOutlineIcon />}
      >
        Créer
      </Button>
      <Grid container spacing={2} mt={1}>
        {macroCampaigns.map((macroCampaign) => {
          return (
            <Grid key={macroCampaign.id} item xs={12}>
              <CardMacroCampaign
                macroCampaign={macroCampaign}
                initPage={init}
                editMacroCampaign={editMacroCampaign}
                toggleActiveMacroCampaign={toggleActiveMacroCampaign}
                deleteMacroCampaign={deleteMacroCampaignUnConfirmed}
              />
              ;
            </Grid>
          );
        })}
      </Grid>
      <ModalNewOrEditMacroCampaign
        brands={brands}
        locations={locations}
        others={others}
        initPage={init}
        editMode={editMode}
        editInitValues={selectedMacroCampaign}
      />
      <ModalContinueOrCancel
        title="Suppression de la campagne"
        content="Souhaitez-vous réellement supprimer cette campagne ? Vous perdrez toutes les informations attachées à cette dernière. Si vous souhaitez garder l'historique de réponse, préférez une désactivation."
        modalId="ModalDeleteMacroCampaign"
        handleCancel={() => {}}
        handleContinue={() => {
          deleteMacroCampaignConfirmed();
        }}
      />
    </div>
  );
}

MagnetMacroCampaignsPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default MagnetMacroCampaignsPage;
