import React, { useEffect } from "react";
import PropTypes from "prop-types";
//import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
//import { GET, POST } from "../../js/HTTPRequest";
//INTERNAL COMPONENTS
import Notification from "./Notification";
//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

function NotificationsContainer({ notifications }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  //const { user, displayNotification } = useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  const displayNotificationItems = () => {
    return notifications.map((Item, Index) => {
      return (
        <Notification
          key={Item.id}
          vertical={Item.vertical}
          horizontal={Item.horizontal}
          type={Item.type}
          message={Item.message}
        />
      );
    });
  };

  return <div className={classes.div}>{displayNotificationItems()}</div>;
}

NotificationsContainer.propTypes = {
  notifications: PropTypes.array.isRequired,
};

export default NotificationsContainer;
