import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import classNames from "classnames";
import { UserContext } from "../../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
//import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import MoreTimeIcon from "@mui/icons-material/MoreTime";
//MATERIAL UI COMPONENT IMPORTS
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
//CONST & INTERNAL IMPORT
import "./style.css";
import AvatarFromInitials from "./AvatarFromInitials";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

//This component is a modal allowing creation (or modification ?) of dahsboard integration
function ModalSchedulerAddTimeStampFunc({ items, handleSubmit, cancel }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen } =
    useContext(UserContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");

  const [selected, setSelected] = useState([]);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    if (items && items.length === 1) {
      var newSelected = [items[0].id];
      setSelected(newSelected);
    }
  }, [items]);

  // *******************************************************
  // ******************** EVENTS HANDLER *******************
  // *******************************************************

  const localHandleSubmit = (e) => {
    if (items && items.length > 0) {
      var attached = [];
      items.map((Item) => {
        if (selected.indexOf(Item.id) >= 0) {
          attached.push(Item);
        }
        return null;
      });
      setSelected([]);
      handleClose();
      return handleSubmit(attached);
    } else {
      setSelected([]);
      handleClose();
      setError("");
      setIsLoading(true);
      return handleSubmit([]);
    }
  };

  const handleErase = (e) => {
    handleClose();
    setSelected([]);
    return handleSubmit([]);
  };

  const onClickItem = (e) => {
    if (items && items.length == 1) {
      return;
    }
    var id = e.target.id;
    var newSelected = selected;
    var index = newSelected.indexOf(id);
    if (index >= 0) {
      newSelected.splice(index, 1);
    } else {
      newSelected.push(id);
    }
    setSelected(newSelected);
  };

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setDialogsOpen({ ...dialogsOpen, ModalSchedulerAddTimeStampFunc: false });
  };

  const handleCancel = () => {
    cancel();
    handleClose();
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  // *******************************************************
  // ******************* DISPLAY FUNCTIONS *****************
  // *******************************************************

  const displayImage = (Item) => {
    if (Item.pictureURL && Item.pictureURL !== "") {
      return (
        <img
          id={Item.id}
          className="img-round-small"
          src={Item.pictureURL}
          alt={Item.name}
        ></img>
      );
    } else {
      return (
        <AvatarFromInitials
          color={Item.color}
          id={Item.id}
          initials={Item.shortName}
          fullname={Item.name}
        />
      );
    }
  };
  const displayItems = () => {
    if (!items || items.length == 0) {
      return <div></div>;
    }

    return items.map((Item) => {
      return (
        <Card
          className={classNames(classes.card, {
            [classes.activeCard]: selected.indexOf(Item.id) >= 0,
          })}
          sx={{ display: "flex", width: "100%", p: 2 }}
          id={Item.id}
          key={Item.id}
          onClick={onClickItem}
        >
          {displayImage(Item)}
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              id={Item.id}
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {Item.name}
            </Grid>
          </Grid>
          {Item.team ? (
            <span id={Item.id} className={`small`}>
              Équipe : {Item.team}
            </span>
          ) : (
            <div></div>
          )}
          {Item.address ? (
            <span id={Item.id} className={`small`}>
              {Item.address}
            </span>
          ) : (
            <div></div>
          )}
        </Card>
      );
    });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Dialog
      open={dialogsOpen.ModalSchedulerAddTimeStampFunc}
      onClose={handleClose}
    >
      <DialogTitle>Ajouter un créneau</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>{displayItems()}</DialogContent>
      )}

      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleCancel}>Annuler</Button>
            <Button onClick={handleErase} variant="outlined">
              Effacer
            </Button>
            <Button
              onClick={localHandleSubmit}
              variant="contained"
              color="primary"
              startIcon={<MoreTimeIcon />}
            >
              Ajouter
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalSchedulerAddTimeStampFunc.propTypes = {};

export default ModalSchedulerAddTimeStampFunc;
