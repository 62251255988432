export const isBuffaloGrill = (channelName) => {
  return channelName.toLowerCase().indexOf("buffalo") >= 0;
};

export const isCourtepaille = (channelName) => {
  return channelName.toLowerCase().indexOf("courtepaille") >= 0;
};

export const isPopeyes = (channelName) => {
  return channelName.toLowerCase().indexOf("popeye") >= 0;
};

export const isBunMeatBun = (channelName) => {
  return channelName.toLowerCase().indexOf("bun meat bun") >= 0;
};

export const isArlette = (channelName) => {
  var toReturn = false;
  if (
    channelName.toLowerCase().indexOf("arlette et jean") >= 0 ||
    channelName.toLowerCase().indexOf("arlette&jean") >= 0 ||
    channelName.toLowerCase().indexOf("arlette & jean") >= 0
  ) {
    toReturn = true;
  }
  return toReturn;
};
