import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
//IMAGES AND ICONS
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { numberWithSpaces } from "../../../js/numberManager";

//MATERIAL UI COMPONENT IMPORTS
import {
  TableRow,
  TableCell,
  Collapse,
  TableHead,
  Chip,
  TableBody,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SkeletonDashboardRow from "../../../ui-components/Skeleton/SkeletonDashboardRow";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  variationDiv: {
    fontSize: 12,
    backgroundColor: theme.palette.background.default,
    width: "fit-content",
    padding: "2px 7px",
    minWidth: "50px",
    textAlign: "center",
  },
  img_marker: {
    height: "150px",
  },
  img_table: {
    height: "50px",
  },
}));

//CONSTANTE
const colWidths_subrow = {
  col1: "7rem",
  col2: "20rem",
  col3: "20rem",
  col4: "20rem",
  col5: "20rem",
  col6: "20rem",
};

// ********************* COMPONENT START ***********************

function RadarCompetitorsExpansionRow({
  row,
  colWidths,
  collapseAllowed,
  handleSparklineClick,
  period,
  onClick,
  uncollapsedRow,
  setUncollapsedRow,
  setActiveRestaurants,
  handleActiveMarker,
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {}, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const calculateVariation = (type) => {
    var currentValue = row[type];
    var previousValue = row["data_" + type + "_" + period][0];
    if (previousValue === 0) {
      return "n/a";
    }
    return parseInt(
      (100 * parseFloat(currentValue - previousValue)) / previousValue
    );
  };

  const displayCell = (column, fixed, suffix, maxValue = null) => {
    var variation = calculateVariation(column);
    return (
      <TableCell style={{ width: colWidths.col3 }} align="center">
        <Grid
          container
          justify="center"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item align="right" xs={3}>
            {isNaN(row[column])
              ? row[column]
              : (maxValue && maxValue <= parseFloat(row[column]).toFixed(fixed)
                  ? ">"
                  : "") +
                parseFloat(row[column]).toFixed(fixed) +
                suffix}
          </Grid>
          <Grid
            item
            align="left"
            xs={3}
            style={{
              color: variation === 0 ? "grey" : variation > 0 ? "green" : "red",
            }}
          >
            <div className={classes.variationDiv}>
              {variation > 0 ? "+" : ""}
              {variation}
            </div>
          </Grid>
          <Grid
            item
            align="center"
            xs={6}
            onClick={() => {
              handleSparklineClick(
                `${column} evolution over the last 7 ${period}`,
                row["x_" + period],
                row[`data_${column}_${period}`]
              );
            }}
          >
            <Sparklines
              data={row[`data_${column}_${period}`]}
              width={100}
              height={20}
            >
              <SparklinesLine
                color={theme.palette.primary.main}
                style={{ fill: "none" }}
              />
              <SparklinesSpots
                spotColors={{
                  "-1": "red",
                  0: "black",
                  1: "#008000",
                }}
              />
            </Sparklines>
          </Grid>
        </Grid>
      </TableCell>
    );
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  console.log("uncollapsedRow", uncollapsedRow);
  var open = row.brand === uncollapsedRow ? true : false;

  const displayContent = () => {
    if (collapseAllowed) {
      return (
        <React.Fragment>
          <TableRow
            sx={{
              "& > *": {
                borderBottom: "unset",
                fontSize: collapseAllowed ? "14px" : "11px!important",
              },
            }}
          >
            <TableCell style={{ width: colWidths.col1 }}>
              {collapseAllowed ? (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    if (open) {
                      setUncollapsedRow("");
                      setActiveRestaurants([]);
                    } else {
                      setUncollapsedRow(row.brand);
                      setActiveRestaurants(row.restaurantsList);
                    }
                  }}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              ) : (
                <div style={{ width: "55px" }} />
              )}
            </TableCell>
            <TableCell style={{ width: colWidths.col2 }}>{row.brand}</TableCell>
            {displayCell("restaurantsCount", 0, "")}
            {displayCell("rating", 2, "⭐")}
            {displayCell("reviewCount", 0, "")}
          </TableRow>
          <TableRow>
            <TableCell style={{ padding: 0, margin: 0 }} colSpan={6}>
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                style={{ padding: 0, margin: 0 }}
              >
                <Table aria-label="subrows" style={{ padding: 0, margin: 0 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: colWidths.col2 }}>
                        Restaurant
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col3 }}
                        align="center"
                      >
                        Rating
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Review count
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Est. daily Uber order in avg on last 90 days
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ padding: 0, margin: 0 }}>
                    {row.restaurantsList.map((subRow) => {
                      return (
                        <RadarCompetitorsExpansionRow
                          key={
                            "RadarCompetitorsExpansionRow_SUBROW_" + subRow.name
                          }
                          period={period}
                          onClick={(platformUUID) =>
                            handleActiveMarker(platformUUID)
                          }
                          colWidths={colWidths}
                          row={subRow}
                          collapseAllowed={false}
                          handleSparklineClick={handleSparklineClick}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {" "}
          <TableRow
            sx={{
              "& > *": {
                borderBottom: "unset",
                fontSize: collapseAllowed ? "14px" : "11px!important",
              },
            }}
            onClick={() => {
              onClick(row.platformUUID);
            }}
          >
            <TableCell style={{ width: colWidths.col2 }}>{row.name}</TableCell>
            {displayCell("rating", 2, "⭐")}
            {displayCell("reviewCount", 0, "")}
            {displayCell("orders", 0, "", 15)}
          </TableRow>
        </React.Fragment>
      );
    }
  };

  return <React.Fragment>{displayContent()}</React.Fragment>;
}

RadarCompetitorsExpansionRow.propTypes = {};

// ********************* COMPONENT END ***********************
// ********************* SUB ROW COMPONENT *******************

export default RadarCompetitorsExpansionRow;
