/*global google*/

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";

/*import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  Rectangle,
  DrawingManager,
} from "react-google-maps";*/
//import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";

//IMAGES AND ICONS

//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Card,
  Checkbox,
  Container,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  Paper,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  TableContainer,
  TableBody,
  TableHead,
  Table,
  Tabs,
  Tab,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import RadarCompetitorsExpansion from "./expansion/RadarCompetitorsExpansion";
import RadarLiveCompetitorsScrapping from "./live_scrapping/RadarLiveCompetitorsScrapping";
//import ModalPricingJob from "./ModalPricingJob";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },

}));

// ********************* COMPONENT START ***********************

function RadarCompetitorsPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Scrapping en cours...");
  const [tab, setTab] = React.useState(0);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside RadarCompetitorsPage");
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  console.log("reloading competitors page...");
  return (
    <div className={classes.div}>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <div>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Competitors expansion" {...a11yProps(0)} />
              <Tab label="Live scrapping" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            <RadarCompetitorsExpansion />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <RadarLiveCompetitorsScrapping />
          </TabPanel>
        </div>
      )}
    </div>
  );
}

RadarCompetitorsPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarCompetitorsPage;
