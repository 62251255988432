import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import RefreshIcon from "@mui/icons-material/Refresh";
//MATERIAL UI COMPONENT IMPORTS
import { visuallyHidden } from "@mui/utils";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Fab,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import SkeletonDashboardTable from "../../ui-components/Skeleton/SkeletonDashboardTable";
import RadarLeaderBoardRow from "./RadarLeaderBoardRow";
import FilterBar from "../generic/FilterBar";
import EnhancedTableHead from "../admin_restaurants/EnhancedTableHead";

//CONSTANTE
const colWidths = {
  col1: "10rem",
  col2: "8rem",
};
const LIMIT_ROWS_LEADERBOARD = 25;
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  overlay: {
    filter: "blur(3px)",
    //position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.54)",
    //color: "white",
    top: "0px",
    left: "0px",
    zIndex: 1000,
  },
  btnUpdate: {
    position: "fixed",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    top: "100px",
    zIndex: 1001,
  },
  hide: {
    display: "none!important",
  },
}));

// ********************* COMPONENT START ***********************

function RadarLeaderBoardPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState([]);
  const [needToRefresh, setNeedToRefresh] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************
  const init = () => {
    setIsLoading(true);
    setNeedToRefresh(false);
    POST("/api/radar/leaderboard/refresh", { filters })
      .then((response) => {
        setIsLoading(false);
        setData(response.data.data);
        setColumns(response.data.columns);
        setFilters(response.data.filters);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  const onFilterUpdate = (newFilters, filterIndex) => {
    setFilters(newFilters);
    setNeedToRefresh(true);
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    var compA = a[orderBy];
    var compB = b[orderBy];
    if (!isNaN(b[orderBy] & !isNaN(a[orderBy]))) {
      compA = parseFloat(compA);
      compB = parseFloat(compB);
    }
    if (compB < compA) {
      return -1;
    }
    if (compB > compA) {
      return 1;
    }
    return 0;
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      <Grid
        container
        justify="center"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 0, mb: 2 }}
        spacing={2}
      >
        <Grid item xs={12} key={`filter_bar_radar_dashboard_0`}>
          <FilterBar
            filters={filters}
            filterIndex={0}
            isLoading={isLoading}
            setFilters={onFilterUpdate}
            enableAddNewSerieButton={false}
          />
        </Grid>
      </Grid>
      <Fab
        onClick={(e) => {
          init([]);
        }}
        variant="extended"
        className={classNames(classes.btnUpdate, {
          [classes.hide]: !needToRefresh,
        })}
      >
        <RefreshIcon sx={{ mr: 1 }} />
        Mettre à jour
      </Fab>
      {isLoading ? (
        <SkeletonDashboardTable />
      ) : (
        <Box
          className={classNames({
            [classes.overlay]: needToRefresh,
          })}
        >
          <Paper>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: colWidths.col1 }} align="left">
                      <Tooltip title="Nom de la campagne">
                        <Typography variant="body">Restaurant</Typography>
                      </Tooltip>
                    </TableCell>
                    {columns.map((column, index) => {
                      if (column.hide) {
                        return null;
                      }
                      return (
                        <TableCell
                          key={"Header_leaderboard_" + column.id}
                          align={"center"}
                          padding={"normal"}
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : "asc"}
                            onClick={createSortHandler(column.id)}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <Box component="span" sx={visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </TableCell>
                      );
                      /*return (
                      <TableCell
                        key={"Header_leaderboard_" + column.id}
                        style={{ width: colWidths.col2 }}
                        align="center"
                      >
                        <Tooltip title={column.tooltip}>
                          <Typography variant="body">{column.label}</Typography>
                        </Tooltip>
                      </TableCell>
                    );*/
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <RadarLeaderBoardRow
                          key={"RadarLeaderBoardRow_" + row.channelId}
                          row={row}
                          ranking={index + 1 + page * rowsPerPage}
                          columns={columns}
                          suffix={
                            filters &&
                            filters.length > 0 &&
                            filters[0].selected &&
                            filters[0].selected.suffix
                              ? filters[0].selected.suffix
                              : ""
                          }
                          happy_when_increase={
                            filters &&
                            filters.length > 0 &&
                            filters[0].selected &&
                            filters[0].selected.happy_when_increase
                              ? filters[0].selected.happy_when_increase
                              : false
                          }
                          colWidths={colWidths}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 25, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Canaux par page"
            />
          </Paper>
        </Box>
      )}
    </div>
  );
}

RadarLeaderBoardPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarLeaderBoardPage;
