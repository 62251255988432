import React from 'react';
import { GoogleLogout } from 'react-google-login';
import {Cookies} from 'react-cookie'; 
import {GET,POST} from '../../js/HTTPRequest'
const clientId ='964627512386-tpjv4au37tq4eljh59fhpvg60po3mrqm.apps.googleusercontent.com';

function Logout(props) {
  const onSuccess = () => {
    console.log('Logout made successfully');
    //document.cookie = "x-auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;" 
    POST('/api/google/logout')
    .then((res)=>{
        const cookies = new Cookies();    
        var visa="deleted"
        cookies.set('x-auth',visa, {path: '/', maxAge: 360*7*24*60*60*1000 })   
        window.location.reload(false);
    })      
  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    const cookies = new Cookies();
    var visa="deleted"
    cookies.set('x-auth',visa, {path: '/', maxAge: 360*7*24*60*60*1000 })
    document.cookie = "x-auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    alert('This terminal is now logged out ✌');
  };

  return (
    <div>
      <GoogleLogout
        clientId={clientId}
        buttonText="DÉCONNEXION"
        onLogoutSuccess={onSuccess}
        onLogoutFailure={onFailure}
        className="btn-logout"
      ></GoogleLogout>
    </div>
  );
}

export default Logout;