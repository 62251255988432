import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ModalContinueOrCancel({
  title,
  content,
  handleContinue,
  handleCancel,
  modalId,
  labelContinue = "Continuer",
  labelCancel = "Annuler",
}) {
  const { dialogsOpen, setDialogsOpen } = useContext(UserContext);

  const handleClose = () => {
    //var newDialogsOpen = JSON.parse(JSON.stringify(dialogsOpen));
    //newDialogsOpen[modalId] = false;
    //setDialogsOpen(newDialogsOpen);

    setDialogsOpen({ ...dialogsOpen, [modalId]: false });
  };

  return (
    <Dialog
      open={dialogsOpen[modalId]}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            handleCancel();
          }}
        >
          {labelCancel}
        </Button>
        <Button
          onClick={() => {
            handleClose();
            handleContinue();
          }}
          autoFocus
        >
          {labelContinue}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
