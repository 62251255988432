export const unFormatActions = (actions, setError) => {
  switch (actions.version) {
    // ---------------------------- VERSION 0.1 ------------------------------
    case "0.1":
      var unformattedActions = [
        //------------------------- order_unknown ----------------------------
        {
          id: "order_unknown|rating_unkown",
          label:
            "Un client vous a écrit sans vous mettre de note et vous ne savez pas combien de fois il a commandé chez vous.",
          answer: actions.answers.order_unknown.rating_unkown[0].answer
            ? actions.answers.order_unknown.rating_unkown[0].answer
            : "",
          offer: actions.answers.order_unknown.rating_unkown[0].offer
            ? actions.answers.order_unknown.rating_unkown[0].offer
            : 0,
        },
        {
          id: "order_unknown|rating_1",
          label:
            "Un client vous a mis une note de 1⭐ et vous ne savez pas combien de fois il a commandé chez vous.",
          answer: actions.answers.order_unknown.rating_1[0].answer
            ? actions.answers.order_unknown.rating_1[0].answer
            : "",
          offer: actions.answers.order_unknown.rating_1[0].offer
            ? actions.answers.order_unknown.rating_1[0].offer
            : 0,
        },
        {
          id: "order_unknown|rating_2",
          label:
            "Un client vous a mis une note de 2⭐ et vous ne savez pas combien de fois il a commandé chez vous.",
          answer: actions.answers.order_unknown.rating_2[0].answer
            ? actions.answers.order_unknown.rating_2[0].answer
            : "",
          offer: actions.answers.order_unknown.rating_2[0].offer
            ? actions.answers.order_unknown.rating_2[0].offer
            : 0,
        },
        {
          id: "order_unknown|rating_3",
          label:
            "Un client vous a mis une note de 3⭐ et vous ne savez pas combien de fois il a commandé chez vous.",
          answer: actions.answers.order_unknown.rating_3[0].answer
            ? actions.answers.order_unknown.rating_3[0].answer
            : "",
          offer: actions.answers.order_unknown.rating_3[0].offer
            ? actions.answers.order_unknown.rating_3[0].offer
            : 0,
        },
        {
          id: "order_unknown|rating_4",
          label:
            "Un client vous a mis une note de 4⭐ et vous ne savez pas combien de fois il a commandé chez vous.",
          answer: actions.answers.order_unknown.rating_4[0].answer
            ? actions.answers.order_unknown.rating_4[0].answer
            : "",
          offer: actions.answers.order_unknown.rating_4[0].offer
            ? actions.answers.order_unknown.rating_4[0].offer
            : 0,
        },
        {
          id: "order_unknown|rating_5",
          label:
            "Un client vous a mis une note de 5⭐ et vous ne savez pas combien de fois il a commandé chez vous.",
          answer: actions.answers.order_unknown.rating_5[0].answer
            ? actions.answers.order_unknown.rating_5[0].answer
            : "",
          offer: actions.answers.order_unknown.rating_5[0].offer
            ? actions.answers.order_unknown.rating_5[0].offer
            : 0,
        },
        //------------------- order_less_or_equal_to_x ---------------------
        {
          id: "order_less_or_equal_to_x|rating_unkown",
          label: `Un client vous a écrit sans vous mettre de note et il a commandé chez vous ${actions.x_parameter} fois au plus.`,
          answer: actions.answers.order_less_or_equal_to_x.rating_unkown[0]
            .answer
            ? actions.answers.order_less_or_equal_to_x.rating_unkown[0].answer
            : "",
          offer: actions.answers.order_less_or_equal_to_x.rating_unkown[0].offer
            ? actions.answers.order_less_or_equal_to_x.rating_unkown[0].offer
            : 0,
        },
        {
          id: "order_less_or_equal_to_x|rating_1",
          label: `Un client vous a mis une note de 1⭐ et il a commandé chez vous ${actions.x_parameter} fois au plus.`,
          answer: actions.answers.order_less_or_equal_to_x.rating_1[0].answer
            ? actions.answers.order_less_or_equal_to_x.rating_1[0].answer
            : "",
          offer: actions.answers.order_less_or_equal_to_x.rating_1[0].offer
            ? actions.answers.order_less_or_equal_to_x.rating_1[0].offer
            : 0,
        },
        {
          id: "order_less_or_equal_to_x|rating_2",
          label: `Un client vous a mis une note de 2⭐ et il a commandé chez vous ${actions.x_parameter} fois au plus.`,
          answer: actions.answers.order_less_or_equal_to_x.rating_2[0].answer
            ? actions.answers.order_less_or_equal_to_x.rating_2[0].answer
            : "",
          offer: actions.answers.order_less_or_equal_to_x.rating_2[0].offer
            ? actions.answers.order_less_or_equal_to_x.rating_2[0].offer
            : 0,
        },
        {
          id: "order_less_or_equal_to_x|rating_3",
          label: `Un client vous a mis une note de 3⭐ et il a commandé chez vous ${actions.x_parameter} fois au plus.`,
          answer: actions.answers.order_less_or_equal_to_x.rating_3[0].answer
            ? actions.answers.order_less_or_equal_to_x.rating_3[0].answer
            : "",
          offer: actions.answers.order_less_or_equal_to_x.rating_3[0].offer
            ? actions.answers.order_less_or_equal_to_x.rating_3[0].offer
            : 0,
        },
        {
          id: "order_less_or_equal_to_x|rating_4",
          label: `Un client vous a mis une note de 4⭐ et il a commandé chez vous ${actions.x_parameter} fois au plus.`,
          answer: actions.answers.order_less_or_equal_to_x.rating_4[0].answer
            ? actions.answers.order_less_or_equal_to_x.rating_4[0].answer
            : "",
          offer: actions.answers.order_less_or_equal_to_x.rating_4[0].offer
            ? actions.answers.order_less_or_equal_to_x.rating_4[0].offer
            : 0,
        },
        {
          id: "order_less_or_equal_to_x|rating_5",
          label: `Un client vous a mis une note de 5⭐ et il a commandé chez vous ${actions.x_parameter} fois au plus.`,
          answer: actions.answers.order_less_or_equal_to_x.rating_5[0].answer
            ? actions.answers.order_less_or_equal_to_x.rating_5[0].answer
            : "",
          offer: actions.answers.order_less_or_equal_to_x.rating_5[0].offer
            ? actions.answers.order_less_or_equal_to_x.rating_5[0].offer
            : 0,
        },
        //------------------------- order_sup_to_x_and_less_or_equal_to_y ----------------------------
        {
          id: "order_sup_to_x_and_less_or_equal_to_y|rating_unkown",
          label: `Un client vous a écrit sans vous mettre de note et il a commandé chez vous entre ${
            actions.x_parameter + 1
          } et ${actions.y_parameter} compris.`,
          answer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_unkown[0].answer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y
                .rating_unkown[0].answer
            : "",
          offer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_unkown[0].offer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y
                .rating_unkown[0].offer
            : 0,
        },
        {
          id: "order_sup_to_x_and_less_or_equal_to_y|rating_1",
          label: `Un client vous a mis une note de 1⭐ et il a commandé chez vous entre ${
            actions.x_parameter + 1
          } et ${actions.y_parameter} compris.`,
          answer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_1[0].answer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_1[0]
                .answer
            : "",
          offer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_1[0].offer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_1[0]
                .offer
            : 0,
        },
        {
          id: "order_sup_to_x_and_less_or_equal_to_y|rating_2",
          label: `Un client vous a mis une note de 2⭐ et il a commandé chez vous entre ${
            actions.x_parameter + 1
          } et ${actions.y_parameter} compris.`,
          answer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_2[0].answer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_2[0]
                .answer
            : "",
          offer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_2[0].offer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_2[0]
                .offer
            : 0,
        },
        {
          id: "order_sup_to_x_and_less_or_equal_to_y|rating_3",
          label: `Un client vous a mis une note de 3⭐ et il a commandé chez vous entre ${
            actions.x_parameter + 1
          } et ${actions.y_parameter} compris.`,
          answer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_3[0].answer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_3[0]
                .answer
            : "",
          offer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_3[0].offer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_3[0]
                .offer
            : 0,
        },
        {
          id: "order_sup_to_x_and_less_or_equal_to_y|rating_4",
          label: `Un client vous a mis une note de 4⭐ et il a commandé chez vous entre ${
            actions.x_parameter + 1
          } et ${actions.y_parameter} compris.`,
          answer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_4[0].answer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_4[0]
                .answer
            : "",
          offer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_4[0].offer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_4[0]
                .offer
            : 0,
        },
        {
          id: "order_sup_to_x_and_less_or_equal_to_y|rating_5",
          label: `Un client vous a mis une note de 5⭐ et il a commandé chez vous entre ${
            actions.x_parameter + 1
          } et ${actions.y_parameter} compris.`,
          answer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_5[0].answer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_5[0]
                .answer
            : "",
          offer: actions.answers.order_sup_to_x_and_less_or_equal_to_y
            .rating_5[0].offer
            ? actions.answers.order_sup_to_x_and_less_or_equal_to_y.rating_5[0]
                .offer
            : 0,
        },
        //------------------------------------- order_sup_to_y ---------------------------------
        {
          id: "order_sup_to_y|rating_unkown",
          label: `Un client vous a écrit sans vous mettre de note et il a commandé chez vous plus de ${actions.y_parameter} fois.`,
          answer: actions.answers.order_sup_to_y.rating_unkown[0].answer
            ? actions.answers.order_sup_to_y.rating_unkown[0].answer
            : "",
          offer: actions.answers.order_sup_to_y.rating_unkown[0].offer
            ? actions.answers.order_sup_to_y.rating_unkown[0].offer
            : 0,
        },
        {
          id: "order_sup_to_y|rating_1",
          label: `Un client vous a mis une note de 1⭐ et il a commandé chez vous plus de ${actions.y_parameter} fois.`,
          answer: actions.answers.order_sup_to_y.rating_1[0].answer
            ? actions.answers.order_sup_to_y.rating_1[0].answer
            : "",
          offer: actions.answers.order_sup_to_y.rating_1[0].offer
            ? actions.answers.order_sup_to_y.rating_1[0].offer
            : 0,
        },
        {
          id: "order_sup_to_y|rating_2",
          label: `Un client vous a mis une note de 2⭐ et il a commandé chez vous plus de ${actions.y_parameter} fois.`,
          answer: actions.answers.order_sup_to_y.rating_2[0].answer
            ? actions.answers.order_sup_to_y.rating_2[0].answer
            : "",
          offer: actions.answers.order_sup_to_y.rating_2[0].offer
            ? actions.answers.order_sup_to_y.rating_2[0].offer
            : 0,
        },
        {
          id: "order_sup_to_y|rating_3",
          label: `Un client vous a mis une note de 3⭐ et il a commandé chez vous plus de ${actions.y_parameter} fois.`,
          answer: actions.answers.order_sup_to_y.rating_3[0].answer
            ? actions.answers.order_sup_to_y.rating_3[0].answer
            : "",
          offer: actions.answers.order_sup_to_y.rating_3[0].offer
            ? actions.answers.order_sup_to_y.rating_3[0].offer
            : 0,
        },
        {
          id: "order_sup_to_y|rating_4",
          label: `Un client vous a mis une note de 4⭐ et il a commandé chez vous plus de ${actions.y_parameter} fois.`,
          answer: actions.answers.order_sup_to_y.rating_4[0].answer
            ? actions.answers.order_sup_to_y.rating_4[0].answer
            : "",
          offer: actions.answers.order_sup_to_y.rating_4[0].offer
            ? actions.answers.order_sup_to_y.rating_4[0].offer
            : 0,
        },
        {
          id: "order_sup_to_y|rating_5",
          label: `Un client vous a mis une note de 5⭐ et il a commandé chez vous plus de ${actions.y_parameter} fois.`,
          answer: actions.answers.order_sup_to_y.rating_5[0].answer
            ? actions.answers.order_sup_to_y.rating_5[0].answer
            : "",
          offer: actions.answers.order_sup_to_y.rating_5[0].offer
            ? actions.answers.order_sup_to_y.rating_5[0].offer
            : 0,
        },
      ];
      return { version: actions.version, actions: unformattedActions };
      break;
    default:
      setError(
        "Cette campagne a été créée sur une ancienne version de la structure de réponse (v" +
          actions.version +
          "). Et ne peut donc plus être utilisée. Pouvez-vous svp la supprimer et la reconstruire ? Merci de votre compréhension."
      );
      return { version: actions.version, actions: [] };
      break;
  }
};

//Unformat actions from database object structure to uniformalize frontendtreatment accross object structure versionning
export const formatActions = (backupSaving, unformattedActions, setError) => {
  console.log("formatActions fired", unformattedActions);
  var newVersion = JSON.parse(JSON.stringify(backupSaving));
  console.log("backupSaving.version", backupSaving.version);
  try {
    switch (backupSaving.version) {
      // ---------------------------- VERSION 0.1 ------------------------------
      case "0.1":
        unformattedActions.map((unformattedAction) => {
          console.log("unformattedAction", unformattedAction);
          if (unformattedAction.unsaved) {
            //To save time
            var path = unformattedAction.id.split("|");
            console.log("path", path);
            newVersion.answers[path[0]][path[1]] = [
              {
                answer: unformattedAction.answer,
                offer: unformattedAction.offer,
              },
            ];
          }
        });
        return newVersion;
        break;
      default:
        setError(
          "Cette campagne a été créée sur une ancienne version de la structure de réponse (v" +
            backupSaving.version +
            "). Et ne peut donc plus être utilisée. Pouvez-vous svp la supprimer et la reconstruire ? Merci de votre compréhension."
        );
        return newVersion;
        break;
    }
  } catch (err) {
    console.log("err", err);
  }
};
