import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import Logo_UberEats from "../../img/logo-cercle-ubereats.png";
import Logo_Deliveroo from "../../img/logo-cercle-deliveroo.png";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TablePagination,
  Tooltip,
  TableContainer,
  TableBody,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SkeletonMagnetRestaurantTable from "../../ui-components/Skeleton/SkeletonMagnetRestaurantTable";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// HEAD CELLS OF THE TABLE
//CONSTANTES
const colWidths = {
  col1: "20rem",
  col2: "10rem",
  col3: "10rem",
  col4: "50rem",
  col5: "10rem",
};

// ********************* COMPONENT START ***********************

function MagnetRestaurantPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [introductionSentence, setIntroductionSentence] = useState("");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log("Frontend Info - I am inside MagnetRestaurantPage");
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleMagnetOptin = (channelId, newValue, channelName) => {
    setIsLoading(true);
    POST("/api/macrocampaigns/activate", {
      channelId,
      newValue,
      channelName,
    })
      .then((response) => {
        setIsLoading(false);
        displayNotification(
          "success",
          "Fonctionnalité " + newValue ? "activée" : "désactivée",
          "top",
          "center"
        );

        init();
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant la mise à jour de la fonctionnalité",
          "top",
          "center"
        );
      });
  };

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    setIsLoading(true);
    GET("/api/pages/magnetrestaurantpage/init")
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
        if (response.data && response.data.length > 0) {
          var reviews = 0;
          var answers = 0;
          var coupons = 0;
          response.data.map((row) => {
            reviews = reviews + parseInt(row.reviews);
            answers = answers + parseInt(row.reviews_answered);
            coupons = coupons + parseInt(row.reviews_answered_with_coupon);
          });
          setIntroductionSentence(
            `Sur les 30 derniers jours, vous avez reçus ${reviews} avis sur vos restaurants. Vous avez répondu à ${answers} d'entre eux et offert ${coupons} coupons de réduction.`
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <div className={classes.div}>
      {isLoading ? (
        <SkeletonMagnetRestaurantTable />
      ) : (
        <div>
          <h4>{introductionSentence}</h4>
          <p style={{ color: "red" }}>
            Important : depuis le 14 novembre. Jarvis ne répond plus aux avis
            inférieur à 4 ⭐ afin que ces derniers soient traités par les
            équipes du restaurant. En moyenne chez Napaqaro 80% des avis sont
            supérieurs 4⭐, Jarvis continuera donc de faire le gros du travail
            de réponse et peut permettre aux équipes de se focaliser sur les
            mauvais avis qu'il est nécessaire de lire.
          </p>
          <Box className={classes.boxWrapper}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: colWidths.col1 }}>
                        Nom
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col3 }}
                        align="center"
                      >
                        Plateforme
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Avis reçus sur les 30 derniers jours
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Réponses envoyées
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Réponses envoyées avec coupon
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Réponse automatique aux avis >4 ⭐ active
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* This method is created for cross-browser compatibility (IE11) */}
                    {stableSort(data, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow>
                            <TableCell component="th" id={labelId} scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell
                              style={{ width: colWidths.col3 }}
                              align="center"
                            >
                              <img
                                style={{ width: 30, height: 30 }}
                                alt={row.platform}
                                src={
                                  row.platform === "Uber Eats" //DIRTY : MOVE IMAGE URL INTO DATABASE
                                    ? Logo_UberEats
                                    : Logo_Deliveroo
                                }
                              />
                            </TableCell>
                            <TableCell align="center">{row.reviews}</TableCell>
                            <TableCell align="center">
                              {row.reviews_answered}
                            </TableCell>
                            <TableCell align="center">
                              {row.reviews_answered_with_coupon}
                            </TableCell>
                            <TableCell
                              style={{ width: colWidths.col4 }}
                              align="center"
                            >
                              <Switch
                                checked={row.is_magnet_active === "TRUE"}
                                onChange={(e) => {
                                  toggleMagnetOptin(
                                    row.channelId,
                                    !(row.is_magnet_active === "TRUE"),
                                    row.name
                                  );
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Canaux par page"
              />
            </Paper>
          </Box>
        </div>
      )}
    </div>
  );
}

MagnetRestaurantPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default MagnetRestaurantPage;
