import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import classNames from "classnames";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
import _ from "lodash";
import moment from "moment";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Fab,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  MenuItem,
  Table,
  Tooltip,
  Typography,
  Toolbar,
  TextField,
  useMediaQuery,
} from "@mui/material";
//INTERNAL IMPORT
import DashboardsCards from "../generic/DashboardsCards";
import ClassicChart from "../generic/ClassicChart";
import FilterBar from "../generic/FilterBar";
import { numberWithSpaces } from "../../js/numberManager";
//import Dashboards_FiltersBar from "../../components/data_dashboards/Dashboards_FiltersBar";
//ICONS

import HealthCards from "../generic/HealthCards";
import SkeletonFilterBar from "../../ui-components/Skeleton/SkeletonFilterBar";

// *******************************************************
// ************************ STYLE ************************
// *******************************************************
const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
  overlay: {
    filter: "blur(3px)",
    //position: "absolute",
    height: "100%",
    width: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.54)",
    //color: "white",
    top: "0px",
    left: "0px",
    zIndex: 1000,
  },
  btnUpdate: {
    position: "fixed",
    left: "50%",
    /* bring your own prefixes */
    transform: "translate(-50%, -50%)",
    top: "100px",
    zIndex: 1001,
  },
  hide: {
    display: "none!important",
  },
}));

//yFilters (array of arrays of filter)
//chartParameters ex: {x:'day'}

function RadarHealthPage({}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // *********************** USE STATES ********************
  // *******************************************************

  const [isLoading, setIsLoading] = useState(true);
  //const [currentChart, setCurrentChart] = useState(null);
  //const [charts, setCharts] = useState([{}, {}, {}, {}]);
  //const [chartParameters, setChartParameters] = useState(null);

  const [data, setData] = useState({
    cards: [{}, {}, {}, {}, {}, {}],
    message: [],
  });
  const [channels, setChannels] = useState([]);
  /*const [data, setData] = useState(() => {
    return [];
  });*/
  //const [x, setX] = useState([]);
  //const [y, setY] = useState([{ name: "init", data: [], type: "line" }]);
  //const [totals, setTotals] = useState([]);
  const [needToRefresh, setNeedToRefresh] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState(null);

  // *******************************************************
  // *********************** USE EFFECT ********************
  // *******************************************************

  useEffect(() => {
    refreshData(null);
  }, []); //Trigger only once

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS ******************
  // *******************************************************

  const refreshData = (tmpSelectedChannelId) => {
    setIsLoading(true);
    POST("/api/radar/health/refresh", {
      selectedChannelId: tmpSelectedChannelId,
      channels,
    }).then((response) => {
      if (response.data.data && response.data.data.cards) {
        setData(response.data.data);
        setChannels(response.data.channels);
        setSelectedChannelId(response.data.selectedChannelId);
      }
      setIsLoading(false);
    });
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  var cardMdWidth = 4;
  return (
    <React.Fragment>
      <Grid
        container
        justify="center"
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 0, mb: 2 }}
        spacing={2}
      >
        <Grid item xs={12}>
          {isLoading ? (
            <SkeletonFilterBar sx={{ ml: 2 }} />
          ) : channels && channels.length > 0 ? (
            <Select
              id="sel_channel"
              value={selectedChannelId}
              label="Restaurant"
              sx={{ ml: 1 }}
              onChange={(event) => {
                refreshData(event.target.value);
              }}
            >
              {channels.map((channel) => {
                return <MenuItem value={channel.id}>{channel.name}</MenuItem>;
              })}
            </Select>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Grid>
      </Grid>
      <Box
        className={classNames({
          [classes.overlay]: needToRefresh,
        })}
      >
        <Grid
          container
          sx={{ width: "100%" }}
          justify="center"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            container
            spacing={2}
            sx={{ width: "100%" }}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            {data.cards.map((card, indexCard) => {
              return (
                <Grid item xs={12} sm={6} md={cardMdWidth} key={card.key}>
                  <HealthCards
                    isLoading={isLoading}
                    refresh={refreshData}
                    primaryBGColor={theme.palette.chart[indexCard]}
                    primaryFontColor="white"
                    subtitle={card.subtitle}
                    metric={card.metric}
                    suffix={card.suffix}
                    value={card.value}
                    icon={card.icon}
                    //trend={calculateTrend(serie, chart.metric)}
                    //onChangeChart={onChangeChart}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 0 }}
            spacing={2}
          >
            <Grid item xs={12}>
              {data.message && data.message.length > 0
                ? "Ce que Jarvis remarque sur votre restaurant : "
                : ""}
              <ul>
                {data.message.map((row) => {
                  return <li>{row}</li>;
                })}
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

/*DashboardsPage.propTypes = {

}*/

export default RadarHealthPage;
