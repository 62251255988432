import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../../App";
import { useTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../../js/HTTPRequest";
import classNames from "classnames";
//IMAGES AND ICONS
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  CardContent,
  CardMedia,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

//CONST AND INTERNAL IMPORT

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "secondary !important",
  },
  activeCard: {
    backgroundColor:
      alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity) +
      " !important",
  },
  disabledCard: {
    backgroundColor: theme.palette.action.disabledBackground + " !important",
    color: theme.palette.action.disabled + " !important",
  },
}));

// ********************* COMPONENT START ***********************

function ModalNewOrEditJetpackCampaignStepActionsType({
  actionType,
  setActionType,  
}) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [state, setState] = useState(null);
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    console.log(
      "Frontend Info - I am inside ModalNewOrEditJetpackCampaignStepActionsType"
    );
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Grid container spacing={2}>
      {constants.jetpack_actionTypes.map((type) => {
        return (
          <Grid item key={type.id} sx={{ width: "100%" }}>
            <Card
              className={classNames(classes.card, {
                [classes.activeCard]: type.id === actionType,
                [classes.disabledCard]: type.disabled,
              })}
              sx={{ display: "flex" }}
              onClick={() => {
                if (!type.disabled) {
                  setActionType(type.id);
                }
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h6">
                    {type.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    component="div"
                  >
                    {type.description}
                  </Typography>
                </CardContent>
              </Box>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

ModalNewOrEditJetpackCampaignStepActionsType.propTypes = {
  actionType: PropTypes.string.isRequired,
  setActionType: PropTypes.func.isRequired,
};

// ********************* COMPONENT END ***********************

export default ModalNewOrEditJetpackCampaignStepActionsType;
