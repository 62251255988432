import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../App";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
//MATERIAL UI COMPONENT IMPORTS
import {
  Autocomplete,
  Box,
  Button,
  TableContainer,
  TableBody,
  TableHead,
  ButtonGroup,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Icons,
  List,
  RadioButton,
  Select,
  Slider,
  Snackbar,
  Switch,
  Table,
  TablePagination,
  Tooltip,
  Toolbar,
  Typography,
  Paper,
  TableCell,
  TableRow,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import RadarCutomersRow from "./RadarCutomersRow";
import SkeletonUsersManagementTable from "../../ui-components/Skeleton/SkeletonUsersManagementTable";
import ModalCustomerDetails from "./ModalCustomerDetails";
//CONSTANTES
const colWidths = {
  col1: "10rem",
  col2: "10rem",
  col3: "10rem",
  col4: "10rem",
  col5: "10rem",
  col6: "10rem",
  col7: "10rem",
};
// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  div: {
    display: "block",
  },
}));

// ********************* COMPONENT START ***********************

function RadarCustomersPage(props) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [customers, setCustomers] = useState([]);
  const [customersUnfiltered, setCustomersUnfiltered] = useState([]); //FILTERED
  const [editedCustomer, setEditedCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************
  useEffect(() => {
    init();
  }, []);
  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************
  const editCustomer = (userItem) => {
    setEditedCustomer(userItem);
    setDialogsOpen({ ...dialogsOpen, ModalCustomerDetails: true });
  };

  const updateFilterSearch = (newSearch) => {
    var newUsers = filterCustomers(customersUnfiltered, newSearch);
    setSearch(newSearch);
    setCustomers(newUsers);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // *******************************************************
  // ******************** LOCAL FUNCTIONS *******************
  // *******************************************************

  const init = () => {
    setIsLoading(true);
    GET("/api/radar/customers/refresh")
      .then((response) => {
        setIsLoading(false);
        var newCustomers = filterCustomers(response.data.customers, search);
        //displayNotification("success", "Chargement réussi", "top", "center");
        setCustomers(newCustomers);
        setCustomersUnfiltered(response.data.customers);
        setEditedCustomer(null);
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Erreur durant le chargement de la page",
          "top",
          "center"
        );
      });
  };

  const filterCustomers = (localCustomersUnfiltered, localSearch) => {
    var localCustomersFiltered = [];
    localCustomersUnfiltered.map((customer) => {
      if (
        customer.name &&
        customer.name.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
      ) {
        localCustomersFiltered.push(customer);
      } else if (
        customer.email &&
        customer.email.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
      ) {
        localCustomersFiltered.push(customer);
      } else if (
        customer.fullName &&
        customer.fullName.toLowerCase().indexOf(localSearch.toLowerCase()) >= 0
      ) {
        localCustomersFiltered.push(customer);
      }
    });
    return localCustomersFiltered;
  };

  /* This method is created for cross-browser compatibility (IE11) */
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <div className={classes.div}>
      {isLoading ? (
        <SkeletonUsersManagementTable />
      ) : (
        <React.Fragment>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <Toolbar
              sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
              }}
            >
              <Tooltip title="Rechercher">
                <TextField
                  className={classes.inputF}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="search"
                  label={`Rechercher un client`}
                  name="search"
                  autoComplete="search"
                  value={search}
                  style={{ height: "80px", paddingTop: "9px" }}
                  onChange={(e) => {
                    updateFilterSearch(e.target.value);
                  }}
                  autoFocus
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            </Toolbar>
            {customers.length < 1 ? (
              <div></div>
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: colWidths.col1 }}>
                        Nom
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col2 }}
                        align="center"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col3 }}
                        align="center"
                      >
                        Commandes
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col4 }}
                        align="center"
                      >
                        Dépensé (inclus promo.)
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col5 }}
                        align="center"
                      >
                        Promotions
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col6 }}
                        align="center"
                      >
                        Dépensé net promo
                      </TableCell>
                      <TableCell
                        style={{ width: colWidths.col7 }}
                        align="center"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      //stableSort(customers, getComparator(order, orderBy))
                      customers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <RadarCutomersRow
                              key={row.id}
                              customerItem={row}
                              editCustomer={editCustomer}
                              colWidths={colWidths}
                            />
                          );
                        })
                    }
                    {/*customers.map((userItem) => {
                      return (
                        <AdminUsersRow
                          key={userItem.id}
                          userItem={userItem}
                          editCustomer={editCustomer}
                          deleteUser={openDeleteUserModal}
                          colWidths={colWidths}
                          addChannels={addChannels}
                          deleteChannelAssociation={deleteChannelAssociation}
                        />
                      );
                    })*/}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 25, 50, 100]}
              component="div"
              count={customers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Canaux par page"
            />
          </Paper>
        </React.Fragment>
      )}

      {/* ----- MODALS ------- */}
      <ModalCustomerDetails customer={editedCustomer}/>
    </div>
  );
}

RadarCustomersPage.propTypes = {};

// ********************* COMPONENT END ***********************

export default RadarCustomersPage;
