import React, { useState, useEffect, useContext } from "react";
//import PropTypes from "prop-types";
import { UserContext } from "../../App";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { GET, POST, POSTLONG } from "../../js/HTTPRequest";
//IMAGES AND ICONS
import LinkIcon from "@mui/icons-material/Link";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
//MATERIAL UI COMPONENT IMPORTS
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
//CONST
const uber_ads_manager_id = "uber_ads_manager_id";

// *******************************************************
// ******************** LOCAL DESIGN *********************
// *******************************************************

const useStyles = makeStyles((theme) => ({
  error: {
    marginTop: "20px",
    color: "red",
    fontSize: "11px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//This component is a modal allowing creation (or modification ?) of dahsboard integration
function ModalGetDateForOrdersHistory({ initPage, platformLoginId }) {
  // *******************************************************
  // ************************ DESIGN ***********************
  // *******************************************************
  const theme = useTheme();
  const classes = useStyles(theme);
  // *******************************************************
  // ******************** STATE & CONTEXT ******************
  // *******************************************************
  const { user, displayNotification, dialogsOpen, setDialogsOpen, constants } =
    useContext(UserContext);
  const [dateLimit, setDateLimit] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Connexion en cours...");

  // *******************************************************
  // ********************** USE EFFECT *********************
  // *******************************************************

  // *******************************************************
  // ************************ CALLBACK *********************
  // *******************************************************

  const handleClose = () => {
    setError("");
    setIsLoading(false);
    setDateLimit(null);
    setDialogsOpen({ ...dialogsOpen, ModalGetDateForOrdersHistory: false });
  };

  const handleSubmit = (e) => {
    console.log("platformLoginId", platformLoginId);
    if (!platformLoginId || platformLoginId === "") {
      return setError("Aucun platform Login sélectionné");
    }
    if (!dateLimit || dateLimit === "") {
      return setError("Veuillez indiquer une date");
    }
    setError("");
    setIsLoading(true);
    synchOrdersHistory(null);
  };

  // *******************************************************
  // ********************* LOCAL FUNCTION ******************
  // *******************************************************

  const synchOrdersHistory = (lastRecordedOrderTimestamp = null) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setLoadingMessage("Récupération de l'historique en cours...");
    POSTLONG(
      `/api/platformlogins/${platformLoginId}/ordershistory/synch`,
      { lastRecordedOrderTimestamp },
      true,
      null,
      120000
    )
      .then((result) => {
        var { finished, message, nextURL, lastRecordedOrderTimestamp } =
          result.data;
        console.log("lastRecordedOrderTimestamp", lastRecordedOrderTimestamp);
        console.log(
          "new Date(dateLimit) >= new Date(lastRecordedOrderTimestamp)",
          new Date(dateLimit) >= new Date(lastRecordedOrderTimestamp)
        );
        if (
          finished ||
          new Date(dateLimit) >= new Date(lastRecordedOrderTimestamp)
        ) {
          handleClose();
          initPage();
          displayNotification(
            "success",
            "L'historique est maintenant récupéré 👌",
            "top",
            "right"
          );
        } else {
          synchOrdersHistory_iterative(
            finished,
            message,
            nextURL,
            lastRecordedOrderTimestamp
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
        displayNotification(
          "error",
          "Une erreur est survenue pendant la synchronisation de l'historique de commandes",
          "top",
          "center"
        );
      });
  };

  const synchOrdersHistory_iterative = (
    finished,
    message,
    nextURL,
    lastRecordedOrderTimestamp
  ) => {
    console.log("lastRecordedOrderTimestamp", lastRecordedOrderTimestamp);
    if (
      finished ||
      new Date(dateLimit) >= new Date(lastRecordedOrderTimestamp)
    ) {
      handleClose();
      initPage();
      displayNotification(
        "success",
        "L'historique est maintenant récupéré 👌",
        "top",
        "right"
      );
    } else {
      setLoadingMessage(message);
      POSTLONG(nextURL, { lastRecordedOrderTimestamp }, true, null, 120000)
        .then((response) => {
          synchOrdersHistory_iterative(
            response.data.finished,
            response.data.message,
            response.data.nextURL,
            response.data.lastRecordedOrderTimestamp
          );
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  };

  // *******************************************************
  // ************************ RENDER ***********************
  // *******************************************************
  return (
    <Dialog
      open={dialogsOpen.ModalGetDateForOrdersHistory}
      onClose={handleClose}
    >
      <DialogTitle>Aspiration de l'historique de commande</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              <DialogContentText>{loadingMessage}</DialogContentText>
            </Grid>
            <Grid
              item
              justify="center"
              alignItems="center"
              justifyContent="center"
            >
              {" "}
              <CircularProgress color="primary" />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogContentText>
            Indiquez jusqu'à quelle date vous souhaitez aspirer les commandes de
            ce restaurant Manager.
          </DialogContentText>
          <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Jusqu'où aspirer vos commandes"
                value={dateLimit}
                onChange={(newDate) => {
                  setDateLimit(newDate);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <Typography component="div" className={classes.error}>
              <Box className={classes.error}>{error}</Box>
            </Typography>
          </FormControl>
        </DialogContent>
      )}
      <DialogActions>
        {isLoading ? (
          <div></div>
        ) : (
          <React.Fragment>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              onClick={() => handleSubmit()}
              variant="contained"
              color="primary"
              startIcon={<SearchIcon />}
            >
              Rechercher
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

ModalGetDateForOrdersHistory.propTypes = {
  platformLoginId: PropTypes.string.isRequired,
};

export default ModalGetDateForOrdersHistory;
